import _ from 'lodash'
import { API_GATEWAY_URI, DEV_MODE } from '../constants'


export async function trackComplete(user, moduleid, completionPercentage, comprehension) {
  if (DEV_MODE) return

  const requestUri = `${API_GATEWAY_URI}track-complete?user=${user.username}&module-id=${moduleid}&perc=${completionPercentage}&comprehension=${comprehension}`
  await fetch(encodeURI(requestUri), {
    method: 'POST',
    mode: 'cors',
  })
}

// apigateway return looks like
// [{ "Completions: { "L": [{ "N": "<completionTime>" }, { "N": "<percentage>" }] }, ... }, ...]
// For convenience, change this to map: moduleId => { date, completionPercentage }
export async function getTrackComplete(user) {
  const requestUri = `${API_GATEWAY_URI}track-complete?user=${user.username}`
  const response = await fetch(requestUri, {
    mode: 'cors',
  })
  const json = await response.json()
  if (!json.Items) return []

  return _.reduce(json.Items, (acc, completionDat) => {
    const { Completions, moduleid, comprehension } = completionDat
    if (_.isEmpty(Completions.L)) return acc // should never happen?
    const completion = _.last(Completions.L).L
    return _.extend(acc, {
      [moduleid.S]: {
        date: Number(completion[0].N),
        completionPercentage: Number(completion[1].N),
        comprehension: comprehension ? comprehension.S : undefined,
      },
    })
  }, {})
}
