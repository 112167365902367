import React, { useState, useEffect, useMemo } from 'react'
import _ from 'lodash'
import {
  Box, Typography, makeStyles, Paper, Popover,
} from '@material-ui/core'
import dayjs from 'dayjs'
import isToday from 'dayjs/plugin/isToday'
import { values } from 'idb-keyval'
import { useSentencesContext, useGlobalStateContext } from '../../contexts'
import { getSentencesByInterval, WORD_INTERACTIONS } from '../../lib/util'
import { getTrackComplete } from '../../lib/apigateway/trackcomplete'
import { PHRASES_BY_LANG, DEFAULT_REVIEW_CHUNK_LENGTH, ALL_SENTENCES } from '../../lib/constants'
import CompletePageComponent from './CompletePageComponent'
import ReviewCardComponent from './ReviewCardComponent'
import ModuleCard from '../../components/ModuleCard'

dayjs.extend(isToday)

const getComprehension = (comprehensionMap) => {
  const results = {}

  _.forEach(comprehensionMap, (obj, key) => {
    const VALID_YT_ID_LENGTH = 19
    if (key === 'review' || key.length !== VALID_YT_ID_LENGTH) {
      return
    }

    let comprehension = decodeURIComponent(obj.comprehension)
    if (comprehension === 'undefined' || !comprehension) {
      results[key] = { comprehension: 0 }
      return
    }

    comprehension = JSON.parse(comprehension)
    if (comprehension === 100 || comprehension.comprehension === 100) {
      return
    }

    if (typeof comprehension === 'number') {
      results[key] = { comprehension }
    } else if (comprehension.comprehension) {
      results[key] = comprehension
    }
  })

  return Object.entries(results).sort((a, b) => a[1].comprehension - b[1].comprehension)
}

const getSentenceChunk = (length, sentences) => {
  if (length === ALL_SENTENCES) {
    return sentences
  }
  return sentences.splice(0, length)
}


const ReviewModule = () => {
  const [allSentences, setAllSentences] = useState([])
  const [unknownSentences, setUnknownSentences] = useState([])
  const [cardInd, setCardInd] = useState(0)
  const [isComplete, setIsComplete] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [stats, setStats] = useState(() => ({
    time: Date.now(), review: [], numToReview: 0, reviewCount: 0, totalCount: 0,
  }))
  const [comprehension, setComprehension] = useState({ ids: [], idx: 0 })
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [modules, setModules] = useState([])

  const classes = useStyle()
  const { sentences } = useSentencesContext()
  const { globalState: { user, settings } } = useGlobalStateContext()

  const searchParams = new URLSearchParams(window.location.href.split('module')[1])
  const reviewLength = _.toNumber(searchParams.get('length')) || DEFAULT_REVIEW_CHUNK_LENGTH
  const { props: sentenceProps } = unknownSentences[cardInd] || ''
  const moduleInfo = useMemo(
    () => modules.find(({ id }) => sentenceProps && (id.S === sentenceProps.source)),
    [modules, sentenceProps],
  )

  useEffect(() => {
    async function init() {
      const orderedSentences = getSentencesByInterval(sentences)
      const totalCount = orderedSentences.length

      const chunk = getSentenceChunk(reviewLength, orderedSentences)

      setStats((prev) => ({
        ...prev,
        reviewCount: prev.reviewCount + chunk.length,
        totalCount: totalCount || chunk.length,
        currentReviewLength: chunk.length,
      }))
      setUnknownSentences(chunk)
      setAllSentences(orderedSentences)
      setIsLoading(false)
    }
    if (!_.isEmpty(sentences) && isLoading) init()
  }, [sentences, isLoading, reviewLength])

  useEffect(() => {
    const init = async () => {
      const comprehensionArr = getComprehension(await getTrackComplete(user))
      setModules(await values())
      setComprehension((prev) => ({ ...prev, ids: comprehensionArr }))
    }

    init()
  }, [user])

  const handleYoutubeClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleYoutubeClose = () => {
    setAnchorEl(null)
  }


  if (_.isEmpty(sentences)) {
    return (
      <Paper elevation={0} className={classes.card}>
        <Typography align="center" variant="h5">{`${PHRASES_BY_LANG.buildSentences[settings.translationLang]}`}</Typography>
      </Paper>
    )
  }

  if (isComplete || (!isLoading && cardInd >= unknownSentences.length)) {
    return (
      <CompletePageComponent
        allSentences={allSentences}
        comprehension={comprehension}
        reviewLength={reviewLength}
        setComprehension={setComprehension}
        setAllSentences={setAllSentences}
        setIsComplete={setIsComplete}
        setCardInd={setCardInd}
        setUnknownSentences={setUnknownSentences}
        stats={stats}
        setStats={setStats}
      />
    )
  }

  if (unknownSentences.length === 0) {
    return (
      <Paper elevation={0} className={classes.card}>
        <Typography variant="h6">
          {`${PHRASES_BY_LANG.noSentences[settings.translationLang]}`}
        </Typography>
      </Paper>
    )
  }


  const remainingSentences = unknownSentences.filter((obj) => (_.last(obj.props.interactions)
    === WORD_INTERACTIONS.ThumbDown)
    || !(dayjs(_.last(obj.props.interactionDates)).isToday())).length
  return (
    <>
      <div className={classes.root}>
        <Typography variant="subtitle2" color="textSecondary">{`오늘 복습할 양: ${stats.totalCount}`}</Typography>
        <Typography variant="subtitle2" color="textSecondary">{`이 복습 세션의 카드 수: ${remainingSentences}`}</Typography>
        <ReviewCardComponent
          unknownSentences={unknownSentences}
          setUnknownSentences={setUnknownSentences}
          cardInd={cardInd}
          setCardInd={setCardInd}
          setIsComplete={setIsComplete}
          setStats={setStats}
        />
        {
          moduleInfo && (
            <Box mt={1} color="text.secondary" className={classes.title}>
              <Box onClick={handleYoutubeClick}>{`${moduleInfo.title.S}`}</Box>
              <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleYoutubeClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
              >
                <ModuleCard
                  id={moduleInfo.id.S}
                  length={moduleInfo.length.N}
                  title={moduleInfo.title.S}
                  completions={{}}
                  thumbnail={moduleInfo['thumbnail-url'].S}
                />
              </Popover>
            </Box>

          )
        }

      </div>
    </>
  )
}

const useStyle = makeStyles(({
  root: {
    marginTop: 35,
    maxWidth: 800,
    margin: '0 auto',
  },
  card: {
    padding: 24,
  },
  title: {
    textDecoration: 'underline',
    cursor: 'pointer',
  },
}))


export default ReviewModule
