import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
// import InfiniteScroll from 'react-infinite-scroll-component'
import InfiniteScroll from 'react-infinite-scroller'
import { Box, makeStyles } from '@material-ui/core'
import Carousel from '../Carousel'
import { ReactComponent as Empty } from '../../graphics/empty_graphic.svg'
import { partitionByProgress, partitionBySeries } from '../../lib/util'

const ROWS_PER_PAGE = 2

const VideoList = ({
  modules, progress, difficulty, completions, setSeries,
}) => {
  const [sortedModules, setSortedModules] = useState([])
  const [hasMore, setHasMore] = useState(false)
  const [page, setPage] = useState(1)

  const classes = useStyles()

  useEffect(() => {
    const init = () => {
      const partitionedModules = partitionByProgress(modules, completions)
      const sortedSeries = partitionBySeries(partitionedModules, progress, difficulty)
      setSortedModules(sortedSeries)
    }
    if (!_.isEmpty(module)) init()
  }, [modules, completions, difficulty, progress])

  useEffect(() => {
    if (sortedModules.length > ROWS_PER_PAGE) {
      setHasMore(true)
    }
    setPage(1)
  }, [sortedModules.length])

  const displayModules = sortedModules.slice(0, page * ROWS_PER_PAGE)

  const addMoreRows = () => {
    if ((ROWS_PER_PAGE * page) >= sortedModules.length) {
      setHasMore(false)
      return
    }
    setPage((prev) => prev + 1)
  }

  return (
    <div>
      {!!sortedModules.length && (
        <InfiniteScroll
          pageStart={0}
          loadMore={addMoreRows}
          hasMore={hasMore}
          loader={<h4 key={0}>Loading...</h4>}
        >
          {displayModules.map(([title, series]) => (
            <Box my={2} key={title}>
              <Carousel
                title={title}
                series={series}
                setSeries={setSeries}
                completions={completions}
              />
            </Box>
          ))}
        </InfiniteScroll>
      )}
      {(!!modules.length && !sortedModules.length) && (
        <Box my={2} display="flex" flexDirection="column" alignItems="center" justifyContent="center">
          <Box fontSize={30} fontWeight={200}>No modules to display</Box>
          <Empty className={classes.emptySvg} />
        </Box>
      )}
    </div>
  )
}

const useStyles = makeStyles(({
  emptySvg: {
    height: 200,
    width: 200,
  },
}))

VideoList.propTypes = {
  modules: PropTypes.arrayOf(PropTypes.object),
  progress: PropTypes.string,
  difficulty: PropTypes.string,
  completions: PropTypes.objectOf(
    PropTypes.shape({
      completionPercentage: PropTypes.number,
      comprehension: PropTypes.string,
      date: PropTypes.number,
    }),
  ),
  setSeries: PropTypes.func,
}
VideoList.defaultProps = {
  modules: [],
  progress: 'lorum',
  difficulty: 'ipsum',
  completions: {},
  setSeries: _.noop(),
}

export default VideoList
