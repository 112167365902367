// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_cognito_identity_pool_id": "us-west-2:2d997a6c-fa88-45fc-aa6b-410ced31cee4",
    "aws_cognito_region": "us-west-2",
    // "aws_user_pools_id": "us-west-2_aVKZ3wYJ5", // Dev userpool
    // "aws_user_pools_web_client_id": "2kikh4gd41tlg8mtr83fn4clcc", // Dev web client
    "aws_user_pools_id": "us-west-2_vElnWbspQ", // Prod userpool
    "aws_user_pools_web_client_id": "6qburg9tlv6afoue8ncpsl4eig", // Prod web client
    "oauth": {}
};


export default awsmobile;
