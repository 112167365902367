/* eslint-disable import/no-named-default */
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { default as NukaCarousel } from 'nuka-carousel'
import { Box, makeStyles, useMediaQuery } from '@material-ui/core'
import ModuleCard from '../ModuleCard'

const Carousel = ({
  title, series, setSeries, completions,
}) => {
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0)

  const classes = useStyles()
  const bigScreen = useMediaQuery('(min-width:1000px)')
  const numOfCards = bigScreen ? 4 : 2
  const handleAfterSlide = (newSlideIndex) => {
    setCurrentSlideIndex(newSlideIndex)
  }

  const lazyLoadPhotos = series.map((card, idx) => {
    if (idx <= currentSlideIndex + 2 * numOfCards) {
      return (
        <Box p={1} key={card.id.S}>
          <ModuleCard
            length={card.length.N}
            id={card.id.S}
            title={card.title.S}
            completions={completions}
            thumbnail={card['thumbnail-url'] && card['thumbnail-url'].S}
          />
        </Box>
      )
    }
    return <div key={card.id.S} />
  })

  const handleSeriesClick = () => {
    setSeries(title)
  }

  return (
    <>
      <Box className={classes.link} fontSize={20} my={1} fontWeight={500} onClick={handleSeriesClick}>{`${title}`}</Box>
      <NukaCarousel
        slidesToShow={numOfCards}
        slidesToScroll={numOfCards}
        cellAlign="left"
        renderBottomCenterControls={null}
        afterSlide={handleAfterSlide}
        style={{ outline: 'none' }}
      >
        {lazyLoadPhotos}
      </NukaCarousel>
    </>
  )
}

const useStyles = makeStyles(({
  link: {
    '&:hover': {
      textDecoration: 'underline',
    },
    cursor: 'pointer',
  },
}))

Carousel.propTypes = {
  title: PropTypes.string,
  series: PropTypes.arrayOf(PropTypes.object),
  setSeries: PropTypes.func,
  completions: PropTypes.objectOf(
    PropTypes.shape({
      completionPercentage: PropTypes.number,
      comprehension: PropTypes.string,
      date: PropTypes.number,
    }),
  ),
}
Carousel.defaultProps = {
  title: 'lorum',
  series: [],
  setSeries: () => { },
  completions: {},
}

export default Carousel
