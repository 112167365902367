import { API_GATEWAY_URI } from '../constants'

export const postAwsData = async (id, service, data) => {
  const requestUri = `${API_GATEWAY_URI}aws`
  const body = JSON.stringify({ id, service, data })
  const response = await fetch(requestUri, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
    body,
  })
  const results = await response.json()
  return results
}

export const getAwsData = async (id, service) => {
  const requestUri = `${API_GATEWAY_URI}aws?id=${encodeURIComponent(id)}&service=${service}`
  const response = await fetch(requestUri, {
    mode: 'cors',
  })
  const { Item } = await response.json()
  if (!Item) {
    return Item
  }
  return Item.data && Item.data.S
}
