import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  Box,
  makeStyles,
  Popover,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
} from '@material-ui/core'

const TAB_VALUE = {
  grammar: 'Grammar',
  sentence: 'Example Sentences',
}

const GrammarReference = ({ reference }) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const [tabValue, setTabValue] = useState(TAB_VALUE.grammar)

  const classes = useStyles()
  const { name, description, examples } = reference

  const handleClick = (e) => {
    setAnchorEl(e.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleTabChange = (e) => {
    setTabValue(e.target.dataset.value)
  }

  return (
    <>
      <Box
        fontSize={16}
        fontWeight={600}
        color="text.secondary"
        mx={1}
        onClick={handleClick}
        style={{ borderBottom: '1px grey solid' }}
        component="span"
      >

        {`${name}`}
      </Box>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Box fontSize={14} maxWidth={400} minWidth={300}>
          {
            !!examples.length && (
              <Box display="flex" px={2} pt={2}>
                <Box
                  flexGrow={1}
                  fontWeight={600}
                  textAlign="center"
                  data-value={TAB_VALUE.grammar}
                  color={tabValue === TAB_VALUE.grammar ? 'primary.main' : 'text.primary'}
                  onClick={handleTabChange}
                  className={classes.pointer}
                >
                  Grammar
                </Box>
                <Box
                  flexGrow={1}
                  fontWeight={600}
                  extAlign="center"
                  data-value={TAB_VALUE.sentence}
                  color={tabValue === TAB_VALUE.sentence ? 'primary.main' : 'text.primary'}
                  onClick={handleTabChange}
                  className={classes.pointer}
                >
                  Example Sentences
                </Box>
              </Box>
            )
          }
          <Box p={2}>
            {tabValue === TAB_VALUE.grammar ? (
              <>{`${description}`}</>
            ) : (
              <>
                <TableContainer className={classes.scroll}>
                  <Table size="small">
                    <TableBody>
                      {
                        examples.map((row) => (
                          <TableRow key={row.sentence}>
                            <TableCell className={classes.noRow}>{row.sentence}</TableCell>
                            <TableCell className={classes.noRow}><Box color="primary.dark">{row.translation}</Box></TableCell>
                          </TableRow>
                        ))
                      }
                    </TableBody>
                  </Table>
                </TableContainer>
              </>
            )}
          </Box>
        </Box>
      </Popover>
    </>
  )
}

const useStyles = makeStyles({
  noRow: {
    borderBottom: 'none',
  },
  scroll: {
    overflowY: 'auto',
    maxHeight: 200,
  },
  pointer: {
    cursor: 'pointer',
  },
})

GrammarReference.propTypes = {
  reference: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
    rule: PropTypes.arrayOf(PropTypes.object),
    examples: PropTypes.arrayOf(PropTypes.shape({
      sentence: PropTypes.string,
      translation: PropTypes.string,
    })),
  }),
}

GrammarReference.defaultProps = {
  reference: PropTypes.shape({
    name: 'lorum',
    description: 'ipsum',
    rule: [],
    examples: [{}],
  }),
}
export default GrammarReference
