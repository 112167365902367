import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { IconButton } from '@material-ui/core'
import { RecordVoiceOver } from '@material-ui/icons'
import { useGlobalStateContext } from '../../../contexts'
import { postAwsData, getAwsData } from '../../../lib/apigateway/aws'
import { encodeWord, getTtsBuffer } from '../../../lib/util'
import { AWS_SERVICE } from '../../../lib/constants'

const audio = new Audio()

const TtsButton = React.memo(({
  sentence, alwaysPlay, ttsPlayed, setTtsPlayed,
}) => {
  const [cache, setCache] = useState()
  const { globalState: { settings } } = useGlobalStateContext()
  const { autoplayTTS } = settings

  const speak = useCallback(async (text) => {
    audio.pause()
    const getTtsData = async (string) => {
      if (cache) {
        return cache
      }

      const ddbTtsData = await getAwsData(encodeWord(sentence), AWS_SERVICE.POLLY)
      if (ddbTtsData) {
        return Buffer.from(ddbTtsData, 'base64')
      }

      const pollyTtsData = await getTtsBuffer(string, settings.moduleLang)
      // Convert to string to store in DynamoDB
      const b64String = Buffer.from(pollyTtsData).toString('base64')
      await postAwsData(encodeWord(sentence), AWS_SERVICE.POLLY, b64String)

      return pollyTtsData
    }

    const ttsData = await getTtsData(text)
    setCache(ttsData)

    const blob = new Blob([ttsData.buffer], { type: 'audio/mpeg' })
    const url = URL.createObjectURL(blob)
    audio.src = url
    await audio.play()
  }, [cache, sentence, settings.moduleLang])

  const handleClick = async () => {
    await speak(sentence)
  }


  useEffect(() => {
    if ((autoplayTTS || alwaysPlay) && !ttsPlayed) {
      speak(sentence)
      setTtsPlayed(true)
    }
  }, [sentence, alwaysPlay, autoplayTTS, speak, ttsPlayed, setTtsPlayed])

  useEffect(() => {
    setCache(undefined)
    setTtsPlayed(false)
  }, [sentence, setTtsPlayed])

  return (
    <span>
      <span>
        <IconButton onClick={handleClick}>
          <RecordVoiceOver />
        </IconButton>
      </span>
    </span>
  )
})

TtsButton.propTypes = {
  sentence: PropTypes.string.isRequired,
  alwaysPlay: PropTypes.bool,
  ttsPlayed: PropTypes.bool.isRequired,
  setTtsPlayed: PropTypes.func.isRequired,
}

TtsButton.defaultProps = {
  alwaysPlay: false,
}

export default TtsButton
