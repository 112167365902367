/* eslint-disable no-param-reassign */
import React from 'react'

import { Box } from '@material-ui/core'

const Lesson = () => (
  <Box fontSize={30} fontWeight={600} display="flex" justifyContent="center">Please follow instructions from Gordon Huang</Box>
)


export default Lesson
