import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import isoWeek from 'dayjs/plugin/isoWeek'
import { Box } from '@material-ui/core'
import Day from './Day'

dayjs.extend(isoWeek)

const getCurrentWeekDays = (datesArr) => {
  // Corresponding value to day of week starting on MONDAY
  const isCheckedValues = [false, false, false, false, false, false, false]
  const currentWeek = dayjs().isoWeek()
  datesArr.filter((date) => dayjs(date).isoWeek() === currentWeek)
    .forEach((date) => {
      const dayIndex = dayjs(date).isoWeekday() - 1
      isCheckedValues[dayIndex] = true
    })

  return isCheckedValues
}

const weekDefault = ['일', '월', '화', '수', '목', '금', '토']

const CompletionCalendar = ({ datesArr }) => {
  // Corresponding value to day of week starting on MONDAY
  const [isChecked, setIsChecked] = useState(getCurrentWeekDays(datesArr))

  useEffect(() => {
    setIsChecked(getCurrentWeekDays(datesArr))
  }, [datesArr])

  return (
    <Box display="flex" justifyContent="space-between">
      {weekDefault.map((label, idx) => <Day label={label} isCheck={isChecked[idx]} key={label} />)}
    </Box>
  )
}

CompletionCalendar.propTypes = {
  datesArr: PropTypes.arrayOf(PropTypes.number),
}

CompletionCalendar.defaultProps = {
  datesArr: [],
}

export default CompletionCalendar
