import React, {
  useContext,
  useEffect,
  useState,
  createContext,
} from 'react'
import PropTypes from 'prop-types'
import { useGlobalStateContext } from '../GlobalContext/GlobalContextProvider'
import { getUserVocabulary } from '../../lib/apigateway/vocabulary'
import { getUserMorphs } from '../../lib/apigateway/morphemes'


export const VocabContext = createContext()
export const useVocabContext = () => useContext(VocabContext)

const VocabContextProvider = (props) => {
  const [vocab, setVocab] = useState(null)
  const [isVocabLoading, setIsVocabLoading] = useState(true)

  const { globalState: { user } } = useGlobalStateContext()

  useEffect(() => {
    async function initVocab() {
      const userVocab = await getUserVocabulary(user)
      const userMorphs = await getUserMorphs(user)
      setVocab({ vocab: userVocab, morphs: userMorphs })
      setIsVocabLoading(false)
    }
    if (user && !vocab) initVocab()
  }, [user, vocab])

  const { children } = props

  return (
    <VocabContext.Provider
      value={{
        vocab, setVocab, isVocabLoading,
      }}
    >
      {children}
    </VocabContext.Provider>
  )
}

VocabContextProvider.propTypes = {
  children: PropTypes.element,
}

VocabContextProvider.defaultProps = {
  children: null,
}

export default VocabContextProvider
