import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import {
  Box,
  Button,
  Paper,
} from '@material-ui/core'

const GrammarCard = ({
  id, title, completions,
}) => {
  let comprehension = completions[id] && decodeURIComponent(completions[id].comprehension)
  if (comprehension === 'undefined') {
    comprehension = undefined
  } else if (comprehension) {
    const parsed = JSON.parse(comprehension)
    if (typeof parsed === 'number') {
      comprehension = _.toString(parsed)
    } else {
      comprehension = parsed.comprehension
    }
  }

  return (
    <>
      <Paper>
        <Box p={2} width={275} height={234} display="flex" flexDirection="column" justifyContent="space-between">
          <Box>
            <Box fontSize={25} fontWeight={500}>
              {title}
            </Box>
            {!!comprehension && (
              <Box color="primary.main">
                {`${comprehension}% comprehension`}
              </Box>
            )}

          </Box>
          <Box>
            <Button fullWidth variant="contained" color="primary" href={`/#/grammar/${id}`}>Start</Button>
          </Box>
        </Box>
      </Paper>
    </>
  )
}

GrammarCard.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  completions: PropTypes.objectOf(
    PropTypes.shape({
      completionPercentage: PropTypes.number,
      comprehension: PropTypes.string,
      date: PropTypes.number,
    }),
  ),
}
GrammarCard.defaultProps = {
  id: '',
  title: '',
  completions: {},
}
export default GrammarCard
