import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import {
  Card,
  CardMedia,
  Typography,
  Link,
  Tooltip,
  makeStyles,
} from '@material-ui/core'
import { parseModuleId } from '../../lib/util'
import { VIDEO_TYPES } from '../../lib/constants'
import vlive from '../../graphics/vlive.jpg'

const getThumbnailUrl = (moduleId, contentType, thumbnail) => {
  if (thumbnail) {
    return thumbnail
  }

  if (contentType === VIDEO_TYPES.YOUTUBE) {
    return `http://i3.ytimg.com/vi/${moduleId}/mqdefault.jpg`
  }

  return vlive
}

const ModuleCard = ({
  length, id, title, completions, thumbnail,
}) => {
  const { videoId, type } = parseModuleId(id)
  const classes = useStyles()

  let comprehension = completions[id] && decodeURIComponent(completions[id].comprehension)
  if (comprehension === 'undefined') {
    comprehension = undefined
  } else if (comprehension) {
    const parsed = JSON.parse(comprehension)
    if (typeof parsed === 'number') {
      comprehension = _.toString(parsed)
    } else {
      comprehension = parsed.comprehension
    }
  }

  return (
    <>
      <Link href={`#/modules/${id}`} underline="none">
        <Card className={classes.card}>
          <CardMedia component="img" image={getThumbnailUrl(videoId, type, thumbnail)} height="150" style={{ overflow: 'hidden' }} />
          <div className={classes.textDiv}>
            <Tooltip arrow title={title}>
              <Typography className={classes.title}>{title}</Typography>
            </Tooltip>
            {/* -3 to remove Youtube/feedback card.  Remove after fixing injest */}
            <Typography className={classes.length} color="textSecondary">{`${length - 2} 단어`}</Typography>
            {!!comprehension && (
              <Typography className={classes.length} color="primary">{`${comprehension}% comprehension`}</Typography>
            )}
          </div>
        </Card>
      </Link>
    </>
  )
}

const useStyles = makeStyles(({
  card: {
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    height: 234,
    maxWidth: 275,
    margin: '0 auto',
  },
  textDiv: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: 12,
    flex: 1,
  },
  title: {
    fontSize: 14,
    // Not compatible with IE https://caniuse.com/#feat=css-line-clamp
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  length: {
    fontSize: 13,
  },
}))

ModuleCard.propTypes = {
  length: PropTypes.string,
  id: PropTypes.string,
  title: PropTypes.string,
  completions: PropTypes.objectOf(
    PropTypes.shape({
      completionPercentage: PropTypes.number,
      comprehension: PropTypes.string,
      date: PropTypes.number,
    }),
  ),
  thumbnail: PropTypes.string,
}
ModuleCard.defaultProps = {
  length: '',
  id: '',
  title: '',
  completions: {},
  thumbnail: '',
}

export default ModuleCard
