import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { Box, makeStyles } from '@material-ui/core'
import ModuleCard from '../ModuleCard'
import { partitionByProgress, partitionBySeries } from '../../lib/util'
import GrammarCard from '../GrammarCard'
import { ReactComponent as Empty } from '../../graphics/empty_graphic.svg'


const SeriesList = ({
  modules, title, progress, completions, difficulty,
}) => {
  const [series, setSeries] = useState([])
  const classes = useStyles()

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    const partitionedModules = partitionByProgress(modules, completions)
    const sortedSeries = partitionBySeries(partitionedModules, progress, difficulty)
    const selectedSeries = _.find(sortedSeries, (module) => module[0] === title)
    if (selectedSeries && selectedSeries[1].length) {
      const [, result] = selectedSeries
      setSeries(result)
    } else {
      setSeries(null)
    }
  }, [modules, title, completions, difficulty, progress])

  useEffect(() => {
    window.scroll(0, 0)
  }, [])

  return (
    <Box>
      <Box my={2} fontSize={20} fontWeight={500}>{`${title} Episodes`}</Box>
      {
        (series === null) ? (
          <Box my={2} display="flex" flexDirection="column" alignItems="center">
            <Box fontSize={30} fontWeight={200}>No modules to display</Box>
            <Empty className={classes.emptySvg} />
          </Box>
        ) : (
          <Box className={classes.seriesList} display="flex">
            {series.map((card) => {
              if (card.tags.SS.includes('Grammar')) {
                return (
                  <Box key={card.id.S} m={1}>
                    <GrammarCard title={card.title.S} id={card.id.S} length={card.length.N} />
                  </Box>
                )
              }

              return (
                <Box p={1} key={card.id.S}>
                  <ModuleCard
                    length={card.length.N}
                    id={card.id.S}
                    title={card.title.S}
                    completions={completions}
                    thumbnail={card['thumbnail-url'] && card['thumbnail-url'].S}
                  />
                </Box>
              )
            })}
          </Box>
        )
      }
    </Box>
  )
}

const useStyles = makeStyles((theme) => ({
  emptySvg: {
    height: 200,
    width: 200,
  },
  seriesList: {
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
    },
    [theme.breakpoints.up('xs')]: {
      flexWrap: 'wrap',
    },
  },
}))


SeriesList.propTypes = {
  modules: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
  progress: PropTypes.string,
  completions: PropTypes.objectOf(PropTypes.object),
  difficulty: PropTypes.string,
}
SeriesList.defaultProps = {
  modules: [],
  title: 'lorum',
  progress: 'foo',
  completions: {},
  difficulty: 'lorem ipsum',
}

export default SeriesList
