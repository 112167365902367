export const version = 'Version: 1.0 - Last Updated: 4/9/2021'
export const references = [
  {
    name: '은/는',
    description: "'은/는' are particles that attach to the subject of the sentence. You use '은' if the subject ends in a consonant and '는' if the subject ends in a vowel.",
    rule: [
      {
        type: 'OR',
        query: [['는', 'JX'], ['은', 'JX']],
      },
    ],
    examples: [],
  },
  {
    name: '이다',
    description: "'이다' means 'to be.' It is conjugated as: '입니다' for polite formal, '예요/이에요' for polite informal, and '야/이야' for the most informal.",
    rule: [
      {
        type: 'AND',
        query: [['이', 'VCP'], ['에요', 'EF']],
      },
      {
        type: 'AND',
        query: [['이', 'VCP'], ['야', 'EF']],
      },
      {
        type: 'AND',
        query: [['입니다', 'VCP+EF']],
      },
      {
        type: 'AND',
        query: [['이', 'VCP'], ['ㅂ니다', 'EF']],
      },
    ],
    examples: [
      {
        sentence: '저는 사람입니다.',
        translation: 'I am a person. (polite formal)',
      },
      {
        sentence: '저는 사람이에요.',
        translation: 'I am a person. (polite informal)',
      },
      {
        sentence: '나는 사람이야.',
        translation: 'I am a person. (informal)',
      },
      {
        sentence: '저는 한국 사람입니다.',
        translation: 'I am a Korean person. (polite formal)',
      },
      {
        sentence: '저는 중국 사람이에요.',
        translation: 'I am a Chinese person. (polite informal)',
      },
      {
        sentence: '나는 스페인 사람이야.',
        translation: 'I am a Spanish person. (informal)',
      },
      {
        sentence: '저는 미국 사람이에요.',
        translation: 'You are an American person. (polite informal)',
      },
    ],
  },
  {
    name: '을/를',
    description: "'을/를' are used to mark the object of the sentence. You use '을' if the object ends with a consonant and '를' if the object ends with a vowel.",
    rule: [
      {
        type: 'OR',
        query: [['을', 'JKO'], ['를', 'JKO']],
      },
    ],
    examples: [],
  },
  {
    name: '아/어요',
    description: "Use '아/어요' to conjugate words into 'informal polite' present tense. Use '(스)ㅂ니다' to indicate 'formal polite.'",
    rule: [
      {
        type: 'OR',
        query: [['아요', 'EF'], ['어요', 'EF'], ['아', 'EF'], ['어', 'EF'], ['ㅂ니다', 'EF']],
      },
    ],
    examples: [
      {
        sentence: '저는 한국을 좋아합니다.',
        translation: 'I like Korea. (polite formal)',
      },
      {
        sentence: '저는 한국을 좋아해요.',
        translation: 'I like Korea (polite informal)',
      },
      {
        sentence: '나는 한국을 좋아해.',
        translation: 'I like Korea (informal)',
      },
      {
        sentence: '저는 그 요리사를 좋아합니다.',
        translation: 'I like that cook. (polite formal)',
      },
      {
        sentence: '저는 그 선생님을 좋아해요.',
        translation: 'I like that teacher. (polite informal)',
      },
      {
        sentence: '나는 그 일을 좋아해.',
        translation: 'I like that work (informal).',
      },
      {
        sentence: '저는 그 간호사를 좋아합니다.',
        translation: 'I like that nurse. (polite formal)',
      },
      {
        sentence: '저는 그 회사원을 좋아해요.',
        translation: 'I like that office worker. (polite informal)',
      },
      {
        sentence: '나는 그 학생을 좋아해.',
        translation: 'I like that student. (informal)',
      },
    ],
  },
  {
    name: '이/가',
    description: "'이/가' are also used to mark the object of the sentence. You use '이' if the object ends with a consonant and '가' if the object ends with a vowel. You use '이/가' instead of '은/는' if you want to emphasize the subject or if you are expressing new information.",
    rule: [
      {
        type: 'OR',
        query: [['이', 'JKS'], ['가', 'JKS']],
      },
    ],
    examples: [],
  },
  {
    name: '으세요',
    description: "'(으)세요' is an honorific ending to talk about a person you respect. It's also a polite way to make a request.",
    rule: [
      {
        type: 'OR',
        query: [['시', 'EP'], ['세', 'VA']],
      },
    ],
    examples: [
      {
        sentence: '그는 한국 사람이세요.',
        translation: 'He is a Korean person.',
      },
      {
        sentence: '그는 요리사세요.',
        translation: 'He is a Cook.',
      },
      {
        sentence: '그녀는 선생님이세요.',
        translation: 'She is a teacher.',
      },
      {
        sentence: '그는 간호사세요?',
        translation: 'Is he a nurse?',
      },
      {
        sentence: '그녀는 회사원이세요?',
        translation: 'Is she an office worker?',
      },
      {
        sentence: '그는 학생이세요.',
        translation: "He's a student.",
      },
      {
        sentence: '하세요.',
        translation: 'Do it.',
      },
      {
        sentence: '일 하세요.',
        translation: 'Do work',
      },
      {
        sentence: '무슨 일 하세요?',
        translation: 'What kind of work do you do?',
      },
      {
        sentence: '여기 앉으세요.',
        translation: 'Please sit here.',
      },
      {
        sentence: '그녀에게 전화하세요.',
        translation: 'Please call her.',
      },
      {
        sentence: '거기로 가세요.',
        translation: 'Please go there.',
      },
      {
        sentence: '들어가세요.',
        translation: 'Please enter.',
      },
    ],
  },
  {
    name: '에',
    description: "Use '에' to indicate location or time.",
    rule: [
      {
        type: 'AND',
        query: [['에', 'JKB']],
      },
    ],
    examples: [
      {
        sentence: '저는 한국에 가요.',
        translation: 'I am going to Korea.',
      },
      {
        sentence: '저는 식당에 가요.',
        translation: 'I am going to the restaurant.',
      },
      {
        sentence: '저는 회사에 가요.',
        translation: 'I am going to the office.',
      },
      {
        sentence: '저는 학교에 가요.',
        translation: 'I am going to school.',
      },
      {
        sentence: '아침에',
        translation: 'In the morning',
      },
      {
        sentence: '밤에',
        translation: 'At night',
      },
      {
        sentence: '동생이 학교에 가요.',
        translation: 'My younger sibling is going to school.',
      },
      {
        sentence: '그는 집에 있어요.',
        translation: 'He is at home.',
      },
      {
        sentence: '저는 8시에 일어나요.',
        translation: 'I wake up at 8:00.',
      },
      {
        sentence: '2시에 시작해요.',
        translation: 'It starts at 2.',
      },
      {
        sentence: '저는 1월 2일에 왔어요.',
        translation: 'I came on January 2nd.',
      },
    ],
  },
  {
    name: '에서',
    description: "Use '에서' to indicate where something happens.",
    rule: [
      {
        type: 'OR',
        query: [['에서', 'JKB']],
      },
    ],
    examples: [
      {
        sentence: '저는 한국에서 일해요.',
        translation: 'I work in Korea.',
      },
      {
        sentence: '저는 학교에서 운동해요.',
        translation: 'I exercise at school.',
      },
      {
        sentence: '저는 공원에서 운동해요.',
        translation: 'I exercise at the park.',
      },
      {
        sentence: '저는 집에서 일해요.',
        translation: 'I work at home.',
      },
      {
        sentence: '저는 한국에서 살아요.',
        translation: 'I live in Korea.',
      },
      {
        sentence: '학교에서 공부를 해요.',
        translation: 'I study at school.',
      },
      {
        sentence: '극장에서 영화를 봤어요.',
        translation: 'I saw a movie at the theater.',
      },
      {
        sentence: '저는 방에서 잤어요.',
        translation: 'I slept in the room.',
      },
    ],
  },
  {
    name: '의',
    description: "'의' is a particle that indicates possession.",
    rule: [
      {
        type: 'OR',
        query: [['의', 'JKG']],
      },
    ],
    examples: [
      {
        sentence: '이 책은 나의 책이야.',
        translation: 'This book is my book.',
      },
      {
        sentence: '이 집은 내 집이야.',
        translation: 'This house is my house. (내 = 나의)',
      },
      {
        sentence: '이 텔레비전은 저의 텔레비전이에요.',
        translation: 'This television is my television.',
      },
      {
        sentence: '이 커피는 제 커피예요.',
        translation: 'This coffee is my coffee. (제 = 저의)',
      },
      {
        sentence: '저는 수빈의 친구예요.',
        translation: "I'm Subin's friend.",
      },
      {
        sentence: '저의 이름은 태연입니다.',
        translation: 'My name is Taeyeon.',
      },
      {
        sentence: '제 이름은 태연입니다.',
        translation: 'My name is Taeyeon. (제 = 저의)',
      },
    ],
  },
  {
    name: '도',
    description: "'도' means 'also' or 'too'",
    rule: [
      {
        type: 'OR',
        query: [['도', 'JX']],
      },
    ],
    examples: [
      {
        sentence: '저도 한국을 좋아해요.',
        translation: 'I also like Korea. (When other people also like Korea)',
      },
      {
        sentence: '저는 한국도 좋아해요.',
        translation: 'I also like Korea. (When I also like other countries)',
      },
      {
        sentence: '저도 미안해요.',
        translation: "I'm also sorry.",
      },
      {
        sentence: '저도 친구랑 운동해요.',
        translation: 'I also exercise with friends.',
      },
      {
        sentence: '저도 친구랑 만나요.',
        translation: 'I also meet with friends.',
      },
      {
        sentence: '내 친구도 그 여자를 좋아해.',
        translation: 'My friend also likes that girl.',
      },
      {
        sentence: '저도 한국어를 배우고 싶어요.',
        translation: 'I also want to learn Korean.',
      },
      {
        sentence: '제 동생도 한국어를 공부하고 있어요.',
        translation: 'My younger sibling is also studing Korean.',
      },
    ],
  },
  {
    name: '을까요',
    description: "'(으)ㄹ까요' is used when the speaker makes a suggestion or asks for an opinion. It can also be used to mean 'I wonder.'",
    rule: [
      {
        type: 'OR',
        query: [['ㄹ까요', 'EF'], ['을까요', 'EF'], ['ㄹ까', 'EF']],
      },
    ],
    examples: [
      {
        sentence: '제가 한국에 갈까요?',
        translation: 'Should I go to Korea?',
      },
      {
        sentence: '우리가 스페인에 갈까요?',
        translation: 'Should we go to Spain?',
      },
      {
        sentence: '우리도 만날까요?',
        translation: 'Should we also meet?',
      },
      {
        sentence: '우리도 먹을까요?',
        translation: 'Should we also eat?',
      },
      {
        sentence: '한국은 어떨까요?',
        translation: 'I wonder how Korea is like?',
      },
      {
        sentence: '그녀는 커피를 좋아할까요?',
        translation: 'I wonder if she will like coffee?',
      },
      {
        sentence: '오늘 파티는 좋을까요?',
        translation: 'I wonder if the party today will be good?',
      },
    ],
  },
  {
    name: '는',
    description: "Add '는' to a verb to make the verb describe a noun.",
    rule: [
      {
        type: 'OR',
        query: [['는', 'ETM'], ['다는', 'ETM']],
      },
    ],
    examples: [
      {
        sentence: '한국을 좋아하는 사람',
        translation: 'A person who likes Korea',
      },
      {
        sentence: '학교에 가는 사람',
        translation: 'A person who is going to school',
      },
      {
        sentence: '학교에 가는 거 좋아해요.',
        translation: 'I like going to school.',
      },
      {
        sentence: '비빔밥을 먹는 사람',
        translation: 'A person who is eating bibimbap',
      },
      {
        sentence: '비빔밥 먹는 거 좋아해요.',
        translation: 'I like eating bibimbap.',
      },
      {
        sentence: '극장에 가는 사람.',
        translation: 'A person who is going to the theatre.',
      },
      {
        sentence: '극장에 가는 거 좋아해요.',
        translation: 'I like going to the theatre.',
      },
      {
        sentence: '영화를 보는 사람',
        translation: 'A person who is watching a movie',
      },
      {
        sentence: '영화를 보는 거 좋아해요.',
        translation: 'I like watching movies',
      },
    ],
  },
  {
    name: 'ㄹ 거예요',
    description: "Use '(으)ㄹ 거예요' to signify future tense.",
    rule: [
      {
        type: 'AND',
        query: [['ㄹ', 'ETM'], ['거', 'NNB']],
      },
      {
        type: 'AND',
        query: [['을', 'ETM'], ['거', 'NNB']],
      },
      {
        type: 'AND',
        query: [['ㄹ', 'ETM'], ['것', 'NNB']],
      },
      {
        type: 'AND',
        query: [['을', 'ETM'], ['것', 'NNB']],
      },
    ],
    examples: [
      {
        sentence: '저는 한국에 갈 거예요.',
        translation: "I'm going to go to Korea.",
      },
      {
        sentence: '저는 박물관에 갈 거예요.',
        translation: "I'm going to go to the museum.",
      },
      {
        sentence: '저는 집에서 쉴 거예요.',
        translation: "I'm going to rest at home.",
      },
      {
        sentence: '파티는 좋을 겁니다.',
        translation: 'The party will be good.',
      },
      {
        sentence: '영화를 볼 거야.',
        translation: "I'm going to watch a movie.",
      },
      {
        sentence: '저는 차를 살 거예요.',
        translation: "I'm going to buy a car.",
      },
      {
        sentence: '친구가 바쁠 거예요.',
        translation: 'My friend will be busy.',
      },
      {
        sentence: '저는 그와 결혼할 거예요.',
        translation: "I'm going to marry him.",
      },
      {
        sentence: '저는 한국어를 공부할 거예요.',
        translation: 'I am going to study Korean.',
      },
      {
        sentence: '내일 재미있을 거예요.',
        translation: 'Tomorrow will be fun.',
      },
      {
        sentence: '요리할 거예요.',
        translation: "I'm going to cook.",
      },
      {
        sentence: '비가 올 거예요.',
        translation: "It's going to rain.",
      },
      {
        sentence: '영화를 볼 거야.',
        translation: "I'm going to watch a movie.",
      },
      {
        sentence: '편지를 쓸 겁니다.',
        translation: "I'm going to write a letter.",
      },
      {
        sentence: '친구랑 여행할 거예요.',
        translation: "I'm going to travel with my friend.",
      },
    ],
  },
  {
    name: 'ㄹ래요',
    description: "Use '(으)ㄹ래요' to gently make a request.",
    rule: [
      {
        type: 'OR',
        query: [['ㄹ래요', 'EF'], ['ㄹ래', 'EF'], ['을래', 'EC']],
      },
    ],
    examples: [
      {
        sentence: '한국에 갈래요?',
        translation: 'Do you want to go to Korea?',
      },
      {
        sentence: '영화를 같이 볼래?',
        translation: 'Do you want to watch a movie together?',
      },
      {
        sentence: '박물관에 같이 갈래요?',
        translation: 'Do you want to go to the museum together?',
      },
      {
        sentence: '집에서 쉴래?',
        translation: 'Do you want to rest at home?',
      },
      {
        sentence: '저녁을 같이 먹을래요?',
        translation: 'Do you want to eat dinner together?',
      },
      {
        sentence: '커피를 마실래?',
        translation: 'Do you want to drink coffee?',
      },
    ],
  },
  {
    name: '았/었어요',
    description: "Use '았/었어요' to signify past tense.",
    rule: [
      {
        type: 'OR',
        query: [['았', 'EP'], ['었', 'EP']],
      },
    ],
    examples: [
      {
        sentence: '저는 한국에 갔어요.',
        translation: 'I went to Korea.',
      },
      {
        sentence: '나는 영화를 봤어.',
        translation: 'I watched a movie.',
      },
      {
        sentence: '영화는 재미있었어요.',
        translation: 'The movie was interesting.',
      },
      {
        sentence: '저는 택시를 탔어요.',
        translation: 'I rode the taxi.',
      },
      {
        sentence: '저는 지하철을 탔습니다.',
        translation: 'I rode the subway.',
      },
      {
        sentence: '저는 빵을 먹었어요.',
        translation: 'I ate the bread.',
      },
      {
        sentence: '저는 그녀를 좋아했어요.',
        translation: 'I liked her.',
      },
      {
        sentence: '친구가 바빴어요.',
        translation: 'My friend was busy.',
      },
      {
        sentence: '저는 그와 결혼했어요.',
        translation: 'I married him.',
      },
      {
        sentence: '저는 한국어를 공부했어요.',
        translation: 'I studied Korean.',
      },
      {
        sentence: '어제 재미있었어요.',
        translation: 'Yesterday was fun.',
      },
    ],
  },
  {
    name: '만',
    description: "'만' means 'only' or 'just.'",
    rule: [
      {
        type: 'OR',
        query: [['만', 'JX']],
      },
    ],
    examples: [
      {
        sentence: '저는 커피만 좋아해요.',
        translation: 'I only like coffee.',
      },
      {
        sentence: '저는 사과만 좋아해요.',
        translation: 'I only like apples.',
      },
      {
        sentence: '저는 귤만 먹었어요.',
        translation: 'I only ate oranges.',
      },
      {
        sentence: '귤 하나만 먹었어요.',
        translation: 'I only ate one orange.',
      },
      {
        sentence: '귤 한 개만 먹었어요.',
        translation: 'I only ate one orange.',
      },
      {
        sentence: '30분만 걸렸어요.',
        translation: 'It only took 30 minutes.',
      },
      {
        sentence: '하영이만 그 영화를 좋아해.',
        translation: 'Only Hayoung likes that movie.',
      },
      {
        sentence: '5분만 기다려 주세요.',
        translation: 'Please wait for just five minutes.',
      },
      {
        sentence: '고기만 먹지 마세요.',
        translation: "Don't just eat meat.",
      },
      {
        sentence: '저는 30분만 있어요.',
        translation: 'I only have 30 minutes.',
      },
    ],
  },
  {
    name: '이나',
    description: "'(이)나' means 'or'. '(이)나' can also be used to indicate that something is higher than expected.",
    rule: [
      {
        type: 'OR',
        query: [['이나', 'JC'], ['나', 'JC']],
      },
    ],
    examples: [
      {
        sentence: '한국이나 중국에 갈 거예요.',
        translation: 'I will go to either Korea or China.',
      },
      {
        sentence: '주스나 커피를 주세요.',
        translation: 'Give me juice or coffee.',
      },
      {
        sentence: '분홍색이나 빨간색 셔츠 주세요.',
        translation: 'Give me a pink or red shirt.',
      },
      {
        sentence: '저는 한 시간이나 기다렸어요.',
        translation: "I waited for one hour (and it's more than expected)",
      },
      {
        sentence: '그는 귤 스무개나 먹었어요.',
        translation: "He ate twenty oranges (and it's more than expected)",
      },
      {
        sentence: '치킨이나 피자를 먹고 싶어요.',
        translation: 'I want to eat chicken or pizza.',
      },
      {
        sentence: '물이나 커피를 주세요.',
        translation: 'Give me water or coffee.',
      },
      {
        sentence: '서울이나 부산으로 여행하고 싶어요.',
        translation: 'I want to travel to Seoul or Busan.',
      },
      {
        sentence: '가방이나 옷을 살 거예요.',
        translation: 'I will buy either bags or clothes.',
      },
      {
        sentence: '그는 아이가 열 명이나 있어요.',
        translation: "He has ten children (and it's more than expected)",
      },
      {
        sentence: '친구가 피자 2개나 먹었어요.',
        translation: "My friend ate two pizzas (and it's more than expected)",
      },
    ],
  },
  {
    name: '고',
    description: "'고' means 'and' or 'then.'",
    rule: [
      {
        type: 'OR',
        query: [['고', 'EC']],
      },
    ],
    examples: [
      {
        sentence: '저는 한국에 가고 중국에 갔어요.',
        translation: 'I went to Korea then China.',
      },
      {
        sentence: '저는 카페에 가고 커피를 마셨어요.',
        translation: 'I went to a cafe then drank coffee.',
      },
      {
        sentence: '우리는 저녁을 먹고 영화를 봤어요.',
        translation: 'We ate dinner then watched a movie.',
      },
      {
        sentence: '홍차를 마셨고 친구랑 이야기했어요.',
        translation: 'I drank black tea and talked with my friend.',
      },
      {
        sentence: '공원에서 사람이 많았고 비가 왔어요.',
        translation: 'There was a lot of people at the park and it rained.',
      },
      {
        sentence: '날씨가 좋았고 학교에 갔다 왔어요.',
        translation: 'The weather was good and I went to school and back.',
      },
      {
        sentence: '얘는 잘생기고 똑똑해.',
        translation: "He's handsome and smart.",
      },
      {
        sentence: '우리는 노래도 부르고 춤도 췄어요.',
        translation: 'We sang songs and danced.',
      },
      {
        sentence: '그는 수영하고 농구를 좋아해요.',
        translation: 'He likes swimming and basketball.',
      },
    ],
  },
  {
    name: '고 있다',
    description: "'고 있다' means '-ing' in English. It indicates that an action is being performed.",
    rule: [
      {
        type: 'AND',
        query: [['고', 'EC'], ['있', 'VX']],
      },
    ],
    examples: [
      {
        sentence: '저는 커피를 마시고 있어요.',
        translation: "I'm drinking coffee.",
      },
      {
        sentence: '우리는 전화하고 있어요.',
        translation: 'We are talking on the phone.',
      },
      {
        sentence: '비가 오고 있어요.',
        translation: "It's raining.",
      },
      {
        sentence: '바람이 불고 있어요.',
        translation: 'The wind is blowing.',
      },
      {
        sentence: '여행하고 있어요.',
        translation: 'I am traveling.',
      },
      {
        sentence: '저는 음악을 듣고 있어요.',
        translation: "I'm listening to music.",
      },
      {
        sentence: '저는 집에 가고 있어요.',
        translation: 'I am going home.',
      },
      {
        sentence: '그는 자고 있어.',
        translation: 'He is sleeping.',
      },
      {
        sentence: '저는 한국어를 공부하고 있어요.',
        translation: 'I am studying Korean.',
      },
      {
        sentence: '그녀는 샤워하고 있어요.',
        translation: 'She is showering.',
      },
    ],
  },
  {
    name: '고 싶다',
    description: "'고 싶다' is used to indicate that you want to do something.",
    rule: [
      {
        type: 'AND',
        query: [['고', 'EC'], ['싶', 'VX']],
      },
    ],
    examples: [
      {
        sentence: '저는 한국에 가고 싶어요.',
        translation: 'I want to go to Korea.',
      },
      {
        sentence: '저는 카페에 가고 싶습니다.',
        translation: 'I want to go to the cafe.',
      },
      {
        sentence: '나는 놀이공원에 가고 싶어.',
        translation: 'I want to go to the amusement park.',
      },
      {
        sentence: '제 친구와 이야기하고 싶어요.',
        translation: 'I want to talk to my friend.',
      },
      {
        sentence: '저는 재미있는 영화를 보고 싶어요.',
        translation: 'I want to watch a fun movie.',
      },
    ],
  },
  {
    name: '에게/한테',
    description: "'에게/한테' indicates a recipient.",
    rule: [
      {
        type: 'WORD',
        query: ['한테'],
      },
      {
        type: 'WORD',
        query: ['애게'],
      },
    ],
    examples: [
      {
        sentence: '저는 친구한테 커피를 줬어요.',
        translation: 'I gave coffee to my friend.',
      },
      {
        sentence: '저는 제 선생님에게 전화했어요.',
        translation: 'I called my teacher.',
      },
      {
        sentence: '저는 선생님에게 맛있는 커피를 드렸어요.',
        translation: 'I gave tasty black tea to my teacher.',
      },
      {
        sentence: '저는 친구한테 전화할 거예요.',
        translation: "I'm going to call my friend.",
      },
      {
        sentence: '저는 그에게 커피잔을 줬어요.',
        translation: 'I gave him a coffee cup.',
      },
      {
        sentence: '그녀에게 인형을 주세요.',
        translation: 'Please give the doll to her.',
      },
      {
        sentence: '저는 친구에게 선물을 줬어요.',
        translation: 'I gave a present to my friend.',
      },
      {
        sentence: '누나에게 밥을 요리했어요.',
        translation: 'I cooked food for my older sister.',
      },
      {
        sentence: '그녀는 개한테 밥을 줄 거예요.',
        translation: "She's going to give food to the dog.",
      },
      {
        sentence: '내 동생이 친구한테 전화해요.',
        translation: 'My younger sibling is calling their friend.',
      },
      {
        sentence: '저는 여자친구에게 꽃을 줬어요.',
        translation: 'I gave my girlfriend flowers.',
      },
    ],
  },
  {
    name: '아/어도 되다',
    description: "Use '아/어도 되다' to ask for or give permission.",
    rule: [
      {
        type: 'WORD-AND',
        query: [['도'], ['되', 'VV']],
      },
    ],
    examples: [
      {
        sentence: '한국에 가도 돼요?',
        translation: 'Can I go to Korea?',
      },
      {
        sentence: '텔레비전을 봐도 돼요?',
        translation: 'Can I watch TV?',
      },
      {
        sentence: '테니스를 쳐도 돼요?',
        translation: 'Can we play tennis?',
      },
      {
        sentence: '일요일에 만나도 돼요.',
        translation: 'I can meet on Sunday.',
      },
      {
        sentence: '축구를 해도 돼요?',
        translation: 'Can we play soccer?',
      },
      {
        sentence: '너를 좋아해도 돼?',
        translation: 'Can I like you?',
      },
      {
        sentence: '여기 앉아도 돼요?',
        translation: 'Can I sit here?',
      },
      {
        sentence: '이 컴퓨터를 써도 돼요?',
        translation: 'Can I use this computer?',
      },
      {
        sentence: '텔레비전을 켜도 돼요?',
        translation: 'Can I turn on the TV?',
      },
      {
        sentence: '펜을 써도 돼요?',
        translation: 'Can I use this pen?',
      },
    ],
  },
  {
    name: '(으)러 가다/오다',
    description: "'(으)러 가다/오다' is used to indicate that you are going somewhere to do something.",
    rule: [
      {
        type: 'OR',
        query: [['러', 'EC'], ['으러', 'EC']],
      },
    ],
    examples: [
      {
        sentence: '저는 커피를 마시러 카페에 갔어요.',
        translation: 'I went to the cafe to drink coffee.',
      },
      {
        sentence: '나는 운동하러 공원에 갔어.',
        translation: 'I went to the park to exercise.',
      },
      {
        sentence: '저는 빵을 먹으러 식당에 갈 거예요.',
        translation: "I'm going to go to the restaurant to eat bread.",
      },
      {
        sentence: '저는 축구를 하러 공원에 갔어요.',
        translation: 'I went to the park to play soccer.',
      },
      {
        sentence: '저는 테니스를 치러 공원에 갔어요.',
        translation: 'I went to the park to play tennis.',
      },
      {
        sentence: '저는 쉬러 집에 갈 거예요.',
        translation: "I'm going home to rest.",
      },
    ],
  },
  {
    name: '(으)면 안 되다',
    description: "Use '(으)면 안 되다' to indicate something is not allowed.",
    rule: [
      {
        type: 'AND',
        query: [['면', 'EC'], ['되', 'VV']],
        // ['안', 'MAG']
      },
      {
        type: 'AND',
        query: [['으면', 'EC'], ['되', 'VV']],
        // ['안', 'MAG']
      },
    ],
    examples: [
      {
        sentence: '여기에서 마시면 안 돼요.',
        translation: "You're not allowed to coffee here.",
      },
      {
        sentence: '지금 전화하면 안 돼요.',
        translation: "You're not allowed to call right now.",
      },
      {
        sentence: '여기에서 먹으면 안 돼요.',
        translation: "You're not allowed to eat here.",
      },
      {
        sentence: '문들 닫으면 안 돼요.',
        translation: "You're not allowed to close the door.",
      },
      {
        sentence: '택시를 타면 안 돼요.',
        translation: "You're not allowed to ride the taxi.",
      },
      {
        sentence: '여기 앉으면 안 돼요.',
        translation: "You're not allowed to sit here.",
      },
      {
        sentence: '빠르게 운전하면 안 돼요.',
        translation: "You're not allowed to drive quickly.",
      },
      {
        sentence: '시끄럽게 이야기하면 안 돼요.',
        translation: "You're not allowed to talk loudly.",
      },
      {
        sentence: '여기서 먹으면 안 돼요.',
        translation: "You're not allowed to eat here.",
      },
    ],
  },
  {
    name: '못/지 못해요',
    description: "'못/~지 못해요' means 'cannot.'",
    rule: [
      {
        type: 'OR',
        query: [['못하', 'VX'], ['못', 'MAG']],
      },
    ],
    examples: [
      {
        sentence: '저는 한국에 가지 못해요.',
        translation: "I can't go to Korea.",
      },
      {
        sentence: '저는 한국에 못 가요.',
        translation: "I can't go to Korea.",
      },
      {
        sentence: '저는 공항에 가지 못합니다.',
        translation: "I can't go to the airport.",
      },
      {
        sentence: '우리는 모임에 못 갑니다.',
        translation: "We can't go to the meeting.",
      },
      {
        sentence: '나는 고향에 가지 못해.',
        translation: "I can't go to my hometown.",
      },
      {
        sentence: '나는 너를 만나지 못해.',
        translation: "I can't meet you.",
      },
      {
        sentence: '저는 수영을 못해요.',
        translation: "I can't swim.",
      },
      {
        sentence: '저는 음식을 못 먹어요.',
        translation: "I can't eat food.",
      },
      {
        sentence: '저는 운전하지 못해요.',
        translation: "I can't drive.",
      },
    ],
  },
  {
    name: '아/어서',
    description: "'아/어서' can be used to mean 'in order to.' It can also indicate a reason for something else.",
    rule: [
      {
        type: 'OR',
        query: [['아서', 'EC'], ['어서', 'EC']],
      },
    ],
    examples: [
      {
        sentence: '카페에 가서 커피 마셨어요.',
        translation: 'I went to a cafe and drank coffee.',
      },
      {
        sentence: '카페에 가서 동생을 만났어요.',
        translation: 'I went to a cafe and met my sister.',
      },
      {
        sentence: '우리는 식당에 가서 저녁을 먹을 거예요.',
        translation: "We're going to go to a cafe and eat dinner.",
      },
      {
        sentence: '비빔밥은 맛있어서 많이 먹었어요.',
        translation: 'The bibimbap was tasty so I ate a lot.',
      },
      {
        sentence: '커피를 마시고 싶어서 카페에 갔어요.',
        translation: 'I wanted to drink coffee so I went to the cafe.',
      },
      {
        sentence: '영화를 보고 싶어서 극장에 갔어요.',
        translation: 'I wanted to watch a movie so I went to the theatre.',
      },
      {
        sentence: '우리는 저녁을 요리해서 먹었어요.',
        translation: 'We cooked dinner and ate it.',
      },
      {
        sentence: '그녀는 바나나를 까서 먹었어요.',
        translation: 'She peeled the banana and ate it.',
      },
      {
        sentence: '우리는 표를 사서 영화를 봤어요.',
        translation: 'We bought tickets and watched a movie.',
      },
      {
        sentence: '내일 친구를 만나서 카페에 갈 거예요.',
        translation: 'Tomorrow, I will meet a friend and go to a cafe.',
      },
    ],
  },
  {
    name: '아/어 보다',
    description: "Use '아/어 보다' to indicate that someone is trying an action.",
    rule: [
      {
        type: 'AND',
        query: [['아', 'EC'], ['보', 'VV']],
      },
      {
        type: 'AND',
        query: [['어', 'EC'], ['보', 'VV']],
      },
    ],
    examples: [
      {
        sentence: '한국에 가 봤어요?',
        translation: 'Have you been to Korea before?',
      },
      {
        sentence: '한국에 가 보세요.',
        translation: 'Try going to Korea.',
      },
      {
        sentence: '한국에 가 보고 싶어요?',
        translation: 'Do you want to try going to Korea?.',
      },
      {
        sentence: '제주도에 가 봤어요?',
        translation: 'Have you tried going to Jeju Island?.',
      },
      {
        sentence: '바다에 가 보세요.',
        translation: 'Try going to the ocean.',
      },
      {
        sentence: '한라산에 가 보고 싶어요?',
        translation: 'Do you want to try going to Halla Mountain?',
      },
      {
        sentence: '여행해 볼 거예요.',
        translation: "I'm going to try traveling.",
      },
      {
        sentence: '산에 올라가 보세요.',
        translation: 'Try going up the mountain.',
      },
    ],
  },
  {
    name: '지요',
    description: "Use '지요' to get confirmation.",
    rule: [
      {
        type: 'OR',
        query: [['지요', 'EF'], ['죠', 'EF'], ['지', 'EF'], ['아야죠', 'EF']],
      },
    ],
    examples: [
      {
        sentence: '한국에 갔지요?',
        translation: 'You went to Korea, right?',
      },
      {
        sentence: '제주도에 갔죠?',
        translation: 'You went to Jeju Island, right?',
      },
      {
        sentence: '할라산에 갔지?',
        translation: 'You went to Halla Mountain, right?',
      },
      {
        sentence: '영화를 보고 싶지요?',
        translation: 'You want to watch a movie, right?',
      },
      {
        sentence: '한국어를 좋아하죠?',
        translation: 'You like the Korean language, right?',
      },
      {
        sentence: '한국어를 배우고 싶죠?',
        translation: 'You want to learn the Korean language, right?',
      },
      {
        sentence: '오늘 바쁘죠?',
        translation: "You're busy today, right?",
      },
      {
        sentence: '한국어를 공부하고 있지?',
        translation: "You're studying the Korean language, right?",
      },
    ],
  },
  {
    name: '(으)로',
    description: "'(으)로' is used to indicate a direction or how something is done.",
    rule: [
      {
        type: 'OR',
        query: [['로', 'JKB'], ['으로', 'JKB'], ('저리로', 'MAG')],
      },
    ],
    examples: [
      {
        sentence: '저는 택시로 갈 거예요.',
        translation: 'I will go by taxi.',
      },
      {
        sentence: '저는 지하철로 식당에 갈 거예요.',
        translation: 'I will go to the restaurant by subway.',
      },
      {
        sentence: '오른쪽으로 가세요.',
        translation: 'Please take a right.',
      },
      {
        sentence: '스마트폰으로 영화를 봤어요.',
        translation: 'I watched a movie with my smartphone.',
      },
      {
        sentence: '스마트폰으로 한국말을 공부하고 있어요.',
        translation: "I'm studying Korean with my smartphone.",
      },
      {
        sentence: '저는 비행기로 갈 거예요.',
        translation: 'I will go by airplane.',
      },
      {
        sentence: '가위로 종이를 잘랐어요.',
        translation: 'I cut the paper with scissors.',
      },
      {
        sentence: '포도로 와인을 만들었어요.',
        translation: 'I made wine from grapes.',
      },
    ],
  },
  {
    name: '까지',
    description: "'까지' means 'to/until.' '에서...까지' means 'from....to', '부터...까지' means 'from...until.'",
    rule: [
      {
        type: 'WORD',
        query: ['까지'],
      },
    ],
    examples: [
      {
        sentence: '집에서 학교까지 갔어요.',
        translation: 'I went to school from home.',
      },
      {
        sentence: '여기에서 지하철까지 어떻게 가요?',
        translation: 'How do I get to the subway from here?',
      },
      {
        sentence: '저는 아침부터 저녁까지 일했어요.',
        translation: 'I worked from morning until night.',
      },
      {
        sentence: 'LA에서 서울까지 얼마나 걸려요?',
        translation: 'How long does it take to get from LA to Seoul?',
      },
      {
        sentence: '5시부터 10시까지 저녁시간입니다.',
        translation: 'Dinner time is from 5pm to 10pm. (다섯시.. 열시)',
      },
      {
        sentence: '오후 1시부터 3시까지 점심시간입니다.',
        translation: 'Lunchtime is from 1pm to 3pm.',
      },
    ],
  },
  {
    name: '(으)ㄴ/는데',
    description: "'(으)ㄴ/는데' can mean 'but' or be used to give background information.",
    rule: [
      {
        type: 'OR',
        query: [['는데', 'EC'], ['은데', 'EC'], ['ㄴ데', 'EC']],
      },
    ],
    examples: [
      {
        sentence: '그녀는 한국을 좋아하는데 한국어를 못 해요.',
        translation: "She likes Korea but can't speak Korean.",
      },
      {
        sentence: '나는 과일을 먹고 싶은데 집에서 과일이 없어.',
        translation: "I want to eat fruit, but there's no fruit at home.",
      },
      {
        sentence: '오늘 시간이 없는데 내일 만나도 돼요?',
        translation: "I don't have time today, but can we meet tomorrow?",
      },
      {
        sentence: '집이 좋은데 싸요.',
        translation: 'The house is good but cheap.',
      },
      {
        sentence: '날씨 좀 좋은데 공원 갈까요?',
        translation: 'The weather is pretty nice today, do you want to go to the park?',
      },
      {
        sentence: '한국으로 여행할 건데 같이 갈래요?',
        translation: "I'm going to travel to Korea, do you want to go together?",
      },
      {
        sentence: '나는 영화를 보고 싶은데 영화관에 같이 갈래?',
        translation: 'I want to watch a movie, do you want to go to the movie theatre together?',
      },
      {
        sentence: '나는 셔츠를 살 건데 가게에 같이 갈래?',
        translation: "I'm going to buy a shirt, do you want to go to the store together?",
      },
      {
        sentence: '그녀는 예쁜데 성격이 나빠요.',
        translation: 'She is pretty but her personality is bad.',
      },
      {
        sentence: '나는 한국어를 못하는데 열심히 공부할 거예요.',
        translation: 'I am not good at Korean but I will study hard.',
      },
      {
        sentence: '저는 누나가 있는데 형은 없어요.',
        translation: 'I have an older sister but not an older brother.',
      },
      {
        sentence: '집이 비싼데 커요.',
        translation: 'The house is expensive but big.',
      },
    ],
  },
  {
    name: '니까',
    description: "Use '니까' to indicate a reason for something or that you did an action and discovered something.",
    rule: [
      {
        type: 'OR',
        query: [['니까', 'EC'], ['으니까', 'EC']],
      },
    ],
    examples: [
      {
        sentence: '한국을 좋아하니까 한국어를 공부해요.',
        translation: 'I study Korean because I like Korea.',
      },
      {
        sentence: '날씨가 좋으니까 공원에 갔어요.',
        translation: 'I went to the park because the weather is good.',
      },
      {
        sentence: '영화가 너무 재미없으니까 잤어요.',
        translation: 'I slept because the movie is very boring.',
      },
      {
        sentence: '과일이 맛있으니까 많이 먹었어요.',
        translation: 'I ate a lot because the fruit was tasty.',
      },
      {
        sentence: '과일을 먹고 싶으니까 시장에 갔어요.',
        translation: 'I went to the market because I wanted to eat fruit.',
      },
      {
        sentence: '공원에 가니까 사람이 많았어요.',
        translation: 'I went to the park and (discovered that) there were a lot of people.',
      },
      {
        sentence: '가게에 가니까 맛있는 과일이 있어요.',
        translation: 'I went to the shop and (discovered that) there was tasty fruit.',
      },
      {
        sentence: '아침에 일어나니까 맛있는 커피가 있어요.',
        translation: 'I woke up in the morning and (discovered that) there was tasty coffee.',
      },
    ],
  },
  {
    name: '(으)면',
    description: "'(으)면' means 'if.'",
    rule: [
      {
        type: 'OR',
        query: [['면', 'EC'], ['다면', 'EC'], ['으면', 'EC'], ['라면', 'EC']],
      },
    ],
    examples: [
      {
        sentence: '한국어를 배우면 한국에 갈 거예요.',
        translation: 'If I learn Korean, I will go to Korea.',
      },
      {
        sentence: '시간이 있다면 커피 한 잔 마실까요?',
        translation: 'If you have time, do you want to get a cup of coffee.',
      },
      {
        sentence: '날씨가 좋으면 공원에 갈까요?',
        translation: 'If the weather is good, do you want to go to the park?',
      },
      {
        sentence: '아프면 쉬세요.',
        translation: "If you're hurt/sick, then rest.",
      },
      {
        sentence: '아프면 병워에 가세요.',
        translation: "If you're hurt/sick, then go to the hospital.",
      },
      {
        sentence: '시간이 많이 걸리지 않으면 파티에 갈 거예요.',
        translation: "If it doesn't take a lot of time, I will go to the party.",
      },
    ],
  },
  {
    name: '후에, (으)ㄴ 후에',
    description: "'후에' means 'after' or 'later'. Use '후에' after a noun and '(으)ㄴ 후에' after a verb.",
    rule: [
      {
        type: 'OR',
        query: [['후', 'NNG']],
      },
    ],
    examples: [
      {
        sentence: '1년 후에 시험 있어요.',
        translation: 'I have a test in 1 year.',
      },
      {
        sentence: '저녁을 먹은 후에 커피를 마셔요.',
        translation: 'I drink coffee after I eat dinner.',
      },
      {
        sentence: '한국어를 배운 후에 한국에 갈 거예요.',
        translation: "I'm going to go to Korea after I learn Korean.",
      },
      {
        sentence: '운동 후에 저녁을 드세요.',
        translation: 'Please eat dinner after exercising.',
      },
      {
        sentence: '한 달 후에 시험 있어요.',
        translation: 'I have a test in 1 month.',
      },
      {
        sentence: '식사 후에 아이스크림 먹을 거예요.',
        translation: "I'm going to eat ice cream after my meal.",
      },
      {
        sentence: '1년 후에 서울에 갈 거예요.',
        translation: "I'm going to Seoul after 1 year.",
      },
      {
        sentence: '운동 후에 점심 먹어요.',
        translation: 'I eat lunch after an exercise.',
      },
    ],
  },
  {
    name: '전에/기 전에',
    description: "'전에/기 전에' means 'before.'",
    rule: [
      {
        type: 'OR',
        query: [['전', 'NNG']],
      },
    ],
    examples: [
      {
        sentence: '한국 가기 전에 한국어를 배울 거예요.',
        translation: "Before I go to Korea, I'm going to learn Korean.",
      },
      {
        sentence: '저녁을 먹기 전에 운동할 거야.',
        translation: "Before I eat dinner, I'm going to exercise.",
      },
      {
        sentence: '약을 먹기 전에 저녁을 먹어.',
        translation: 'Before you eat medicine, eat dinner.',
      },
      {
        sentence: '저는 1년 전에 아팠어요.',
        translation: 'I was sick 1 year ago.',
      },
      {
        sentence: '식당 가기 전에 숙제해야 돼요.',
        translation: 'I have to finish my homework before I go to the cafeteria.',
      },
      {
        sentence: '점심 먹기 전에 운동할 거야.',
        translation: "Before I eat lunch, I'm going to exercise.",
      },
      {
        sentence: '우리는 5년 전에 결혼했어요.',
        translation: 'We married 5 years ago.',
      },
      {
        sentence: '운동하기 전에 스트레칭하세요.',
        translation: 'Stretch before you exercise.',
      },
    ],
  },
  {
    name: '아/어야',
    description: "Use '아/어야' to indicate that someone must do something.",
    rule: [
      {
        type: 'OR',
        query: [['아야', 'EC'], ['어야', 'EC'], ['아야죠', 'EF'], ['가야', 'NNP']],
      },
    ],
    examples: [
      {
        sentence: '저는 한국어를 공부해야 돼요.',
        translation: 'I have to study Korean.',
      },
      {
        sentence: '먹기 전에 예매해야 해요.',
        translation: 'You have to make a reservation before you eat.',
      },
      {
        sentence: '친구들을 만나야 돼요.',
        translation: 'I have to meet my friends.',
      },
      {
        sentence: '오늘 친구랑 영화를 봐야 돼요.',
        translation: 'I have to watch a movie with my friend today.',
      },
      {
        sentence: '먹기 전에 돈을 내야 해요.',
        translation: 'You have to pay before you eat.',
      },
      {
        sentence: '방을 청소해야 해요.',
        translation: 'I have to clean my room.',
      },
    ],
  },
  {
    name: '지만',
    description: "'지만' means 'but.'",
    rule: [
      {
        type: 'OR',
        query: [['지만', 'EC']],
      },
    ],
    examples: [
      {
        sentence: '그는 한국을 좋아하지만 한국어를 못 해요.',
        translation: "He likes Korea but can't speak Korean.",
      },
      {
        sentence: '한국어는 어렵지만 재미있어요.',
        translation: 'Korean is difficult but interesting.',
      },
      {
        sentence: '저는 시간이 없지만 영화를 보고 싶어요.',
        translation: "I don't have time, but I want to watch a movie.",
      },
      {
        sentence: '저는 바쁘지만 한국어를 공부해야 돼요.',
        translation: "I'm busy, but I need to study Korean.",
      },
      {
        sentence: '한국어를 배우는 거 어렵지만 재미있어요.',
        translation: 'Learning Korean is hard but fun.',
      },
      {
        sentence: '케이크는 맛있지만 건강에 안 좋아요.',
        translation: 'Cake is tasty but not good for your health.',
      },
      {
        sentence: '이 책은 길지만 재미있어요.',
        translation: 'This book is long but interesting.',
      },
      {
        sentence: '그는 외국인이지만 한국어를 잘해요.',
        translation: "He's a foreigner but is good at Korean.",
      },
    ],
  },
  {
    name: '지 마세요',
    description: "Use '지 마세요' to politely request not to do something.",
    rule: [
      {
        type: 'WORD-AND',
        query: [['지'], ['말', 'VX']],
      },
    ],
    examples: [
      {
        sentence: '커피를 마시지 마세요.',
        translation: "Please don't drink coffee.",
      },
      {
        sentence: '그녀에게 전화하지 마세요.',
        translation: "Please don't call her.",
      },
      {
        sentence: '거기로 가지 마세요.',
        translation: "Please don't go there.",
      },
      {
        sentence: '모르면 이야기하지 마.',
        translation: "If you don't know, don't talk.",
      },
      {
        sentence: '여기에서 주차하지 마세요.',
        translation: "Please don't park here.",
      },
      {
        sentence: '담배를 피우지 마.',
        translation: "Don't smoke cigarettes.",
      },
      {
        sentence: '여기 앉지 마세요.',
        translation: "Please don't sit here.",
      },
      {
        sentence: '들어가지 마세요.',
        translation: "Please don't enter.",
      },
    ],
  },
  {
    name: '네요',
    description: "Use '네요' to express surprise.",
    rule: [
      {
        type: 'OR',
        query: [['네요', 'EF'], ['네', 'EC'], ['네', 'EF']],
      },
    ],
    examples: [
      {
        sentence: '한국어가 좋네요.',
        translation: 'Wow, your Korean is good.',
      },
      {
        sentence: '노래가 멋있네요.',
        translation: 'Wow, this song is awesome.',
      },
      {
        sentence: '사람이 엄청 많네',
        translation: "Wow, there's a lot of people.",
      },
      {
        sentence: '한국어가 많이 늘었네요.',
        translation: 'Wow, your Korean improved a lot',
      },
    ],
  },
  {
    name: '와/과, 랑, 하고',
    description: "'와/과', '랑', '하고' mean 'and.'",
    rule: [
      {
        type: 'OR',
        query: [['과', 'JC'], ['랑', 'JKB'], ['와', 'JKB'], ['하고', 'JKB'], ['이랑', 'NNP']],
      },
    ],
    examples: [
      {
        sentence: '저는 한국과 중국을 좋아해요.',
        translation: 'I like Korea and China.',
      },
      {
        sentence: '저는 그녀랑 전화해요.',
        translation: 'I am calling with her.',
      },
      {
        sentence: '저는 그와 일해요.',
        translation: 'I am working with him.',
      },
      {
        sentence: '저는 선생님하고 쉬어요.',
        translation: 'I am resting with the teacher.',
      },
      {
        sentence: '저는 바지와 티셔츠를 샀어요.',
        translation: 'I bought pants and a t-shirt.',
      },
      {
        sentence: '저는 한국어와 중국어를 좋아해요.',
        translation: 'I like Korean and Chinese.',
      },
      {
        sentence: '저는 친구랑 여자친구를 만났어요.',
        translation: 'I met with my friend and my girlfriend.',
      },
      {
        sentence: '저는 햄버거하고 물 주세요.',
        translation: 'Please give me a hamburger and water.',
      },
      {
        sentence: '저는 학생이랑 이야기해요.',
        translation: 'I am talking with the student.',
      },
    ],
  },
  {
    name: '(으)ㄴ',
    description: "Add '(으)ㄴ' to an adjective to make the adjective describe a noun. You can also add ('으)ㄴ' to a past tense verb to make it describe a noun.",
    rule: [
      {
        type: 'OR',
        query: [['ㄴ', 'ETM'], [('은', 'ETM')]],
      },
    ],
    examples: [
      {
        sentence: '예쁜 꽃이다.',
        translation: "It's a pretty flower.",
      },
      {
        sentence: '어려운 시험이야.',
        translation: "It's a difficult test.",
      },
      {
        sentence: '쉬운 문제야.',
        translation: "It's an easy problem.",
      },
      {
        sentence: '비싼 신발이다.',
        translation: 'They are expensive shoes.',
      },
    ],
  },
  {
    name: '(으)ㄹ',
    description: "Add '(으)ㄹ' to a future tense verb to make it describe a noun.",
    rule: [
      {
        type: 'AND',
        query: [['ㄹ', 'ETM']],
      },
    ],
    examples: [
      {
        sentence: '여행할 때 가져갈 가방이다.',
        translation: "It's a bag that I will bring when I travel.",
      },
      {
        sentence: '저녁에 요리할 재료들이야.',
        translation: 'These are the ingredients I will cook for dinner.',
      },
      {
        sentence: '제가 사용할 물건들입니다.',
        translation: 'These are the things I will use.',
      },
      {
        sentence: '내가 필요할 도구들이야.',
        translation: 'These are the tools I need.',
      },
      {
        sentence: '우리가 구매할 새로운 차야.',
        translation: 'This is the new car that we will purchase.',
      },
    ],
  },
  {
    name: '았/었었어요',
    description: "Use '았/었었어요' to signify that something happened in the past but is not happening anymore.",
    rule: [
      {
        type: 'AND',
        query: [['있', 'VX'], ['었', 'EP']],
      },
      {
        type: 'AND',
        query: [['았었', 'EP']],
      },
    ],
    examples: [
      {
        sentence: '저는 음악을 듣고 있었어요.',
        translation: 'I was listening to music.',
      },
      {
        sentence: '저는 한국에 갔었어요.',
        translation: 'I have been to Korea.',
      },
      {
        sentence: '그는 자고 있었어요.',
        translation: 'He was sleeping.',
      },
      {
        sentence: '저는 한국에서 살았었어요.',
        translation: 'I have lived in Korea.',
      },
      {
        sentence: '그녀는 뚱뚱했었어요.',
        translation: 'She was overweight (but not anymore).',
      },
    ],
  },
  {
    name: '안/~지 않아요',
    description: "'안/~지 않아요' is one of the ways to negate a word.",
    rule: [
      {
        type: 'AND',
        query: [['지', 'EC'], ['않', 'VX']],
      },
      {
        type: 'AND',
        query: [['안', 'MAG']],
      },
    ],
    examples: [
      {
        sentence: '저는 파스타 먹지 않아요.',
        translation: "I don't eat pasta",
      },
      {
        sentence: '이 옷은 예쁘지 않아요.',
        translation: "These clothes aren't pretty",
      },
      {
        sentence: '저는 차를 안 운전해요.',
        translation: "I don't drive",
      },
      {
        sentence: '저는 안 불편해요.',
        translation: "I'm not uncomfortable",
      },
      {
        sentence: '저는 너를 좋아하지 않아요.',
        translation: "I don't like you",
      },
    ],
  },
  {
    name: '밖에',
    description: "'밖에' is used to indicate that only one thing or option is available.",
    rule: [
      {
        type: 'AND',
        query: [['밖', 'NNG'], ['에', 'JKB']],
      },
      {
        type: 'AND',
        query: [['밖에', 'JX']],
      },
    ],
    examples: [
      {
        sentence: '천원 밖에 없어요.',
        translation: 'I only have 1000 won left.',
      },
      {
        sentence: '저는 물 밖에 없어요.',
        translation: 'I only have water.',
      },
      {
        sentence: '그녀는 영어밖에 몰라요.',
        translation: "I don't know any language besides English.",
      },
      {
        sentence: '30분 밖에 없어요.',
        translation: 'I only have 30 minutes.',
      },
    ],
  },
  {
    name: '쯤',
    description: "'쯤' means 'about' or 'around.'",
    rule: [
      {
        type: 'AND',
        query: [['쯤', 'NNB']],
      },
    ],
    examples: [
      {
        sentence: '나는 5000원쯤 있어요.',
        translation: 'I have around 5000 won.',
      },
      {
        sentence: '50명쯤 있어요.',
        translation: 'There are around 50 people.',
      },
      {
        sentence: '지금 두 시쯤이에요.',
        translation: "Right now is around 2 o'clock.",
      },
      {
        sentence: '1년 전쯤 한국에 왔어요.',
        translation: 'I came to Korea around 1 year ago.',
      },
    ],
  },
  {
    name: '처럼, 같이',
    description: "'처럼, 같이' mean 'like' or 'as.'",
    rule: [
      {
        type: 'WORD',
        query: ['처럼'],
      },
      {
        type: 'AND',
        query: [['같이', 'JKB']],
      },
    ],
    examples: [
      {
        sentence: '그는 물고기처럼 수영해요.',
        translation: 'He swims like a fish.',
      },
      {
        sentence: '제 인생은 영화같이 아름다워요.',
        translation: 'My life is as beautiful as a movie.',
      },
      {
        sentence: '그녀는 꽃처럼 예쁘다.',
        translation: "She's as pretty as a flower.",
      },
      {
        sentence: '그는 나무같이 크다.',
        translation: 'He is big like a tree.',
      },
    ],
  },
  {
    name: '보다',
    description: "'보다' means 'more than.'",
    rule: [
      {
        type: 'AND',
        query: [['보다', 'JKB']],
      },
    ],
    examples: [
      {
        sentence: '차는 말보다 빠르다.',
        translation: 'Cars are faster than horses.',
      },
      {
        sentence: '겨울보다 여름을 좋아해요.',
        translation: 'I like summer more than winter.',
      },
      {
        sentence: '사과보다 수박이 맛있다.',
        translation: 'Watermelons are tastier than apples.',
      },
      {
        sentence: '형보다 동생이 키가 크다.',
        translation: 'The younger brother is taller than the older brother.',
      },
    ],
  },
  {
    name: '마다',
    description: "'마다' means 'each.'",
    rule: [
      {
        type: 'AND',
        query: [['마다', 'JX']],
      },
    ],
    examples: [
      {
        sentence: '저는 날마다 게임을 해요.',
        translation: 'I play games everyday.',
      },
      {
        sentence: '나라마다 국기가 달라요.',
        translation: 'Each country has a different flag.',
      },
      {
        sentence: '주말마다 영화를 봐요.',
        translation: 'I watch a movie each weekend.',
      },
      {
        sentence: '금요일마다 친구랑 요래해요.',
        translation: 'I cook with my friends each Friday.',
      },
    ],
  },
  {
    name: '거나',
    description: "'거나' means 'or' and is attached to verbs.",
    rule: [
      {
        type: 'AND',
        query: [['거나', 'EC']],
      },
    ],
    examples: [
      {
        sentence: '집에 도착한 후에 저는 영화 보거나 음식을 요리해요.',
        translation: 'I watch movies or cook food after I get home.',
      },
      {
        sentence: '바쁘거나 피곤할 때 배달 음식을 시켜요.',
        translation: "I order delivery food when I'm busy or tired.",
      },
      {
        sentence: '아침에 빵을 먹거나 커피를 마셔요.',
        translation: 'In the morning, I eat bread or drink coffee.',
      },
      {
        sentence: '주말에 자거나 영화를 봐요.',
        translation: 'On the weekends, I sleep or watch movies.',
      },
    ],
  },
  {
    name: '고 나서',
    description: "Use '고 나서' to showcase that one action is done right after another action.",
    rule: [
      {
        type: 'AND',
        query: [['고', 'EC'], ['나서', 'VV']],
      },
    ],
    examples: [
      {
        sentence: '일을 하고 나서 집에 가요.',
        translation: 'I go home after finishing my work.',
      },
      {
        sentence: '얼굴 세수하고 나서 자요.',
        translation: 'I sleep after washing my face.',
      },
      {
        sentence: '일어나고 나서 운동해요.',
        translation: 'I exercise after I wake up.',
      },
      {
        sentence: '회의가 끝나고 나서 쉬세요.',
        translation: 'Please rest after the meeting ends.',
      },
    ],
  },
  {
    name: '때, (으)ㄹ 때',
    description: "'때' means 'when'. '때' is used after a noun and '(으)ㄹ 때' is used after a verb.",
    rule: [
      {
        type: 'AND',
        query: [['때', 'NNG']],
      },
    ],
    examples: [
      {
        sentence: '친구랑 밥 먹을 때 행복해요.',
        translation: "I'm happy when I eat with friends.",
      },
      {
        sentence: '제가 자고 있을 때 전화하지 마세요.',
        translation: "Don't call me when I'm sleeping.",
      },
      {
        sentence: '피곤할 때 운동하세요.',
        translation: "Exercise when you're tired.",
      },
      {
        sentence: '점심 때 스시 먹고 싶어요.',
        translation: 'I want to eat sushi during lunch.',
      },
      {
        sentence: '집에 있을 때 뭐 해?',
        translation: "What do you do when you're at home?",
      },
    ],
  },
  {
    name: '(으)면서',
    description: "'(으)면서' is used to indicate two actions that happen at the same time.",
    rule: [
      {
        type: 'AND',
        query: [['면서', 'EC']],
      },
    ],
    examples: [
      {
        sentence: '운전하면서 전화하지 마세요.',
        translation: "Don't call (phone) while driving.",
      },
      {
        sentence: '텔레비전을 보면서 점심 먹었어요.',
        translation: 'I ate lunch while watching TV.',
      },
      {
        sentence: '일을 하면서 음악 들어요.',
        translation: 'I listen to music while working.',
      },
      {
        sentence: '커피 마시면서 책을 읽어요.',
        translation: 'I read books while drinking coffee.',
      },
    ],
  },
  {
    name: '중, 는중',
    description: "'중' means 'in the middle of'. Use '중' after a noun and '는중' after a verb.",
    rule: [
      {
        type: 'AND',
        query: [['중', 'NNB']],
      },
    ],
    examples: [
      {
        sentence: '저는 공부하는 중이에요.',
        translation: "I'm in the middle of studying.",
      },
      {
        sentence: '우리는 회의 중입니다.',
        translation: 'We are in the middle of a meeting.',
      },
      {
        sentence: '저는 서울로 가는 중이에요.',
        translation: "I'm in the middle of going to Korea.",
      },
      {
        sentence: '그는 샤워하는 중이야.',
        translation: "He's in the middle of showering.",
      },
    ],
  },
  {
    name: '자마자',
    description: "'자마자' means 'right after' or 'as soon as.'",
    rule: [
      {
        type: 'AND',
        query: [['자마자', 'EC']],
      },
    ],
    examples: [
      {
        sentence: '저는 일어나자마자 샤워해요.',
        translation: 'I shower right after I wake up.',
      },
      {
        sentence: '저는 대학교를 졸업하자마자 일을 할 거예요.',
        translation: "I'm going to work right after I graduate from college.",
      },
      {
        sentence: '그는 집에 도착하자마자 잤어요.',
        translation: 'He slept as soon as he got home.',
      },
      {
        sentence: '그는 여자친구를 보자마자 그녀를 안았어요.',
        translation: 'He hugged his girlfriend as soon as he saw her.',
      },
    ],
  },
  {
    name: '동안, 는 동안',
    description: "'동안' means 'while' or 'during'. Use '동안' after a noun and use '는 동안' after a verb.",
    rule: [
      {
        type: 'AND',
        query: [['동안', 'NNG']],
      },
    ],
    examples: [
      {
        sentence: '우리는 3시간 동안 얘기했어요.',
        translation: 'We talked for 3 hours.',
      },
      {
        sentence: '학생들은 여름방학 동안 뭐해요?',
        translation: 'What do students do during summer vacation?',
      },
      {
        sentence: '우리는 치킨 먹는 동안 맥주 마셔요.',
        translation: 'We drink beer while eating chicken.',
      },
      {
        sentence: '저는 30분 동안 한국어를 공부했어요.',
        translation: 'I studied Korean for 30 minutes.',
      },
    ],
  },
  {
    name: '(으)ㄴ 지',
    description: "Use '(으)ㄴ 지' to show how much time has passed.",
    rule: [
      {
        type: 'AND',
        query: [['ㄴ', 'ETM'], ['지', 'NNB']],
      },
    ],
    examples: [
      {
        sentence: '저는 한국에 온 지 1년이 되었어요.',
        translation: "It's been 1 year since I've come to Korea.",
      },
      {
        sentence: '한국어를 공부한 지 2년이 됐어요.',
        translation: "It's been 2 years since I've started studying Korean.",
      },
      {
        sentence: '우리는 사귄 지 6개월 넘었어요.',
        translation: "It's been 6 months since we started dating.",
      },
    ],
  },
  {
    name: '(으)ㄹ 수 있다/없다',
    description: "Use '(으)ㄹ 수 있다/없다' to indicate that something is or is not possible.",
    rule: [
      {
        type: 'AND',
        query: [['ㄹ', 'ETM'], ['수', 'NNB']],
      },
      {
        type: 'AND',
        query: [['을', 'ETM'], ['수', 'NNB']],
      },
    ],
    examples: [
      {
        sentence: '나는 할 수 없어.',
        translation: "I can't do it.",
      },
      {
        sentence: '나는 수영할 수 없어.',
        translation: "I can't swim.",
      },
      {
        sentence: '저는 한국 영화를 이해할 수 없어요.',
        translation: "I can't understand Korean movies.",
      },
      {
        sentence: '내일 점심 같이 먹을 수 있어요!',
        translation: 'I can eat lunch with you tomorrow!',
      },
    ],
  },
  {
    name: '(으)ㄹ 줄 알다/모르다',
    description: "Use '(으)ㄹ 줄 알다/모르다' to indicate that you know or don't know how to do something.",
    rule: [
      {
        type: 'AND',
        query: [['ㄹ', 'ETM'], ['주', 'VX'], ['ㄹ', 'ETM'], ['알', 'VV']],
      },
      {
        type: 'AND',
        query: [['ㄹ', 'ETM'], ['주', 'VX'], ['ㄹ', 'ETM'], ['모르', 'VV']],
      },
    ],
    examples: [
      {
        sentence: '저는 볶음밥 만들 줄 알아요.',
        translation: 'I know how to make fried rice.',
      },
      {
        sentence: '비디오게임할 줄 몰라요.',
        translation: "I don't know how to play videogames.",
      },
      {
        sentence: '농구할 줄 알아요?',
        translation: 'Do you know how to play basketball?',
      },
      {
        sentence: '컴퓨터 사용할 줄 알아요?',
        translation: 'Do you know how to use the computer?',
      },
    ],
  },
  {
    name: '지 않아도 되다, 안... 아/어도 되다',
    description: "Use '지 않아도 되다, 안... 아/어도 되다' to indicate that something is not required.",
    rule: [
      {
        type: 'AND',
        query: [['지', 'EC'], ['않', 'VX'], ['아도', 'EC'], ['되', 'VV']],
      },
      {
        type: 'AND',
        query: [['안', 'MAG'], ['어도', 'EC'], ['되', 'VV']],
      },
      {
        type: 'AND',
        query: [['안', 'MAG'], ['아도', 'EC'], ['되', 'VV']],
      },
    ],
    examples: [
      {
        sentence: '숙제를 하지 않아도 돼요.',
        translation: "You don't have to do the homework.",
      },
      {
        sentence: '우리는 같이 안 먹어도 돼요.',
        translation: "We don't have to eat together.",
      },
      {
        sentence: '영화를 보지 않아도 돼요.',
        translation: "You don't have to watch the movie.",
      },
      {
        sentence: '일을 안 끝내도 돼요.',
        translation: "You don't have to finish your work.",
      },
    ],
  },
  {
    name: '았/었으면 좋겠다',
    description: "'았/었으면 좋겠다' is used to express hope or desire. It roughly translates to 'It would be good if I...'",
    rule: [
      {
        type: 'AND',
        query: [['았었', 'EP'], ['으면', 'EC'], ['좋', 'VA']],
      },
      {
        type: 'AND',
        query: [['았', 'EP'], ['으면', 'EC'], ['좋', 'VA']],
      },
      {
        type: 'AND',
        query: [['었', 'EP'], ['으면', 'EC'], ['좋', 'VA']],
      },
    ],
    examples: [
      {
        sentence: '비가 왔었으면 좋겠다.',
        translation: 'I wish it would rain.',
      },
      {
        sentence: '돈이 많았으면 좋겠다.',
        translation: 'I wish I had a lot of money.',
      },
      {
        sentence: '키가 더 컸으면 좋겠다.',
        translation: 'I wish I were taller.',
      },
      {
        sentence: '문제가 없었으면 좋겠다.',
        translation: 'I wish there were no problems.',
      },
      {
        sentence: '뉴욕에서 살았으면 좋겠다.',
        translation: 'I wish I lived in New York.',
      },
    ],
  },
  {
    name: '때문에, 기 때문에',
    description: "'때문에, 기 때문에' mean 'because.' '때문에' is used after nouns and '기 때문에' is used after adjectives and verbs.",
    rule: [
      {
        type: 'AND',
        query: [['때문', 'NNB']],
      },
    ],
    examples: [
      {
        sentence: '일 때문에 바뻐.',
        translation: "I'm busy because of work.",
      },
      {
        sentence: '바람 때문에 춥다.',
        translation: "It's cold because of the wind.",
      },
      {
        sentence: '친구 때문에 화났어.',
        translation: "I'm mad because of my friend.",
      },
      {
        sentence: '피곤하기 때문에 잘 거야.',
        translation: "I'm going to sleep because I'm tired.",
      },
      {
        sentence: '배고프기 때문에 음식을 먹을 거야.',
        translation: "I'm going to eat food because I'm hungry.",
      },
      {
        sentence: '궁금하기 때문에 물어본 거야.',
        translation: 'I asked because I am curious.',
      },
    ],
  },
  {
    name: '아/어 주세요, 아/어 주시겠어요?',
    description: "'아/어 주세요, 아/어 주시겠어요?' are used to politely make requests.",
    rule: [
      {
        type: 'WORD-AND',
        query: [['주'], ['시', 'EP']],
      },
    ],
    examples: [
      {
        sentence: '그녀에게 이 편지를 전해 주세요.',
        translation: 'Please deliver this letter to her.',
      },
      {
        sentence: '물 한 잔 주시겠어요?',
        translation: 'Will you give me a glass of water please?',
      },
      {
        sentence: '저는 콜라 주세요.',
        translation: 'Please give me a coke.',
      },
      {
        sentence: '우리를 도와주시겠어요?',
        translation: 'Can you help us?',
      },
      {
        sentence: '안 바쁠 때 저에게 전화 주세요.',
        translation: "Please give me a call when you're not busy.",
      },
    ],
  },
  {
    name: '아/어 줄게요, 아/어 줄까요?',
    description: "'아/어 줄게요, 아/어 줄까요?' is used to indicate that you will help someone or to ask someone if they need your help.",
    rule: [
      {
        type: 'AND',
        query: [['주', 'VX'], ['ㄹ게요', 'EF']],
      },
      {
        type: 'AND',
        query: [['주', 'VX'], ['ㄹ까요', 'EF']],
      },
      {
        type: 'AND',
        query: [['주', 'VX'], ['ㄹ게', 'EF']],
      },
      {
        type: 'AND',
        query: [['주', 'VX'], ['ㄹ까', 'EF']],
      },
    ],
    examples: [
      {
        sentence: '우리가 도움을 줄게요.',
        translation: "We'll give you help.",
      },
      {
        sentence: '제가 고장 난 컴퓨터를 고쳐 줄까요?',
        translation: 'Do you want me to fix the broken computer?',
      },
      {
        sentence: '돈이 없으면 내 돈을 빌려줄게.',
        translation: "If you don't have money, I'll let you borrow my money.",
      },
      {
        sentence: '내가 요리해 줄까?',
        translation: 'Do you want me to cook for you?',
      },
      {
        sentence: '모르는 문제 있으면 내가 풀어줄게.',
        translation: "If there is a problem you don't know, I'll solve it for you.",
      },
    ],
  },
  {
    name: '(으)ㄴ 적이 있다/없다',
    description: "'(으)ㄴ 적이 있다/없다' is used to indicate that you have or have not done something before.",
    rule: [
      {
        type: 'AND',
        query: [['적', 'NNB']],
      },
    ],
    examples: [
      {
        sentence: '그는 운전한 적이 없어요.',
        translation: 'He has never driven before.',
      },
      {
        sentence: '나는 진 적이 없다.',
        translation: 'I have never lost before.',
      },
      {
        sentence: '그녀는 담배를 핀 적이 없다.',
        translation: 'She has never smoked a cigarette before.',
      },
      {
        sentence: '저는 한국에 간 적이 있어요.',
        translation: 'I have been to Korea before.',
      },
      {
        sentence: '난 마라톤을 뛴 적이 있어.',
        translation: 'I have ran a marathon before.',
      },
      {
        sentence: '우리는 여행한 적이 있어.',
        translation: 'We have traveled before.',
      },
    ],
  },
  {
    name: '(으)ㅂ시다',
    description: "'(으)ㅂ시다' is used to formally make a suggestion.",
    rule: [
      {
        type: 'OR',
        query: [['읍시다', 'EF'], ['ㅂ시다', 'EF']],
      },
    ],
    examples: [
      {
        sentence: '음악을 들읍시다.',
        translation: "Let's listen to music.",
      },
      {
        sentence: '이제 시작합시다.',
        translation: "Let's start now.",
      },
      {
        sentence: '같이 영화를 봅시다.',
        translation: "Let's watch a movie together.",
      },
      {
        sentence: '한국 식당으로 갑시다.',
        translation: "Let's go to a Korean restaurant.",
      },
      {
        sentence: '일 그만하고 놉시다!',
        translation: "Let's stop working and play!",
      },
    ],
  },
  {
    name: '(으)시겠어요?',
    description: "'(으)시겠어요?' is used to politely ask if the listener wants something.",
    rule: [
      {
        type: 'AND',
        query: [['시', 'EP'], ['겠', 'EP']],
      },
      {
        type: 'AND',
        query: [['으시', 'EP'], ['겠', 'EP']],
      },
    ],
    examples: [
      {
        sentence: '시도해 보시겠어요?',
        translation: 'Would you like to try?',
      },
      {
        sentence: '커피에 설탕을 넣으시겠어요?',
        translation: 'Would you like to put sugar in the coffee?',
      },
      {
        sentence: '방을 예약하시겠어요?',
        translation: 'Would you like to reserve a room?',
      },
      {
        sentence: '몇 시에 오시겠어요?',
        translation: 'What time would you like to come?',
      },
      {
        sentence: '제 핸드폰을 고쳐 주시겠어요?',
        translation: 'Would you like to fix my phone?',
      },
    ],
  },
  {
    name: '겠어요',
    description: "'겠어요' is used to express the intention of the speaker. It can also be used to say that something looks a certain way.",
    rule: [
      {
        type: 'AND',
        query: [['겠', 'EP'], ['어요', 'EF']],
      },
    ],
    examples: [
      {
        sentence: '어두운 하늘을 보니 비가 오겠어요.',
        translation: 'Looking at the dark sky, it must rain.',
      },
      {
        sentence: '저는 술을 끊겠어요.',
        translation: "I'm going to quit drinking.",
      },
      {
        sentence: '손녀가 생겨서 좋으시겠어요!',
        translation: 'You must be happy to have a granddaughter!',
      },
      {
        sentence: '매일 일하느라 힘들겠어요.',
        translation: 'You must be having a hard time working every day.',
      },
      {
        sentence: '내년까지 한국어를 꼭 배우겠어요.',
        translation: 'I will definitely learn Korean by next year.',
      },
    ],
  },
  {
    name: '(으)ㄹ게요',
    description: "'(으)ㄹ게요' is an informal way to indicate what the speaker will be doing.",
    rule: [
      {
        type: 'AND',
        query: [['ㄹ게요', 'EF']],
      },
    ],
    examples: [
      {
        sentence: '제가 저녁을 요리할게요.',
        translation: "I'll cook dinner.",
      },
      {
        sentence: '제가 음식을 준비할게요.',
        translation: "I'll prepare the food.",
      },
      {
        sentence: '제가 운전할게요.',
        translation: "I'll drive.",
      },
      {
        sentence: '제가 문제를 고칠게요.',
        translation: "I'll fix the problem.",
      },
      {
        sentence: '제가 도와줄게요.',
        translation: "I'll help.",
      },
    ],
  },
  {
    name: '(으)려고',
    description: "'(으)려고' roughly means 'in order to.'",
    rule: [
      {
        type: 'AND',
        query: [['려고', 'EC']],
      },
    ],
    examples: [
      {
        sentence: '여행하려고 짐 쌓는 중이야.',
        translation: "I'm packing up luggage to go travel.",
      },
      {
        sentence: '학교에 가려고 버스를 기다리고 있어요.',
        translation: "I'm waiting for the bus to get to school.",
      },
      {
        sentence: '친구들이랑 놀려고 계획하고 있어.',
        translation: "I'm planning to play with my friends.",
      },
      {
        sentence: '영화 보려고 티켓을 구매했어.',
        translation: 'I bought a ticket to watch a movie.',
      },
      {
        sentence: '잠을 자려고 세수했어.',
        translation: 'I washed my face to go to sleep.',
      },
    ],
  },
  {
    name: '(으)려고 하다',
    description: "'(으)려고 하다' is used to express what the speaker plans to do.",
    rule: [
      {
        type: 'AND',
        query: [['려고', 'EC'], ['하', 'VX']],
      },
      {
        type: 'AND',
        query: [['으려고', 'EC'], ['하', 'VX']],
      },
    ],
    examples: [
      {
        sentence: '저는 커서 선생님이 되려고 해요.',
        translation: "I'm going to be a teacher when I grow up.",
      },
      {
        sentence: '집에 가려고 해요.',
        translation: "I'm going home.",
      },
      {
        sentence: '가족이랑 저녁을 먹으려고 해요.',
        translation: "I'm going to have dinner with my family.",
      },
      {
        sentence: '내일은 일찍 일어나려고 해요.',
        translation: "I'm going to get up early tomorrow.",
      },
      {
        sentence: '휴가 때 여행하려고 해요.',
        translation: "I'm going to travel on vacation.",
      },
    ],
  },
  {
    name: '을/를 위해(서), 기 위해(서)',
    description: "'을/를 위해(서), 기 위해(서)' indicates the reason why someone performs an action. Use 을/를 위해(서) after nouns and 기 위해(서) after verbs.",
    rule: [
      {
        type: 'AND',
        query: [['위하', 'VV']],
      },
    ],
    examples: [
      {
        sentence: '콘서트를 위해서 연습한다.',
        translation: 'I practice for the concert.',
      },
      {
        sentence: '학교를 가기 위해서 전철을 탄다.',
        translation: 'I take the subway to get to school.',
      },
      {
        sentence: '친구를 위해서 옷을 빌려줬다.',
        translation: 'I lent clothes for my friend.',
      },
      {
        sentence: '새로운 차를 사기 위해서 돈을 모았다.',
        translation: 'I saved up money to buy a new car.',
      },
      {
        sentence: '가족을 위해서 음식을 요리했어요.',
        translation: 'I cooked food for my family.',
      },
    ],
  },
  {
    name: '기로 하다',
    description: "'기로 하다' indicates that a promise/decision was made.",
    rule: [
      {
        type: 'WORD-AND',
        query: [['기'], ['로', 'JKB'], ['하', 'VV']],
      },
    ],
    examples: [
      {
        sentence: '담배를 끊기로 했습니다.',
        translation: 'I decided to quit smoking.',
      },
      {
        sentence: '뉴욕으로 이사하기로 했다.',
        translation: 'I decided to move to New York.',
      },
      {
        sentence: '우리는 공원에서 만나기로 했다.',
        translation: 'We decided to meet at the park.',
      },
      {
        sentence: '과제를 내일까지 끝내기로 했다.',
        translation: 'I decided to finish the assignment by tomorrow.',
      },
      {
        sentence: '비밀을 지키기로 약속했어.',
        translation: 'I promised to keep it a secret.',
      },
    ],
  },
  {
    name: '(으)려면',
    description: "'(으)려면' means 'if you want to...'",
    rule: [
      {
        type: 'AND',
        query: [['려면', 'EC']],
      },
    ],
    examples: [
      {
        sentence: '기타를 잘 치려면 연습해야 돼.',
        translation: 'You have to practice if you want to play the guitar well.',
      },
      {
        sentence: '친구랑 놀려면 숙제를 먼저 끝내.',
        translation: 'Finish homework first if you want to play with your friend.',
      },
      {
        sentence: '살 빼려면 음식을 더 적게 먹어.',
        translation: 'Eat less food if you want to lose weight.',
      },
      {
        sentence: '시험을 잘 보려면 공부를 더 많이 해.',
        translation: 'Study a lot more if you want to do well on the test.',
      },
      {
        sentence: '건강하려면 운동을 해야 돼요.',
        translation: 'You have to exercise if you want to be healthy.',
      },
    ],
  },
  {
    name: '아/어도',
    description: "'아/어도' means 'even if'",
    rule: [
      {
        type: 'OR',
        query: [['어도', 'EC'], ['아도', 'EC']],
      },
    ],
    examples: [
      {
        sentence: '음식이 맛있어도 난 안 먹을 거야.',
        translation: "Even if the food is delicious, I won't eat it.",
      },
      {
        sentence: '바빠도 꼭 와야 해.',
        translation: "You have to come even if you're busy.",
      },
      {
        sentence: '옷이 비싸도 사고 싶어요.',
        translation: 'I want to buy clothes even if they are expensive.',
      },
      {
        sentence: '힘들어도 포기 안 할게요.',
        translation: "I won't give up even if it's hard.",
      },
      {
        sentence: '아파도 참아야 돼요.',
        translation: 'You have to hold it in even if it hurts.',
      },
    ],
  },
  {
    name: '(으)ㄴ/는/(으)ㄹ 것 같다',
    description: "'(으)ㄴ/는/(으)ㄹ 것 같다' means 'it seems like...'",
    rule: [
      {
        type: 'AND',
        query: [['것', 'NNB'], ['같', 'VA']],
      },
    ],
    examples: [[
      {
        sentence: '발이 아픈 것 같다.',
        translation: 'My feet seem to hurt.',
      },
      {
        sentence: '시험이 어려울 것 같다.',
        translation: 'The test seems to be difficult.',
      },
      {
        sentence: '음식이 맛이 없을 것 같다.',
        translation: 'The food seems like it will not be tasty.',
      },
      {
        sentence: '지갑이 없어진 것 같다.',
        translation: 'Seems like my wallet is missing.',
      },
      {
        sentence: '핸드폰이 고장 난 것 같다.',
        translation: 'It seems like my phone is broken.',
      },
    ]],
  },
  {
    name: '기',
    description: "'기' is added to adjectives or verbs to make them into nouns.",
    rule: [
      {
        type: 'AND',
        query: [['기', 'ETN']],
      },
    ],
    examples: [
      {
        sentence: '한국어를 발음하기 어려워요.',
        translation: 'Pronouncing Korean is difficult.',
      },
      {
        sentence: '걷기는 건강에 좋아요.',
        translation: 'Walking is good for health.',
      },
      {
        sentence: '한국 음식을 먹기가 어려웠어요.',
        translation: 'Eating Korean food was hard.',
      },
      {
        sentence: '책을 매일 읽기가 내 목표야.',
        translation: 'Reading a book everyday is my goal.',
      },
      {
        sentence: '혼자 여행 가기 싫어요.',
        translation: "I don't like traveling alone.",
      },
    ],
  },
  {
    name: '게',
    description: "'게' is added to adjectives to turn them into adverbs.",
    rule: [
      {
        type: 'AND',
        query: [['게', 'EC']],
      },
    ],
    examples: [
      {
        sentence: '밖에 추우니까 따뜻하게 입으세요.',
        translation: "It's cold outside, so dress warmly.",
      },
      {
        sentence: '방을 깨끗하게 청소했어요.',
        translation: 'I cleaned the room clean.',
      },
      {
        sentence: '별이 밝게 빛나요.',
        translation: 'The star is shining brightly.',
      },
      {
        sentence: '머리를 짧게 잘랐어요.',
        translation: 'I cut my hair short.',
      },
      {
        sentence: '크게 읽으세요.',
        translation: 'Read loudly.',
      },
    ],
  },
  {
    name: '아/어하다',
    description: "'아/어하다' is added to adjectives to express someone else's feelings.",
    rule: [
      {
        type: 'AND',
        query: [['아', 'EC'], ['하', 'VX']],
      },
      {
        type: 'AND',
        query: [['어', 'EC'], ['하', 'VX']],
      },
    ],
    examples: [
      {
        sentence: '그녀의 치아가 썩어서 아파해요.',
        translation: 'She is hurting because her teeth are rotting.',
      },
      {
        sentence: '그는 심심해합니다.',
        translation: 'He is bored.',
      },
      {
        sentence: '아이가 괴물을 무서워해요.',
        translation: 'The kid is scared of monsters.',
      },
      {
        sentence: '아버지가 피곤해합니다.',
        translation: 'My father is tired.',
      },
      {
        sentence: '그는 밥을 안 먹어서 배고파해요.',
        translation: "He's hungry because he didn't eat.",
      },
    ],
  },
  {
    name: '아/어 있다',
    description: "'아/어 있다' indicates that an action was completed and is still occuring.",
    rule: [
      {
        type: 'AND',
        query: [['어', 'EC'], ['있', 'VX']],
      },
      {
        type: 'AND',
        query: [['아', 'EC'], ['있', 'VX']],
      },
    ],
    examples: [
      {
        sentence: '꽃이 피어 있다.',
        translation: 'The flowers are bloomed.',
      },
      {
        sentence: '문이 닫혀 있어요.',
        translation: 'The door is closed.',
      },
      {
        sentence: '학생들이 앉아 있다.',
        translation: 'The students are sitting.',
      },
      {
        sentence: '환자들이 침대에 누워 있어.',
        translation: 'The patients are lying in bed.',
      },
      {
        sentence: '음식이 남아 있다.',
        translation: 'There is food left.',
      },
    ],
  },
  {
    name: '아/어지다',
    description: "'아/어지다' is added to adjectives to mean 'became.'",
    rule: [
      {
        type: 'AND',
        query: [['지', 'VX']],
      },
    ],
    examples: [
      {
        sentence: '빨래하면 옷이 작아져요.',
        translation: 'The clothes get smaller when you do laundry.',
      },
      {
        sentence: '저는 건강해졌어요.',
        translation: 'I have become healthier.',
      },
      {
        sentence: '살이 빠져서 얼굴이 작아졌어.',
        translation: 'My face got smaller because I lost weight.',
      },
      {
        sentence: '수업 시간이 길어졌다.',
        translation: 'The class time has gotten longer.',
      },
      {
        sentence: '시간이 지나면 몸이 피곤해져요.',
        translation: 'My body gets tired as time goes by.',
      },
    ],
  },
  {
    name: '게 되다',
    description: "'게 되다' is added to verbs to indicate that something happened as a result of the environment.",
    rule: [
      {
        type: 'AND',
        query: [['게', 'EC'], ['되', 'VV']],
      },
    ],
    examples: [
      {
        sentence: '추위 때문에 물이 얼게 됐다.',
        translation: 'The cold caused the water to freeze.',
      },
      {
        sentence: '그 회사에 취직하게 되었어.',
        translation: 'I got a job in that company.',
      },
      {
        sentence: '내가 장학금을 받게 됐어.',
        translation: 'I got a scholarship.',
      },
      {
        sentence: '그녀를 사랑하게 되었어.',
        translation: 'I fell in love with her.',
      },
      {
        sentence: '드디어 이사를 가게 되었어.',
        translation: 'I finally got to move.',
      },
    ],
  },
  {
    name: '(으)ㄴ/는지',
    description: "'(으)ㄴ/는지' is used to add more information is required.",
    rule: [
      {
        type: 'OR',
        query: [['ㄴ지', 'EC'], ['는지', 'EC'], ['은지', 'EC']],
      },
    ],
    examples: [
      {
        sentence: '오늘 날씨가 어떤지 알아요?',
        translation: 'Do you know what the weather is like today?',
      },
      {
        sentence: '친구가 어디 있는지 몰라요.',
        translation: "I don't know where my friend is.",
      },
      {
        sentence: '어제 무엇을 했는지 기억이 안 나요.',
        translation: "I don't remember what I did yesterday.",
      },
      {
        sentence: '서울에 어떻게 가는지 알아요?',
        translation: 'Do you know how to go to Seoul?',
      },
      {
        sentence: '저 빌딩이 얼마나 높은지 몰라요.',
        translation: "I don't know how tall that building is.",
      },
    ],
  },
  {
    name: '는 데 걸리다/들다',
    description: "'는 데 걸리다/들다' is used to indicate how much money, time, or effort is used to do a task.",
    rule: [
      {
        type: 'AND',
        query: [['는', 'ETM'], ['데', 'NNB'], ['걸리', 'VV']],
      },
      {
        type: 'AND',
        query: [['는', 'ETM'], ['데', 'NNB'], ['들', 'VV']],
      },
    ],
    examples: [
      {
        sentence: '운전하는 법을 배우는 데 한 달 걸렸어요.',
        translation: 'It took me one month to learn how to drive/',
      },
      {
        sentence: '차를 고치는 데 백만 원 들었어요.',
        translation: 'It cost me one million won to fix the car.',
      },
      {
        sentence: '뉴욕에 가는 데 다섯 시간 걸려요.',
        translation: 'It takes five hours to go to New York.',
      },
      {
        sentence: '요리하는 데 삼십 분 걸려요.',
        translation: 'It takes thirty minutes to cook.',
      },
      {
        sentence: '머리를 자르는 데 삼만 원 들어요.',
        translation: 'It costs thirty thousand won to cut my hair.',
      },
    ],
  },
  {
    name: '군요, 는군요',
    description: "'군요, 는군요' is used to express surprise or wonder. Use '군요' after adjectives and '는군요' after verbs.",
    rule: [
      {
        type: 'OR',
        query: [['는군요', 'EF'], ['군요', 'EF'], [('구나', 'EF')]],
      },
    ],
    examples: [
      {
        sentence: '저 사람은 매일 운동하는군요.',
        translation: 'That person works out every day.',
      },
      {
        sentence: '음식이 아주 달군요.',
        translation: 'The food is very sweet.',
      },
      {
        sentence: '노래를 아주 잘하는군요!',
        translation: 'You sing very well!',
      },
      {
        sentence: '날씨가 참 좋군요.',
        translation: 'The weather is really good.',
      },
      {
        sentence: '저분이 사장님이시군요.',
        translation: 'That person is the boss.',
      },
    ],
  },
  {
    name: '(으)ㄴ 가요?, 나요?',
    description: "'(으)ㄴ 가요?, 나요?' is used to politely ask a question.",
    rule: [
      {
        type: 'OR',
        query: [['나요', 'EF'], ['나', 'EF'], ['ㄴ가요', 'EF']],
      },
      {
        type: 'AND',
        query: [['ㄴ', 'ETM'], ['가', 'VV']],
      },
      {
        type: 'AND',
        query: [['ㄴ', 'ETM'], ['가요', 'VV']],
      },
    ],
    examples: [
      {
        sentence: '언제 떠나고 싶나요?',
        translation: 'When do you want to leave?',
      },
      {
        sentence: '뭐가 필요한 가요?',
        translation: 'What do you need?',
      },
      {
        sentence: '뭘 보고 싶나요?',
        translation: 'What do you want to watch?',
      },
      {
        sentence: '일이 어려운가요?',
        translation: 'Is the work difficult?',
      },
      {
        sentence: '어디에서 살고 싶나요?',
        translation: 'Where do you want to live?',
      },
    ],
  },
  {
    name: '(으)ㄴ/는데요',
    description: "'(으)ㄴ/는데요' is used to express disagreement, the expectation for a response, or surprise.",
    rule: [
      {
        type: 'OR',
        query: [['는데요', 'EF'], ['ㄴ데요', 'EF']],
      },
    ],
    examples: [
      {
        sentence: '음식이 너무 맛있는데요.',
        translation: 'The food is so delicious.',
      },
      {
        sentence: '한국말을 아주 잘하시는데요.',
        translation: "You're very good at Korean.",
      },
      {
        sentence: '저는 안 추운데요.',
        translation: "I'm not cold.",
      },
      {
        sentence: '네 맞는데요.',
        translation: "Yes, that's right.",
      },
      {
        sentence: '저는 이 영화가 재미없는데요.',
        translation: "I don't think this movie is fun.",
      },
    ],
  },
  {
    name: '하고/라고 - Direct Quotes',
    description: "Use '하고/라고' to follow direct quotations.",
    rule: [
      {
        type: 'AND',
        query: [['하', 'VV'], ['고', 'EC']],
      },
      {
        type: 'AND',
        query: [['라고', 'JKQ']],
      },
    ],
    examples: [
      {
        sentence: "여자친구가 '누가 제일 예뻐?' 하고 물어봤어요.",
        translation: "My girlfriend asked, 'Who's the prettiest?'",
      },
      {
        sentence: "그는 '그만해!'라고 했다.",
        translation: "He said, 'Stop!'",
      },
      {
        sentence: "벨을 누르니까 '띵동' 하고 소리가 났어요.",
        translation: "When I rang the bell, the bell made a 'ding dong' noise.",
      },
      {
        sentence: "그녀는 '살려 주세요!'라고 소리쳤다.",
        translation: "She screamed, 'Help me!'",
      },
      {
        sentence: "저는 '아니요'라고 했어요.",
        translation: "I said, 'No.'",
      },
    ],
  },
  {
    name: '고 - Indirect Quotes',
    description: "Attach '고' to a word make an indirect quotation. You can also shorten them.",
    rule: [
      {
        type: 'OR',
        query: [['다고', 'EC'], ['자고', 'EC'], ['라고', 'EC'], ['다는', 'ETM'], ['라면', 'EC']],
      },
    ],
    examples: [
      {
        sentence: '그녀가 피곤하다고 했다.',
        translation: 'She said she was tired.',
      },
      {
        sentence: '친구가 같이 저녁 먹자고 물었어.',
        translation: 'My friend asked me to have dinner together.',
      },
      {
        sentence: '선생님이 숙제를 끝내라고 하셨어요.',
        translation: 'The teacher said to finish the homework.',
      },
      {
        sentence: '남자친구가 영화 보자고 물었어.',
        translation: 'My boyfriend asked to watch a movie.',
      },
      {
        sentence: '부모님이 집에 오라고 하셨어.',
        translation: 'My parents said to come home.',
      },
    ],
  },
  {
    name: '아/어 보이다',
    description: "Use '아/어 보이다' to make an observation on how something looks.",
    rule: [
      {
        type: 'AND',
        query: [['아', 'EC'], ['보이', 'VV']],
      },
      {
        type: 'AND',
        query: [['어', 'EC'], ['보이', 'VV']],
      },
    ],
    examples: [
      {
        sentence: '그 옷을 입으니까 날씬해 보여요.',
        translation: 'You look slim in that outfit.',
      },
      {
        sentence: '그들은 대학생 같아 보여.',
        translation: 'They look like college students.',
      },
      {
        sentence: '너 피곤해 보여.',
        translation: 'You look tired.',
      },
      {
        sentence: '그녀가 나보다 어려 보여.',
        translation: 'She looks younger than me.',
      },
      {
        sentence: '그는 바쁘게 사는 것 같아 보여요.',
        translation: 'He seems to be living a busy life.',
      },
    ],
  },
  {
    name: '(으)ㄴ/는 모양이다',
    description: "Use '(으)ㄴ/는 모양이다' to make an observation on how something looks.",
    rule: [
      {
        type: 'AND',
        query: [['모양', 'NNG']],
      },
    ],
    examples: [
      {
        sentence: '상황이 더 안 좋아진 모양이야.',
        translation: 'Looks like the situation has gotten worse.',
      },
      {
        sentence: '그녀가 많이 아픈 모양이에요.',
        translation: 'She must be very sick.',
      },
      {
        sentence: '친구가 살이 빠진 모양이야.',
        translation: 'Looks like my friend has lost weight.',
      },
      {
        sentence: '그는 밤늦게까지 공부한 모양이야.',
        translation: 'He looks like he studied until late at night.',
      },
      {
        sentence: '뼈가 부러진 모양이야.',
        translation: 'The bone seems to be broken.',
      },
    ],
  },
  {
    name: '(으)ㄹ 텐데',
    description: "Use '(으)ㄹ 텐데' to express an expectation or about a future situation.",
    rule: [
      {
        type: 'AND',
        query: [['텐', 'NNG'], ['데', 'NNB']],
      },
    ],
    examples: [
      {
        sentence: '일하느라 피곤할 텐데 쉬세요.',
        translation: 'You must be tired from work, take a rest.',
      },
      {
        sentence: '내일 비가 올 텐데 우산 까먹지 마세요.',
        translation: "It's going to rain tomorrow, so don't forget your umbrella.",
      },
      {
        sentence: '내 편지를 받았을 텐데 아직 답장이 없네.',
        translation: 'He must have received my mail, but there is no reply yet',
      },
      {
        sentence: '집에 아무도 없을 텐데 어떡하지?',
        translation: "I don't think anyone's home, what should I do?",
      },
      {
        sentence: '공부를 안 해서 시험을 못 볼 텐데.',
        translation: "I won't be able to take the test because I didn't study.",
      },
    ],
  },
  {
    name: '(으)ㄹ 테니까',
    description: "Use '(으)ㄹ 테니까' to give background information before making a suggestion.",
    rule: [
      {
        type: 'AND',
        query: [['테', 'NNG'], ['니까', 'EC']],
      },
    ],
    examples: [
      {
        sentence: '곧 나갈 테니까 준비하세요.',
        translation: "We'll be leaving soon, so get ready.",
      },
      {
        sentence: '어렵지 않을 테니까 시도해 보세요.',
        translation: "It won't be difficult, so try it.",
      },
      {
        sentence: '맵지 않을 거니까 먹어보세요.',
        translation: "It won't be spicy, so try eating it.",
      },
      {
        sentence: '길이 막힐 테니까 일찍 출발하세요.',
        translation: 'There will be traffic, so leave early.',
      },
      {
        sentence: '비가 올 테니까 우산을 가져가세요.',
        translation: 'It will rain, so take the umbrella.',
      },
    ],
  },
  {
    name: '(으)ㄹ 걸요',
    description: "Use '(으)ㄹ 걸요' to informally make a guess.",
    rule: [
      {
        type: 'AND',
        query: [['ㄹ', 'ETM'], ['걸', 'VV']],
      },
      {
        type: 'OR',
        query: [['ㄹ걸요', 'EF'], ['을걸요', 'EF']],
      },
    ],
    examples: [
      {
        sentence: '선생님은 알 걸요.',
        translation: 'The teacher will probably know.',
      },
      {
        sentence: '내일 비가 올걸요.',
        translation: 'I think it will rain tomorrow.',
      },
      {
        sentence: '친구는 집에 있을걸요.',
        translation: 'My friend will probably be at home.',
      },
      {
        sentence: '가게는 아홉 시에 열 걸요.',
        translation: 'The store will probably open at nine.',
      },
      {
        sentence: '이미 늦었을걸요.',
        translation: "It's probably already too late.",
      },
    ],
  },
  {
    name: '(으)ㄴ/는 /(으)ㄹ 줄 몰랐다/알았다',
    description: "Use '(으)ㄴ/는 /(으)ㄹ 줄 몰랐다/알았다' to indicate that the speaker thought they knew or didn't know something.",
    rule: [
      {
        type: 'AND',
        query: [['주', 'VX'], ['ㄹ', 'ETM'], ['모르', 'VV']],
      },
      {
        type: 'AND',
        query: [['주', 'VX'], ['ㄹ', 'ETM'], ['알', 'VV']],
      },
    ],
    examples: [
      {
        sentence: '김밥 만들 줄 몰라?',
        translation: "You don't know how to make kimbap?",
      },
      {
        sentence: '핸드폰이 이렇게 비쌀 줄 몰랐어요.',
        translation: "I didn't know the phone would be this expensive.",
      },
      {
        sentence: '물건이 오늘 배송될 줄 알았다.',
        translation: 'I knew the goods would be delivered today.',
      },
      {
        sentence: '숙제가 있는 줄 몰랐어.',
        translation: "I didn't know there was homework today.",
      },
      {
        sentence: '그녀가 결혼한 줄 알았다.',
        translation: 'I knew that she was married.',
      },
    ],
  },
  {
    name: '(으)ㄹ지도 모르다',
    description: "Use '(으)ㄹ지도 모르다' to indicate that something could happen.",
    rule: [
      {
        type: 'AND',
        query: [['ㄹ지', 'EC'], ['도', 'JX'], ['모르', 'VV']],
      },
      {
        type: 'AND',
        query: [['지도', 'NNP'], ['모르', 'VV']],
      },
      {
        type: 'AND',
        query: [['을지', 'EC'], ['도', 'JX'], ['모르', 'VV']],
      },
    ],
    examples: [
      {
        sentence: '너 후회할지도 몰라.',
        translation: 'You might regret it.',
      },
      {
        sentence: '눈이 올지도 몰라요.',
        translation: 'It might snow.',
      },
      {
        sentence: '그 사람이 범인일지도 몰라.',
        translation: 'He might be the culprit.',
      },
      {
        sentence: '나 내일 못 올지도 몰라.',
        translation: 'I might not be able to come tomorrow.',
      },
      {
        sentence: '그녀가 집에 없을지도 몰라요.',
        translation: 'She might not be at home.',
      },
    ],
  },
  {
    name: '기는 하지만, 기는...지만',
    description: "기는 하지만, 기는...지만' means 'but.'",
    rule: [
      {
        type: 'WORD-AND',
        query: [['기는'], ['지만']],
      },
    ],
    examples: [
      {
        sentence: '춤을 추기는 추지만 잘 추지는 못해요.',
        translation: "I dance, but I'm not good at dancing.",
      },
      {
        sentence: '아프기는 하지만 참을 수 있어.',
        translation: 'It hurts, but I can bear it.',
      },
      {
        sentence: '덥기는 덥지만 에어컨이 있어서 괜찮아요.',
        translation: "It's hot, but it's okay because there's an air conditioner.",
      },
      {
        sentence: '소주를 마시기는 했지만 안 취했어요.',
        translation: "I drank soju, but I'm not drunk.",
      },
      {
        sentence: '음식이 맵기는 하지만 맛있어요.',
        translation: "The food is spicy, but it's delicious.",
      },
    ],
  },
  {
    name: '거든요',
    description: "'거든요' is an informal way to give background information or answer a question.",
    rule: [
      {
        type: 'AND',
        query: [['거든요', 'EF']],
      },
    ],
    examples: [
      {
        sentence: '저를 가르쳐주세요. 제가 잘 모르거든요.',
        translation: "Please teach me. I don't really know.",
      },
      {
        sentence: '저는 일찍 일어나요. 일이 많거든요.',
        translation: 'I wake up early. I have a lot of work.',
      },
      {
        sentence: '나가지 못해요. 밖에 비가 오거든요.',
        translation: "I can't go out. It's raining outside.",
      },
      {
        sentence: '컴퓨터가 느리거든요. 그래서 새로 샀어요.',
        translation: 'My computer is slow. So I bought a new one.',
      },
      {
        sentence: '어제 노느라 잠을 못 잤거든요.',
        translation: "I couldn't sleep because I was playing yesterday.",
      },
    ],
  },
  {
    name: '잖아요',
    description: "'잖아요' is used to informally make a statement that the listener should already know.",
    rule: [
      {
        type: 'OR',
        query: [['잖아요', 'EF'], ['잖아', 'EF']],
      },
    ],
    examples: [
      {
        sentence: '그녀는 아름답고 키가 크잖아요.',
        translation: '(As you know) she is beautiful and tall.',
      },
      {
        sentence: '나는 알레르기가 있잖아.',
        translation: '(You already know) I have allergies.',
      },
      {
        sentence: '그는 미국으로 이민 갔잖아요.',
        translation: '(As you know) he immigrated to the United States.',
      },
      {
        sentence: '제가 어제 아팠잖아요.',
        translation: '(As you know) I was sick yesterday.',
      },
      {
        sentence: '내 말이 맞잖아요.',
        translation: '(You already know) that I am right.',
      },
    ],
  },
  {
    name: '느라고',
    description: "'느라고' is used to provide a reason for something. It can also be shortened to 느라.",
    rule: [
      {
        type: 'AND',
        query: [['느라고', 'EC']],
      },
    ],
    examples: [
      {
        sentence: '늦잠을 자느라고 회의를 놓쳤어요.',
        translation: 'I missed the meeting because I was oversleeping.',
      },
      {
        sentence: '공부하느라고 밥을 못 먹었어.',
        translation: "I didn't eat because I was studying.",
      },
      {
        sentence: '주식을 하느라고 돈을 다 잃었어.',
        translation: 'I lost all my money doing stocks.',
      },
      {
        sentence: '음식 준비하느라고 바빠요.',
        translation: "I'm busy preparing food.",
      },
      {
        sentence: '택시 대신 자전거 타느라고 늦었어요.',
        translation: 'I was late because I was riding a bicycle instead of a taxi.',
      },
    ],
  },
  {
    name: '는 바람에',
    description: "'는 바람에' is used to provide a reason for something negative.",
    rule: [
      {
        type: 'AND',
        query: [['는', 'ETM'], ['바람', 'NNG'], ['에', 'JKB']],
      },
      {
        type: 'AND',
        query: [['ㄴ', 'ETM'], ['바람', 'NNG'], ['에', 'JKB']],
      },
    ],
    examples: [
      {
        sentence: '재료가 없는 바람에 요리를 못했어요.',
        translation: "I couldn't cook because I didn't have ingredients.",
      },
      {
        sentence: '비가 오는 바람에 길이 미끄러웠다.',
        translation: 'The road was slippery because of the rain.',
      },
      {
        sentence: '날씨가 추워진 바람에 감기에 걸렸어요.',
        translation: 'I caught a cold because of the cold weather.',
      },
      {
        sentence: '급하게 가는 바람에 지갑을 까먹었다.',
        translation: 'I forgot my wallet because I went in a hurry.',
      },
      {
        sentence: '먹을 시간이 없는 바람에 굶었어요.',
        translation: "I starved because I didn't have time to eat.",
      },
    ],
  },
  {
    name: '(으)ㄴ/는 탓에',
    description: "'(으)ㄴ/는 탓에' is used to provide a reason for something negative.",
    rule: [
      {
        type: 'AND',
        query: [['탓', 'NNG'], ['에', 'JKB']],
      },
    ],
    examples: [
      {
        sentence: '늦잠을 잔 탓에 지각했다.',
        translation: 'I was late because I overslept.',
      },
      {
        sentence: '성격이 급한 탓에 실수를 해요.',
        translation: 'I make mistakes because I have a rash personality.',
      },
      {
        sentence: '집에만 있는 탓에 체중이 늘었어요.',
        translation: 'I gained weight because I have only stayed at home.',
      },
      {
        sentence: '게임을 하루 종일 한 탓에 시험을 망쳤어요.',
        translation: 'I messed up the test bcause I was gaming all day.',
      },
      {
        sentence: '어제 술을 마신 탓에 아침에 머리가 아팠어요.',
        translation: 'My head hurt in the morning because I drank yesterday.',
      },
    ],
  },
  {
    name: '고 해서',
    description: "Use '고 해서' to provide a reason for something. It also implies that there are other reasons that are not being mentioned.",
    rule: [
      {
        type: 'AND',
        query: [['고', 'EC'], ['하', 'VV'], ['아서', 'EC']],
      },
    ],
    examples: [
      {
        sentence: '돈이 없고 해서 쇼핑을 안 했어.',
        translation: "I don't have money, so I didn't go shopping.",
      },
      {
        sentence: '피곤하고 해서 약속을 취소했어요.',
        translation: 'I was tired, so I cancelled the appointment.',
      },
      {
        sentence: '시간이 없고 해서 김밥을 먹었어요.',
        translation: "I didn't have time, so I ate kimbap.",
      },
      {
        sentence: '날씨도 춥고 해서 집에 일찍 갔어.',
        translation: 'The weather was cold, so I went home early.',
      },
      {
        sentence: '취직도 하고 해서 친구들이랑 파티했어.',
        translation: 'I got a job and had a party with my friends.',
      },
    ],
  },
  {
    name: '(으)ㄹ까 봐',
    description: "Use '(으)ㄹ까 봐' to indicate that you are worried about something.",
    rule: [
      {
        type: 'AND',
        query: [['ㄹ까', 'EC'], ['보', 'VV']],
      },
      {
        type: 'AND',
        query: [['을까', 'EC'], ['보', 'VV']],
      },
    ],
    examples: [
      {
        sentence: '길이 미끄러울까 봐 운동화를 신었어요.',
        translation: 'I wore sneakers because I was afraid the road would be slippery.',
      },
      {
        sentence: '회의에 늦을까 봐 걱정이야.',
        translation: "I'm worried that I'll be late for the meeting.",
      },
      {
        sentence: '비가 올까 봐 우산을 가져왔어.',
        translation: 'I brought my umbrella because I was afraid it would rain.',
      },
      {
        sentence: '시험을 잘 못 봤을까 봐 걱정이에요.',
        translation: "I'm worried that I might have done poorly on the test.",
      },
      {
        sentence: '그녀가 나를 좋아하지 않을까 봐 걱정이야.',
        translation: "I'm worried that she might not like me.",
      },
    ],
  },
  {
    name: '다고 하던데',
    description: "Use '다고 하던데' to provide background information that you heard from someone else.",
    rule: [
      {
        type: 'AND',
        query: [['다고', 'EC'], ['하', 'VV'], ['던데', 'EC']],
      },
    ],
    examples: [
      {
        sentence: '공원이 닫았다고 하던데 우리 집에서 놀자.',
        translation: "I heard the park is close, let's play at my house.",
      },
      {
        sentence: '집 앞에 식당이 새로 생겼다고 하던데 가보자.',
        translation: "I heard a new restaurant opened in front of our house, let's go.",
      },
      {
        sentence: '친구는 요즘 바쁘다고 하던데.',
        translation: 'I heard that my friend is busy these days.',
      },
      {
        sentence: '영화가 재밌다고 하던데 같이 볼래?',
        translation: 'I heard the movie is fun, do you want to watch it together?',
      },
      {
        sentence: '시험 힘들다고 하던데 공부 많이 했어?',
        translation: 'I heard the exam is tough, did you study a lot?',
      },
    ],
  },
  {
    name: '다면서요',
    description: "Use '다면서요' to confirm information that you heard from someone else.",
    rule: [
      {
        type: 'AND',
        query: [['다면서요', 'EF']],
      },
      {
        type: 'AND',
        query: [['ㄴ다면', 'EC'], ['서', 'VV']],
      },
    ],
    examples: [
      {
        sentence: '한국은 여름에 매우 덥다면서요.',
        translation: "I heard it's very hot in Korea during the summer.",
      },
      {
        sentence: '곧 결혼한다면서요.',
        translation: "I heard you're about to get married.",
      },
      {
        sentence: '남자친구랑 헤어졌다면서요.',
        translation: 'I heard you broke up with your boyfriend.',
      },
      {
        sentence: '의사가 됐다면서요.',
        translation: 'I heard you became a doctor.',
      },
      {
        sentence: '다리에 수술했다면서요.',
        translation: 'I heard you had surgery on your leg.',
      },
    ],
  },
  {
    name: '다니요',
    description: "Use '다니요' (or 라니, 자니) to express surprise at something someone else said.",
    rule: [
      {
        type: 'WORD',
        query: ['라니'],
      },
      {
        type: 'WORD',
        query: ['다니'],
      },
      {
        type: 'WORD',
        query: ['자니'],
      },

    ],
    examples: [
      {
        sentence: '저 사람이 사장이라니요?',
        translation: "He's the boss?",
      },
      {
        sentence: '그 사람을 모른다니요?',
        translation: "You don't know that person?",
      },
      {
        sentence: '복권에 담청 됐다니요? 진짜예요?',
        translation: 'You won the lottery? Is it true?',
      },
      {
        sentence: '이 상황에 파티를 하자니요?',
        translation: "What do you mean let's have a party in this situation?",
      },
      {
        sentence: '벌써 가자니요?',
        translation: 'You already want to go?',
      },
    ],
  },
  {
    name: '(으)ㄹ까 하다',
    description: "Use '(으)ㄹ까 하다' to indicate a plan that can still be changed.",
    rule: [
      {
        type: 'AND',
        query: [['ㄹ까', 'EC'], ['하', 'VX']],
      },
      {
        type: 'AND',
        query: [['을까', 'EC'], ['하', 'VX']],
      },
    ],
    examples: [
      {
        sentence: '이번 주말은 휴식을 취할까 해요.',
        translation: "I think I'll take a rest this weekend.",
      },
      {
        sentence: '오늘 저녁으로 삼겹살을 먹을까 한다.',
        translation: "I'm thinking of pork belly for tonight's dinner.",
      },
      {
        sentence: '다음 주부터는 수영을 배울까 해.',
        translation: "I'm thinking of learning to swim starting next week.",
      },
      {
        sentence: '회사를 옮길까 하는데 넌 어떻게 생각해?',
        translation: "I'm thinking about moving companies, what do you think",
      },
      {
        sentence: '찜질방에 갈까 하는데 같이 갈래?',
        translation: "I'm thinking of going to a sauna, do you want to go together?",
      },
    ],
  },
  {
    name: '고자',
    description: "'고자' is a formal way to provide a reason.",
    rule: [
      {
        type: 'AND',
        query: [['고자', 'EC']],
      },
    ],
    examples: [
      {
        sentence: '꿈을 이루고자 노력했다.',
        translation: 'I made an effort to achieve my dreams.',
      },
      {
        sentence: '부탁을 드리고자 전화드렸어요.',
        translation: 'I called to ask for a favor.',
      },
      {
        sentence: '대학에 가고자 공부를 열심히 했다.',
        translation: 'I studied hard to go to college.',
      },
      {
        sentence: '관계를 끝내고자 해.',
        translation: 'I want to end the relationship.',
      },
      {
        sentence: '부모님께 선물을 드리고자 합니다.',
        translation: "I'd like to give my parents a gift.",
      },
    ],
  },
  {
    name: '(으)려던 참이다',
    description: "Use '(으)려던 참이다' to express a plan or to indicate that you were about to do something.",
    rule: [
      {
        type: 'AND',
        query: [['으려던', 'ETM'], ['참', 'NNB']],
      },
      {
        type: 'AND',
        query: [['려던', 'ETM'], ['참', 'NNB']],
      },
    ],
    examples: [
      {
        sentence: '밥 먹으려던 참인데 같이 먹을래?',
        translation: "I'm about to eat, do you want to eat with me?",
      },
      {
        sentence: '친구들이 집에 온대서 청소하려던 참이야.',
        translation: 'I was about to clean because my friends said they were coming over.',
      },
      {
        sentence: '너에게 내가 전화하려던 참이었는데!',
        translation: 'I was about to call you!',
      },
      {
        sentence: '쉬려던 참인데 같이 쉴래?',
        translation: "I'm about to take a break. Do you want to take a break with me?",
      },
      {
        sentence: '오후에 쇼핑을 하려던 참이야.',
        translation: 'I was going to go shopping this afternoon.',
      },
    ],
  },
  {
    name: '(으)ㄹ 겸...(으)ㄹ 겸',
    description: "Use '(으)ㄹ 겸...(으)ㄹ 겸' means 'and, in addition to.'",
    rule: [
      {
        type: 'AND',
        query: [['겸', 'NNB']],
      },
    ],
    examples: [
      {
        sentence: '친구도 사귈 겸 게임도 할 겸 아케이드에 놀러 왔어.',
        translation: 'I came to the arcade to make friends and play games.',
      },
      {
        sentence: '바람도 쐴 겸 강아지 산책도 할 겸 근처 공원에 왔습니다.',
        translation: 'I came to the park to get some fresh air and walk the dog.',
      },
      {
        sentence: '살도 뺄 겸 건강도 생각할 겸 체육관에 가려고.',
        translation: "I'm going to go to the gym to lose weight and to think about my health.",
      },
      {
        sentence: '기분 전환도 할 겸 쇼핑도 할 겸 명동에 갔다.',
        translation: 'I went to Meyongdong to change my mood and go shopping.',
      },
      {
        sentence: '여자친구도 만날 겸 영화도 볼 겸 서울에 왔다.',
        translation: 'I came to Seoul to meet my girlfriend and watch a movie.',
      },
    ],
  },
  {
    name: '(으)ㄹ 만하다',
    description: "Use '(으)ㄹ 만하다' to indicate that something is worth doing.",
    rule: [
      {
        type: 'AND',
        query: [['ㄹ', 'ETM'], ['만', 'NNB'], ['하', 'XSA']],
      },
      {
        type: 'AND',
        query: [['을', 'ETM'], ['만', 'NNB'], ['하', 'XSA']],
      },
    ],
    examples: [
      {
        sentence: '호텔에 지낼 만해.',
        translation: "It's worth staying at the hotel.",
      },
      {
        sentence: '저 사람은 믿을 만한 사람이야.',
        translation: "It's worth trusting that person.",
      },
      {
        sentence: '이 도시에서 놀러 가 볼 만한 곳이 있니?',
        translation: 'Is there a place worth visiting in this city?',
      },
      {
        sentence: '이 밥은 먹을 만해.',
        translation: 'This food is worth eating.',
      },
      {
        sentence: '쓸 만한 제품인지 봐줄래?',
        translation: "Can you see if it's a product worth using?",
      },
    ],
  },
  {
    name: '도록 하다',
    description: "Use '도록 하다' to make a strong suggestion.",
    rule: [
      {
        type: 'WORD-AND',
        query: [['도록'], ['하', 'VV']],
      },
    ],
    examples: [
      {
        sentence: '서류를 가져오도록 하세요.',
        translation: 'Bring the documents.',
      },
      {
        sentence: '세금은 꼭 내도록 합시다.',
        translation: "Let's make sure to pay taxes.",
      },
      {
        sentence: '이번 경기는 꼭 이기도록 해볼게요.',
        translation: "I'll make sure to win this game.",
      },
      {
        sentence: '전화로 찾아봬도록 하겠습니다.',
        translation: "I'll give you a call.",
      },
      {
        sentence: '다음 주에 뵙도록 합시다.',
        translation: "Let's meet next week.",
      },
    ],
  },
  {
    name: '지 그래요?',
    description: "Use '지 그래요?' means 'why not?' or 'why didn't you?'",
    rule: [
      {
        type: 'AND',
        query: [['지', 'EC'], ['그래요', 'IC']],
      },
      {
        type: 'AND',
        query: [['지', 'EC'], ['그래', 'IC']],
      },
      {
        type: 'AND',
        query: [['지', 'EC'], ['그렇', 'VA']],
      },
    ],
    examples: [
      {
        sentence: '이제 그만하지 그래요?',
        translation: "Why don't you stop now?",
      },
      {
        sentence: '밥 좀 먹지 그래?',
        translation: "Why don't you eat some food?",
      },
      {
        sentence: '밖에 나가서 운동 좀 하지 그러니?',
        translation: "Why don't you go outside and do some exercise?",
      },
      {
        sentence: '잘 모르면 선생님께 여쭤보지 그래요?',
        translation: "If you don't know, why don't you ask the teacher?",
      },
      {
        sentence: '공부 좀 하지 그래?',
        translation: "Why don't you study a little?",
      },
    ],
  },
  {
    name: '던',
    description: "Use '던' to a word to make the word describe a noun in the past. It can also mean 'used to.'",
    rule: [
      {
        type: 'AND',
        query: [['던', 'ETM']],
      },
    ],
    examples: [
      {
        sentence: '예뻤던 꽃이다.',
        translation: 'It was a pretty flower.',
      },
      {
        sentence: '어려웠던 시험이야.',
        translation: 'It was a difficult test.',
      },
      {
        sentence: '쉬웠던 문제야.',
        translation: 'It was an easy problem.',
      },
      {
        sentence: '비쌌던 신발이다.',
        translation: 'They were expensive shoes.',
      },
    ],
  },
  {
    name: '더라고요',
    description: "'더라고요' is used to give information that the speaker experienced directly.",
    rule: [
      {
        type: 'OR',
        query: [['더라고요', 'EF'], ['더라고', 'EF']],
      },
    ],
    examples: [
      {
        sentence: '바다는 참 예쁘더라고요.',
        translation: 'The sea was so beautiful.',
      },
      {
        sentence: '반미 샌드위치 너무 맛있더라고!',
        translation: 'Banh mi sandwich was so delicious!',
      },
      {
        sentence: '우리 아들이 속을 썩이더라고요.',
        translation: 'My son was upsetting me.',
      },
      {
        sentence: '그 친구는 그림을 잘 그리더라고요.',
        translation: 'That friend is good at drawing.',
      },
      {
        sentence: '창문을 열어두니 먼지가 많이 들어오더라고요.',
        translation: 'Because I opened the window, a lot of dust came in.',
      },
    ],
  },
  {
    name: '던데요',
    description: "'던데요' is used to express contradiction or surprise.",
    rule: [
      {
        type: 'OR',
        query: [['던데요', 'EF'], ['던데', 'EF']],
      },
    ],
    examples: [
      {
        sentence: '저는 그 영화가 재밌던데요.',
        translation: 'I thought the movie was fun.',
      },
      {
        sentence: '그는 학생이 아니라 은행 직원이던데.',
        translation: "He's a bank employee and not a student.",
      },
      {
        sentence: '그 친구 좋은 사람인 것 같던데 만나볼래?',
        translation: 'That friends seems like a good person, do you want to meet him?',
      },
      {
        sentence: '고양이가 너무 귀엽던데 만져봐도 돼?',
        translation: 'The cat is so cute. Can I touch it?',
      },
      {
        sentence: '기타 어려워 보여서 배우고 싶진 않던데.',
        translation: "I didn't want to learn the guitar because it looked difficult.",
      },
    ],
  },
  {
    name: '게 되다',
    description: "'게 되다' is used to indicate that something happened. It is used in passive sentences.",
    rule: [
      {
        type: 'AND',
        query: [['게', 'EC'], ['되', 'VV']],
      },
    ],
    examples: [
      {
        sentence: '그 친구를 좋아하게 됐어.',
        translation: "I've come to like the friend.",
      },
      {
        sentence: '네팔로 여행을 가게 되어 기뻐!',
        translation: "I'm happy to be traveling to Nepal!",
      },
      {
        sentence: '아침에 운동을 하려고 일찍 일어나게 되었어.',
        translation: 'I woke up early to work out in the morning.',
      },
      {
        sentence: '그녀를 회사에서 알게 되었지.',
        translation: 'I got to know her from work.',
      },
      {
        sentence: '열심히 노력했더니 농구를 잘하게 됐어.',
        translation: 'I became good at basketball because I tried hard.',
      },
    ],
  },
  {
    name: '게 하다',
    description: "Add '게 하다' to the end of a word to change it into an adverb.",
    rule: [
      {
        type: 'AND',
        query: [['게', 'EC'], ['하', 'VV']],
      },
    ],
    examples: [
      {
        sentence: '날 한 시간이나 기다리게 했어.',
        translation: 'You kept me waiting for an hour.',
      },
      {
        sentence: '선생님이 노래를 부르게 했어요.',
        translation: 'The teacher made me sing.',
      },
      {
        sentence: '엄마는 책을 많이 읽게 했습니다.',
        translation: 'My mom made me read a lot of books.',
      },
      {
        sentence: '아빠가 밥 먹기 전에 아이스크림 못 먹게 해.',
        translation: "Dad won't let me eat ice cream before I eat food.",
      },
      {
        sentence: '넌 날 행복하게 해.',
        translation: 'You make me happy.',
      },
    ],
  },
  {
    name: '거든',
    description: "'거든' is an informal way to say 'if...'",
    rule: [
      {
        type: 'AND',
        query: [['거든', 'EC']],
      },
    ],
    examples: [
      {
        sentence: '학원 가기 싫거든 가지 마.',
        translation: "If you don't want to go to the academy, then don't go.",
      },
      {
        sentence: '배가 고프지 않거든 먹지 않아도 돼.',
        translation: "If you're not hungry, you don't have to eat.",
      },
      {
        sentence: '그가 오거든 출발합시다.',
        translation: "Let's go when he comes.",
      },
      {
        sentence: '바쁘지 않거든 나랑 커피 마실래?',
        translation: "If you're not busy, would you like to drink coffee with me?",
      },
      {
        sentence: '꽃이 피거든 공원 가자.',
        translation: "Let's go to the park if the flowers bloom.",
      },
    ],
  },
  {
    name: '(으)ㄹ 뿐만 아니라',
    description: "'(으)ㄹ 뿐만 아니라' means 'not only'",
    rule: [
      {
        type: 'AND',
        query: [['뿐', 'NNB'], ['만', 'JX'], ['아니', 'VCN'], ['라', 'EC']],
      },
    ],
    examples: [
      {
        sentence: '책을 많이 읽을 뿐만 아니라 신문도 봐요.',
        translation: 'Not only do I read books, but I also read newspapers.',
      },
      {
        sentence: '숙제도 있을 뿐만 아니라 시험도 있어.',
        translation: 'Not only do I have homework, but I also have a test.',
      },
      {
        sentence: '그녀는 얼굴이 예쁠 뿐만 아니라 마음씨도 곱다.',
        translation: 'Not only is her face pretty, but she also has a kind heart.',
      },
      {
        sentence: '산이 있을 뿐만 아니라 바다도 있어!',
        translation: 'Not only are there mountains, but there are also seas.',
      },
      {
        sentence: '청소를 할 뿐만 아니라 빨래도 합니다.',
        translation: 'Not only do I clean, but I also do laundry.',
      },
    ],
  },
  {
    name: '(으)ㄴ/는 데다가',
    description: "'(으)ㄴ/는 데다가' means 'in addition'",
    rule: [
      {
        type: 'AND',
        query: [['데', 'NNB'], ['다가', 'JX']],
      },
    ],
    examples: [
      {
        sentence: '날씨가 쌀쌀한 데다가 비까지 왔어.',
        translation: 'The weather was chilly and it even rained.',
      },
      {
        sentence: '방이 작은 데다가 더러워.',
        translation: 'The room is small and dirty.',
      },
      {
        sentence: '저 옷은 질이 안 좋은 데다가 비싸.',
        translation: 'Those clothes are poor quality and expensive.',
      },
      {
        sentence: '이 도시는 날씨가 좋은 데다가 식당들도 많아.',
        translation: 'This city has great weather and there are many restaurants.',
      },
      {
        sentence: '요즘 잠을 못 잔 데다가 일까지 많아 힘드네.',
        translation: "I haven't been able to sleep recently in addition to having a lot of work, so it's tough.",
      },
    ],
  },
  {
    name: '조차',
    description: "'조차' means 'even'",
    rule: [
      {
        type: 'AND',
        query: [['조차', 'JX']],
      },
    ],
    examples: [
      {
        sentence: '그 사람 이름조차 들어본 적이 없는데.',
        translation: "I've never even heard of the person's name.",
      },
      {
        sentence: '너조차 날 못 믿겠니?',
        translation: "Even you can't trust me?",
      },
      {
        sentence: '추운데 바람조차 많이 부네요.',
        translation: "It's cold and even windy.",
      },
      {
        sentence: '밥 먹을 시간조차 없는 날이네.',
        translation: "It's a day when I don't even have time to eat.",
      },
      {
        sentence: '너무 바빠서 앉아 있을 시간조차 없었어.',
        translation: "I was so busy that I didn't even have time to sit.",
      },
    ],
  },
  {
    name: '만 해도',
    description: "'만 해도' is used to emphasize a point.",
    rule: [
      {
        type: 'WORD-AND',
        query: [['만'], ['하', 'VV'], ['아도', 'EC']],
      },
    ],
    examples: [
      {
        sentence: '오늘만 해도 간식을 세 번이나 먹었어.',
        translation: 'I ate snacks three times today alone.',
      },
      {
        sentence: '어제만 해도 10시간이나 공부했어.',
        translation: 'Yesterday alone I studied for 10 hours.',
      },
      {
        sentence: '전기세만 해도 오만 원이야.',
        translation: 'The electricity bill alone is fifty thousand won.',
      },
      {
        sentence: '10년 전만 해도 인터넷이 없었어.',
        translation: 'Ten years ago, there was no internet.',
      },
      {
        sentence: '내 친구들만 해도 다섯 명이나 여행 다녀왔어.',
        translation: 'Five of my friends went on a trip.',
      },
    ],
  },
  {
    name: '다가',
    description: "'다가' indicates that the speaker was doing something before getting interrupted and switching to another task.",
    rule: [
      {
        type: 'AND',
        query: [['다가', 'EC']],
      },
    ],
    examples: [
      {
        sentence: '꽃을 보다가 네가 생각났어.',
        translation: 'I thought of you while looking at the flowers.',
      },
      {
        sentence: '버스를 타다가 지하철로 갈아타세요.',
        translation: 'Take the bus then transfer to the subway.',
      },
      {
        sentence: '잠을 자다가 천둥소리에 깼다.',
        translation: 'I woke up by the sound of thunder while sleeping.',
      },
      {
        sentence: '공부를 하다가 잠깐 졸았어.',
        translation: 'I dozed off while studying.',
      },
      {
        sentence: '밥을 먹다가 전화를 받았습니다.',
        translation: 'I got a call while eating.',
      },
    ],
  },
  {
    name: '(으)ㄹ 정도로',
    description: "'(으)ㄹ 정도로' means 'to the degree that'",
    rule: [
      {
        type: 'AND',
        query: [['정도', 'NNG'], ['로', 'JKB']],
      },
    ],
    examples: [
      {
        sentence: '배가 아플 정도로 많이 먹었다.',
        translation: 'I ate so much that I had a stomachache.',
      },
      {
        sentence: '꽃이 필 정도로 날씨가 따뜻하다.',
        translation: 'The weather is warm enough to bloom.',
      },
      {
        sentence: '영화를 두 편씩 볼 정도로 영화를 좋아합니다.',
        translation: 'I like movies enough to watch two movies at a time.',
      },
      {
        sentence: '코피가 날 정도로 열심히 공부합니다.',
        translation: 'I study hard enough to have a nosebleed.',
      },
      {
        sentence: '앞이 안 보일 정도로 비가 온다.',
        translation: "It's raining so hard that I can't see.",
      },
    ],
  },
  {
    name: '만 하다',
    description: "'만 하다' is used to emphasize a size, amount, or number.",
    rule: [
      {
        type: 'AND',
        query: [['만', 'JX'], ['하', 'VX']],
      },
      {
        type: 'AND',
        query: [['만', 'JX'], ['한', 'MM']],
      },
    ],
    examples: [
      {
        sentence: '월급이 쥐 꼬리만 해.',
        translation: "My salary is as small as a rat's tail. (expression)",
      },
      {
        sentence: '어른 팔뚝만 한 물고기를 잡았지!',
        translation: "I caught a fish as big as an adult's forearm!",
      },
      {
        sentence: '눈이 단춧구멍만 해요.',
        translation: 'My eyes are as small as a button hole. (expression)',
      },
      {
        sentence: '너무 무서워서 간이 콩알만 해졌어요.',
        translation: 'I was so scared that my liver became as small as a bean. (expression)',
      },
      {
        sentence: '얼굴이 주먹만 하네.',
        translation: 'Your face is as small as a fist. (expression)',
      },
    ],
  },
  {
    name: '(으)ㄴ/는/(으)ㄹ 만큼',
    description: "'(으)ㄴ/는/(으)ㄹ 만큼' means 'to the extent'",
    rule: [
      {
        type: 'WORD',
        query: ['만큼'],
      },
    ],
    examples: [
      {
        sentence: '뒤 사람도 들을 수 있을 만큼 큰 소리로 발표해 주세요.',
        translation: 'Please make the announcement loud enough for the person behind you to hear it.',
      },
      {
        sentence: '힘든 만큼 성숙해질 거야.',
        translation: "You'll mature as much as you're suffering.",
      },
      {
        sentence: '너랑 놀 만큼 한가하지 않아.',
        translation: "I'm not free enough to play with you.",
      },
      {
        sentence: '나도 그녀만큼 잘 할 수 있어.',
        translation: 'I can do as well as her.',
      },
      {
        sentence: '많이 먹은 만큼 키가 클 거야.',
        translation: "You'll be as tall as much as you eat.",
      },
    ],
  },
  {
    name: '아무 (이)나/ 아무 도',
    description: "'아무 (이)나' means 'anyone/anything.' '아무 도' means 'no one/nothing.'",
    rule: [
      {
        type: 'WORD',
        query: ['아무'],
      },
    ],
    examples: [
      {
        sentence: '약속 시간인데 아무도 안 왔나요?',
        translation: "It's our appointment time, but no one's here?",
      },
      {
        sentence: '이거 아무나 가져가도 돼.',
        translation: 'Anyone can take this.',
      },
      {
        sentence: '아무도 없는 곳에 가고 싶다.',
        translation: 'I want to go where no one is.',
      },
      {
        sentence: '아무거나 먹어도 돼.',
        translation: 'You can eat anything.',
      },
      {
        sentence: '아무 데서나 눕지 마.',
        translation: "Don't lie around anywhere.",
      },
    ],
  },
  {
    name: '(이)라도',
    description: "Use '(이)라도' to indicate that even though something is not the first choice, it is good enough.",
    rule: [
      {
        type: 'AND',
        query: [['라도', 'EC']],
      },
    ],
    examples: [
      {
        sentence: '우리는 파스타라도 먹을까요?',
        translation: 'Should we at least have pasta?',
      },
      {
        sentence: '책이라도 읽을 거예요.',
        translation: "I'm at least going to read a book.",
      },
      {
        sentence: '오늘 영화라도 볼까?',
        translation: 'Should we at least watch a movie?',
      },
      {
        sentence: '잠깐이라도 좀 쉬세요.',
        translation: 'Take a break, even for a moment.',
      },
      {
        sentence: '이 신발이라도 살래?',
        translation: 'Do you want to buy these shoes at least?',
      },
    ],
  },
  {
    name: '든지',
    description: "Use '든지' to indicate that any choice is fine.",
    rule: [
      {
        type: 'AND',
        query: [['든지', 'EC']],
      },
    ],
    examples: [
      {
        sentence: '저는 언제든지 만나도 돼요.',
        translation: 'You can meet me anytime.',
      },
      {
        sentence: '우리는 어디에서든지 만나도 돼요.',
        translation: 'We can meet anywhere.',
      },
      {
        sentence: '커피를 마시든지 차를 마시든지 둘 다 좋아요!',
        translation: 'Drinking coffee or drinking tea are both good!',
      },
      {
        sentence: '영화를 보든지 노래를 부르든지 재미있을 거예요.',
        translation: 'It will be fun to watch a movie or sing a song.',
      },
      {
        sentence: '내일 비가 오든지 화창하든지 한국어를 공부하려고 해요.',
        translation: "I'm going to study Korean whether it's raining or it's sunny tomorrow.",
      },
    ],
  },
  {
    name: '만에',
    description: "Use '만에' to indicate how long something took.",
    rule: [
      {
        type: 'AND',
        query: [['만', 'NNB'], ['에', 'JKB']],
      },
    ],
    examples: [
      {
        sentence: '회의는 1시간 만에 끝났어요.',
        translation: 'The meeting ended in an hour.',
      },
      {
        sentence: '미국에서 비행기를 타니까 9시간 만에 한국에 도착해요.',
        translation: "Since I'm flying from America, I arrive in Korea in 9 hours.",
      },
      {
        sentence: '얼마 만에 한국어를 배웠어요?',
        translation: 'How long did it take you to learn Korean?',
      },
      {
        sentence: '저는 공부한 지 1시간 만에 다시 쉬었어요.',
        translation: 'I took a break again within an hour of studying.',
      },
      {
        sentence: '저는 이틀 만에 잠을 잤어요.',
        translation: 'I slept in two days.',
      },
    ],
  },
  {
    name: '아/어 가지고',
    description: "Use '아/어 가지고' to indicate a sequence of actions. It means 'then.'",
    rule: [
      {
        type: 'AND',
        query: [['가지', 'VV'], ['고', 'EC']],
      },
    ],
    examples: [
      {
        sentence: '저는 요리를 해 가지고 설거지를 할 거예요.',
        translation: "I'm going to wash the dishes because I cooked.",
      },
      {
        sentence: '음식이 비싸 가지고 못 사겠어요.',
        translation: "I can't buy the food because it is expensive.",
      },
      {
        sentence: '돈이 없어 가지고 아르바이트를 해야 돼요.',
        translation: "I have to work part-time because I don't have money.",
      },
      {
        sentence: '돈을 모아 가지고 차를 사고 싶어요.',
        translation: 'I want to save up money then buy a car.',
      },
      {
        sentence: '만두를 만들어 가지고 잠깐 쉴 거예요.',
        translation: "I made dumplings, so I'm going to take a break.",
      },
    ],
  },
  {
    name: '아/어다가',
    description: "'아/어다가' means 'then.' It indicates that the two actions are done in two different locations.",
    rule: [
      {
        type: 'AND',
        query: [['다가', 'EC']],
      },
    ],
    examples: [
      {
        sentence: '요리를 하다가 칼에 베였어요.',
        translation: 'I cut myself with a knife while cooking.',
      },
      {
        sentence: '커피를 사다가 마셨어요.',
        translation: 'I bought coffee and drank it.',
      },
      {
        sentence: '음식을 냉장고에서 꺼내다가 먹어.',
        translation: 'Take food out of the fridge and eat it.',
      },
      {
        sentence: '친구랑 통화하다가 울었어요.',
        translation: 'I talked to my friend over the friend and cried.',
      },
      {
        sentence: '만두를 사다가 먹었어요.',
        translation: 'I bought dumpllings and ate it.',
      },
    ],
  },
  {
    name: '고서',
    description: "'고서' means 'then.' It indicates that the first action was completed before the second began.",
    rule: [
      {
        type: 'WORD',
        query: ['고서'],
      },
    ],
    examples: [
      {
        sentence: '저는 저녁을 먹고서 아이스크림을 먹었어요.',
        translation: 'I ate ice cream after dinner.',
      },
      {
        sentence: '스트레칭을 하고서 운동하세요.',
        translation: 'Exercise after you stretch.',
      },
      {
        sentence: '화를 내고서 사과했어요.',
        translation: 'I apologized after getting mad.',
      },
      {
        sentence: '이야기하고서 공감을 받았어요.',
        translation: 'I received sympathy after talking.',
      },
      {
        sentence: '영화를 보고서 카페에 갔어요.',
        translation: 'I went to the cafe after watching a movie.',
      },
    ],
  },
  {
    name: '고 보니',
    description: "Use '고 보니' to indicate that you learned something after doing another action.",
    rule: [
      {
        type: 'WORD-AND',
        query: [['고'], ['보니']],
      },
    ],
    examples: [],
  },
  {
    name: '다 보니',
    description: "'다 보니' means 'after,' 'while,' or 'because.'",
    rule: [
      {
        type: 'WORD-AND',
        query: [['다'], ['보니']],
      },
    ],
    examples: [
      {
        sentence: '역사를 배우고 보니 한국은 재미있는 나라인 걸 알게 됐어요.',
        translation: 'After learning history, I learned that Korea is an interesting country.',
      },
      {
        sentence: '집을 떠나고 보니까 지갑이 없었어요.',
        translation: "After leaving home, I saw that I didn't have my wallet.",
      },
      {
        sentence: '그녀를 사귀고 보니 그녀가 좋은 사람인 걸 알았어요.',
        translation: 'After dating her, I realized she was a good person.',
      },
      {
        sentence: '선물을 받고 보니까 그는 나를 좋아한다는 걸 알게 됐어요.',
        translation: 'I learned that he liked me after receiving a gift.',
      },
      {
        sentence: '영어책을 공부하고 보니 영어를 더 이해했어요.',
        translation: 'After studying the English book, I understood English more.',
      },
      {
        sentence: '제가 운동을 하다 보니 에너지가 늘었어요.',
        translation: 'My energy improved as I worked out.',
      },
      {
        sentence: '우리는 만나다 보니 사귀게 됐어요.',
        translation: 'After seeing each other, we ended up going out.',
      },
      {
        sentence: '그를 자주 보다 보니 좋아하게 됐어요.',
        translation: 'I started to like him after seeing him more often.',
      },
      {
        sentence: '연습하지 않다 보니 경주에서 졌어요.',
        translation: "I lost the race because I didn't practice.",
      },
      {
        sentence: '음식을 준비하다 보니 시간이 많이 지났어요.',
        translation: 'A long time has passed while I was preparing food.',
      },
    ],
  },
  {
    name: '다 보면',
    description: "'다 보면' means 'while.'",
    rule: [
      {
        type: 'AND',
        query: [['다', 'EC'], ['보', 'VV'], ['면', 'EC']],
      },
    ],
    examples: [
      {
        sentence: '방을 청소하다 보면 온 집을 청소하게 돼요.',
        translation: 'When I clean my room, I end up cleaning the whole house.',
      },
      {
        sentence: '요리하다 보면 음식이 불에 탈 수도 있어요.',
        translation: 'Food can burn while cooking.',
      },
      {
        sentence: '살다 보면 나쁜 일이 생길 수도 있지만 긍정적으로 생각해야 돼요.',
        translation: 'Bad things can happen in life, but you have to think positively.',
      },
      {
        sentence: '치킨을 먹다 보면 맥주가 당겨요.',
        translation: 'When I eat chicken, I crave beer.',
      },
      {
        sentence: '친구랑 놀다 보면 시간이 가는 줄 몰라요.',
        translation: "I don't realize how time flies when I play with my friend.",
      },
    ],
  },
  {
    name: '더니',
    description: "'더니' is used when the speaker wants to reference something they saw in the past to describe how something changed.",
    rule: [
      {
        type: 'AND',
        query: [['더니', 'EC']],
      },
    ],
    examples: [
      {
        sentence: '그는 계속 공부하더니 커서 의사가 됐어요.',
        translation: 'He kept studying and grew up to be a doctor.',
      },
      {
        sentence: '그녀는 실력을 꾸준히 키우더니 지금은 농구선수가 됐어요.',
        translation: 'She steadily improved her skills and now she is a basketball player.',
      },
      {
        sentence: '오늘 아침에 피곤하더니 지금은 에너지가 많다.',
        translation: 'You were tired this morning, but now you have a lot of energy.',
      },
      {
        sentence: '친구가 예전에는 작더니 지금은 키가 엄청 컸어요.',
        translation: "My friend used to be short, but now he's really tall.",
      },
      {
        sentence: '상을 받더니 행복해졌어요.',
        translation: 'I became happy after receiving the award.',
      },
    ],
  },
  {
    name: '았/었더니',
    description: "'았/었더니' is used to indicate cause and effect.",
    rule: [
      {
        type: 'AND',
        query: [['았', 'EP'], ['더니', 'EC']],
      },
      {
        type: 'AND',
        query: [['었', 'EP'], ['더니', 'EC']],
      },
    ],
    examples: [
      {
        sentence: '며칠 동안 안 잤더니 감기에 걸렸어요.',
        translation: "I caught a cold because I haven't slept for a few days.",
      },
      {
        sentence: '디저트를 먹었더니 기분이 좋아지게 됐어요.',
        translation: 'I felt better after eating dessert.',
      },
      {
        sentence: '식당에 갔더니 손님들이 많아서 기다렸어요.',
        translation: 'When I went to the restaurant, I had to wait because there were a lot of customers.',
      },
      {
        sentence: '매일 운동했더니 몸이 튼튼해졌어요.',
        translation: 'My body became stronger after exercising every day.',
      },
      {
        sentence: '커피를 마셨더니 더 이상 안 졸려요.',
        translation: "I'm not sleepy anymore after drinking coffee.",
      },
    ],
  },
  {
    name: '다가는',
    description: "Use '다가는' to indicate that if an action continues to occur, then an there will be a consequence.",
    rule: [
      {
        type: 'AND',
        query: [['다가', 'EC'], ['는', 'JX']],
      },
    ],
    examples: [
      {
        sentence: '음식을 많이 먹다가는 뚱뚱해질 거예요.',
        translation: "You'll get fat if you eat a lot.",
      },
      {
        sentence: '잠을 계속 늦게 자다가는 키가 크지 않을 거야.',
        translation: "If you keep sleeping late, you won't grow tall.",
      },
      {
        sentence: '술을 마신 후에 운전하다가는 큰일 나요.',
        translation: "You'll be in trouble if you drive after drinking.",
      },
      {
        sentence: '열심히 공부하지 않다가는 한국어가 늘지 않을 거야.',
        translation: "If you don't study hard, your Korean won't improve.",
      },
      {
        sentence: '날마다 게임하다가는 숙제에 집중하지 못할 거예요.',
        translation: "If you play games every day, you won't be able to concentrate on your homework.",
      },
    ],
  },
  {
    name: '(으)ㄴ/는 셈이다',
    description: "'(으)ㄴ/는 셈이다' means 'it's almost as if'",
    rule: [
      {
        type: 'AND',
        query: [['셈', 'NNB'], ['이', 'VCP']],
      },
    ],
    examples: [
      {
        sentence: '거의 다 투표한 셈이에요.',
        translation: 'Almost everyone voted.',
      },
      {
        sentence: '너무 싸서 그 물건은 거의 무료였던 셈이에요.',
        translation: 'It was so cheap that it was almost free.',
      },
      {
        sentence: '10분 뒤에 숙제를 끝낼 셈일 거예요.',
        translation: "I'll be done with homework in almost ten minutes.",
      },
      {
        sentence: '나는 거의 매일 한국어를 공부하는 셈이야.',
        translation: "I'm studying Korean almost every day.",
      },
      {
        sentence: '귤 한 개만 먹었으니까 점심을 안 먹은 세이에요.',
        translation: "I only ate one tangerine, so I practically didn't eat lunch.",
      },
    ],
  },
  {
    name: '아/어 놓다',
    description: "Use '아/어 놓다' to indicate that a state stays the same after an action.",
    rule: [
      {
        type: 'AND',
        query: [['아', 'EC'], ['놓', 'VX']],
      },
      {
        type: 'AND',
        query: [['어', 'EC'], ['놓', 'VX']],
      },
    ],
    examples: [
      {
        sentence: '공부할 때 텔레비전 꺼 놓아요.',
        translation: 'Turn off the TV when you study.',
      },
      {
        sentence: '짐을 싸 놓았어요?',
        translation: 'Did you pack your things?',
      },
      {
        sentence: '이것은 제가 만들어 놓은 책상이예요.',
        translation: 'This is the table I made.',
      },
      {
        sentence: '방을 청소해 놓았다면 좋았을 텐데.',
        translation: 'It would have been good if I had cleaned the room.',
      },
      {
        sentence: '이미 호텔을 예약해 놔서 걱정하지 마세요.',
        translation: "I already booked a hotel, so don't worry.",
      },
    ],
  },
  {
    name: '아/어 두다',
    description: "Use '아/어 두다' to indicate that a state stays the same after an action.",
    rule: [
      {
        type: 'AND',
        query: [['아', 'EC'], ['두', 'VV']],
      },
      {
        type: 'AND',
        query: [['어', 'EC'], ['두', 'VV']],
      },
    ],
    examples: [
      {
        sentence: '옷을 세탁소에 뒀어요.',
        translation: 'I left my clothes in the laundromat.',
      },
      {
        sentence: '비행기 표를 예약해 두었으니까 걱정하지 마세요.',
        translation: "I reserved a plane ticket, so don't worry.",
      },
      {
        sentence: '여행을 해야 하니까 약을 싸 두어야겠다.',
        translation: 'I need to pack some medicine because I have to travel.',
      },
      {
        sentence: '문 열어 두세요.',
        translation: 'Leave the door open.',
      },
      {
        sentence: '세워 둔 차는 어디에 있어요?',
        translation: 'Where is the parked car?',
      },
    ],
  },
  {
    name: '(으)ㄴ 채로',
    description: "Use '(으)ㄴ 채로' to indicate that an action occurs in a particular state or circumstance.",
    rule: [
      {
        type: 'AND',
        query: [['채', 'NNB'], ['로', 'JKB']],
      },
    ],
    examples: [
      {
        sentence: '그는 취한 채로 춤췄어요.',
        translation: 'He danced while being drunk.',
      },
      {
        sentence: '저는 피곤한 채로 일했어요.',
        translation: 'I worked in a tired manner.',
      },
      {
        sentence: '그녀는 화려한 옷을 입은 채로 책을 읽었어요.',
        translation: 'She read a book while wearing colorful clothes.',
      },
      {
        sentence: '모자를 쓴 채로 시험을 봤어요.',
        translation: 'I took the test with my hat on.',
      },
      {
        sentence: '기분이 좋은 채로 일하면 성공할 거야!',
        translation: "If you work in a good mood, you'll succeed!",
      },
    ],
  },
  {
    name: '(으)ㄴ/는 대로',
    description: "'(으)ㄴ/는 대로' means 'according to'",
    rule: [
      {
        type: 'AND',
        query: [['대로', 'NNB']],
      },
    ],
    examples: [
      {
        sentence: '우리는 항상 네가 원하는 대로 놀아.',
        translation: 'We always play as you want.',
      },
      {
        sentence: '네 마음대로 해!',
        translation: 'Do as you please!',
      },
      {
        sentence: '우리는 예상대로 이겼어요.',
        translation: 'We won as expected.',
      },
      {
        sentence: '뉴욕에 도착하는 대로 연락할게요.',
        translation: "I'll call you as soon as I get to New York.",
      },
      {
        sentence: '책에서 읽은 대로 따라 했어요.',
        translation: 'I followed what I read in the book.',
      },
    ],
  },
  {
    name: '(으)ㄴ/는 편이다',
    description: "Use '(으)ㄴ/는 편이다' to say that someone is closer to one characteristic than another characteristic.",
    rule: [
      {
        type: 'AND',
        query: [['ㄴ', 'ETM'], ['편', 'NNB'], ['이', 'VCP']],
      },
    ],
    examples: [
      {
        sentence: '그는 똑똑한 편이에요.',
        translation: "He's pretty smart.",
      },
      {
        sentence: '이런 음식은 비싼 편이에요.',
        translation: 'This kind of food is expensive.',
      },
      {
        sentence: '그녀는 착한 편이라서 깜짝 놀랐어요.',
        translation: 'I was surprised because she was rather kind.',
      },
      {
        sentence: '그는 날씬한 편이에요.',
        translation: "He's on the slim side.",
      },
      {
        sentence: '그는 게으른 편이에요.',
        translation: "He's rather lazy.",
      },
    ],
  },
  {
    name: '스럽다',
    description: "'스럽다' is attached to nouns to convert it to an adjective or adverb.",
    rule: [
      {
        type: 'AND',
        query: [['스럽', 'XSA']],
      },
    ],
    examples: [
      {
        sentence: '그녀는 아주 사랑스럽다.',
        translation: 'She is really lovely.',
      },
      {
        sentence: '왜 바보스럽게 행동하니?',
        translation: 'Why are you acting so stupid?',
      },
      {
        sentence: '오늘은 아주 여성스러워 보여요.',
        translation: 'You look very feminine today.',
      },
      {
        sentence: '포즈가 아주 자연스럽다!',
        translation: 'The pose is very natural!',
      },
      {
        sentence: '너의 행위가 부담스럽다.',
        translation: 'Your conduct is burdensome.',
      },
    ],
  },
  {
    name: '답다',
    description: "'답다' is attached to nouns and indicates that someone or something is similar to that noun.",
    rule: [
      {
        type: 'AND',
        query: [['답', 'XSA']],
      },
    ],
    examples: [
      {
        sentence: '그는 괴물답게 행동했어요.',
        translation: 'He acted like a monster.',
      },
      {
        sentence: '저는 미친 사람답게 일을 할 거예요.',
        translation: "I'm going to work like a crazy person.",
      },
      {
        sentence: '그의 옷차림은 모델답다.',
        translation: 'He is dressed like a model.',
      },
      {
        sentence: '아이답게 행동하지 마세요.',
        translation: "Don't act like a child.",
      },
      {
        sentence: '그는 연예인답다.',
        translation: 'He is like a celebrity.',
      },
    ],
  },
  {
    name: '(으)ㄹ 수밖에 없다',
    description: "Use '(으)ㄹ 수밖에 없다' when there is no choice but to do a particular action.",
    rule: [
      {
        type: 'AND',
        query: [['수', 'NNB'], ['밖에', 'JX'], ['없', 'VA']],
      },
    ],
    examples: [
      {
        sentence: '돈이 없어서 일을 할 수밖에 없어.',
        translation: 'I have no choice but to work because I have no money.',
      },
      {
        sentence: '너를 좋아할 수밖에 없어.',
        translation: 'I have no choice but to like you.',
      },
      {
        sentence: '오늘 텔레비전을 볼 수밖에 없어.',
        translation: 'I have no choice but to watch TV today.',
      },
      {
        sentence: '하루 종일 일 할 수밖에 없어요.',
        translation: 'I have no choice but to work all day.',
      },
      {
        sentence: '슬퍼서 울 수밖에 없어.',
        translation: "I can't help but cry because I'm sad.",
      },
    ],
  },
  {
    name: '(으)ㄹ 뿐이다',
    description: "Use '(으)ㄹ 뿐이다' to mean 'only'",
    rule: [
      {
        type: 'AND',
        query: [['ㄹ', 'ETM'], ['뿐', 'NNB']],
      },
      {
        type: 'AND',
        query: [['을', 'ETM'], ['뿐', 'NNB']],
      },
    ],
    examples: [
      {
        sentence: '저는 목표에 집중할 뿐이에요.',
        translation: 'I only focus on my goal.',
      },
      {
        sentence: '오늘 잤을 뿐이에요.',
        translation: 'I just slept today.',
      },
      {
        sentence: '하루 종일 일했을 뿐이야.',
        translation: "I've only been working all day.",
      },
      {
        sentence: '물만 마시고 싶을 뿐이에요.',
        translation: 'I just want to drink water.',
      },
      {
        sentence: '30분만 쉬었을 뿐이다.',
        translation: 'I only rested for 30 minutes.',
      },
    ],
  },
  {
    name: '(이)야말로',
    description: "Use '(이)야말로' to emphasize a noun.",
    rule: [
      {
        type: 'OR',
        query: [['이야말로', 'JX'], ['야말로', 'JX']],
      },
    ],
    examples: [
      {
        sentence: '맛있는 디저트가 많은데 아이스크림이야말로 먹어 보세요.',
        translation: 'There are many delicious desserts, but you have to try the ice cream.',
      },
      {
        sentence: '이 노래야말로 제가 찾던 거예요.',
        translation: "I've been looking for this particular song.",
      },
      {
        sentence: '도시 중에 신촌이야말로 가기에 좋을 것 같아요.',
        translation: 'I think Sinchon is the place to go out of all the cities.',
      },
      {
        sentence: '너야말로 잘해!',
        translation: "You're the best!",
      },
      {
        sentence: '커피야말로 제가 제일 좋아하는 음료수예요.',
        translation: 'Coffee is indeed my favorite drink.',
      },
    ],
  },
  {
    name: '게',
    description: "Attach '게' to a word express the speaker's goal.",
    rule: [
      {
        type: 'OR',
        query: [['게', 'EC'], ['게끔', 'EC']],
      },
    ],
    examples: [
      {
        sentence: '한국어를 잊지 않게 매일 써야 돼요.',
        translation: "You have to use Korean every day so that you don't forget it.",
      },
      {
        sentence: '집을 청소하게 약속을 다 취소했어요.',
        translation: 'I canceled all my appointments to clean the house.',
      },
      {
        sentence: '시험을 망치지 않게 많이 공부했어요.',
        translation: "I studied a lot so that I wouldn't mess up the exam.",
      },
      {
        sentence: '다른 사람들이 자게 불을 껐어요.',
        translation: 'I turned off the lights for others to sleep.',
      },
      {
        sentence: '지갑을 잃어버리지 않게끔 조심하세요.',
        translation: 'Be careful not to lose your wallet.',
      },
    ],
  },
  {
    name: '도록',
    description: "Attach '도록' to a word express the speaker's goal.",
    rule: [
      {
        type: 'AND',
        query: [['도록', 'EC']],
      },
    ],
    examples: [
      {
        sentence: '그녀가 한국어를 배우도록 도와줄 거예요.',
        translation: 'I will help her learn Korean.',
      },
      {
        sentence: '우리가 이기도록 노력할게.',
        translation: "We'll try to win.",
      },
      {
        sentence: '다리가 아프도록 달렸어요.',
        translation: 'I ran my until legs hurt.',
      },
      {
        sentence: '아침에 일어나도록 일찍 잤어요.',
        translation: 'I went to bed early to wake up in the morning.',
      },
      {
        sentence: '버스를 놓치지 않도록 미리 가 있었어요.',
        translation: 'I went ahead to not miss the bus.',
      },
    ],
  },
  {
    name: '아/어 버리다',
    description: "Use '아/어 버리다' to indicate that something unfortunate happened or to indicate that an action was or will be thoroughly completed.",
    rule: [
      {
        type: 'AND',
        query: [['아', 'EC'], ['버리', 'VX']],
      },
      {
        type: 'AND',
        query: [['어', 'EC'], ['버리', 'VX']],
      },
    ],
    examples: [
      {
        sentence: '우리는 방을 청소해 버렸어요.',
        translation: 'We cleaned the room.',
      },
      {
        sentence: '제 지갑을 잃어버렸어요.',
        translation: 'I lost my wallet.',
      },
      {
        sentence: '우리는 게임에 져버렸어요.',
        translation: 'We lost the game.',
      },
      {
        sentence: '내 돈을 다 줘 버렸네.',
        translation: 'I gave all my money.',
      },
      {
        sentence: '음식을 다 먹어 버렸어요.',
        translation: 'I ate all the food.',
      },
    ],
  },
  {
    name: '고 말다',
    description: "Use '고 말다' to indicate regret or sadness about an unplanned action.",
    rule: [
      {
        type: 'AND',
        query: [['고', 'EC'], ['말', 'VX']],
      },
    ],
    examples: [
      {
        sentence: '어제 공부하고 싶은데 자고 말았어요.',
        translation: 'I wanted to study yesterday, but I ended up sleeping.',
      },
      {
        sentence: '음식을 많이 먹으니까 배가 아프고 말았어요.',
        translation: 'I got a stomachache because I ate a lot of food.',
      },
      {
        sentence: '노력을 했는데 게임에서 지고 말았어요.',
        translation: 'I tried but lost the game.',
      },
      {
        sentence: '일을 해야 되는데 안 하고 말았네.',
        translation: 'I had to work, but I ended up not working.',
      },
      {
        sentence: '친구랑 싸우니까 후회하고 말았다.',
        translation: 'I ended up regretting fighting with my friend.',
      },
    ],
  },
  {
    name: '(으)나 마나',
    description: "Use '(으)나 마나' to indicate that an action is useless.",
    rule: [
      {
        type: 'WORD-AND',
        query: [['나'], ['마나']],
      },
    ],
    examples: [
      {
        sentence: '잠 잘 자지 않으면 공부하나 마나예요.',
        translation: "If you don't sleep well, studying is useless.",
      },
      {
        sentence: '음식을 잘 먹지 않으면 운동하나 마나예요.',
        translation: "If you don't eat well, exercising is useless.",
      },
      {
        sentence: '이 책은 읽으나 마나 재미없을 것 같아요.',
        translation: 'This book will be boring regardless of if I read it or not.',
      },
      {
        sentence: '지금 가나 마나 늦을 거예요.',
        translation: "I'll be late even if I go now.",
      },
      {
        sentence: '노력해보나 마나 소용이 없어요.',
        translation: "Even if I try, it'll be no use.",
      },
    ],
  },
  {
    name: '아/어 봤자',
    description: "Use '아/어 봤자' to indicate that even if you performed an action, it wouldn't matter.",
    rule: [
      {
        type: 'AND',
        query: [['보', 'VV'], ['았', 'EP'], ['자', 'EC']],
      },
    ],
    examples: [
      {
        sentence: '그한테 이야기해 봤자 소용 없을 거예요.',
        translation: "It wouldn't have been useful to talk to him.",
      },
      {
        sentence: '공부해 봤자 시험을 잘 보지 않았을 것이다.',
        translation: "Even if I studied, I wouldn't have done well on the test.",
      },
      {
        sentence: '일이 어려워 봤자 저는 꼭 극복해야 돼요.',
        translation: 'I have to overcome the difficulties even if the work is difficult.',
      },
      {
        sentence: '말을 해 봤자 그는 듣지 않았을 거예요.',
        translation: "He wouldn't have listened even if I talked.",
      },
      {
        sentence: '예뻐 봤자 성격이 나빠서 그녀를 좋아하지 않아요.',
        translation: "Even if she's pretty, I don't like her because she has a bad personality.",
      },
    ],
  },
  {
    name: '았/었더라면',
    description: "Use '았/었더라면' to indicate that if something happened in the past, then the present would be different.",
    rule: [
      {
        type: 'AND',
        query: [['더라면', 'EC']],
      },
    ],
    examples: [
      {
        sentence: '제가 더 열심히 공부했더라면 한국어를 더 잘했을 거예요.',
        translation: 'If I had studied harder, I would have been better at Korean.',
      },
      {
        sentence: '여자친구한테 더 잘했더라면 우리 둘이 안 헤어졌을 거야.',
        translation: "If I had done better to my girlfriend, we wouldn't have broken up.",
      },
      {
        sentence: '키가 더 컸더라면 좋았을 텐데.',
        translation: 'It would have been nice if I were taller.',
      },
      {
        sentence: '돈이 있더라면 밥 사줬을 거예요.',
        translation: 'If I had money, I would have bought you a meal.',
      },
      {
        sentence: '시간 있더라면 파티에 갔을 거예요.',
        translation: 'If I had time, I would have gone to the party.',
      },
    ],
  },
  {
    name: '(으)ㄹ 뻔하다',
    description: "'(으)ㄹ 뻔하다' means 'almost'",
    rule: [
      {
        type: 'AND',
        query: [['뻔', 'XR'], ['하', 'XSA']],
      },
    ],
    examples: [
      {
        sentence: '넘어질 뻔했어요.',
        translation: 'I almost fell.',
      },
      {
        sentence: '실패할 뻔했다.',
        translation: 'I almost failed.',
      },
      {
        sentence: '이길 뻔했다.',
        translation: 'I almost won.',
      },
      {
        sentence: '졸 뻔했어요.',
        translation: 'I almost fell asleep.',
      },
      {
        sentence: '그녀와 싸울 뻔했어요.',
        translation: 'I almost had a fight with her.',
      },
    ],
  },
  {
    name: '(으)ㄹ걸 그랬다',
    description: "'(으)ㄹ걸 그랬다' means 'should have'",
    rule: [
      {
        type: 'AND',
        query: [['걸', 'VV'], ['그렇', 'VA']],
      },
    ],
    examples: [
      {
        sentence: '내가 너를 도와줄 걸 그랬어.',
        translation: 'I should have helped you.',
      },
      {
        sentence: '제가 더 열심히 공부할 걸 그랬어요.',
        translation: 'I should have studied harder.',
      },
      {
        sentence: '일찍 일어날 걸 그랬어.',
        translation: 'I should have gotten up early.',
      },
      {
        sentence: '전화할 걸 그랬어.',
        translation: 'I should have called you.',
      },
      {
        sentence: '한국어를 배울 걸 그랬어요.',
        translation: 'I should have learned Korean.',
      },
    ],
  },
  {
    name: '았/었어야 했는데',
    description: "'았/었어야 했는데' means 'should have'",
    rule: [
      {
        type: 'AND',
        query: [['어야', 'EC'], ['는데', 'EC']],
      },
    ],
    examples: [
      {
        sentence: '내가 너를 도와줬어야 했는데 다음에는 도와줄게.',
        translation: "I should have helped you, but I'll help you next time.",
      },
      {
        sentence: '내가 더 열심히 공부했어야 했는데 지금부터 더 열심히 할게요.',
        translation: "I should have studied harder, but I'll do my best from now on.",
      },
      {
        sentence: '일찍 일어났어야 했는데.',
        translation: 'I should have gotten up early.',
      },
      {
        sentence: '전화했어야 했는데 죄송합니다.',
        translation: "I'm sorry I should have called you.",
      },
      {
        sentence: '한국어를 배웠어야 했는데 지금부터 공부할게요.',
        translation: 'I should have learned Korean, but I will study from now on.',
      },
    ],
  },
  {
    name: '곤 하다',
    description: "Use '곤 하다' to indicate that an action is repeated often.",
    rule: [
      {
        type: 'AND',
        query: [['곤', 'EC'], ['하', 'VX']],
      },
      {
        type: 'AND',
        query: [['ㄴ', 'JX'], ['하', 'VX']],
      },
    ],
    examples: [
      {
        sentence: '스트레스 받을 때 노래방을 가곤 해.',
        translation: "I go to karaoke when I'm stressed out.",
      },
      {
        sentence: '저는 심심할 때 그림을 그리곤 해요.',
        translation: "I draw when I'm bored.",
      },
      {
        sentence: '시간이 없을 때 음식을 시키곤 해요.',
        translation: "I order food when I don't have time.",
      },
      {
        sentence: '부끄러울 땐 얼굴이 빨개지곤 해.',
        translation: "My face turns red when I'm shy.",
      },
      {
        sentence: '주말엔 외식을 하곤 해.',
        translation: 'I eat out during weekends.',
      },
    ],
  },
  {
    name: '기는요',
    description: "Use '기는요' to politely refute a statement.",
    rule: [
      {
        type: 'AND',
        query: [['기', 'ETN'], ['는', 'JX']],
      },
      {
        type: 'AND',
        query: [['기', 'ETN'], ['ㄴ', 'JX']],
      },
    ],
    examples: [
      {
        sentence: '열심히 공부하기는요. 전 계속 놀았어요.',
        translation: 'What do you mean I studied hard. I just kept on playing.',
      },
      {
        sentence: '날씨가 덥기는요. 아직 쌀쌀한데.',
        translation: "What do you mean the weather is hot. It's still chilly.",
      },
      {
        sentence: '제가 한국말을 잘하긴요 아직 잘 못해요.',
        translation: "What do you mean I'm goot at Korean. I'm not good at it yet.",
      },
      {
        sentence: '영화가 재밌기는요. 전 졸았어요.',
        translation: 'What do you mean the movie is fun. I dozed off.',
      },
      {
        sentence: '배가 부르기는요. 음식을 더 시킬 거예요.',
        translation: "What do you mean we're full. We're going to order more food.",
      },
    ],
  },
]
