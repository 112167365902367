import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import Typography from '@material-ui/core/Typography'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: '20px',
  },
  accrodian: {
    margin: '10px',
  },
  rounded: {
    borderRadius: '4px',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
    fontWeight: 700,
  },
  summary: {
    alignItems: 'center',
  },
}))

export default function ProgressByScene({ cards }) {
  const classes = useStyles()
  const [expanded, setExpanded] = React.useState(false)

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }

  return (
    <div className={classes.root}>
      { cards.map((card, index) => (
        <Accordion
          key={index}
          classes={{ root: classes.accrodian, rounded: classes.rounded }}
          square={false}
          variant="elevation"
          expanded={expanded === card.title}
          onChange={handleChange(card.title)}
        >
          <AccordionSummary
          // expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
            classes={{
              content: classes.summary,
            }}
          >
            <Typography
              style={{ color: card.completed === card.total ? '#6fd077' : '#c8cec8' }}
              className={classes.heading}
            >
              <CheckCircleIcon fontSize="large" />
            </Typography>
            <Typography className={classes.secondaryHeading}>
              {card.title}
-
              {' '}
              {card.completed}
/
              {card.total}
              {' '}
카드
            </Typography>
          </AccordionSummary>
          {/* <AccordionDetails>
          <Typography>
            Nulla facilisi. Phasellus sollicitudin nulla et quam mattis feugiat. Aliquam eget
            maximus est, id dignissim quam.
          </Typography>
        </AccordionDetails> */}
        </Accordion>
      ))}
    </div>
  )
}

ProgressByScene.defaultProps = {
  cards: [],
}
