import React from 'react'
import PropTypes from 'prop-types'
import { Box, makeStyles } from '@material-ui/core'
import Grey from '../../graphics/grey png.png'

const getSrc = (youtubeId) => {
  if (youtubeId) {
    return `http://i3.ytimg.com/vi/${youtubeId}/hqdefault.jpg`
  }
  return Grey
}

const ContinueWatchingList = ({
  series, setSeries, setDifficulty, setProgress,
}) => {
  const classes = useStyles()

  const handleClick = (seriesTitle, difficulty) => {
    setSeries(seriesTitle)
    setDifficulty(difficulty)
    setProgress((prev) => ({ ...prev, disabled: false }))
  }

  return (
    <Box display="flex" flexWrap="wrap" mt={4}>
      {series.map(({ title, youtubeId, difficulty }) => {
        const src = getSrc(youtubeId)
        return (
          <Box
            className={classes.thumbnail}
            key={youtubeId}
            onClick={() => handleClick(title, difficulty)}
          >
            <img alt="thumbnail" src={src} />
            <Box className={classes.centeredText}>{`${title}`}</Box>
          </Box>
        )
      })}
    </Box>
  )
}

ContinueWatchingList.propTypes = {
  series: PropTypes.arrayOf(PropTypes.object),
  setSeries: PropTypes.func,
  setDifficulty: PropTypes.func,
  setProgress: PropTypes.func,
}
ContinueWatchingList.defaultProps = {
  series: [],
  setSeries: () => { },
  setDifficulty: () => { },
  setProgress: () => { },

}

const useStyles = makeStyles((theme) => ({
  centeredText: {
    fontFamily: 'sans-serif',
    position: 'absolute',
    top: '50%',
    left: '50%',
    textTransform: 'uppercase',
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.75rem',
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: '1.25rem',
    },
    fontWeight: '900',
    transform: 'translate(-50%, -50%)',
  },
  thumbnail: {
    margin: '12px auto',
    display: 'inline-block',
    position: 'relative',
    color: 'white',
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      width: '115px',
      height: '115px',
    },
    [theme.breakpoints.up('sm')]: {
      width: '250px',
      height: '150px',
    },
    overflow: 'hidden',
    '&& img': {
      filter: 'brightness(60%)',
      width: 'auto',
      height: '100%',
      transform: 'scale(1.35, 1.35)',
    },
    cursor: 'pointer',
  },
}))

export default ContinueWatchingList
