/* eslint-disable no-await-in-loop */
import _ from 'lodash'
import { API_GATEWAY_URI, DEV_MODE } from '../constants'

export const NEW_WORD_DEFAULTS = {
  interactions: [],
  interactionCount: 0,
  interval: 10,
  interactionDates: [],
}

const BATCH_WRITE_ITEM_MAX_LEN = 25

const PUT_RETRIES = 4
const PUT_RETRY_TIMEOUT = 100
export async function putUserMorphs(user, morphemes) {
  if (DEV_MODE) return
  const requestUri = `${API_GATEWAY_URI}users/morphs`
  // put user vocab using total vocab state, not module-specific one
  const body = _.map(
    _.compact(_.keys(morphemes)), // drop any empty words, will ValidationError
    (morph) => JSON.stringify({
      user: { S: user.username },
      morpheme: { S: morph },
      props: { S: JSON.stringify(morphemes[morph]) },
    }),
  )
  const chunkedBodies = _.chunk(body, BATCH_WRITE_ITEM_MAX_LEN)
  _.each(chunkedBodies, async (chunkedBody) => {
    let retries = PUT_RETRIES
    while (retries > 0) {
      try {
        const resp = await fetch(requestUri, {
          method: 'POST',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(chunkedBody),
        })
        const respJson = await resp.json()
        if (_.size(respJson.UnprocessedItems) === 0) break
      } catch (err) {
        await new Promise((cb) => setTimeout(cb, PUT_RETRY_TIMEOUT))
        retries -= 1
      }
    }
  })
}

export async function getUserMorphs(user) {
  const requestUri = `${API_GATEWAY_URI}users/morphs?user=${user.username}`
  const response = await fetch(requestUri, {
    mode: 'cors',
  })
  let json = await response.json()
  const vocabArrayItems = json.Items

  while (json.LastEvaluatedKey) {
    const newRequestUri = `${API_GATEWAY_URI}users/morphs?user=${user.username}&last_evaluated_id=${json.LastEvaluatedKey.morpheme.S}`
    const newResponse = await fetch(
      newRequestUri,
      { mode: 'cors' },
    )
    json = await newResponse.json()
    vocabArrayItems.push(...json.Items)
  }

  // create map of encodedWord => { interactions: [int, int, ... ] }
  const vocabMap = _.reduce(
    vocabArrayItems,
    (acc, vocabRow) => {
      const props = _.defaults(JSON.parse(vocabRow.props.S), NEW_WORD_DEFAULTS)
      return _.extend(acc, { [vocabRow.morpheme.S]: props })
    },
    {},
  )
  return vocabMap
}
