/* eslint-disable react/no-array-index-key */
import React, {useEffect, useState, useMemo, useRef} from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import _ from 'lodash'
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  // CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  makeStyles,
  LinearProgress,
  Switch,
  Paper,
  Typography,
  useMediaQuery,
  Link
} from '@material-ui/core'
import {
  NavigateNext,
} from '@material-ui/icons'
import { FixedSizeList } from 'react-window'
import {
  WORD_INTERACTIONS,
  updateVocabulary,
  shouldCardBeSurfaced,
  isKnownWord,
  cacheModuleInfo,
  parseUserComprehension,
  updateVocabularyListCard,
  calculateNewInterval,
  getRealInteraction,
  getCache,
  cacheInfo,
  deleteCache,
  updateVocabularyMaxInterval,
  pushLimitVariable,
  getWord, getContent, getEncodedTokenWord,
} from '../../lib/util'
import { useGlobalStateContext, useVocabContext, useSentencesContext } from '../../contexts'
import { NEW_WORD_DEFAULTS, putUserVocabularyNew, putUserVocabulary } from '../../lib/apigateway/vocabulary'
import { trackComplete, getTrackComplete } from '../../lib/apigateway/trackcomplete'
import { getModuleById } from '../../lib/apigateway/modules'
import { postUserProgress, getUserProgress } from '../../lib/apigateway/progress'
import { postTrackingData } from '../../lib/apigateway/tracking'
import { postAccountSettings } from '../../lib/apigateway/settings'
import {
  MODULE_DIFFICULTIES,
  PHRASES_BY_LANG,
  COMPREHENSION_DEFAULTS,
  VIDEO_TYPES,
  TIMESTAMP_QUERY,
  TRACKING_DEFAULTS,
} from '../../lib/constants'
import TtsSwitch from '../../components/UI-Components/TtsSwitch'
import TtsButton from '../../components/UI-Components/TtsButton'
import Tooltip from '../../components/UI-Components/Tooltip'
import CttContentComponent from '../../components/UI-Components/CttContentComponent/CttContentComponent'
import FeedbackButton from '../../components/FeedbackButton'
import Spoiler from '../../components/UI-Components/Spoiler'
import CompletionCalendar from '../../components/CompletionCalendar'
import promptPic from '../../graphics/prompt.png'
import { COMPLETION_TYPES, getCompletions, postCompletions } from '../../lib/apigateway/completions'
import ProgressByScene from './ProgressByScene';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CongratsNetflix from './CongratsNetflix'
import SceneProgressBar from './SceneProgressBar'

const DAILY_GOAL_COUNT = 50

/**
 * Add youtube, feedback, and review card to module before it is set to
 * state and displayCardInd calculated. Mutates array, no return
 */
const spliceOtherCards = (
  contentId, title, vocabCards, setEndCardNumber, contentType, progress, containsNetflix,
) => {
  const youtubeCard = {
    type: 'video',
    content: `${contentId}`,
    description: `${title}`,
    contentType,
  }
  // const wordListCard = {
  //   type: 'wordList',
  //   content: `${contentId}`,
  //   contentType,
  // }
  // const promptCard = {
  //   type: 'prompt',
  // }

  const progressCard = {
    type: 'progress',
  }

  vocabCards.unshift(progressCard)

  if (!containsNetflix) {
    vocabCards.push(youtubeCard)
  }

  setEndCardNumber(-1) // Number of cards PUSHED at END
}
const getTabStyle = () => {
  const layout = {
    borderTopRightRadius: '20px',
    padding: '8px 16px',
    borderTopLeftRadius: '20px',
    cursor: 'pointer',
  }
  return layout
}

const Module = (props) => {
  const {
    moduleId, moduleLang, translationLang, history,
  } = props
  const prevModuleId = usePrevious(moduleId);
  // allCards[displayCardInds[cardFocusInd]] = current card, i.e. cardFocusInd = display index, not
  // index in allCards
  const [cardFocusInd, setCardFocusInd] = useState(0)
  const [allCards, setCards] = useState([])
  const [sceneCards, setSceneCards] = useState([])
  const [displayCardInds, setDisplayCardInds] = useState([])
  const [isTranslationExpanded, setIsTranslationExpanded] = useState(false)
  const [moduleInfo, setModuleInfo] = useState({
    title: '', contentID: '', contentType: '', completions: {},
  })
  const [, setCttWords] = useState([])
  const [buttonDisabled, setButtonDisabled] = useState(false)
  const [endCardNumber, setEndCardNumber] = useState(0)
  const [review] = useState([])
  const [dialogOpenState, setDialogOpenState] = useState(false)
  const [wordsRemaining, setWordsRemaining] = useState({ new: 0, review: 0, count: 1 })
  const [startTime] = useState(() => Date.now())
  const [tracking, setTracking] = useState({ ...TRACKING_DEFAULTS, moduleId })

  const classes = useStyles()
  const { globalState: { user, settings, session }, setGlobalState } = useGlobalStateContext()
  const { vocab: { vocab: mainVocab, morphs }, setVocab: setMainVocab } = useVocabContext()
  const [vocab, setVocab] = useState(null)
  const { sentences } = useSentencesContext()
  const [cardsByScene, setCardsByScene] = useState([]);
  const [currentScene, setCurrentScene] = useState(0)
  const [netflixLink, setNetflixLink] = useState('')
  const [progressCongratsScene, setProgressCongratsScene] = useState('')
  const [allCongratsIndexes, setAllCongratsIndexes] = useState([])
  const [totalRemovedCardsInModule, setTotalRemovedCardsInModule] = useState(0)
  const [totalRemovedCards, setTotalRemovedCards] = useState(0)
  const [_cardInterval, setCardInterval] = useState(null)
  const [allDataReset, setAllDataReset] = useState(false)

  function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }

  useEffect(() => {
    setAllDataReset(false)
    if (prevModuleId !== moduleId) {
      setCurrentScene(0)
      setDisplayCardInds([])
      setCardFocusInd(0)
      setWordsRemaining({ new: 0, review: 0, count: 1 })
      setIsTranslationExpanded(false)
      setProgressCongratsScene('')
      setAllCongratsIndexes([])
      setTotalRemovedCardsInModule(0)
      setTotalRemovedCards(0)
      setCardInterval(null)
      setCardsByScene([])
      setAllDataReset(true)
    }
    if (!vocab || prevModuleId !== moduleId) {
      setVocab(() => _.reduce(_.keys(mainVocab), (acc, item) => {
        let data = mainVocab[item]
        if (Array.isArray(data)) {
          data = data[data.length - 1];
        }
        return _.extend(acc, {
          [item]: _.defaultsDeep(data, { ...NEW_WORD_DEFAULTS, moduleId }),
        })
      }, {}))
    }
  }, [vocab, mainVocab, moduleId])

  useEffect(() => {
    /** added a card interval state to determine card interval each time cardFocusId changes */
    const _card = allCards[displayCardInds[cardFocusInd]]
    if (_card && _card.type === 'word') {
      const cardProps = _.defaultsDeep(vocab[getEncodedTokenWord(_card.word)], NEW_WORD_DEFAULTS)
      setCardInterval(cardProps.interval);
    }
  }, [cardFocusInd]);

  const card = allCards[displayCardInds[cardFocusInd]]

  // last 2 cards are not actual cards
  const cardsRemaining = Math.max(displayCardInds.length - cardFocusInd + endCardNumber, 0)
  const actualCardsLength = allCards.length + endCardNumber // sometimes can be -3 right
  function getCompletionPercentage(remaining) {
    return Math.min(Math.round((100 * (actualCardsLength - remaining)) / actualCardsLength) || 0,
      100)
  }

  // Track module session
  useEffect(() => {
    const uploadUserTracking = async () => {
      const name = user.attributes.name || 'No registered name'
      await postTrackingData(user, name, session.startDate, tracking)
    }
    uploadUserTracking()
  }, [user, tracking, session.startDate])


  useEffect(() => {
    if (!allDataReset) return;
    async function initModule() {
      const {
        cards: moduleCards,
        sceneCards: cardsForScene,
        tags: moduleTags,
        title,
        contentID,
        series,
        contentType,
      } = await getModuleById(moduleId, moduleLang, translationLang)
      const progress = await getUserProgress(user, moduleId)
      const containsNetflix = moduleCards.some(({ type }) => type === 'netflix')
      spliceOtherCards(
        contentID, title, moduleCards, setEndCardNumber, contentType, progress, containsNetflix,
      )
      // Sort module by review cards first
      // moduleCards.sort((a, b) => {
      //   if (!(a.type === 'word' && b.type === 'word')) {
      //     return 0
      //   }
      //   const aProps = _.defaultsDeep(vocab[getEncodedTokenWord(a.word)], NEW_WORD_DEFAULTS)
      //   const bProps = _.defaultsDeep(vocab[getEncodedTokenWord(b.word)], NEW_WORD_DEFAULTS)

      //   if (aProps.interval === 0 && bProps.interval !== 0) {
      //     return 1
      //   }

      //   if (aProps.interval !== 0 && bProps.interval === 0) {
      //     return -1
      //   }

      //   return 0
      // })

      setNetflixLink(moduleCards.find(item => item.type === 'congrats')?.link)

      const userCompletions = await getTrackComplete(user)
      const completionData = userCompletions[moduleId]
      let comprehension
      if (!completionData) {
        comprehension = { ...COMPREHENSION_DEFAULTS }
      } else {
        comprehension = parseUserComprehension(completionData.comprehension)
      }


      const [difficulty] = _.intersection(moduleTags, MODULE_DIFFICULTIES)
    
      let congratsIndexes = []
      let cardInds = _.range(moduleCards.length).filter(
        (idx) => {
          let filter = shouldCardBeSurfaced(moduleCards[idx], vocab)
          if(filter && moduleCards[idx].type === 'congrats') {
            congratsIndexes.push(idx)
            filter = !!(moduleCards[idx + 1] && moduleCards[idx + 1].type === 'word');
          }
          return filter
        },
      )
      setAllCongratsIndexes(congratsIndexes)
      let lastCongratIndex = -1
      let removeCongratsIndexes = []
      for (let i = 0; i < congratsIndexes.length; i++) {
        let currentCardIndex = cardInds.findIndex(item => item === congratsIndexes[i])
        if(currentCardIndex === -1 
          || moduleCards[cardInds[currentCardIndex+1]]?.type !== 'word' 
          || moduleCards[cardInds[currentCardIndex-1]]?.type !== 'word') {
            removeCongratsIndexes.push(cardInds[currentCardIndex])
            lastCongratIndex++
        }
      }

      if(lastCongratIndex === congratsIndexes.length - 1){
        //Means "you understand the entire episode!"
        setProgressCongratsScene({
          content: "에피소드 전체를 이해할 수 있으실 거예요!"
        })
      }else {
        if(lastCongratIndex >= 0) {
          setProgressCongratsScene(moduleCards[congratsIndexes[lastCongratIndex]])
        }
      }

      cardInds = cardInds.filter(item => !removeCongratsIndexes.includes(item))

      let newCard = 0
      let reviewCardNumber = 0

      cardInds.forEach((idx) => {
        const currCard = moduleCards[idx]

        if (currCard.type === 'word') {
          // eslint-disable-next-line max-len
          const { interval } = _.defaultsDeep(vocab[getEncodedTokenWord(currCard.word)], NEW_WORD_DEFAULTS)
          if (interval === 0) {
            newCard += 1
          } else {
            reviewCardNumber += 1
          }
        }
      })

      const completions = await getCompletions(user, COMPLETION_TYPES.DAILY_GOAL) || []

      setDisplayCardInds(cardInds)
      setWordsRemaining((prev) => ({ ...prev, new: newCard, review: reviewCardNumber }))
      setCards(moduleCards)
      setSceneCards(cardsForScene)
      setModuleInfo({
        title, contentID, series, difficulty, comprehension, contentType, completions,
      })
    }
    if (user !== null && vocab) initModule()
  }, [user, moduleId, moduleLang, translationLang, vocab, allDataReset])

  useEffect(() => { // Create CTT array
    setButtonDisabled(false) // cardFocusInd has changed, enable buttons
    let allScenes = []
    let currentScene = 0
    const currCard = allCards[displayCardInds[cardFocusInd]]
    const currCardIndex = displayCardInds[cardFocusInd]
    if (currCard && (currCard.type === 'word')) {
      const individualWords = getContent(currCard).split(' ')
      setCttWords(_.map(individualWords, () => false))
    }

    for (let i = 0; i < allCards.length; i++) {
      if (i >= currCardIndex) {
        break
      }
      if(allCards[i]?.type === 'congrats') {
        currentScene += 1
      }
    }

    setCurrentScene(currentScene)

    // filter only those cards which are of type word
    for (let i = 0; i < sceneCards.length; i++) { // eslint-disable-line no-plusplus
      const scene = sceneCards[i].filter((s) => s.type === 'word')
      const totalInScene = scene.length
      let completed = 0
      scene.forEach((cardObj) => {
        const cardProps = _.defaultsDeep(vocab[getEncodedTokenWord(cardObj.word)], NEW_WORD_DEFAULTS)
        if (cardProps.interval > 0) {
          completed++ // eslint-disable-line no-plusplus
        }
      })
      allScenes.push({ title: `장면# ${i + 1}`, completed, total: totalInScene })
    }
    setCardsByScene(allScenes || [])
  }, [cardFocusInd, allCards, sceneCards, displayCardInds])

  useEffect(() => {
    const upload = async () => {
      const clone = _.cloneDeep(moduleInfo.completions)
      const dates = pushLimitVariable(clone.completionDates, Date.now(), 50)
      clone.completionDates = dates
      await postCompletions(user, COMPLETION_TYPES.DAILY_GOAL, clone)
    }

    if (wordsRemaining.count % DAILY_GOAL_COUNT === 0) {
      upload()
    }
  }, [moduleInfo.completions, user, wordsRemaining.count])

  const RightButton = ({ position }) => {
    RightButton.propTypes = {
      position: PropTypes.string,
    }
    RightButton.defaultProps = {
      position: '',
    }
    const handleClick = () => {
      const isLast = cardFocusInd >= displayCardInds.length - 1
      if (isLast) {
        cacheModuleInfo(moduleInfo)
        history.push(`/congrats?moduleId=${moduleId}&moduleLength=${allCards.length}&numCardsSeen=${cardFocusInd}&sentencesLearned=${review.length}&start=${startTime}&end=${Date.now()}`)
        return
      }
      setCardFocusInd(cardFocusInd + 1)
      setIsTranslationExpanded(false)
    }
    return (
      <IconButton
        className={(position === 'side') ? classes.rightArrowSide : null}
        aria-label="right"
        color="primary"
        onClick={handleClick}
      >
        <NavigateNext fontSize="large" />
      </IconButton>
    )
  }

  const getResurfaceLength = (interaction, interval) => {
    // Incorrect
    if (interaction === WORD_INTERACTIONS.ThumbDown) {
      return 10
    }
    // I know this && Previous interaction was thumbs down (interval 0.1)
    if (interaction === WORD_INTERACTIONS.ThumbUp && !isTranslationExpanded && interval === 0.1) {
      return 100
    }
    // Correct
    if (interaction === WORD_INTERACTIONS.ThumbUp && isTranslationExpanded && interval === 0.1) {
      return 40
      // if (interval === 0.1) {
      //   // eslint-disable-next-line no-else-return
      // } else if (interval === 0) {
      //   return 100
      // }
    }

    return 0 // TODO Change default
  }

  async function handleCardChange(
    translationExpanded, interaction, word,
  ) {
    let { interval } = _.defaultsDeep(vocab[word], NEW_WORD_DEFAULTS)
    if (card && sentences) {
      const updatedCardVocab = updateVocab(translationExpanded, interaction, interval)
      // const encodedWordsArr = _.map([word], encodeWord)/
      if (interval === 0) {
        putUserVocabularyNew(user, card, updatedCardVocab.vocab)
      } else {
        putUserVocabulary(user, updatedCardVocab.vocab)
      }
    }
    // update completion status, w/ one fewer card remaining than current
    const comprehension = {
      ...moduleInfo.comprehension,
      moduleLength: allCards.length,
      numCardsSeen: cardFocusInd,
    }

    setModuleInfo((prev) => ({ ...prev, comprehension }))

    await trackComplete(
      user,
      moduleId,
      getCompletionPercentage(cardsRemaining - 1),
      encodeURIComponent(JSON.stringify(comprehension)),
    )
    // Resurface card
    let totalCardsRemoved = 0;
    card.content.forEach((wordObj) => {
      if (getEncodedTokenWord(wordObj) === word) {
        return
      }
      const isKnown = isKnownWord(vocab[getEncodedTokenWord(wordObj)])
      if (isKnown) {
        const wordIndexes = [];
        allCards.forEach((cardObj, index) => {
          if (cardObj.type === 'word' && getEncodedTokenWord(cardObj.word) === getEncodedTokenWord(wordObj)) {
            wordIndexes.push(index);
          }
        })
        if (wordIndexes.length > 0) {
          let displayIndexes = [];
          displayCardInds.forEach((c, index) => {
            if (wordIndexes.includes(c) && index > cardFocusInd) {
              displayIndexes.push(index)
            }
          })
          if (displayIndexes.length > 0) {
            handleReviewCount(interaction, interval)
            totalCardsRemoved += 1;
            while (displayIndexes.length) {
              displayCardInds.splice(displayIndexes.pop(), 1)
            }
          }
        }
      }
    })
    setTotalRemovedCardsInModule(totalRemovedCardsInModule + totalCardsRemoved);
    setTotalRemovedCards(totalCardsRemoved);

    if (totalCardsRemoved > 0) {
      setTimeout(() => {
        setTotalRemovedCards(0)
      }, 3 * 1000)
    }

    if (
      interval === 0.1 // Previous interaction was "Incorrect"
      || (interval === 0 && isTranslationExpanded) // New card had translation shown
      || (interval > 0.1 && interaction === WORD_INTERACTIONS.ThumbDown) // "Incorrect"
    ) {
      const currCardInd = displayCardInds[cardFocusInd]
      let resurfaceLength = getResurfaceLength(interaction, interval)
      if (resurfaceLength > 0) {
        displayCardInds.splice(Math.min(cardFocusInd + resurfaceLength
            - displayCardInds.length, endCardNumber), 0, currCardInd)
      }
    }
    setDisplayCardInds(displayCardInds)
    return displayCardInds.length
  }

  // return the updated vocabs map since otherwise callers don't get access to the updated vocabs
  // map
  function updateVocab(translationExpanded, interaction, interval) {
    const newVocabAndMorphs = getUpdatedVocab(
      translationExpanded, card, interaction, interval,
    )
    setVocab(newVocabAndMorphs.vocab)
    let updatedMainVocab
    setMainVocab((prevState) => {
      const prevMainVocab = prevState.vocab
      updatedMainVocab = { vocab: prevMainVocab, morphs: newVocabAndMorphs.morphs }
      card.content.forEach((word) => {
        const encodedWord = getEncodedTokenWord(word)
        const updatedVocab = newVocabAndMorphs.vocab[encodedWord]
        const mainVocabData = prevMainVocab[encodedWord]
        if (Array.isArray(mainVocabData)) {
          const index = mainVocabData.findIndex((item) => item.moduleId === moduleId)
          if (index !== -1) {
            prevMainVocab[encodedWord][index] = { ...updatedVocab, moduleId }
          } else {
            prevMainVocab[encodedWord].push({ ...updatedVocab, moduleId })
          }
        } else {
          prevMainVocab[encodedWord] = [{ ...updatedVocab, moduleId }]
        }
        updatedMainVocab = { vocab: prevMainVocab, morphs: newVocabAndMorphs.morphs }
      })
      return updatedMainVocab
    })
    return updatedMainVocab
  }

  // for a given card currCard and vocab, returns an updated vocab dict that accounts for the user's
  // response (thumbUp, thumbDown, translationExpanded) to currCard
  function getUpdatedVocab(translationExpanded, currCard, interaction, interval) {
    let updatedVocab = { vocab, morphs }
    currCard.content.forEach((word) => {
      const mainWord = getEncodedTokenWord(word)
      const mainWordInteraction = getRealInteraction(
        mainWord, interaction, translationExpanded, false, vocab,
      )

      if (interval === 0
          && (mainWordInteraction === WORD_INTERACTIONS.weakThumbUp
              || mainWordInteraction === WORD_INTERACTIONS.ThumbUp)
      ) {
        updatedVocab = updateVocabularyMaxInterval(vocab, morphs, mainWord, [mainWordInteraction], translationExpanded)
      } else {
        updatedVocab = updateVocabulary(vocab, morphs, mainWord, [mainWordInteraction], interval !== 0)
      }
    })
    return updatedVocab
  }

  function handleClosePrompt(setState, prompt) {
    setState(false)
    const newSettings = { ...settings, prompts: { ...settings.prompts, [prompt]: false } }
    setGlobalState((prevState) => ({ ...prevState, settings: newSettings }))
    postAccountSettings(user.username, newSettings)
  }

  function handleReviewCount(interaction, interval) {
    if (interval > 0.1 && interaction !== WORD_INTERACTIONS.ThumbDown) {
      setWordsRemaining((prev) => ({ ...prev, review: prev.review > 0 ? prev.review - 1 : 0 }))
    } else if (interval === 0) {
      if (interaction !== WORD_INTERACTIONS.ThumbUp) {
        setWordsRemaining((prev) => ({ ...prev, new: prev.new - 1, review: prev.review + 1 }))
      } else {
        setWordsRemaining((prev) => ({ ...prev, new: prev.new - 1 }))
      }
    } else if (interval === 0.1) {
      if (interaction !== WORD_INTERACTIONS.ThumbDown) {
        setWordsRemaining((prev) => ({ ...prev, review: prev.review > 0 ? prev.review - 1 : 0 }))
      }
    }

    setWordsRemaining((prev) => ({ ...prev, count: prev.count + 1 }))
  }

  const ModuleCard = (props) => {
    const {cardsByScene, currentScene} = props
    VideoCardComponent.propTypes = {
      content: PropTypes.string,
      contentType: PropTypes.string,
    }

    VideoCardComponent.defaultProps = {
      content: '',
      contentType: '',
    }

    renderGenericCardComponent.propTypes = {
      title: PropTypes.string,
      description: PropTypes.string,
      body: PropTypes.string,
      link: PropTypes.string,
    }

    renderGenericCardComponent.defaultProps = {
      title: 'Lorem ipsum',
      description: 'dolor sit amet, ',
      body: 'consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
      link: '',
    }

    WordCardComponent.propTypes = {
      content: PropTypes.string,
      translation: PropTypes.string,
      word: PropTypes.string,
      notes: PropTypes.arrayOf(PropTypes.string),
      examples: PropTypes.arrayOf(PropTypes.object),
    }

    WordCardComponent.defaultProps = {
      content: 'Lorem ipsum',
      translation: 'dolor sit amet, ',
      word: 'lorum ipsum',
      notes: [],
      examples: [],
    }

    NetflixComponent.propTypes = {
      link: PropTypes.string.isRequired,
    }

    async function toggleInteraction(interaction, word) {
      const newDisplayCardIndsLength = await handleCardChange(
        isTranslationExpanded, interaction, word,
      )
      if (cardFocusInd < newDisplayCardIndsLength - 1) {
        setCardFocusInd((prevState) => prevState + 1)
      }

      setTracking((prev) => ({ ...prev, numCardsSeen: prev.numCardsSeen + 1 }))

      setIsTranslationExpanded(false)
    }

    switch (card.type) {
      case 'generic':
        return renderGenericCardComponent(card)
      case 'word':
        return (
          <WordCardComponent
            content={getContent(card)}
            translation={card.translation}
            word={getEncodedTokenWord(card.word)}
            mainWord={card.word}
            notes={card.notes}
            examples={card.examples}
          />
        )
      case 'video':
        return <NetflixComponent link={netflixLink} />
      case 'wordList':
        return <WordListCardComponent />
      case 'netflix':
        return <NetflixComponent/>
      case 'prompt':
        return <PromptCardComponent />
      case 'progress':
        return <ProgressCardComponent />
      case 'congrats':
        return <CongratsCardComponent {...card} cardsByScene={cardsByScene} currentScene={currentScene}/>
      default:
        throw TypeError(`Card is of unexpected type: ${card.type}`)
    }

    function PromptCardComponent() {
      const handleStartClick = () => {
        setCardFocusInd((prev) => prev + 1)
      }

      return (
        <Card>
          <Box height={400} display="flex" flexDirection="column" justifyContent="center" p={2}>
            <Box fontSize={24} fontWeight={500}>수준을 알기 위해 시험을 보세요!</Box>
            <Box fontSize={16} color="text.secondary" my={2}>약 10~20분이 소요되며 모듈에서 어떤 어휘를 연습할지 결정됩니다.</Box>
            <Box height={50} my={2}><img src={promptPic} alt="promptPic" style={{ height: 50 }} /></Box>
            <Box><Button color="secondary" variant="contained" onClick={handleStartClick}>시작</Button></Box>
            <Box my={1}><Button color="secondary" href="/#/">취소</Button></Box>
          </Box>
        </Card>
      )
    }

    function renderGenericCardComponent({
      title, description, body, link,
    }) {
      return (
        <Card>
          <CardContent>
            <Typography variant="h5" component="h2">
              {title}
            </Typography>
            <Typography className={classes.pos} color="textSecondary">
              {description}
            </Typography>
            <Typography component="p" variant="subtitle1">
              {body}
            </Typography>
            {link && <CardMedia className={classes.tutorialImg} component="img" image={link} />}
          </CardContent>
        </Card>
      )
    }

    function WordCardComponent({
      content, translation, word, notes, examples = [], mainWord
    }) {
      const [ttsPlayed, setTtsPlayed] = useState(false)

      // eslint-disable-next-line arrow-body-style
      const { interval } = useMemo(() => {
        return _.defaultsDeep(vocab[word], NEW_WORD_DEFAULTS)
      }, [word])

      const primaryButtons = (
        <Box display="flex" justifyContent="center" flexWrap="wrap">
          <Box>
            <Button
              className={classes.knownButton}
              disableElevation
              onClick={() => {
                setButtonDisabled(true)
                toggleInteraction(
                  WORD_INTERACTIONS.ThumbUp, word,
                )
                handleReviewCount(WORD_INTERACTIONS.ThumbUp, interval)
              }}
              variant="contained"
              disabled={buttonDisabled}
            >
              {`${PHRASES_BY_LANG.understand[settings.translationLang]}`}
            </Button>
            <Box textAlign="center" fontSize={12} color="text.secondary">{interval ? '(3일 후 복습)' : '(학습에서 제외하기)'}</Box>
          </Box>
          <Box>
            <Button
              className={classes.showAnswerButton}
              disableElevation
              onClick={() => {
                setIsTranslationExpanded(true)
                if (settings.prompts.showAnswer) setDialogOpenState(true)
              }}
              variant="contained"
              disabled={buttonDisabled}
            >
              {`${PHRASES_BY_LANG.showTranslation[settings.translationLang]}`}
            </Button>
          </Box>
        </Box>
      )
      const correctButtons = (
        <Box display="flex" justifyContent="center" flexWrap="wrap">
          <Box>
            <Button
              className={classes.correctButton}
              disableElevation
              onClick={(e) => {
                e.stopPropagation()
                setButtonDisabled(true)
                toggleInteraction(
                  WORD_INTERACTIONS.ThumbUp, word,
                )
                handleReviewCount(WORD_INTERACTIONS.Translate, interval)
              }}
              variant="contained"
              disabled={buttonDisabled}
            >
              O
            </Button>
            <Box textAlign="center" fontSize={12} color="text.secondary">{`(${calculateNewInterval(interval, WORD_INTERACTIONS.Translate)}일 후 복습)`}</Box>
          </Box>
          <Box>
            <Button
              className={classes.incorrectButton}
              disableElevation
              onClick={(e) => {
                e.stopPropagation()
                setButtonDisabled(true)
                toggleInteraction(
                  WORD_INTERACTIONS.ThumbDown,
                  word,
                )
                handleReviewCount(WORD_INTERACTIONS.ThumbDown, interval)
              }}
              variant="contained"
              disabled={buttonDisabled}
            >
              X
            </Button>
            <Box textAlign="center" fontSize={12} color="text.secondary">(1분 후 복습)</Box>
          </Box>
        </Box>
      )

      const sentenceArray = content.split(' ')

      const showAudioCard = (cardInterval, autoTts, audioCard) => {
        if (audioCard === true) {
          return true
        }

        if (autoTts === false) {
          return false
        }

        if (cardInterval > 0) {
          return true
        }

        return false
      }

      return (
        <Card>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-evenly"
            minHeight="25rem"
            position="relative"
            p={3}
          >
            <Box className={classes.alertBox} style={{
              display: totalRemovedCards > 0 ? 'block' : 'none'
            }}>
              카드가 {totalRemovedCards}개 더 제거되었습니다.
            </Box>
            <Box
              display="flex"
              justifyContent="center"
              fontSize={30}
              fontWeight={400}
              minHeight={100}
            >
              <Box alignSelf="flex-end">
                <CttContentComponent
                  wordsArr={sentenceArray}
                  setCttWords={setCttWords}
                  mainWord={mainWord?.word}
                  useSpoiler={showAudioCard(interval, settings.autoplayTTS, settings.audioCard)}
                  clickedExpand={isTranslationExpanded}
                />
                <TtsButton
                  sentence={content}
                  alwaysPlay={showAudioCard(interval, settings.audioCard)}
                  ttsPlayed={ttsPlayed}
                  setTtsPlayed={setTtsPlayed}
                />
              </Box>
            </Box>
            <Box
              minHeight={50}
              fontSize={20}
              fontWeight="fontWeightLight"
              style={isTranslationExpanded ? { visibility: 'visible' } : { visibility: 'hidden' }}
            >
              {`${translation}`}
              {!!examples.length && (
                <>
                  <Box py={2}><hr width="60%" /></Box>
                  <table style={{ margin: '0 auto' }}>
                    <tbody>
                      {
                        examples.map((obj) => (
                          <tr key={obj.content}>
                            <td align="left">{obj.content}</td>
                            <td align="left"><Spoiler text={obj.translation} /></td>
                          </tr>
                        ))
                      }
                    </tbody>
                  </table>
                </>
              )}
            </Box>
            {
              notes.map((text) => (
                <Box
                  color="text.secondary"
                  style={(isTranslationExpanded && notes.length) ? { visibility: 'visible' } : { visibility: 'hidden' }}
                  key={text}
                >
                  {`${text}`}
                </Box>
              ))
            }
            <Box>
              <hr width="20%" />
              <Box
                mt={1}
                fontSize={16}
                fontWeight={400}
              >
                {isTranslationExpanded ? '위 문장과 같이 번역했나요?' : '문장을 이해하세요?'}
              </Box>
              <Box>{isTranslationExpanded ? correctButtons : primaryButtons}</Box>
            </Box>
          </Box>
          <Box display="flex" justifyContent="space-between">
            <Box ml={2} display="flex" justifyContent="center">
              <TtsSwitch autoplayTTS={settings.autoplayTTS} />
            </Box>
            <FeedbackButton
              sentence={content}
              translation={translation}
              moduleId={moduleId}
              username={`${user.attributes.name}-${user.username}`}
            />

          </Box>
          <Dialog
            open={dialogOpenState}
            onClose={() => setDialogOpenState(false)}
          >
            <DialogTitle>{`${PHRASES_BY_LANG.showAnswerPrompt.title[settings.translationLang]}`}</DialogTitle>
            <DialogContent>
              <DialogContentText>
                {`${PHRASES_BY_LANG.showAnswerPrompt.body[settings.translationLang]}`}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => handleClosePrompt(setDialogOpenState, 'showAnswer')} color="secondary" autoFocus>
                {`${PHRASES_BY_LANG.doNotShow[settings.translationLang]}`}
              </Button>
            </DialogActions>
          </Dialog>
        </Card>
      )
    }
    // TODO: Implement favorite video
    function VideoCardComponent({ content, contentType }) {
      const getSrc = (videoId, videoType) => {
        if (videoType === VIDEO_TYPES.YOUTUBE) {
          const [id, timeFromId] = videoId.split(TIMESTAMP_QUERY)
          let timestamp

          if (timeFromId) {
            const [start, end] = timeFromId.split('-')
            timestamp = `start=${start}&end=${end}`
          }
          return `https://www.youtube.com/embed/${id}?${timestamp || ''}`
        }
        return `https://vlive.tv/embed/${videoId}?autoPlay=false`
      }

      const YoutubeIframe = () => (
        <iframe
          title={content}
          className={classes.iframe}
          src={getSrc(content, contentType)}
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      )
      return (
        <Card>
          <CardMedia className={classes.media}>
            <YoutubeIframe />
          </CardMedia>
        </Card>
      )
    }

    function NetflixComponent({ link }) {
      return (
        <Paper>
          <Box height={100} display="flex" justifyContent="center" alignItems="center">
            <Button
              className={classes.netflixButton}
              rel="noopener noreferrer"
              target="_blank"
              href={`${link}`}
            >
              넷플릭스에서 시청하기
            </Button>
          </Box>
        </Paper>
      )
    }

    function WordListCardComponent() {
      const [wordList, setWordList] = useState({ known: [], weak: [], expressions: [] })
      const [switchState, setSwitchState] = useState({})
      const [toUpload, setToUpload] = useState({})
      const [progress, setProgress] = useState(0)

      const isDesktop = useMediaQuery('(min-width:600px)')
      const CACHE_KEY = `wordList_cache_${moduleId}_${user.username}`
      const getWordListCache = useMemo(() => getCache(CACHE_KEY), [CACHE_KEY])
      const cacheWordList = useMemo(() => cacheInfo(CACHE_KEY), [CACHE_KEY])

      useEffect(() => {
        const cache = getWordListCache()

        if (_.isEmpty(cache)) {
          const known = []
          const weak = []
          const expressions = []
          const tempSwitchState = {}
          const tempToUpload = {}

          allCards.forEach((cardObj, idx) => {
            if (cardObj.type !== 'word') {
              return
            }

            if (getWord(cardObj.word).split(' ').length > 1) {
              expressions.push({ cardObj, idx })
              return
            }
            if (isKnownWord(vocab[getEncodedTokenWord(cardObj.word)])) {
              known.push({ cardObj, idx })
              return
            }
            tempToUpload[getEncodedTokenWord(cardObj.word)] = WORD_INTERACTIONS.ThumbUp
            tempSwitchState[getEncodedTokenWord(cardObj.word)] = true
            weak.push({ cardObj, idx })
          })

          const toCache = {
            toUpload: tempToUpload,
            wordList: { known, weak, expressions },
            switchState: tempSwitchState,
          }

          cacheWordList(toCache)

          setToUpload(tempToUpload)
          setWordList({ known, weak, expressions })
          setSwitchState(tempSwitchState)
        } else {
          setToUpload(cache.toUpload)
          setWordList(cache.wordList)
          setSwitchState(cache.switchState)
        }
      }, [cacheWordList, getWordListCache])

      const handleStartClick = async () => {
        // Process and update each interaction
        const uploadInteractions = async () => {
          const entries = Object.entries(toUpload)
          entries.forEach(async ([word, interaction]) => {
            let updated
            // setVocab((prevState) => {
            //   updated = updateVocabularyListCard(
            //     prevState.vocab, {}, word, [interaction],
            //   )
            //   return prevState
            // })
            setVocab((prevState) => {
              const { interval } = prevState[word]
              updated = updateVocabularyListCard(
                prevState, {}, word, [interaction], interval !== 0,
              )
              return prevState
            })

            setMainVocab((prevState) => {
              const prevMainVocab = prevState.vocab
              const updatedVocab = updated.vocab[word]
              const mainVocabData = prevMainVocab[word]
              if (Array.isArray(mainVocabData)) {
                const index = mainVocabData.findIndex((item) => item.moduleId === moduleId)
                if (index !== -1) {
                  prevMainVocab[word][index] = { ...updatedVocab, moduleId }
                } else {
                  prevMainVocab[word].push({ ...updatedVocab, moduleId })
                }
              } else {
                prevMainVocab[word] = [{ ...updatedVocab, moduleId }]
              }
              updated = { vocab: prevMainVocab, morphs: updated.morphs }
              return updated
            })

            const cardObj = allCards.find((item) => item.type === 'word' && getEncodedTokenWord(item.word) === word)
            // const encodedMainWord = encodeWord(word)
            await putUserVocabularyNew(user, cardObj, updated.vocab)
          })
        }

        await uploadInteractions()
        await postUserProgress(user, moduleId, `test -> delete later. Started ${new Date().toString()}`)

        // Set new displayCardInds
        const toggledArr = wordList.weak
          .filter(({ cardObj }) => !switchState[getEncodedTokenWord(cardObj.word)])
          .map(({ idx }) => idx)
          .sort((a, b) => a - b)

        const expressionIdx = wordList.expressions.map(({ idx }) => idx)
        const progressIdx = allCards.findIndex((cardObj) => cardObj.type === 'progress')

        const displayIdx = [0,
          progressIdx,
          ...toggledArr,
          ...expressionIdx,
          allCards.length - 1]// Add last video/wordlist index
        setDisplayCardInds(displayIdx)

        let newCard = 0
        let reviewCardNumber = 0

        displayIdx.forEach((idx) => {
          const currCard = allCards[idx]

          if (currCard.type === 'word') {
            // eslint-disable-next-line max-len
            const { interval } = _.defaultsDeep(vocab[getEncodedTokenWord(currCard.word)], NEW_WORD_DEFAULTS)
            if (interval === 0) {
              newCard += 1
            } else {
              reviewCardNumber += 1
            }
          }
        })
        deleteCache(CACHE_KEY)
        setWordsRemaining((prev) => ({ ...prev, new: newCard, review: reviewCardNumber }))
        // setCardFocusInd((prev) => prev + 1)
      }

      const Row = ({ index, style }) => {
        const { word } = wordList.weak[index].cardObj

        const handleChange = (event) => {
          const cardWord = event.target.name
          const value = event.target.checked

          const cache = getWordListCache()

          if (toUpload[cardWord]) {
            setToUpload((prev) => {
              // eslint-disable-next-line no-param-reassign
              delete prev[getEncodedTokenWord(word)]
              cacheWordList({
                ...cache,
                toUpload: prev,
                switchState: { ...cache.switchState, [cardWord]: value },
              })
              return prev
            })
          } else {
            setToUpload((prev) => {
              // eslint-disable-next-line no-param-reassign
              prev[cardWord] = value ? WORD_INTERACTIONS.ThumbUp : WORD_INTERACTIONS.ThumbDown
              cacheWordList({
                ...cache,
                toUpload: prev,
                switchState: { ...cache.switchState, [cardWord]: value },
              })
              return prev
            })
          }

          setSwitchState((prev) => ({ ...prev, [cardWord]: value }))
        }

        return (
          <Box style={style} display="flex" mt={1}>
            <Box width="50%" display="flex" justifyContent="center" alignItems="center">{getWord(word)}</Box>
            <Box width="50%" display="flex" justifyContent="center" alignItems="center">
              <Switch checked={switchState[getEncodedTokenWord(word)]} color="primary" name={getEncodedTokenWord(word)} onChange={handleChange} />
            </Box>
          </Box>
        )
      }

      Row.propTypes = {
        index: PropTypes.number.isRequired,
        // eslint-disable-next-line react/forbid-prop-types
        style: PropTypes.object.isRequired,
      }

      const handleScroll = (e) => {
        const scrollPx = e.target.scrollTop
        const winHeightPx = e.target.scrollHeight - e.target.clientHeight
        const scrolled = Math.round((scrollPx / winHeightPx) * 100)

        setProgress(scrolled)
      }

      return (
        <Box>
          <Card>
            <Box p={3} maxWidth={700} margin="0 auto">
              <Box fontSize={isDesktop ? 24 : 18} fontWeight={500} style={{ wordBreak: 'keep-all' }}>
                아래 에피소드에 나타나는 단어 목록입니다
              </Box>
              <Box fontSize={isDesktop ? 24 : 18} fontWeight={400}>
                {moduleInfo.title}
              </Box>
              <Box color="text.secondary" my={1}>모르는 단어를 선택해 주세요</Box>
              <Box display="flex" justifyContent={isDesktop ? 'space-evenly' : 'center'}>
                <Box m={1}>
                  <Paper elevation={2}>
                    <Box
                      bgcolor="text.disabled"
                      borderRadius={4}
                      color="white"
                      display="flex"
                      flexDirection="column"
                      height={isDesktop ? 200 : 125}
                      justifyContent="center"
                      width={isDesktop ? 200 : 125}
                    >
                      <Box fontSize={isDesktop ? 60 : 40} fontWeight={600}>
                        {_.filter(switchState, (state) => !state).length}
                      </Box>
                      <Box
                        fontSize={isDesktop ? 20 : 14}
                        fontWeight={400}
                      >
                        모르는 단어
                      </Box>
                      <Box
                        fontSize={isDesktop ? 20 : 14}
                        fontWeight={400}
                      >
                        {`+${wordList.expressions.length} 표현`}
                      </Box>
                    </Box>
                  </Paper>
                </Box>
                <Box m={1}>
                  <Paper elevation={2}>
                    <Box
                      bgcolor="primary.main"
                      borderRadius={4}
                      color="white"
                      display="flex"
                      flexDirection="column"
                      height={isDesktop ? 200 : 125}
                      justifyContent="center"
                      width={isDesktop ? 200 : 125}
                    >
                      <Box fontSize={isDesktop ? 60 : 40} fontWeight={600}>
                        {_.filter(switchState, (state) => state).length}
                      </Box>
                      <Box fontSize={isDesktop ? 20 : 14} fontWeight={400}>아는 단어</Box>
                    </Box>
                  </Paper>
                </Box>
              </Box>
              <Box maxWidth={500} my={2} mx="auto">
                <LinearProgress value={progress} variant="determinate" />
              </Box>
              <Box display="flex" justifyContent="center" my={2} id="wordList" onScroll={handleScroll} border={1} borderColor="grey.300" borderRadius={4}>
                <FixedSizeList
                  height={isDesktop ? 285 : 275}
                  itemCount={wordList.weak.length}
                  itemSize={50}
                  width={isDesktop ? 500 : 275}
                >
                  {Row}
                </FixedSizeList>
              </Box>
              <Button
                color="secondary"
                disabled={progress !== 100 && wordList.weak.length > 7}
                fullWidth
                onClick={handleStartClick}
                size="small"
                variant="contained"
              >
                {(progress === 100 || wordList.weak.length < 7) ? '확인' : '시작하려면 끝까지 스크롤 하세요'}
              </Button>
            </Box>
          </Card>
        </Box>
      )
    }

    function ProgressCardComponent() {
      const [words, setWords] = useState(
        { new: [], review: [], displayStats: { new: 0, review: 0 } },
      )
      const [progress, setProgress] = useState(39)

      const getTotalWordCount = () => {
        const count = allCards.filter((cardObj) => {
          if (cardObj.type !== 'word') {
            return false
          }

          return true
        })

        return count.length
      }

      const totalWordCount = getTotalWordCount()
      useEffect(() => {
        const newCard = []
        const reviewCard = []

        allCards.forEach((cardObj) => {
          if (cardObj.type !== 'word') {
            return
          }
          const cardProps = _.defaultsDeep(vocab[getEncodedTokenWord(cardObj.word)], NEW_WORD_DEFAULTS)
          if (isKnownWord(cardProps)) {
            return
          }

          if (!shouldCardBeSurfaced(cardObj, vocab)) {
            return
          }

          if (cardProps.interval === 0) {
            newCard.push(cardObj)
            return
          }

          reviewCard.push(cardObj)
        })

        const displayStats = [...reviewCard, ...newCard].slice(0, 50).reduce((stats, item) => {
          const { interval } = _.defaultsDeep(vocab[getEncodedTokenWord(item.word)], NEW_WORD_DEFAULTS)
          // eslint-disable-next-line no-param-reassign
          stats[interval === 0 ? 'new' : 'review'] += 1
          return stats
        }, { new: 0, review: 0 })

        setProgress(Math.round(((totalWordCount - newCard.length) / totalWordCount) * 100))
        setWords({ new: newCard, review: reviewCard, displayStats })
      }, [totalWordCount])
      return (
        <Card>
          <Box height={250} display="flex" flexDirection="column" justifyContent="space-around" alignItems="flex-start" p={2}>
            <Box fontSize={30} fontWeight={600} color="text.secondary">{moduleInfo.title}</Box>
            <Box display="flex" flexDirection="column" alignItems="flex-start" width="100%">
              <Box fontSize={24} fontWeight={500}>{`${totalWordCount} 카드`}</Box>
              <Box width="100%" my={1}>
                <LinearProgress classes={{root: classes.progressBarBg, bar: classes.progressBarFg}} value={progress} variant="determinate" />
              </Box>
              <Box>
                <Box component="span">
                  {`${totalWordCount - words.new.length} 개의 단어와 표현을 배우셨어요!`}
                </Box>
              </Box>
              <Box>
                {progressCongratsScene?.link 
                  ? <Link href={progressCongratsScene?.link} target='_blank'>
                    {progressCongratsScene?.content}
                  </Link>
                : progressCongratsScene?.content}
              </Box>
            </Box>
          </Box>

          <Box p={2}>
            <Box fontWeight={600} fontSize={16} textAlign="left" mb={1} color="text.secondary">출석 체크</Box>
            <CompletionCalendar datesArr={moduleInfo.completions.completionDates} />
          </Box>
          <Box p={2}>
            <Button color="primary" variant="contained" fullWidth onClick={() => setCardFocusInd((prev) => prev + 1)}>모듈 시작</Button>
          </Box>
        </Card>
      )
    }

    function CongratsCardComponent(props) {
      const {cardsByScene, currentScene} = props
      return (
        <Card
          classes={{
            root: classes.congratsRoot,
          }}
        >
          <Typography variant="h3" style={{fontWeight: 700}}>축하합니다!</Typography>
          <Typography variant="subtitle1" >장면 {currentScene + 1} 학습 카드를 완료했습니다</Typography>
          <Card           
            classes={{
              root: classes.congratsSceneRoot,
            }}>
            <Typography
              style={{color: cardsByScene[currentScene]?.completed === cardsByScene[currentScene]?.total ?  '#6fd077' : '#c8cec8'}}
              className={classes.congratsHeading}><CheckCircleIcon fontSize='large' /></Typography>
            <Typography className={classes.congratsSecondaryHeading}>{`장면# ${currentScene + 1}`} - {cardsByScene[currentScene]?.completed}/{cardsByScene[currentScene]?.total} 카드</Typography>
          </Card>
          <Card
            classes={{
              root: classes.congratsSceneRoot,
            }}>
            <Typography
              style={{color: cardsByScene[currentScene+1]?.completed === cardsByScene[currentScene+1]?.total ?  '#6fd077' : '#c8cec8'}}
              className={classes.congratsHeading}><CheckCircleIcon fontSize='large' /></Typography>
            <Typography className={classes.congratsSecondaryHeading}>{`장면# ${currentScene + 2}`} - {cardsByScene[currentScene+1]?.completed}/{cardsByScene[currentScene+1]?.total} 카드</Typography>
          </Card>
          <Button style={{
              marginTop: 20,
            }} color="secondary"  variant="contained" onClick={() => setCardFocusInd((prev) => prev + 1)} fullWidth>
            장면 {currentScene+2} 학습 시작
          </Button>
      </Card>)
    }
  }

  function getCardNumberLine(type) {
    if (type === 'wordList') {
      return null
    }
    return (
      <Box display="flex" flexDirection="row">
        {/** title="Cards you haven't seen yet" */}
        <Tooltip title="아직 보지 못한 카드" arrow placement="top">
          <Box style={ _cardInterval !== null && _cardInterval === 0 ? { ...getTabStyle(), backgroundColor: '#f6962c', color: 'white' } : { ...getTabStyle(), backgroundColor: '#e0e0e0' }}>{`학습:${wordsRemaining.new}`}</Box>
        </Tooltip>
        {/** title="Cards you have already seen" */}
        <Tooltip title="이미 본 카드" arrow placement="top">
          <Box style={ _cardInterval !== null && _cardInterval !== 0 ? { ...getTabStyle(), backgroundColor: '#f6962c', color: 'white' } : { ...getTabStyle(), backgroundColor: '#e0e0e0' }}>{`복습:${wordsRemaining.review}`}</Box>
        </Tooltip>
      </Box>
    )
  }

  const moduleCards = allCards.map(() => <ModuleCard cardsByScene={cardsByScene} currentScene={currentScene}/>)
  return (
    <Box className={classes.root}>
      <Grid
        container
        direction="column"
        alignItems="center"
        wrap="nowrap"
      >
        {(cardFocusInd === displayCardInds.length - 1) && (
          <>
            <Box fontSize={40} fontWeight={600}>동영상을 시청하세요</Box>
            <Box mb={2}>한국어 자막은 끄고 영어 자막과 함께 시청하세요!</Box>
          </>
        )}
        <Grid container item justifyContent="space-between" alignItems="flex-end">
          <Grid item>
            <Box className={classes.textHeaders} color="text.secondary" display="flex">
              {getCardNumberLine(card && card.type)}
            </Box>
          </Grid>
        </Grid>
        <Grid container item style={{ position: 'relative' }}>
          <Grid className={classes.card} item>
            {displayCardInds[cardFocusInd] != null
              ? moduleCards[displayCardInds[cardFocusInd]]
              : null}
          </Grid>
          {
            card && (card.type === 'video') && (
              <RightButton position="side" />
            )
          }
        </Grid>
        <Grid item container wrap="nowrap">
          <Grid item zeroMinWidth xs style={{ width: 100 }}>
            <Typography variant="subtitle2" color="textSecondary" noWrap>{moduleInfo.title}</Typography>
          </Grid>
        </Grid>
        {card && card.type === 'progress' ?
        (<Grid item container wrap="nowrap">
          <ProgressByScene cards={cardsByScene} />
        </Grid>): null}
        {card && card.type === 'congrats' ?
        (<Grid item container wrap="nowrap">
          <CongratsNetflix card={card} />
        </Grid>): null}
        {card && card.type === 'word' ?
        (<Grid item container wrap="nowrap">
          <SceneProgressBar
            allCards={allCards}
            sceneCards={sceneCards}
            cardFocusInd={cardFocusInd}
            allCongratsIndexes={allCongratsIndexes}
            currentScene={currentScene} 
            displayCardInds={displayCardInds} />
        </Grid>): null}
        <Grid alignItems="center" container item justifyContent="center">
          {/* <Grid item>
            <Box position="relative" display="inline-flex">
              <CircularProgress variant="static" value={getCompletionPercentage(cardsRemaining)} />
              <Box
                top={0}
                left={0}
                bottom={0}
                right={0}
                position="absolute"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Typography
                  variant="caption"
                  component="div"
                  color="textSecondary"
                >
                  {`${getCompletionPercentage(cardsRemaining)}%`}
                </Typography>
              </Box>
            </Box>
          </Grid> */}
          {
            card && (card.type === 'video') && (
              <Grid item className={classes.bottomNavigationButtons}>
                <RightButton />
              </Grid>
            )
          }
        </Grid>
      </Grid>
    </Box>
  )
}

const useStyles = makeStyles((theme) => ({
  card: {
    alignItems: 'center',
    justify: 'center',
    textAlign: 'center',
    width: '100%',
  },
  media: {
    position: 'relative',
    width: '100%',
    height: 0,
    paddingBottom: '56.25%', /* 16:9 */
    paddingTop: 25,
  },
  alertBox: {
    background: '#efd075',
    width: 'fit-content',
    paddingRight: '10px',
    paddingLeft: '10px',
    paddingTop: '5px',
    paddingBottom: '5px',
    borderRadius: '5px'
  },
  iframe: {
    position: 'absolute',
    top: 0,
    left: 0,
    border: 0,
    width: '100%',
    height: '100%',
    overflow: 'hidden',
  },
  pos: {
    marginBottom: 12,
  },
  root: {
    position: 'relative',
    top: 35,
    paddingBottom: '100px',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
    [theme.breakpoints.up('sm')]: {
      width: '90%',
      margin: '0 auto',
    },
    [theme.breakpoints.up('md')]: {
      width: '70%',
      margin: '0 auto',
    },
  },
  grid: {
    width: '100%',
    direction: 'row',
    justify: 'space-between',
    alignItems: 'flex-end',
    alignContent: 'flex-end',
  },
  hoverEffect: {
    '&:hover': {
      backgroundColor: 'rgba(232, 236, 241, 0.9)',
    },
  },
  tutorialImg: {
    maxHeight: 400,
    objectFit: 'contain',
    paddingTop: '2.25rem',
  },
  bottomNavigationButtons: {
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
    },
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  rightArrowSide: {
    position: 'absolute',
    right: -70,
    top: '50%',
    transform: 'translateY(-50%)',
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  textHeaders: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.75rem',
    },
  },
  correctButton: {
    background: '#57b44c',
    color: 'white',
    '&:hover': {
      backgroundColor: '#4b9a42',
    },
    margin: 6,
    width: 137,
  },
  incorrectButton: {
    background: '#df5f6a',
    color: 'white',
    '&:hover': {
      backgroundColor: '#d2202f',
    },
    margin: 6,
    width: 137,
  },
  knownButton: {
    background: '#2d8fd5',
    color: 'white',
    '&:hover': {
      backgroundColor: '#006fbe',
    },
    margin: 6,
    width: 137,
  },
  netflixButton: {
    backgroundColor: '#E50914',
    color: 'white',
    height: '46px',
    '&:hover': {
      backgroundColor: '#d90913',
      color: '#FFF',
    },
  },
  showAnswerButton: {
    margin: 6,
    width: 137,
  },
  wordList: {
    overflowY: 'auto',
    maxHeight: 400,
  },
  wordListSelectors: {
    cursor: 'pointer',
    textDecoration: 'underline',
  },
  listStats: {
    [theme.breakpoints.down('xl')]: {
      margin: '0px 72px',
    },
    [theme.breakpoints.down('md')]: {
      margin: '0px 32px',
    },
  },
  wordsToLearn: {
    [theme.breakpoints.down('xs')]: {
      fontSize: 45,
      fontWeight: 600,
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: 55,
      fontWeight: 600,
    },
  },
  wordsToLearnNum: {
    [theme.breakpoints.down('xs')]: {
      fontSize: 18,
      fontWeight: 400,
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: 20,
      fontWeight: 400,
    },
  },
  congratsRoot: {
    padding: 30,
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  congratsSceneRoot: {
    alignItems: 'center',
    justifyContent: 'center',
    display:'flex',
    width: 'fit-content',
    padding: '5px 15px',
    marginTop: '15px',
    boxShadow: '-1px 3px 4px 1px rgb(0 0 0 / 20%)'
  },
  congratsHeading: {
    fontSize: theme.typography.pxToRem(15),
    marginRight: 20
  },
  congratsSecondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
    fontWeight: 700,
  },
  progressBarBg: {
    backgroundColor: '#fff4c2'
  },
  progressBarFg: {
    backgroundColor: '#f9de7d'
  }
}))

Module.propTypes = {
  moduleId: PropTypes.string,
  moduleLang: PropTypes.string,
  translationLang: PropTypes.string,
  history: PropTypes.shape({
    action: PropTypes.string,
    block: PropTypes.func,
    createHref: PropTypes.func,
    go: PropTypes.func,
    goBack: PropTypes.func,
    goForward: PropTypes.func,
    length: PropTypes.number,
    listen: PropTypes.func,
    location: PropTypes.object,
    push: PropTypes.func,
    replace: PropTypes.func,
  }),
}
Module.defaultProps = {
  moduleId: '',
  moduleLang: 'en',
  translationLang: 'ko',
  history: {},
}

export default withRouter(Module)
