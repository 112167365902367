import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  AppBar,
  Box,
  Drawer,
  Hidden,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
  useMediaQuery,
} from '@material-ui/core'
import {
  Close,
  Help,
  LocalLibrary,
  Menu,
  Settings,
  Person,
  PlayArrow,
} from '@material-ui/icons'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { PHRASES_BY_LANG, OFF_WHITE } from '../../../lib/constants'
import { useGlobalStateContext } from '../../../contexts'
import logo from '../../../graphics/logo_primary.svg'
import LoadingSpinner from '../../LoadingSpinner'

const drawerWidth = 240

const ResponsiveTopAppBar = ({ children }) => {
  const [mobileOpen, setMobileOpen] = useState(false)

  const classes = useStyles()
  const theme = useTheme()
  const { globalState: { settings } } = useGlobalStateContext()
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('xs'))

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  if (settings === null) {
    return <LoadingSpinner />
  }

  const drawer = (
    <div>
      <List>
        <a href="/#/">
          <Box display="flex" justifyContent="center">
            <img src={logo} alt="logo" className={classes.logo} />
          </Box>
        </a>
        <Box fontSize={21} my={3}>
          <ListItem className={classes.listItem} button component="a" href="/#/" onClick={mobileOpen ? handleDrawerToggle : null}>
            <ListItemIcon className={classes.icon}><PlayArrow /></ListItemIcon>
            <ListItemText disableTypography primary="학습" />
          </ListItem>
        </Box>
        <Box fontSize={21} my={3}>
          <ListItem button className={classes.listItem} component="a" href="/#/review" onClick={mobileOpen ? handleDrawerToggle : null}>
            <ListItemIcon className={classes.icon}><LocalLibrary /></ListItemIcon>
            <ListItemText disableTypography>
              <Typography variant="h6" style={{ fontWeight: 400 }}>
                {`${settings.translationLang ? PHRASES_BY_LANG.review[settings.translationLang] : ''}`}
              </Typography>
            </ListItemText>
          </ListItem>
        </Box>
        <Box fontSize={21} my={3}>
          <ListItem button className={classes.listItem} component="a" href="/#/profile" onClick={mobileOpen ? handleDrawerToggle : null}>
            <ListItemIcon className={classes.icon}><Person /></ListItemIcon>
            <ListItemText disableTypography primary="프로필" />
          </ListItem>
        </Box>
        <Box fontSize={21} my={3}>
          <ListItem button className={classes.listItem} component="a" href="/#/faq" onClick={mobileOpen ? handleDrawerToggle : null}>
            <ListItemIcon className={classes.icon}><Help /></ListItemIcon>
            <ListItemText disableTypography primary="자주 묻는 질문" />
          </ListItem>
        </Box>
        <Box fontSize={21} my={3}>
          <ListItem button className={classes.listItem} component="a" href="/#/settings" onClick={mobileOpen ? handleDrawerToggle : null}>
            <ListItemIcon className={classes.icon}><Settings /></ListItemIcon>
            <ListItemText disableTypography primary={`${settings.translationLang ? PHRASES_BY_LANG.settings[settings.translationLang] : ''}`} />
          </ListItem>
        </Box>
      </List>
    </div>
  )

  return (
    <div className={classes.root}>
      {isMobileScreen
        && (
          <AppBar position="fixed" className={classes.appBar} elevation={0} color="inherit">
            <Toolbar>
              <IconButton
                color="inherit"
                aria-label="Open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                className={classes.menuButton}
              >
                <Menu />
              </IconButton>
            </Toolbar>
          </AppBar>
        )}
      <nav className={classes.drawer}>
        <Hidden smUp implementation="css">
          <Drawer
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true,
            }}
          >
            <IconButton onClick={handleDrawerToggle} className={classes.closeMenuButton}>
              <Close />
            </IconButton>
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            className={classes.drawer}
            variant="permanent"
            classes={{
              paper: classes.drawerPaper,
            }}
          >
            <div className={classes.toolbar} />
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      <div className={classes.content}>
        <div className={classes.toolbar} />
        {children}
      </div>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  appBar: {
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
    zIndex: theme.zIndex.drawer,
    boxShadow: 'none',
    backgroundColor: OFF_WHITE,
  },
  cardsDue: {
    color: 'red',
    fontSize: 12,
  },
  closeMenuButton: {
    marginRight: 'auto',
    marginLeft: 0,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  drawerPaper: {
    width: drawerWidth,
  },
  icon: {
    paddingLeft: 35,
    paddingRight: 15,
  },
  logo: {
    [theme.breakpoints.down('sm')]: {
      height: 75,
    },
    [theme.breakpoints.up('sm')]: {
      height: 100,
    },
  },
  listItem: {
    '&:hover': {
      backgroundColor: 'transparent',
      color: '#5690cc',
    },
    '&:hover > $icon': {
      color: '#5690cc',
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  root: {
    display: 'flex',
  },
  toolbar: {
    marginTop: 33,
  },
}))

ResponsiveTopAppBar.propTypes = {
  children: PropTypes.node,
}

ResponsiveTopAppBar.defaultProps = {
  children: null,
}

export default ResponsiveTopAppBar
