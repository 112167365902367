import React, { useState } from 'react'
import {
  Box,
  Button,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  makeStyles,
  Paper,
  TextField,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { postUserFeedback } from '../../lib/apigateway/feedback'
import { useGlobalStateContext } from '../../contexts'
import { PHRASES_BY_LANG } from '../../lib/constants'

const FAQ = () => {
  const [buttonText, setButtonText] = useState('문의하기')
  const [email, setEmail] = useState('')
  const [textField, setTextField] = useState('')
  const { globalState: { user, settings } } = useGlobalStateContext()
  const classes = useStyles()

  const handleTextChange = (e) => {
    if (buttonText !== 'Submit') {
      setButtonText('문의하기')
    }
    setTextField(e.target.value)
  }

  const handleEmailChange = (e) => {
    setEmail(e.target.value)
  }

  const handleSubmit = async () => {
    await postUserFeedback(`${email} - ${user.username}`, 'FAQ Questions', textField)
    setButtonText('문의 완료')
    setTextField('')
  }

  return (
    <main className={classes.root}>
      <section className={classes.header}>
        <Box fontSize={36} fontWeight="fontWeightBold">{`${PHRASES_BY_LANG.faq[settings.translationLang]}`}</Box>
        <Box fontWeight="fontWeightLight">궁금하 점들에 대한 답을 여기서 찾으세요</Box>
      </section>
      <Box mb={3}>
        {PHRASES_BY_LANG.faqList.map(({ q, a }) => (
          <ExpansionPanel key={q[settings.translationLang]}>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Box fontWeight={500}>
                {q[settings.translationLang]}
              </Box>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Box fontWeight="fontWeightLight">
                {a[settings.translationLang]}
              </Box>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        ))}
      </Box>

      <Paper>
        <Box p={3}>
          <Box mb={1} fontWeight={500} fontSize={16}>
            다른 질문 있으세요? 여기서 문의하시면 최대한 빠르게 답변 드릴게요.
          </Box>
          <Box>이메일:</Box>
          <Box mb={2}>
            <TextField value={email} onChange={handleEmailChange} />
          </Box>
          <Box>질문:</Box>
          <TextField
            value={textField}
            fullWidth
            variant="outlined"
            multiline
            rows={3}
            onChange={handleTextChange}
          />
          <Box display="flex" justifyContent="center" mt={2}>
            <Button color="secondary" disabled={!(email && textField)} onClick={handleSubmit} variant="contained">{`${buttonText}`}</Button>
          </Box>
        </Box>

      </Paper>
    </main>
  )
}

const useStyles = makeStyles(({
  header: {
    paddingBottom: 24,
  },
}))

export default FAQ
