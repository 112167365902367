import React, {
  useContext,
  useEffect,
  useState,
  createContext,
} from 'react'
import PropTypes from 'prop-types'
import { useGlobalStateContext } from '../GlobalContext/GlobalContextProvider'
import { getUserSentence } from '../../lib/apigateway/weakSentence'

export const SentencesContext = createContext()
export const useSentencesContext = () => useContext(SentencesContext)

const SentenceContextProvider = ({ children }) => {
  const [sentences, setSentences] = useState(null)
  const [isSentencesLoading, setIsSentencesLoading] = useState(true)
  const { globalState: { user } } = useGlobalStateContext()

  useEffect(() => {
    async function initSentence() {
      const userSentence = await getUserSentence(user)
      setSentences(userSentence)
      setIsSentencesLoading(false)
    }
    if (user && !sentences) initSentence()
  }, [user, sentences])

  return (
    <SentencesContext.Provider
      value={{
        sentences, setSentences, isSentencesLoading,
      }}
    >
      {children}
    </SentencesContext.Provider>
  )
}

SentenceContextProvider.propTypes = {
  children: PropTypes.element,
}

SentenceContextProvider.defaultProps = {
  children: null,
}

export default SentenceContextProvider
