/* eslint-disable consistent-return */
import { API_GATEWAY_URI, DEV_MODE } from '../constants'

// eslint-disable-next-line import/prefer-default-export
export const postTrackingData = async (user, name, session, data) => {
  if (DEV_MODE) return

  const requestUri = `${API_GATEWAY_URI}tracking`
  const body = JSON.stringify({
    user: user.username, session, data: JSON.stringify(data), name,
  })
  const result = await fetch(requestUri, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
    body,
  })
  return result
}
