/* eslint-disable consistent-return */
/* eslint-disable no-await-in-loop */
import _ from 'lodash'
import { API_GATEWAY_URI, DEV_MODE, NEW_SENTENCE_DEFAULTS } from '../constants'

export const putUserSentence = async (user, encodedSentence, props) => {
  if (DEV_MODE) return

  _.defaults(props, NEW_SENTENCE_DEFAULTS)
  const requestUri = `${API_GATEWAY_URI}users/sentences?user=${user.username}&sentence=${encodeURIComponent(encodedSentence)}`
  const body = JSON.stringify({
    props,
  })
  await fetch(requestUri, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
    body,
  })
}

export const getUserSentence = async (user) => {
  const requestUri = `${API_GATEWAY_URI}users/sentences?user=${user.username}`
  const response = await fetch(requestUri, {
    mode: 'cors',
  })
  let json = await response.json()
  const sentenceArrayItems = json.Items

  while (json.LastEvaluatedKey) {
    const newRequestUri = `${API_GATEWAY_URI}users/sentences?user=${user.username}&last_evaluated_id=${json.LastEvaluatedKey.sentence.S}`
    const newResponse = await fetch(
      newRequestUri,
      { mode: 'cors' },
    )
    json = await newResponse.json()
    sentenceArrayItems.push(...json.Items)
  }
  // create map of encodedSentence => { interactions: [int, int, ... ] }
  const sentenceMap = _.reduce(
    sentenceArrayItems,
    (acc, sentenceRow) => {
      const props = _.defaults(JSON.parse(sentenceRow.props.S), NEW_SENTENCE_DEFAULTS)
      return _.extend(acc, { [sentenceRow.sentence.S]: props })
    },
    {},
  )
  return sentenceMap
}

export const deleteSentence = async (user, sentence) => {
  if (DEV_MODE) return

  const requestUri = `${API_GATEWAY_URI}users/sentences?user=${user.username}&sentence=${encodeURIComponent(sentence)}`
  const response = await fetch(requestUri, {
    method: 'DELETE',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
  })
  const json = await response.json()
  return json
}
