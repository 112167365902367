import React from 'react'
import PropTypes from 'prop-types'
import { Box, Button, makeStyles } from '@material-ui/core'

const CttDropdown = ({ word, translation }) => {
  const classes = useStyles()
  function naverLookupPopup() {
    const params = 'width=600,height=700'
    return window.open(`https://en.dict.naver.com/#/search?range=all&query=${word}`, 'translation', params)
  }

  function googleImagePopup() {
    const params = 'width=600,height=700'
    return window.open(`https://google.com/search?q=${word}&tbm=isch&safe=active`, 'translation', params)
  }

  if (!translation) {
    return (
      <Box p={2}>Getting translation...</Box>
    )
  }

  return (
    <Box p={2}>
      <Box fontWeight={500} fontSize={16} mb={1}>기계 번역:</Box>
      <Box fontWeight={200} fontSize={14}>{translation}</Box>
      <Box my={1}>
        <hr />
      </Box>
      <Box display="flex">
        <Button className={classes.naverButton} onClick={naverLookupPopup}>네이버</Button>
        <Box width={12} />
        <Button className={classes.googleButton} onClick={googleImagePopup}>구글 이미지</Button>
      </Box>
    </Box>
  )
}

const useStyles = makeStyles(({
  naverButton: {
    backgroundColor: '#19ce60',
    color: 'white',
    '&:hover': {
      backgroundColor: '#16b854',
      color: '#FFF',
    },
  },
  googleButton: {
    backgroundColor: '#4285F4',
    color: 'white',
    '&:hover': {
      backgroundColor: '#346ac2',
      color: '#FFF',
    },
  },
  container: {
    maxWidth: 300,
    padding: 12,
    display: 'flex',
    flexDirection: 'column',
  },
}))

CttDropdown.propTypes = {
  word: PropTypes.string,
  translation: PropTypes.string,
}
CttDropdown.defaultProps = {
  word: 'lorum',
  translation: 'ipsum',
}

export default CttDropdown
