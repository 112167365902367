import { API_GATEWAY_URI } from '../constants'


// eslint-disable-next-line import/prefer-default-export
export const getReference = async (id, type) => {
  const requestUri = `${API_GATEWAY_URI}references?id=${id}&type=${type}`
  const response = await fetch(requestUri, {
    mode: 'cors',
  })
  const { Item } = await response.json()
  if (!Item) {
    return Item
  }
  return Item.data && JSON.parse(Item.data.S)
}
