import React, { useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { getCurrentAuthenticatedUser } from '../../lib/auth'
import { getAccountSettings } from '../../lib/apigateway/settings'

export const GlobalContext = React.createContext()
export const useGlobalStateContext = () => useContext(GlobalContext)

const GlobalContextProvider = (props) => {
  const startMillis = Date.now()

  const [globalState, setGlobalState] = useState({
    user: null,
    session: {
      id: null,
      startMillis,
      startDate: new Date(startMillis).toString(),
    },
    settings: null,
  })

  useEffect(() => {
    async function initUser() {
      const user = await getCurrentAuthenticatedUser()
      const accountSettings = await getAccountSettings(user.username)
      setGlobalState((prevState) => ({ ...prevState, user, settings: accountSettings }))
    }
    if (globalState.user === null) initUser()
  })

  const { children } = props

  return (
    <GlobalContext.Provider
      value={{
        globalState, setGlobalState,
      }}
    >
      {children}
    </GlobalContext.Provider>
  )
}

GlobalContextProvider.propTypes = {
  children: PropTypes.element,
}

GlobalContextProvider.defaultProps = {
  children: null,
}

export default GlobalContextProvider
