import { API_GATEWAY_URI, DEV_MODE } from '../constants'
import { uuid } from '../util'

export const postUserFeedback = async (user, moduleId, string) => {
  if (DEV_MODE) return

  const requestUri = `${API_GATEWAY_URI}feedback?user=${user}&moduleId=${moduleId}&uuid=${uuid()}&reason=${string}`
  await fetch(requestUri, {
    method: 'POST',
    mode: 'cors',
  })
}

export const postUserFlag = async (user, moduleId, string, sentence, translation) => {
  if (DEV_MODE) return

  const requestUri = `${API_GATEWAY_URI}feedback?user=${user}&moduleId=${moduleId}&uuid=${uuid()}&reason=${string}&sentence=${sentence}&translation=${translation}`
  await fetch(requestUri, {
    method: 'POST',
    mode: 'cors',
  })
}
