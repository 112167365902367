/* eslint-disable func-names */
import _ from 'lodash'
import { postUserProgress } from '../apigateway/progress'
import { PROGRESS_DEFAULTS, TIMESTAMP_QUERY } from '../constants'

export const MODULE_LOCAL_STORAGE_KEY = 'MODULE_INFO'

export function getCache(storageKey) {
  return function () {
    const cachedDifficultyObj = window.localStorage.getItem(storageKey)
    if (cachedDifficultyObj) {
      return JSON.parse(cachedDifficultyObj)
    }
    return {}
  }
}

export function cacheInfo(storageKey) {
  return function (data) {
    if (_.isNil(data)) return
    window.localStorage.setItem(storageKey, JSON.stringify(data))
  }
}

export function deleteCache(storageKey) {
  return function () {
    window.localStorage(storageKey)
  }
}

/**
 * Updates current Module difficulty state and caches in localStorage
 */
export function cacheModuleInfo(state) {
  if (_.isNil(state)) return
  window.localStorage.setItem(MODULE_LOCAL_STORAGE_KEY, JSON.stringify(state))
}

/**
 * Gets current module difficulty state from localStorage
 */
export function getCachedModuleInfo() {
  const cachedDifficultyObj = window.localStorage.getItem(MODULE_LOCAL_STORAGE_KEY)
  if (cachedDifficultyObj) {
    return JSON.parse(cachedDifficultyObj)
  }
  return {}
}


/**
 * Partitions fetchedModules into { completed, incomplete }
 * completed = in trackedCompletions && completionPercentage = 100,
 */
export const partitionByProgress = (fetchedModules, trackedCompletions) => {
  const all = fetchedModules
  const completed = []
  const incomplete = []

  _.each(fetchedModules, (module) => {
    if (!trackedCompletions[module.id.S]) {
      incomplete.push(module)
    } else if (trackedCompletions[module.id.S].completionPercentage === 100) {
      completed.push(module)
    } else {
      incomplete.push(module)
    }
  })

  return {
    all,
    completed,
    incomplete,
  }
}

export const partitionBySeries = (modules, progress, difficulty) => {
  const seriesMap = {}

  const moduleByDiff = modules[progress].filter(
    (module) => module.tags.SS.includes(difficulty),
  )

  moduleByDiff.forEach((module) => {
    if (!module.series) {
      if (!seriesMap.Other) {
        seriesMap.Other = [module]
      } else {
        seriesMap.Other.push(module)
      }
    } else if (!seriesMap[module.series.S]) {
      seriesMap[module.series.S] = [module]
    } else {
      seriesMap[module.series.S].push(module)
    }
  })

  const clipSeries = [
    'ODG',
    'Korean Fairy Tales',
    'The Frog and the Ox',
    'The Fox and the Stork',
    'The Dog and Its Reflection',
    'The Bear and the Travelers',
    'Belling the Cat',
  ]

  // Sort episodes in series by title
  _.forEach(seriesMap, (seriesArr, key) => {
    if (key === 'Other') {
      seriesArr.sort((a, b) => _.toNumber(a.length.N) - _.toNumber(b.length.N))
    } else if (clipSeries.includes(key)) {
      seriesArr.sort((a, b) => {
        if (a.title.S.split('#')[0] !== b.title.S.split('#')[0]) {
          return 1
        }
        return _.toNumber(a.title.S.split('#')[1]) - _.toNumber(b.title.S.split('#')[1])
      })
    } else {
      seriesArr.sort((a, b) => _.toNumber(a.title.S.split('.')[0]) - _.toNumber(b.title.S.split('.')[0]))
    }
  })
  const seriesArr = Object.entries(seriesMap)
  // Sort series list by average length of series
  seriesArr.sort((a, b) => {
    const avgA = _.meanBy(a[1], (m) => _.toNumber(m.length.N))
    const avgB = _.meanBy(b[1], (m) => _.toNumber(m.length.N))
    return avgA - avgB
  })
  return seriesArr
}

/**
 * Loops through each grammar rule and using the type, sees
 * if the sentence contains the corresponding morphs/words.
 * Returns an array of all the grammar rules found.
 */
export const findGrammarReference = (cardMorphs = [], rules, sentence) => {
  const TYPES = {
    OR: 'OR',
    AND: 'AND',
    WORD: 'WORD',
    WORD_AND: 'WORD-AND',
  }
  const grammarReferences = []

  if (!cardMorphs.length || !sentence || !rules.length) {
    return grammarReferences
  }

  // Loop through all the grammar references
  rules.forEach((reference) => {
    const { rule } = reference

    // Loop through each grammar reference rule
    for (let i = 0; i < rule.length; i += 1) {
      const { query, type } = rule[i]
      // If WORD type, see if sentence includes query
      if (type === TYPES.WORD) {
        for (let j = 0; j < query.length; j += 1) {
          if (sentence.includes(query[i])) {
            grammarReferences.push(reference)
            break
          }
        }
        // If OR/AND type, see if the morphs match
      } else if (type === TYPES.OR || type === TYPES.AND) {
        const matches = _.intersectionWith(cardMorphs, query, _.isEqual)
        if (
          (matches.length && type === TYPES.OR)
          || ((matches.length === query.length) && type === TYPES.AND)
        ) {
          grammarReferences.push(reference)
        }
        // If WORD-AND type, see if morphs match AND sentences includes query
      } else {
        let containsWord = true
        let containsMorphs = true
        for (let l = 0; l < query.length; l += 1) {
          if (query[l].length === 1) { // Query length = 1 is a word
            if (!sentence.includes(query[l][0]) && containsWord) {
              containsWord = false
            }
          } else { // Query length = 2 is a morph
            const matches = _.intersectionWith(cardMorphs, query[l], _.isEqual)
            if (!matches.length && containsMorphs) {
              containsMorphs = false
            }
          }
        }
        if (containsWord && containsMorphs) {
          grammarReferences.push(reference)
        }
      }
    }
  })

  return _.uniqBy(grammarReferences, 'name')
}

export const handleProgressUpload = async (
  user, moduleId, displayCardInds, cardFocusInd, wordsRemaining, review,
) => {
  const data = _.defaultsDeep({
    displayCardInds,
    cardFocusInd,
    wordsRemaining,
    review,
  }, PROGRESS_DEFAULTS)

  const response = await postUserProgress(user, moduleId, data)
  return response
}

export const parseModuleId = (moduleId) => {
  const result = {
    type: null,
    videoId: null,
    timestamp: null,
  }
  if (!moduleId) {
    return result
  }
  const split = moduleId.split('_')
  const videoId = split.splice(1).join('_')
  const [id, timeFromId] = videoId.split(TIMESTAMP_QUERY)
  let timestamp

  if (timeFromId) {
    timestamp = timeFromId.split('-')
    result.timestamp = timestamp
  }

  // eslint-disable-next-line prefer-destructuring
  result.type = split[0]
  result.videoId = id

  return result
}
