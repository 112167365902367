import React, { useEffect, useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import {
  Box,
  Button,
  Slider,
  TextField,
  makeStyles,
  Paper,
  LinearProgress,
} from '@material-ui/core'
import { useGlobalStateContext } from '../../../contexts'
import {
  updateConsecutiveStreak, getStreakData, getStreakFlame, parseModuleId,
} from '../../../lib/util'
import { trackComplete, getTrackComplete } from '../../../lib/apigateway/trackcomplete'
import { postUserFeedback } from '../../../lib/apigateway/feedback'
import { PHRASES_BY_LANG, DEFAULT_REVIEW_CHUNK_LENGTH } from '../../../lib/constants'

const getSentencePerMin = (length, start, end) => {
  const unixDiff = end - start
  const seconds = unixDiff / 1000

  return (length * 60) / seconds
}

const LinearProgressWithlabel = ({ reviewCount, totalCount }) => {
  const value = _.round((reviewCount / totalCount) * 100, 1)
  return (
    <Box display="flex" alignItems="center" height="100%">
      <Box width="100%" mr={1}>
        <LinearProgress variant="determinate" value={value} />
      </Box>
      <Box minWidth={75} color="text.secondary">{`${value}% (${reviewCount}/${totalCount})`}</Box>
    </Box>
  )
}

LinearProgressWithlabel.propTypes = {
  reviewCount: PropTypes.number.isRequired,
  totalCount: PropTypes.number.isRequired,
}

const CompletePageComponent = ({
  allSentences,
  setAllSentences,
  comprehension,
  reviewLength,
  setComprehension,
  setIsComplete,
  setIsLoading,
  setCardInd,
  setUnknownSentences,
  stats,
  setStats,
}) => {
  const [display, setDisplay] = useState(() => ({ endTime: Date.now(), streak: '' }))
  const [feedback, setFeedback] = useState('')
  const [slider, setSlider] = useState({ value: 0, submitted: false })

  const classes = useStyles()
  const { globalState: { user, settings } } = useGlobalStateContext()
  const percentageRetained = _.round((reviewLength - _.uniqBy(stats.review, 'ko').length) / reviewLength, 1) * 100
  const delayedTrackChanges = useCallback(
    _.debounce((v) => {
      setSlider((prev) => ({ ...prev, submitted: true }))
      trackComplete(user, youtubeId, 100, v)
    }, 400), [],
  )

  const { ids, idx } = comprehension
  const youtubeId = ids.length && ids[idx][0]
  const prevComprehension = ids[idx][1]
  const percentComprehension = ids.length && ids[idx][1].comprehension
  const { videoId } = parseModuleId(youtubeId)

  useEffect(() => {
    const updateStreak = async () => {
      const data = await getTrackComplete(user)
      const streakData = getStreakData(data)
      setDisplay((prev) => ({ ...prev, streak: streakData.streak }))
      const newStreak = updateConsecutiveStreak(streakData, true)
      trackComplete(user, 'review', 100, encodeURIComponent(JSON.stringify(newStreak)))
    }
    if (user) updateStreak()
  }, [user])

  // Reset state to default state
  const handleClick = async () => {
    if (feedback) {
      await postUserFeedback(user.username, 'review module', feedback)
    }

    if (slider.value && !slider.submitted) {
      await trackComplete(user, youtubeId, 100, JSON.stringify({
        ...prevComprehension,
        comprehension: slider.value,
      }))
    }

    setCardInd(0)
    if (allSentences.length) {
      setAllSentences((prev) => {
        const chunk = prev.splice(0, reviewLength)
        setStats((prevState) => ({
          ...prevState,
          currentReviewLength: chunk.length,
          time: Date.now(),
          reviewCount: prevState.reviewCount + chunk.length,
        }))
        setUnknownSentences(chunk)
        return prev
      })

      setComprehension((prev) => {
        let newIdx = prev.idx + 1

        if (prev.idx === prev.ids.length - 1) {
          newIdx = 0
        }
        return { ...prev, idx: newIdx }
      })
      setSlider({ value: 0, submitted: false })
    } else {
      window.location = '/#/'
    }
    setIsComplete(false)
    setIsLoading(true)
  }

  const handleSliderChange = (_event, newValue) => {
    setSlider((prev) => ({ ...prev, value: newValue }))
    delayedTrackChanges(encodeURIComponent(JSON.stringify({
      ...prevComprehension,
      comprehension: newValue,
    })))
  }

  return (
    <>
      <Box>
        <section className={classes.header}>
          <Box fontSize={36} fontWeight="fontWeightBold">복습 완료!</Box>
          <Box fontWeight="fontWeightLight">
            {`${display.streak} ${PHRASES_BY_LANG.streak[settings.translationLang]} ${getStreakFlame(display.streak)}`}
          </Box>
        </section>
      </Box>

      <Box display="flex" className={classes.statWrapper}>
        <Box my={2} flex={1} className={classes.spacer}>
          <Paper>
            <Box p={2} height={102}>
              <Box fontWeight={500} fontSize={14} mb={1}>속도</Box>
              <Box>
                <Box component="span" color="success.light" fontSize={30} fontWeight={500}>
                  {`${_.round(getSentencePerMin(stats.currentReviewLength, stats.time, display.endTime), 1)}`}
                </Box>
                <Box component="span" fontWeight={400} fontSize={16} color="grey.500">
                  &nbsp;문장/분
                </Box>
              </Box>
            </Box>
          </Paper>
        </Box>
        <Box my={2} flex={1}>
          <Paper>
            <Box p={2} height={102}>
              <Box display="flex" justifyContent="space-between">
                <Box fontWeight={500} fontSize={14}>복습 진도</Box>
                <Box color="text.secondary">{`${percentageRetained}% 정확도`}</Box>
              </Box>
              <LinearProgressWithlabel
                reviewCount={stats.reviewCount}
                totalCount={stats.totalCount}
              />
            </Box>
          </Paper>
        </Box>
      </Box>

      <Paper style={{ marginBottom: 24 }}>
        <Box p={3}>
          <Box fontWeight={500} fontSize={20}>{`지난번에 이 동영상을 ${percentComprehension}% 이해하셨어요. 다시 한 번 시청해 보세요!`}</Box>
          <Box maxWidth={600} margin="0 auto" my={2}>
            <Box className={classes.youtubeWrapper}>
              <iframe
                width="853"
                height="480"
                title={youtubeId}
                className={classes.iframe}
                src={`https://www.youtube.com/embed/${videoId}?cc_load_policy=3`}
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </Box>
          </Box>
          <Box>
            <Box fontWeight={500} fontSize={20} mb={5}>
              동영상의 몇 퍼센트를 이해했나요?
            </Box>
            <Slider
              value={slider.value}
              onChange={handleSliderChange}
              min={5}
              max={100}
              marks
              step={5}
              valueLabelDisplay="on"
            />
          </Box>

          <TextField
            value={feedback}
            className={classes.feedbackInput}
            placeholder="앱의 어떤 점들을 개선할 수 있을까요?"
            fullWidth
            multiline
            rows={3}
            variant="outlined"
            onChange={(e) => setFeedback(e.target.value)}
          />
          <Box mt={2} display="flex" justifyContent="center">
            <Button
              variant="contained"
              size="large"
              color="secondary"
              onClick={handleClick}
            >
              {allSentences.length ? '확인' : '학습 페이지로 이동'}
            </Button>
          </Box>
        </Box>
      </Paper>

      <Box align="center" fontWeight={400}>
        {'피드백 있으세요? 레딧을 방문해 주세요 '}
        <a
          href="https://www.reddit.com/r/siplanguage/"
          rel="noopener noreferrer"
          target="_blank"
        >
          /r/siplanguage
        </a>
      </Box>
    </>
  )
}

const useStyles = makeStyles((theme) => ({
  header: {
    paddingBottom: 24,
  },
  iframe: {
    left: 0,
    top: 0,
    height: '100%',
    width: '100%',
    position: 'absolute',
    border: 0,
  },
  spacer: {
    [theme.breakpoints.up('sm')]: {
      marginRight: '1rem',
    },
  },
  youtubeWrapper: {
    overflow: 'hidden',
    paddingBottom: '56.25%',
    position: 'relative',
    height: 0,
  },
  statWrapper: {
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
}))

CompletePageComponent.propTypes = {
  allSentences: PropTypes.arrayOf(PropTypes.object),
  comprehension: PropTypes.shape({
    ids: PropTypes.arrayOf(PropTypes.array),
    idx: PropTypes.number,
  }),
  reviewLength: PropTypes.number,
  setComprehension: PropTypes.func,
  setAllSentences: PropTypes.func,
  setIsComplete: PropTypes.func,
  setIsLoading: PropTypes.func,
  setCardInd: PropTypes.func,
  setUnknownSentences: PropTypes.func,
  stats: PropTypes.shape({
    time: PropTypes.number,
    review: PropTypes.arrayOf(PropTypes.object),
    numToReview: PropTypes.number,
    totalCount: PropTypes.number,
    reviewCount: PropTypes.number,
    currentReviewLength: PropTypes.number,
  }),
  setStats: PropTypes.func.isRequired,
}
CompletePageComponent.defaultProps = {
  allSentences: [],
  comprehension: { ids: [], idx: 0 },
  reviewLength: DEFAULT_REVIEW_CHUNK_LENGTH,
  setComprehension: () => { },
  setAllSentences: () => { },
  setIsComplete: () => { },
  setIsLoading: () => { },
  setCardInd: () => { },
  setUnknownSentences: () => { },
  stats: {},
}

export default CompletePageComponent
