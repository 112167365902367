import React, { useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';

const useStyles = makeStyles({
  root: {
    width: '100%',
    marginTop: '10px'
  },
  sceneText: {
    fontWeight: 500,
    fontSize: '1.15rem',
    color: '#6FB159',
    marginBottom: '5px',
    textAlign:'right',
    width: '100%'
  },
  progressbar: {
    height: '6px'
  },
  barColorPrimary:{
    backgroundColor: '#6FB159'
  },
  colorPrimary: {
    backgroundColor: '#D4F2CA'
  }
});

export default function SceneProgressBar(props) {
  const { currentScene, cardFocusInd, allCongratsIndexes, displayCardInds, allCards } = props
  const classes = useStyles();

  const progress = useMemo(() => {
    const currentIndex = displayCardInds[cardFocusInd]

    let minIndex = 1

    if (currentScene !== 0) {
      minIndex = allCongratsIndexes[currentScene - 1];
    }
    
    let maxIndex = allCards.length - 2
  
    if(currentScene <= allCongratsIndexes.length - 1) {
      maxIndex = allCongratsIndexes[currentScene]
    }
  
    return ((currentIndex - minIndex) / (maxIndex - minIndex)) * 100
  }, [currentScene, cardFocusInd, allCongratsIndexes, displayCardInds, allCards]) 

  return (
    <div className={classes.root}>
      <div className={classes.sceneText}>Scene {currentScene + 1}</div>
      <LinearProgress
        classes={{
          root: classes.progressbar,
          colorPrimary: classes.colorPrimary,
          barColorPrimary: classes.barColorPrimary
        }}
        variant="determinate" 
        value={progress || 0} />
    </div>
  );
}