import React, { useEffect, useState, useMemo } from 'react'
import _ from 'lodash'
import {
  Box,
  Button,
  FormControl,
  makeStyles,
  MenuItem,
  Paper,
  Select,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Typography,
  useMediaQuery,
} from '@material-ui/core'
import { useGlobalStateContext, useVocabContext, useSentencesContext } from '../../contexts'
import { isKnownWord, decodeWord, getSentencesByInterval } from '../../lib/util'
import { PHRASES_BY_LANG, ALL_SENTENCES } from '../../lib/constants'


const Review = () => {
  const [words, setWords] = useState({ known: [], unknown: [] })
  const [page, setPage] = useState({ known: 0, unknown: 0 })
  const [rowsPerPage, setRowsPerPage] = useState({ known: 10, unknown: 10 })
  const [dropdown, setDropdown] = useState('')

  const classes = useStyles()
  const { globalState: { user, settings } } = useGlobalStateContext()
  const { vocab: { vocab } } = useVocabContext()
  const { sentences } = useSentencesContext()
  const isLarge = useMediaQuery('(min-width:1430px)')
  const isMedium = useMediaQuery('(min-width:850px)')


  useEffect(() => {
    const init = async () => {
      window.scroll(0, 0)
      const vocabEntries = Object.entries(vocab)
      const [known, unknown] = _.partition(vocabEntries, (word) => isKnownWord(word[1]))
      setWords({ known, unknown })
    }
    if (user && vocab) init()
  }, [user, vocab])

  const handleKnownChangePage = (_event, newPage) => {
    setPage({ ...page, known: newPage })
  }

  const handleKnownChangeRowsPerPage = (event) => {
    setRowsPerPage({ ...rowsPerPage, known: +event.target.value })
    setPage({ ...page, known: 0 })
  }

  const handleUnknownChangePage = (_event, newPage) => {
    setPage({ ...page, unknown: newPage })
  }

  const handleUnknownChangeRowsPerPage = (event) => {
    setRowsPerPage({ ...rowsPerPage, unknown: +event.target.value })
    setPage({ ...page, unknown: 0 })
  }

  const handleDropdownChange = (e) => {
    setDropdown(e.target.value)
  }

  const dueSentences = useMemo(() => getSentencesByInterval(sentences), [sentences])

  return (
    <main className={classes.root}>
      <section className={classes.header}>
        <Box fontSize={36} fontWeight="fontWeightBold">학습이 더 필요한 문장들을 연습하세요</Box>
        <Box fontWeight="fontWeightLight">매일 연습해서 모듈에서 “오답”을 누른 문장들을 습득하고 유지하세요</Box>
      </section>

      <Box display="flex" flexDirection={isLarge ? 'row' : 'column'}>
        <Box flex={1} mb={2} className={isLarge ? classes.left : null}>
          <Paper>
            <Box p={1} display="flex" justifyContent="space-between" flexDirection={isMedium ? 'row' : 'column'} alignItems="center">
              <Box>
                <Box component="span" fontSize={30} fontWeight={600}>{`${dueSentences.length}`}</Box>
                <Box component="span" fontSize={20} fontWeight={400}>&nbsp;오늘 할 복습</Box>
              </Box>
              <Box display="flex" alignItems="center" flexDirection={isMedium ? 'row' : 'column'}>
                <Box my={isMedium ? 0 : 1}>
                  <FormControl className={classes.formControl}>
                    <Select
                      value={dropdown}
                      onChange={handleDropdownChange}
                      displayEmpty
                      className={classes.selectEmpty}
                      inputProps={{ 'aria-label': 'Without label' }}
                    >
                      <MenuItem value="" disabled>
                        복습할 카드 수
                      </MenuItem>
                      <MenuItem value={10}>10</MenuItem>
                      <MenuItem value={25}>25</MenuItem>
                      <MenuItem value={50}>50</MenuItem>
                      <MenuItem value={ALL_SENTENCES}>모든 카드</MenuItem>
                    </Select>
                  </FormControl>

                </Box>
                <Button color="primary" className={isMedium ? classes.button : ''} href={`/#/review/module?length=${dropdown}`} variant="contained" disabled={!dropdown}>
                  <Typography align="center">복습 시작</Typography>
                </Button>
              </Box>
            </Box>
          </Paper>
        </Box>
        <Box flex={1} className={isLarge ? classes.right : null} mb={2}>
          <Paper>
            <Box p={2} display="flex" flexDirection="row" alignItems="center" height={60} fontSize={16}>
              <Box>
                <span role="img" aria-label="light bulb emoji">💡</span>
              &nbsp;배웠던 단어를 잊기 직전에 연습할 수 있도록&nbsp;
                <a href="https://www.youtube.com/watch?v=cVf38y07cfk" target="_blank" rel="noopener noreferrer">SRS</a>
          &nbsp;사용합니다.

              </Box>
            </Box>
          </Paper>
        </Box>
      </Box>

      <Box display="flex" className={classes.main}>
        <Box flex={1} className={classes.left}>
          <Paper elevation={1}>
            <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.noDivider}>
                      <Box color="success.main" fontSize={18} fontWeight="fontWeightBold" py={1}>
                        {`${PHRASES_BY_LANG.knownWords[settings.translationLang]}`}
                      </Box>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(words.known.slice(page.known * rowsPerPage.known,
                    page.known * rowsPerPage.known + rowsPerPage.known)
                  ).map((word) => (
                    <TableRow key={word[0]}>
                      <TableCell className={classes.noDivider} component="th" scope="row">
                        {decodeWord(word[0])}
                      </TableCell>

                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={words.known.length}
              rowsPerPage={rowsPerPage.known}
              page={page.known}
              onChangePage={handleKnownChangePage}
              onChangeRowsPerPage={handleKnownChangeRowsPerPage}
              className={classes.pagination}
            />
          </Paper>
        </Box>
        <Box flex={1} className={classes.right}>
          <Paper elevation={1}>
            <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.noDivider}>
                      <Box color="error.main" fontSize={18} fontWeight="fontWeightBold" py={1}>
                        {`${PHRASES_BY_LANG.reviewWords[settings.translationLang]}`}
                      </Box>

                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(rowsPerPage.unknown > 0
                    ? words.unknown.slice(page.unknown * rowsPerPage.unknown,
                      page.unknown * rowsPerPage.unknown + rowsPerPage.unknown)
                    : words.unknown
                  ).map((word) => (
                    <TableRow key={word[0]}>
                      <TableCell className={classes.noDivider} component="th" scope="row">
                        {decodeWord(word[0])}
                      </TableCell>

                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={words.unknown.length}
              rowsPerPage={rowsPerPage.unknown}
              page={page.unknown}
              onChangePage={handleUnknownChangePage}
              onChangeRowsPerPage={handleUnknownChangeRowsPerPage}
              className={classes.pagination}
            />
          </Paper>
        </Box>
      </Box>
    </main>
  )
}

const useStyles = makeStyles((theme) => ({
  button: {
    marginLeft: 12,
  },
  header: {
    paddingBottom: 24,
  },
  main: {
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  pagination: {
    display: 'flex',
    justifyContent: 'center',
  },
  noDivider: {
    borderBottom: 'none',
  },
  left: {
    [theme.breakpoints.up('md')]: {
      marginRight: 16,
    },
  },
  right: {
    [theme.breakpoints.up('md')]: {
      marginLeft: 16,
    },
  },
  formControl: {
    minWidth: 160,
  },
}))

export default Review
