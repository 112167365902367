/* eslint-disable no-bitwise */
import _ from 'lodash'
import dayjs from 'dayjs'
import isToday from 'dayjs/plugin/isToday'
import isYesterday from 'dayjs/plugin/isYesterday'
import { NEW_STREAK, MILLISECONDS_IN_DAY, COMPREHENSION_DEFAULTS } from '../constants'

dayjs.extend(isToday)
dayjs.extend(isYesterday)
const NON_WORD_REGEX = /[.,?!'"&]/g

/**
 * Serializes a str to base64 after toLowerCase and removing non-chars
 */
export function encodeWord(str, replaceNonWords = true) {
  if (replaceNonWords) {
    return Buffer.from(str.toLowerCase().replace(NON_WORD_REGEX, '')).toString('base64') // TODO stemming?
  }
  return Buffer.from(str.toLowerCase()).toString('base64') // TODO stemming?
}

/**
 * Deserializes a str from base64
 */
export function decodeWord(str) {
  return Buffer.from(str, 'base64').toString()
}

/**
 * Converts obj to str and converts to base64, no characters altered
 */
export function encodeObject(obj) {
  return Buffer.from(JSON.stringify(obj)).toString('base64')
}

/**
 * Converts a base64 encoded object back into a JavaScript object
 */
export function decodeObject(obj) {
  return JSON.parse(Buffer.from(obj, 'base64').toString())
}

/* _.merge in lodash, but concatenate arrays (default replaces arraps) */
export function mergeConcat(...args) {
  return _.mergeWith(...args, (dest, src) => {
    if (_.isArray(dest)) return dest.concat(src)
    return undefined // use lodash's default merge algorithm, = assign values & merge maps
  })
}

/**
 * Takes in string and returns a string with Korean letters replaced with a space
 */
export const removeIndividualKoreanLetters = (string) => {
  // Unicode range of Hangul Compatibility Jamo
  const koreanRegex = /[\u3130-\u318F]/g
  return (string.replace(koreanRegex, ' '))
}

/**
 * Takes in string and returns a string with Chinese characters replaced with a space
 */
export const removeChineseCharacters = (string) => {
  // Unicode range of Chinese characters
  const chineseRegex = /[\u4e00-\u9fff\u3400-\u4dff\uf900-\ufaff]/g
  return (string.replace(chineseRegex, ' '))
}

/**
 * Takes in string and returns a string with symbols replaced with a space
 */
export const removeMiscSymbols = (string) => {
  // Unicode range of miscellaneous symbols
  const symbolsRegex = /[\u2600-\u26FF]/g
  return (string.replace(symbolsRegex, ' '))
}

/**
 * Takes in string and returns a string with punctuations replaced with a space
 */
export const removeMiscCharacters = (string) => {
  const miscRegex = /[!"$%&'()*+,-./:;<=>?@[\]^_`{|}~]/g
  return (string.replace(miscRegex, ' '))
}

/**
 * Takes in string and returns a string with punctuations replaced with a space
 */
export const removePunctuation = (string) => {
  const punctuationRegex = /[!,.?]/g
  return (string.replace(punctuationRegex, ''))
}

/**
 * Filters out strings containing "#" in an array
 */
export const removeHashtags = (arr) => arr.filter((word) => !word.includes('#'))

/**
 * Filters out strings containing english words EXCEPT konglish (ex: CG급, n행시) in an array
 */
export const removeEnglish = (arr) => {
  const alphabetRegex = /[\u0041-\u005A\u0061-\u007A]/
  const hangulRegex = /[\uAC00-\uD7AF]/
  return arr.filter((word) => !(alphabetRegex.test(word) && !hangulRegex.test(word)))
}

/**
 * Filters out strings containing numbers in an array
 */
export const removeNumbers = (arr) => arr.filter((word) => !(/^\d+$/.test(word)))

/**
 * Parse and return Youtube ID from link
 */
export const getYoutubeId = (url) => {
  const id = url.split(/(vi\/|v%3D|v=|\/v\/|youtu\.be\/|\/embed\/)/)
  return undefined !== id[2] ? id[2].split(/[^0-9a-z_-]/i)[0] : id[0]
}

/**
 * Calls personal heroku server to get captions.  Takes ~10 seconds to startup
 * after idle like all free heroku servers
 */
export const getCaptions = (string) => new Promise((resolve) => {
  const id = getYoutubeId(string)
  fetch(`https://salty-cliffs-01225.herokuapp.com/api/${id}`)
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText)
      }
      return response
    }).then(async (response) => {
      const data = await response.json()
      resolve(data)
    })
    .catch(() => {
      // eslint-disable-next-line no-alert
      alert('No captions were found or invalid link')
    })
})

// Based off of https://abhishekdutta.org/blog/standalone_uuid_generator_in_javascript.html
// UUID generation is up to spec per the Updates section
export const uuid = () => {
  const tempUrl = URL.createObjectURL(new Blob())
  const uuidString = tempUrl.toString()
  URL.revokeObjectURL(tempUrl)
  return uuidString.substr(uuidString.lastIndexOf('/') + 1)
}

// Returns data about streaks.
export const getStreakData = (trackedCompletions) => {
  const { review } = _.pick(trackedCompletions, ['review'])
  if (review) {
    return JSON.parse(decodeURIComponent(review.comprehension))
  }
  return NEW_STREAK
}

export const updateConsecutiveStreak = (streakData, completedReview) => {
  // Update but only return dates of current month.
  // Completion dates only used for heatmap, not streak calculation
  const updateAndFilterDates = (completionDates) => {
    const newDates = [...completionDates, Date.now()]
    return newDates.filter((date) => dayjs(date).month() === dayjs().month())
  }
  const { totalReviewCompletions, completionDates, streak } = streakData
  if (!totalReviewCompletions && !completionDates.length && !streak) {
    // No previous streak
    return {
      totalReviewCompletions: completedReview ? 1 : 0,
      completionDates: [Date.now()],
      streak: 1,
    }
  }
  if (dayjs(_.last(completionDates)).isToday()) {
    if (completedReview) {
      // Same day and completed review
      return {
        ...streakData,
        totalReviewCompletions: totalReviewCompletions + 1,
        completionDates: updateAndFilterDates(completionDates),
      }
    }
    return streakData // Same day but nothing to review
  }
  if (dayjs(_.last(completionDates)).isYesterday()) {
    if (completedReview) {
      return { // Consecutive day and completed review
        totalReviewCompletions: totalReviewCompletions + 1,
        completionDates: updateAndFilterDates(completionDates),
        streak: streak + 1,
      }
    }
    return { // Consecutive day but nothing to review
      ...streakData, streak: streak + 1, completionDates: updateAndFilterDates(completionDates),
    }
  }
  return { // Break streak
    ...streakData, streak: 1, completionDates: [Date.now()],
  }
}

/**
 * Convert object of base64/interaction9s to word/corresponding score
 *  Returns array of objects of weak words. Does NOT return "known" words
 */
export const getSentencesByInterval = (sentencesObj) => {
  const orderedSentenceArr = []
  _.each(sentencesObj, (props, sentence) => {
    const intervalSinceLastSeen = _.round((Date.now() - _.last(props.interactionDates))
      / MILLISECONDS_IN_DAY, 1)
    const intervalDifference = _.round(intervalSinceLastSeen - props.interval, 1)
    if (intervalDifference >= 0 || props.interactionDates.length === 1) {
      orderedSentenceArr.push({
        sentence,
        props,
        intervalDifference,
      })
    }
  })
  return _.orderBy(orderedSentenceArr, ['intervalDifference'], ['asc'])
}

export const calculateHeatMap = (dates) => {
  const currentMonth = dayjs().month()
  const numberOfDays = dayjs().daysInMonth()
  const heatmapDates = {}
  for (let i = 0; i < numberOfDays; i += 1) {
    dayjs().startOf('month')
    const date = dayjs(dayjs().startOf('month').add(i, 'day')).format('MM/DD/YYYY')
    _.assign(heatmapDates, { [date]: 0 })
  }
  dates.forEach((date) => {
    const formattedDate = dayjs(date).format('MM/DD/YYYY')
    if (dayjs(date).month() === currentMonth) {
      heatmapDates[formattedDate] += 1
    }
  })
  return _.map(heatmapDates, (count, date) => ({
    date,
    count: Math.min(count, 4), // Max CSS color value for heatmap is 4
  }))
}

export const getTimeSpent = (start, end) => {
  const str = new Date(_.toNumber(end) - _.toNumber(start)).toISOString().substr(11, 8)
  const [hours, minutes, seconds] = str.split(':').map((num) => _.toNumber(num))
  if (!hours && !minutes && !seconds) {
    return '0s'
  }
  let time = ''
  if (hours) {
    time += `${hours}h `
  }
  if (minutes) {
    time += `${minutes}분 `
  }
  if (seconds) {
    time += `${seconds}초`
  }
  return time
}

export const getStreakFlame = (count) => {
  if (count > 10) {
    return '🔥🔥🔥'
  }
  if (count > 5) {
    return '🔥🔥'
  }
  if (count > 1) {
    return '🔥'
  }
  return ''
}

export const parseUserComprehension = (string) => {
  if (string === 'undefined' || !string) {
    return { ...COMPREHENSION_DEFAULTS }
  }

  let comprehension = JSON.parse(decodeURIComponent(string))
  if (typeof comprehension === 'number') {
    comprehension = { comprehension }
  }
  return _.defaults(comprehension, COMPREHENSION_DEFAULTS)
}


export async function asyncForEach(array, callback) {
  for (let index = 0; index < array.length; index += 1) {
    // eslint-disable-next-line no-await-in-loop
    await callback(array[index], index, array)
  }
}

export const formatSeconds = (time) => {
  // Hours, minutes and seconds
  const hrs = ~~(time / 3600)
  const mins = ~~((time % 3600) / 60)
  const secs = ~~time % 60

  // Output like "1:01" or "4:03:59" or "123:03:59"
  let ret = ''
  if (hrs > 0) {
    ret += `${hrs}:${mins < 10 ? '0' : ''}`
  }
  ret += `${mins}:${secs < 10 ? '0' : ''}`
  ret += `${secs}`
  return ret
}
