import React from 'react'

import PropTypes from 'prop-types'
import { Box, Container } from '@material-ui/core'
import { DEV_MODE } from '../../../lib/constants'
import ResponsiveTopAppBar from '../ResponsiveTopAppBar'

const AppContainer = (props) => {
  const { children } = props
  return (
    <>
      <ResponsiveTopAppBar>
        {DEV_MODE && <Box bgcolor="error.main" display="flex" justifyContent="center" alignItems="center" fontSize={20} color="white" height={75}>DEV MODE</Box>}
        <Container maxWidth="lg" height="100%" disableGutters>
          {children}
        </Container>
      </ResponsiveTopAppBar>
    </>
  )
}

AppContainer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
  ]),
}

AppContainer.defaultProps = {
  children: null,
}

export default AppContainer
