import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Menu,
  MenuItem,
  Snackbar,
} from '@material-ui/core'
import { Flag } from '@material-ui/icons'
import { postUserFlag } from '../../lib/apigateway/feedback'
import { PHRASES_BY_LANG } from '../../lib/constants'
import { useGlobalStateContext } from '../../contexts'

const FeedbackButton = ({
  sentence, translation, moduleId, username,
}) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const [snackbar, setSnackbar] = useState(false)
  const [dialog, setDialog] = useState({ open: false, text: '' })

  const { globalState: { settings } } = useGlobalStateContext()


  const handleFlagClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = (e) => {
    const { value } = e.currentTarget.dataset
    if (value) {
      setDialog({ open: true, text: value })
    }
    setAnchorEl(null)
  }

  const handleYes = () => {
    setSnackbar(true)
    postUserFlag(username, moduleId, dialog.text, sentence, translation)
    handleNo()
  }

  const handleNo = () => {
    setDialog({ open: false, text: '' })
  }

  return (
    <>
      <IconButton
        aria-controls="feedback menu"
        aria-haspopup="true"
        onClick={handleFlagClick}
      >
        <Flag />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleClose} data-value="Bad Sentence">{`${PHRASES_BY_LANG.badSentence[settings.translationLang]}`}</MenuItem>
        <MenuItem onClick={handleClose} data-value="Bad Translation">{`${PHRASES_BY_LANG.badTranslation[settings.translationLang]}`}</MenuItem>
        <MenuItem onClick={handleClose} data-value="Too Hard">{`${PHRASES_BY_LANG.tooHard[settings.translationLang]}`}</MenuItem>
      </Menu>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        open={snackbar}
        autoHideDuration={1000}
        onClose={() => setSnackbar(false)}
        message="Feedback Submitted"
      />
      <Dialog
        open={dialog.open}
        onClose={handleNo}
      >
        <DialogTitle id="alert-dialog-title">Are you sure you want to submit this feedback?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {`${dialog.text}`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleYes} color="primary">
            Yes
          </Button>
          <Button onClick={handleNo} color="primary" autoFocus>
            No
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

FeedbackButton.propTypes = {
  sentence: PropTypes.string,
  translation: PropTypes.string,
  moduleId: PropTypes.string,
  username: PropTypes.string,
}
FeedbackButton.defaultProps = {
  sentence: 'lorum',
  translation: 'ipsum',
  moduleId: 'moduleId',
  username: 'noUsername',
}

export default FeedbackButton
