import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
import ErrorIcon from '@material-ui/icons/Error'

const NotFound = () => {
  const classes = useStyles()

  return (
    <div>
      <Typography color="secondary" className={classes.top}>
        <ErrorIcon className={classes.icon} />
      Page Not Found
      </Typography>
      <Typography className={classes.bottom}> Sorry, this page does not exist</Typography>
    </div>
  )
}

const useStyles = makeStyles((themes) => ({
  top: {
    [themes.breakpoints.down('sm')]: {
      fontSize: 30,
    },
    [themes.breakpoints.up('md')]: {
      fontSize: 60,
    },
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    [themes.breakpoints.down('sm')]: {
      fontSize: 30,
    },
    [themes.breakpoints.up('md')]: {
      fontSize: 60,
    },
  },
  bottom: {
    [themes.breakpoints.down('sm')]: {
      fontSize: 15,
    },
    [themes.breakpoints.up('md')]: {
      fontSize: 30,
    },
  },
}))
export default NotFound
