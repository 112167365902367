import React from 'react'
import {
  HashRouter as Router,
  Switch,
  Route,
  useParams,
} from 'react-router-dom'
import PropTypes from 'prop-types'
import Review from '../pages/Review'
import Profile from '../pages/Profile'
import FAQ from '../pages/FAQ'
import Module from '../pages/Module'
import CongratsPage from '../pages/CongratsPage'
import ModuleCreation from '../components/ModuleCreation'
import NotFound from '../pages/NotFound'
import ReviewModule from '../pages/ReviewModule/ReviewModule'
import { version } from '../../package.json'
import { version as grammarVersion } from '../lib/grammarReferences'
import LoadingSpinner from '../components/LoadingSpinner'
import Settings from '../pages/Settings'
import Grammar from '../pages/Grammar'
import GrammarCongratsPage from '../pages/GrammarCongratsPage'
import { useGlobalStateContext, useVocabContext, useSentencesContext } from '../contexts'
import Lesson from '../pages/Lesson'

const RootRouter = () => {
  const { globalState: { settings } } = useGlobalStateContext()
  const { isVocabLoading } = useVocabContext()
  const { isSentencesLoading } = useSentencesContext()

  if (isVocabLoading || isSentencesLoading) {
    return <LoadingSpinner text="Syncing data...Hang tight!" />
  }

  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <Lesson />
        </Route>
        <Route exact path="/review">
          <Review />
        </Route>
        <Route exact path="/profile">
          <Profile />
        </Route>
        <Route exact path="/faq">
          <FAQ />
        </Route>
        <Route path="/modules/:moduleId">
          <ModuleWithId
            moduleLang={settings.moduleLang}
            translationLang={settings.translationLang}
          />
        </Route>
        <Route exact path="/grammar/:moduleId">
          <Grammar />
        </Route>
        <Route exact path="/congrats/grammar">
          <GrammarCongratsPage />
        </Route>
        <Route path="/create">
          <ModuleCreation />
        </Route>
        <Route path="/congrats">
          <CongratsPage />
        </Route>
        <Route exact path="/review/module">
          <ReviewModule />
        </Route>
        <Route path="/_version">
          <>
            <div>{`App version is: ${version}`}</div>
            <div>{`Grammar ${grammarVersion}`}</div>
          </>
        </Route>
        <Route exact path="/settings">
          <Settings />
        </Route>
        <Route>
          <NotFound />
        </Route>
      </Switch>
    </Router>
  )
}

const ModuleWithId = ({ moduleLang, translationLang }) => {
  ModuleWithId.propTypes = {
    moduleLang: PropTypes.string,
    translationLang: PropTypes.string,
  }
  ModuleWithId.defaultProps = {
    moduleLang: 'ko',
    translationLang: 'en',
  }
  const { moduleId } = useParams()
  return (
    <>
      <Module moduleId={moduleId} moduleLang={moduleLang} translationLang={translationLang} />
    </>
  )
}

export default RootRouter
