import _ from 'lodash'
import dayjs from 'dayjs'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import { encodeWord } from './misc'
import { NEW_WORD_DEFAULTS } from '../apigateway/vocabulary'
import {
  NEW_SENTENCE_DEFAULTS,
  SENTENCE_INTERVAL_LOWER_BOUND,
  MILLISECONDS_IN_DAY,
} from '../constants'

dayjs.extend(isSameOrBefore)


export const SCORES_STACK_LIMIT = 20
const SCORE_THRESHOLDS = {
  phrase: 0.7,
  word: 0.7,
}
export const WORD_INTERACTIONS = { // enum by hand
  None: 0,
  ThumbDown: 1,
  ThumbUp: 2,
  Translate: 3,
  TranslateSingleOnly: 4, // click-to-translate (CTT)
  TranslateSingleAndAll: 5,
  weakThumbUp: 6,
  weakThumbDown: 7,
  weakTranslate: 8,
  weakTranslateSingleOnly: 9,
  weakTranslateSingleAndAll: 10,
}
const INTERACTION_SCORES = {
  [WORD_INTERACTIONS.ThumbDown]: 0.5,
  [WORD_INTERACTIONS.ThumbUp]: 1,
  [WORD_INTERACTIONS.Translate]: 0.8,
  [WORD_INTERACTIONS.TranslateSingleOnly]: 0,
  [WORD_INTERACTIONS.TranslateSingleAndAll]: 0,
  [WORD_INTERACTIONS.weakThumbUp]: 1,
  [WORD_INTERACTIONS.weakThumbDown]: 0,
  [WORD_INTERACTIONS.weakTranslate]: 0.7,
  [WORD_INTERACTIONS.weakTranslateSingleOnly]: 0,
  [WORD_INTERACTIONS.weakTranslateSingleAndAll]: 0,
}

export function getScoreFromProps(wordProps) {
  if (!wordProps || !wordProps.interactions || !wordProps.interactions.length) return 0
  return _.meanBy(wordProps.interactions, (interaction) => INTERACTION_SCORES[interaction])
}

/**
 * Updates vocabulary and morphs for each word
 */
export function updateVocabulary(vocabMap, morphMap, content, interactions, isReviewCard = false) {
  const encodedWordsArr = [content]

  encodedWordsArr.forEach((word, idx) => {
    const currentInteraction = interactions[idx]
    const wordProps = _.defaultsDeep(vocabMap[word], NEW_WORD_DEFAULTS) // new word
    updateForInteraction(wordProps, currentInteraction, isReviewCard)

    _.assign(vocabMap, { [word]: wordProps })
  })


  return { vocab: vocabMap, morphs: morphMap }
}

export function updateVocabularyListCard(vocabMap, morphMap, content, interactions,
  isReviewCard = false) {
  const encodedWordsArr = [content]

  encodedWordsArr.forEach((word, idx) => {
    const currentInteraction = interactions[idx]
    const wordProps = _.defaultsDeep(vocabMap[word], NEW_WORD_DEFAULTS) // new word
    updateForInteractionListCard(wordProps, currentInteraction, isReviewCard)

    _.assign(vocabMap, { [word]: wordProps })
  })

  return { vocab: vocabMap, morphs: morphMap }
}

export function updateVocabularyMaxInterval(vocabMap, morphMap, content,
  interaction, translationExpanded) {
  const encodedWordsArr = [content]

  encodedWordsArr.forEach((word) => {
    const wordProps = _.defaultsDeep(vocabMap[word], NEW_WORD_DEFAULTS) // new word

    const maxInterval = translationExpanded ? 1 : 999
    _.assign(wordProps, {
      interactionCount: (wordProps.interactionCount || wordProps.interactions.length) + 1,
      interval: maxInterval,
      interactionDates: pushLimit(wordProps.interactionDates, Date.now()),
      interactions: pushLimit(wordProps.interactions, interaction),
    })

    _.assign(vocabMap, { [word]: wordProps })
  })


  return { vocab: vocabMap, morphs: morphMap }
}

export function updateForInteractionListCard(vocab, interaction, isReviewCard = false) {
  if (interaction === WORD_INTERACTIONS.None) return

  let newInterval = vocab.interval
  if (interaction === WORD_INTERACTIONS.ThumbUp) {
    newInterval = _.round(vocab.interval * (getScoreFromProps(vocab) + 1.5), 1)
  } else if (interaction === WORD_INTERACTIONS.ThumbDown) {
    newInterval = SENTENCE_INTERVAL_LOWER_BOUND // 0.67
  }

  let updatedWordProps
  if (isReviewCard) {
    updatedWordProps = {
      ...vocab,
      interval: newInterval,
    }
  } else {
    updatedWordProps = {
      interval: newInterval,
      interactionCount: (vocab.interactionCount || vocab.interactions.length) + 1,
      interactionDates: pushLimit(vocab.interactionDates, Date.now()),
      interactions: pushLimit(vocab.interactions, interaction),
    }
  }
  _.assign(vocab, updatedWordProps)
}

function pushLimit(arr, x) {
  return _.slice(_.concat(arr, x), -SCORES_STACK_LIMIT)
}

export function pushLimitVariable(arr, x, limit) {
  return _.slice(_.concat(arr, x), -limit)
}

/**
 * Pushes recent interaction into queue: mutates, no return
 */
export function updateForInteraction(vocab, interaction, isReviewCard = false) {
  if (interaction === WORD_INTERACTIONS.None) return
  const newInterval = calculateNewInterval(vocab.interval, interaction)
  let updatedWordProps
  if (isReviewCard) {
    updatedWordProps = {
      ...vocab,
      interval: newInterval,
    }
  } else {
    updatedWordProps = {
      interval: newInterval,
      interactionCount: (vocab.interactionCount || vocab.interactions.length) + 1,
      interactionDates: pushLimit(vocab.interactionDates, Date.now()),
      interactions: pushLimit(vocab.interactions, interaction),
    }
  }
  _.assign(vocab, updatedWordProps)
}

/**
 * Get word from object or a string.
 * To support both old and new module JSON format
 * */

export function getWord(word) {
  return typeof word === 'string' ? word : word.word
}

/**
 * Get word + attributes from word Object.
 * */

export function getEncodedTokenWord(word) {
  if (Array.isArray(word.tokens)) {
    return encodeWord(`(${word.tokens.join(',')})`, false)
  }
  return encodeWord(`(${word.word})`, false)
}

/**
 * Get content from word card.
 * To support both old and new module JSON format
 * */

export function getContent(card) {
  // eslint-disable-next-line no-prototype-builtins
  return card.hasOwnProperty('sentence') ? card.sentence : card.content
}

export function isKnownWord(wordProps) {
  return getScoreFromProps(wordProps) >= SCORE_THRESHOLDS.word
}

/**
 * Surface card if any morphs of the main word has a score lower than 0.7
 */
export function shouldCardBeSurfaced(card, vocab) {
  switch (card.type) {
    case 'word': {
      const { word } = card
      const props = _.defaultsDeep(vocab[getEncodedTokenWord(word)], NEW_WORD_DEFAULTS)
      const lastSeenDate = _.last(props.interactionDates)

      let isDue = true

      if (lastSeenDate) {
        const dueDate = _.round(lastSeenDate + props.interval * MILLISECONDS_IN_DAY)
        isDue = dayjs(dueDate).isSameOrBefore(dayjs(Date.now()))
      }

      const isWeak = getScoreFromProps(props) < SCORE_THRESHOLDS.word

      return isWeak && isDue
    }
    default:
      return true
  }
}

export const calculateNewInterval = (interval, interaction) => {
  let newInterval = interval
  if (interaction === WORD_INTERACTIONS.ThumbUp || interaction === WORD_INTERACTIONS.weakThumbUp) {
    if (interval > SENTENCE_INTERVAL_LOWER_BOUND) {
      newInterval = _.round(interval * (2), 1)
    } else if (interval === 0) {
      newInterval = 3
    } else {
      newInterval = 1
    }
  } else if (
    interaction === WORD_INTERACTIONS.ThumbDown || interaction === WORD_INTERACTIONS.weakThumbDown
  ) {
    newInterval = SENTENCE_INTERVAL_LOWER_BOUND // 0
  } else if (
    interaction === WORD_INTERACTIONS.Translate || interaction === WORD_INTERACTIONS.weakTranslate
  ) {
    if (interval > SENTENCE_INTERVAL_LOWER_BOUND) {
      newInterval = _.round(interval * (1.5), 1)
    } else {
      newInterval = 1
    }
  }

  return newInterval
}

export function setSentenceProps(
  sentence, interaction, encodedSentence, moduleId,
) {
  const sentenceProps = _.defaultsDeep(sentence[encodedSentence], NEW_SENTENCE_DEFAULTS)

  const newInterval = calculateNewInterval(sentenceProps.interval, interaction)

  _.assign(sentenceProps, {
    interval: Math.max(newInterval, SENTENCE_INTERVAL_LOWER_BOUND),
    interactions: pushLimit(sentenceProps.interactions, interaction),
    interactionCount: (sentenceProps.interactionCount || sentenceProps.interactions.length) + 1,
    interactionDates: pushLimit(sentenceProps.interactionDates, Date.now()),
  })
  if (moduleId) {
    _.assign(sentenceProps, { source: moduleId })
  }
  return sentenceProps
}

export const getRealInteraction = (
  encodedWord, interaction, translationExpanded, clickedToTranslate, vocab,
) => {
  if (!interaction) return WORD_INTERACTIONS.None
  if (isKnownWord(vocab[encodedWord])) {
    if (interaction === WORD_INTERACTIONS.ThumbDown) {
      return WORD_INTERACTIONS.ThumbDown
    }
    if (translationExpanded) {
      return clickedToTranslate
        ? WORD_INTERACTIONS.TranslateSingleAndAll
        : WORD_INTERACTIONS.Translate
    }
    return clickedToTranslate
      ? WORD_INTERACTIONS.TranslateSingleOnly
      : WORD_INTERACTIONS.ThumbUp
  }
  if (interaction === WORD_INTERACTIONS.ThumbDown) {
    return WORD_INTERACTIONS.weakThumbDown
  }
  if (translationExpanded) {
    return clickedToTranslate
      ? WORD_INTERACTIONS.weakTranslateSingleAndAll
      : WORD_INTERACTIONS.weakTranslate
  }
  return clickedToTranslate
    ? WORD_INTERACTIONS.weakTranslateSingleOnly
    : WORD_INTERACTIONS.weakThumbUp
}

// Filter duplicate word token in an array
export function filterDuplicates(content) {
  let copy = JSON.parse(JSON.stringify(content))
  copy = copy.map((item) => ({
    ...item,
    encodedTokenWord: getEncodedTokenWord(item),
  })).filter((item, index, arr) => index === arr.findIndex((t) => (
    t.encodedTokenWord === item.encodedTokenWord
  ))).map((item) => ({
    word: item.word,
    tokens: item.tokens,
  }))
  return copy
}
