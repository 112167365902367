import _ from 'lodash'
import React, { useState } from 'react'
import {
  Typography,
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import {
  removeIndividualKoreanLetters,
  removeChineseCharacters,
  removeMiscSymbols,
  removeMiscCharacters,
  removeHashtags,
  removeEnglish,
  removeNumbers,
  getCaptions,
} from '../../lib/util'

const ModuleCreation = () => {
  const classes = useStyles()

  const [rawInput, setRawInput] = useState('')
  const [link, setLink] = useState('')
  const [analyzedText, setAnalyzedText] = useState([])

  // Analyze text when submitted and set state analyzed text
  const handleSubmit = async (e) => {
    e.preventDefault()
    let input = ''
    if (rawInput.length) {
      input = rawInput
    } else {
      const captions = await getCaptions(link)
      captions.forEach((element) => {
        input += `${element.text} `
      })
    }
    const result = cleanKoreanText(input)
    const counts = {}
    // Count duplicates
    result.forEach((x) => { counts[x] = (counts[x] || 0) + 1 })
    // Return sorted array of most common words
    const keysSorted = Object.entries(counts).sort((a, b) => b[1] - a[1])
    setAnalyzedText(keysSorted)
  }

  // Remove symbols and other "messy" data from KOREAN text
  const cleanKoreanText = (string) => {
    let cleaned = string.trim().replace(/\n/g, ' ')
    cleaned = removeIndividualKoreanLetters(cleaned)
    cleaned = removeChineseCharacters(cleaned)
    cleaned = removeMiscSymbols(cleaned)
    cleaned = removeMiscCharacters(cleaned)

    let cleanedArray = _.compact(cleaned.split(' '))
    cleanedArray = removeHashtags(cleanedArray)
    cleanedArray = removeEnglish(cleanedArray)
    cleanedArray = removeNumbers(cleanedArray)
    return cleanedArray
  }

  return (
    <>
      <Typography variant="h3" className={classes.header}>Text Analyzer</Typography>
      <Typography gutterBottom variant="h5">Youtube Link</Typography>
      <form autoComplete="off" onSubmit={handleSubmit}>
        <TextField
          name="userInput"
          variant="outlined"
          onChange={(e) => setLink(e.target.value)}
        />
        <Typography gutterBottom variant="h5">Raw  Text</Typography>

        <TextField
          name="userInput"
          fullWidth
          multiline
          rows={10}
          variant="outlined"
          onChange={(e) => setRawInput(e.target.value)}
        />
        <Button type="submit" variant="contained" color="secondary" className={classes.button}>
          Analyze Text
        </Button>
      </form>

      <Typography gutterBottom variant="h5">Word Count</Typography>
      <TableContainer component={Paper} className={classes.table}>
        <Table aria-label="word count table" size="small">
          <TableHead>
            <TableRow>
              <TableCell>
                Word
              </TableCell>
              <TableCell align="right">
                Wordcount
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              analyzedText.map((row) => (
                <TableRow key={row[0]}>
                  <TableCell component="th" scope="row">
                    {row[0]}
                  </TableCell>
                  <TableCell align="right">
                    {row[1]}
                  </TableCell>
                </TableRow>
              ))
            }
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

const useStyles = makeStyles({
  header: {
    padding: '24px 0',
  },
  button: {
    margin: '24px 0',
  },
  table: {
    maxWidth: 350,
  },
})

export default ModuleCreation
