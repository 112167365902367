import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles, Typography } from '@material-ui/core'

const Spoiler = ({ text }) => {
  const [isHidden, setHidden] = useState(true)
  const classes = useStyles()

  const handleClick = (e) => {
    e.stopPropagation()
    setHidden((prevState) => !prevState)
  }

  return (
    <div>
      <Typography
        className={isHidden ? classes.spoiler : classes.noSpoiler}
        onClick={handleClick}
      >
        {text}
      </Typography>
    </div>
  )
}

const useStyles = makeStyles(({
  spoiler: {
    color: 'transparent',
    textShadow: '0 0 10px rgba(0,0,0,0.5)',
    userSelect: 'none',
  },
  noSpoiler: {
    backgroundColor: 'none',
  },
}))

Spoiler.propTypes = {
  text: PropTypes.string,
}
Spoiler.defaultProps = {
  text: 'Lorum ipsum',
}

export default Spoiler
