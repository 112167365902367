/* eslint-disable no-await-in-loop */
import _ from 'lodash'
import { API_GATEWAY_URI, DEV_MODE } from '../constants'
import { encodeWord, filterDuplicates, getEncodedTokenWord } from '../util'

export const NEW_WORD_DEFAULTS = {
  interval: 0,
  interactions: [],
  interactionCount: 0,
  interactionDates: [],
}
const BATCH_WRITE_ITEM_MAX_LEN = 25

const PUT_RETRIES = 4
const PUT_RETRY_TIMEOUT = 100
export async function putUserVocabulary(user, vocab) {
  if (DEV_MODE) return
  const requestUri = `${API_GATEWAY_URI}users/vocab`
  // put user vocab using total vocab state, not module-specific one
  const body = _.map(
    _.compact(_.keys(vocab)), // drop any empty words, will ValidationError
    (word) => JSON.stringify({
      user: { S: user.username },
      word: { S: word },
      props: { S: JSON.stringify(vocab[word]) },
    }),
  )
  const chunkedBodies = _.chunk(body, BATCH_WRITE_ITEM_MAX_LEN)

  _.each(chunkedBodies, async (chunkedBody) => {
    let retries = PUT_RETRIES
    while (retries > 0) {
      try {
        const resp = await fetch(requestUri, {
          method: 'POST',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(chunkedBody),
        })
        const respJson = await resp.json()
        if (_.size(respJson.UnprocessedItems) === 0) break
      } catch (err) {
        await new Promise((cb) => setTimeout(cb, PUT_RETRY_TIMEOUT))
        retries -= 1
      }
    }
  })
}

export async function putUserVocabularyNew(user, card, updatedVocabs) {
  if (DEV_MODE) return
  const requestUri = `${API_GATEWAY_URI}users/vocab`

  let body
  if (typeof card.word === 'object') {
    if (!body) body = []
    card.content = filterDuplicates(card.content)
    card.content.forEach((content) => {
      const encodedWord = getEncodedTokenWord(content)
      const vocab = _.pick(updatedVocabs, encodedWord)
      if (vocab && _.keys(vocab).length > 0) {
        const temp = _.map(
          _.compact(_.keys(vocab)), // drop any empty words, will ValidationError
          (word) => JSON.stringify({
            user: { S: user.username },
            word: { S: encodedWord },
            props: { S: JSON.stringify(vocab[word]) },
          }),
        )
        body = [...body, ...temp]
      } else {
        const temp = [JSON.stringify({
          user: { S: user.username },
          word: { S: encodedWord },
          props: { S: JSON.stringify(NEW_WORD_DEFAULTS) },
        })]
        body = [...body, ...temp]
      }
    })
  } else {
    // put user vocab using total vocab state, not module-specific one
    const vocab = _.pick(updatedVocabs, encodeWord(card.word))
    body = _.map(
      _.compact(_.keys(vocab)), // drop any empty words, will ValidationError
      (word) => JSON.stringify({
        user: { S: user.username },
        word: { S: word },
        props: { S: JSON.stringify([vocab[word]]) },
      }),
    )
  }

  body = body.filter((v, i, a) => a.findIndex((t) => (t === v)) === i)

  const chunkedBodies = _.chunk(body, BATCH_WRITE_ITEM_MAX_LEN)
  _.each(chunkedBodies, async (chunkedBody) => {
    let retries = PUT_RETRIES
    while (retries > 0) {
      try {
        const resp = await fetch(requestUri, {
          method: 'POST',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(chunkedBody),
        })
        const respJson = await resp.json()
        if (_.size(respJson.UnprocessedItems) === 0) break
      } catch (err) {
        await new Promise((cb) => setTimeout(cb, PUT_RETRY_TIMEOUT))
        retries -= 1
      }
    }
  })
}

export async function getUserVocabulary(user) {
  const requestUri = `${API_GATEWAY_URI}users/vocab?user=${user.username}`
  const response = await fetch(requestUri, {
    mode: 'cors',
  })
  let json = await response.json()
  const vocabArrayItems = json.Items

  while (json.LastEvaluatedKey) {
    const newRequestUri = `${API_GATEWAY_URI}users/vocab?user=${user.username}&last_evaluated_id=${json.LastEvaluatedKey.word.S}`
    const newResponse = await fetch(
      newRequestUri,
      { mode: 'cors' },
    )
    json = await newResponse.json()
    vocabArrayItems.push(...json.Items)
  }

  // create map of encodedWord => { interactions: [int, int, ... ] }
  const vocabMap = _.reduce(
    vocabArrayItems,
    (acc, vocabRow) => {
      const content = JSON.parse(vocabRow.props.S)
      if (Array.isArray(content)) {
        const props = []
        content.forEach((item) => {
          const prop = _.defaults(item, NEW_WORD_DEFAULTS)
          // TODO remove this once migration runs; backfill interaction dates
          if (prop.interactionDates.length < prop.interactions.length) {
            prop.interactionDates = _.concat(
              (new Array(prop.interactions.length - prop.interactionDates.length)).fill(Date.now()),
              prop.interactionDates,
            )
          }
          props.push(prop)
        })

        return _.extend(acc, { [vocabRow.word.S]: props })
      }
      const props = _.defaults(content, NEW_WORD_DEFAULTS)

      // TODO remove this once migration runs; backfill interaction dates
      if (props.interactionDates.length < props.interactions.length) {
        props.interactionDates = _.concat(
          (new Array(props.interactions.length - props.interactionDates.length)).fill(Date.now()),
          props.interactionDates,
        )
      }
      return _.extend(acc, { [vocabRow.word.S]: [props] })
    },
    {},
  )
  return vocabMap
}
