import React, { useState, useCallback, useEffect } from 'react'
import _ from 'lodash'
import {
  Box,
  Button,
  Card,
  Grid,
  makeStyles,
  Slider,
  TextField,
  Typography,
} from '@material-ui/core'
import { trackComplete, getTrackComplete } from '../../lib/apigateway/trackcomplete'
import { postUserFeedback } from '../../lib/apigateway/feedback'
import { useGlobalStateContext, useVocabContext } from '../../contexts'
import {
  getStreakData,
  updateConsecutiveStreak,
  isKnownWord,
  getTimeSpent,
  getStreakFlame,
} from '../../lib/util'
import { PHRASES_BY_LANG, COMPREHENSION_DEFAULTS } from '../../lib/constants'
import { deleteUserProgress } from '../../lib/apigateway/progress'

const CongratsPage = () => {
  const [value, setValue] = useState(0)
  const [feedback, setFeedback] = useState('')
  const [streak, setStreak] = useState('')
  const { vocab: { vocab } } = useVocabContext()
  const { globalState: { user, settings } } = useGlobalStateContext()
  const classes = useStyles()

  const searchParams = new URLSearchParams(window.location.href.split('congrats')[1])
  const moduleId = searchParams.get('moduleId')
  const moduleLength = _.toNumber(searchParams.get('moduleLength'))
  const numCardsSeen = _.toNumber(searchParams.get('numCardsSeen'))
  const start = searchParams.get('start')
  const end = searchParams.get('end')
  const delayedTrackChanges = useCallback(
    _.debounce((v) => trackComplete(user, moduleId, 100, v), 500), [],
  )


  useEffect(() => {
    const init = async () => {
      const data = await getTrackComplete(user)
      const streakData = getStreakData(data)
      setStreak(streakData.streak)
      const updatedStreak = updateConsecutiveStreak(streakData, false)
      await deleteUserProgress(user, moduleId)
      trackComplete(user, 'review', 100, encodeURIComponent(JSON.stringify(updatedStreak)))
    }
    if (user) init()
  }, [user, moduleId])

  const handleChange = (_event, newValue) => {
    setValue(newValue)
    const props = {
      comprehension: newValue,
      moduleLength,
      numCardsSeen,
    }
    delayedTrackChanges(
      encodeURIComponent(JSON.stringify(_.defaults(props, COMPREHENSION_DEFAULTS))),
    )
  }
  const handleSubmit = async () => {
    if (feedback) {
      await postUserFeedback(user.username, moduleId, feedback)
    }
    window.location.href = '/#/'
  }

  return (
    <Box>
      <section className={classes.header}>
        <Box fontSize={36} fontWeight="fontWeightBold">학습 완료!</Box>
        <Box fontWeight="fontWeightLight">
          {`${streak} ${PHRASES_BY_LANG.streak[settings.translationLang]} ${getStreakFlame(streak)}`}
        </Box>
      </section>

      <Card>
        <Box display="flex" p={2} justifyContent="space-around">
          <Box display="flex" flexDirection="column" alignItems="center">
            <Box color="success.light" className={classes.statNumber} fontWeight={500}>{getTimeSpent(start, end)}</Box>
            <Box className={classes.statText} fontWeight={400} color="grey.500" textAlign="center">소요된 학습 시간</Box>
          </Box>
          <Box display="flex" flexDirection="column" alignItems="center">
            <Box color="success.light" className={classes.statNumber} fontWeight={500}>{`${_.filter(vocab, isKnownWord).length}`}</Box>
            <Box className={classes.statText} fontWeight={400} color="grey.500" textAlign="center">총 아는 단어</Box>
          </Box>
        </Box>
      </Card>

      <Card className={classes.surveyCard}>
        <Typography variant="h6" style={{ fontWeight: 600 }}>동영상의 몇 퍼센트를 이해했나요?</Typography>
        <Slider
          value={value}
          className={classes.slider}
          onChange={handleChange}
          min={5}
          max={100}
          marks
          step={5}
          valueLabelDisplay="on"
        />
        <TextField
          value={feedback}
          className={classes.feedbackInput}
          placeholder="앱의 어떤 점들을 개선할 수 있을까요?"
          fullWidth
          multiline
          rows={3}
          variant="outlined"
          onChange={(e) => setFeedback(e.target.value)}
        />
        {!value && <Typography align="center" color="textSecondary" variant="body2">{`${PHRASES_BY_LANG.pickPercentage[settings.translationLang]}`}</Typography>}
        <Grid container justify="center">
          <Grid item>
            <Button
              disabled={!value}
              variant="contained"
              size="large"
              color="secondary"
              className={classes.button}
              onClick={handleSubmit}
            >
              확인
            </Button>
          </Grid>
        </Grid>
      </Card>
      <Typography align="center">
        {`${PHRASES_BY_LANG.feedbackFooter[settings.translationLang]}`}
        <a
          href="https://www.reddit.com/r/siplanguage/"
          rel="noopener noreferrer"
          target="_blank"
        >
          /r/siplanguage
        </a>
      </Typography>
    </Box>
  )
}

const useStyles = makeStyles((theme) => ({
  body: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.5rem',
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: '2.5rem',
    },
  },
  button: {
    marginTop: 12,
  },
  feedbackInput: {
    padding: '12px 0px',
  },
  header: {
    paddingBottom: 24,
  },
  surveyCard: {
    padding: 24,
    margin: '24px 0px',
  },
  slider: {
    marginTop: 36,
  },
  statNumber: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem',
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: '1.7rem',
    },
  },
  statText: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.85rem',
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: '1rem',
    },
  },
}))
export default CongratsPage
