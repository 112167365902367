import Tooltip from '@material-ui/core/Tooltip'
import { withStyles } from '@material-ui/core/styles'

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#5690cc', // theme.palette.common.white,
    color: 'white', // 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip)

export default LightTooltip
