/* eslint-disable no-await-in-loop */
import _ from 'lodash'
import { API_GATEWAY_URI } from '../constants'

export async function getModuleById(moduleId, moduleLang, translationLang) {
  const requestUri = `${API_GATEWAY_URI}modules/${moduleId}?module_lang=${moduleLang}&translation_lang=${translationLang}`
  const response = await fetch(requestUri, {
    mode: 'cors',
  })
  const json = await response.json()
  const content = json.Item.content.S
  console.log(JSON.parse(content))
  const allCards = JSON.parse(content).cards
  const oldPatternCards = []
  allCards.forEach((card) => {
    if (Array.isArray(card)) {
      oldPatternCards.push(...card)
    } else {
      oldPatternCards.push(card)
    }
  })

  const vocabCards = oldPatternCards.filter(({ type }) => _.includes(['word', 'phrase', 'generic', 'netflix', 'congrats'], type))
  return {
    cards: vocabCards,
    sceneCards: allCards.slice(1),
    tags: json.Item.tags.SS,
    title: json.Item.title.S,
    contentID: json.Item['content-id'].S,
    series: json.Item.series ? json.Item.series.S : 'Other',
    contentType: json.Item['content-type'].S,
    thumbnail: json.Item['thumbnail-url'].S,
  }
}

// for now, no module langs are used
export async function getModules(moduleLang, translationLang) {
  const requestUri = `${API_GATEWAY_URI}modules?module_lang=${moduleLang}&translation_lang=${translationLang}`
  const response = await fetch(
    requestUri,
    { mode: 'cors' },
  )
  const items = []
  let json = await response.json()
  items.push(...json.Items)

  while (json.LastEvaluatedKey) {
    const newRequestUri = `${API_GATEWAY_URI}modules?module_lang=${moduleLang}&translation_lang=${translationLang}&last_evaluated_id=${json.LastEvaluatedKey.id.S}`
    const newResponse = await fetch(
      newRequestUri,
      { mode: 'cors' },
    )
    json = await newResponse.json()
    items.push(...json.Items)
  }

  for (let i = 0; i < items.length; i += 1) {
    if (!('thumbnail-url' in items[i])) {
      items[i]['thumbnail-url'] = { S: '' }
    }
  }
  return items
}
