import React from 'react'
import { createTheme, ThemeProvider } from '@material-ui/core/styles'
import PropTypes from 'prop-types'

const sipTheme = createTheme({
  palette: {
    primary: { main: '#5690cc', light: '#a9c7e5', dark: '#27415c' },
    secondary: {
      main: '#f69522',
      contrastText: '#fff',
    },
    orange: {
      main: '#EA9940'
    }
  },
})

const SipThemeProvider = (props) => {
  const { children } = props
  return (
    <ThemeProvider theme={sipTheme}>
      { children}
    </ThemeProvider>
  )
}

SipThemeProvider.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element),
}

SipThemeProvider.defaultProps = {
  children: [],
}


export default SipThemeProvider
