import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import {
  Box,
  Button,
  makeStyles,
  Icon,
  Paper,
} from '@material-ui/core'
import { ExitToApp, Reddit, Web } from '@material-ui/icons'
import Discord from '../../graphics/Discord-Logo-White.svg'
import { useGlobalStateContext, useVocabContext, useSentencesContext } from '../../contexts'
import {
  isKnownWord, getSentencesByInterval, getStreakData, calculateHeatMap,
} from '../../lib/util'
import { getTrackComplete } from '../../lib/apigateway/trackcomplete'
import { PHRASES_BY_LANG } from '../../lib/constants'
import { signOutCurrentAuthenticatedUser } from '../../lib/auth'
import CalendarHeatMap from '../../components/UI-Components/CalendarHeatMap'

const getStreakFlame = (count) => {
  if (count > 10) {
    return '🔥🔥🔥'
  }
  if (count > 5) {
    return '🔥🔥'
  }
  if (count > 1) {
    return '🔥'
  }
  return ''
}

const Profile = () => {
  const [stats, setStats] = useState({ complete: 0, streak: {} })
  const [heatmap, setHeatmap] = useState()

  const classes = useStyles()
  const { globalState: { user, settings } } = useGlobalStateContext()
  const { vocab: { vocab } } = useVocabContext()
  const { sentences } = useSentencesContext()
  const dueSentences = getSentencesByInterval(sentences)

  useEffect(() => {
    const init = async () => {
      const data = await getTrackComplete(user)
      const streakData = getStreakData(data)
      const completeCount = _.filter(data, (obj, key) => key !== 'review' && obj.completionPercentage === 100)
      setStats({ complete: completeCount.length, streak: streakData })
      setHeatmap(calculateHeatMap(streakData.completionDates))
    }
    if (user) init()
  }, [user])

  // Prevent user from seing undefined while data is being loaded
  if (_.isEmpty(stats.streak)) {
    return null
  }

  return (
    <main className={classes.root}>
      <section className={classes.header}>
        <Box fontSize={36} fontWeight="fontWeightBold">
          {`${PHRASES_BY_LANG.hello[settings.translationLang]} ${user.attributes.name ? user.attributes.name : ''}!`}
          <span aria-label="wave emoji" role="img">&nbsp;👋</span>
        </Box>
        <Box fontWeight="fontWeightLight">학습 진도 및 통계를 확인하세요</Box>
      </section>
      <Paper>
        <Box display="flex" flexWrap="wrap" justifyContent="space-evenly" p={2}>
          <Box display="flex" flexGrow={1} justifyContent="space-between">
            <Box display="flex" flexDirection="column" width="50%" alignItems="center">
              <Box fontSize={40} fontWeight="fontWeightBold">
                {`${_.filter(vocab, isKnownWord).length}`}
              </Box>
              <Box fontSize={14} color="text.secondary" fontWeight="fontWeightMedium" textAlign="center">
                {`${PHRASES_BY_LANG.knownWords[settings.translationLang]}`}
              </Box>
            </Box>
            <Box display="flex" flexDirection="column" width="50%" alignItems="center">
              <Box fontSize={40} fontWeight="fontWeightBold">
                {`${stats.complete}`}
              </Box>
              <Box fontSize={14} color="text.secondary" fontWeight="fontWeightMedium" textAlign="center">
                {`${PHRASES_BY_LANG.modulesCompleted[settings.translationLang]}`}
              </Box>
            </Box>
          </Box>
          <Box display="flex" flexGrow={1} justifyContent="space-between">
            <Box display="flex" flexDirection="column" width="50%" alignItems="center">
              <Box fontSize={40} fontWeight="fontWeightBold">
                {`${stats.streak.totalReviewCompletions}`}
              </Box>
              <Box fontSize={14} color="text.secondary" fontWeight="fontWeightMedium" textAlign="center">
                {`${PHRASES_BY_LANG.reviewsCompleted[settings.translationLang]}`}
              </Box>
            </Box>
            <Box display="flex" flexDirection="column" width="50%" alignItems="center">
              <Box fontSize={40} fontWeight="fontWeightBold">
                {`${dueSentences.length}`}
              </Box>
              <Box fontSize={14} color="text.secondary" fontWeight="fontWeightMedium" textAlign="center">
                {`${PHRASES_BY_LANG.cardsDue[settings.translationLang]}`}
              </Box>
            </Box>
          </Box>
        </Box>
      </Paper>

      <Box display="flex" className={classes.main}>
        <Box mt={4} flex={2} className={classes.left}>
          <Paper>
            <Box p={2} height={400}>
              <Box component="span" fontSize={30}>{`${stats.streak.streak}`}</Box>
              <Box component="span" fontSize={14} color="text.secondary" fontWeight="fontWeightMedium">
                {` ${PHRASES_BY_LANG.streak[settings.translationLang]} ${getStreakFlame(stats.streak.streak)}`}
              </Box>
              <Box fontWeight="fontWeightLight" color="text.secondary">{`${PHRASES_BY_LANG.streakDetail[settings.translationLang]}`}</Box>
              <CalendarHeatMap dates={heatmap} />
            </Box>
          </Paper>
        </Box>
        <Box mt={4} flex={1} className={classes.right}>
          <Paper>
            <Box p={3} display="flex" flexDirection="column" height={400} justifyContent="space-evenly">
              <Button
                fullWidth
                className={classes.discordButton}
                startIcon={<Icon><img className={classes.imageIcon} src={Discord} alt="discord-icon" /></Icon>}
                variant="contained"
                href="https://discord.gg/Zq6Zrkh"
                target="_blank"
                rel="noopener noreferrer"
              >
                커뮤니티 가입하기
              </Button>
              <Button className={classes.redditButton} rel="noopener noreferrer" target="_blank" targ href="https://www.reddit.com/r/siplanguage/" startIcon={<Reddit />} variant="contained">피드백 남기기</Button>
              <Button className={classes.sipButton} rel="noopener noreferrer" target="_blank" href="https://www.siplanguage.com/" startIcon={<Web />} variant="contained">홈페이지 방문하기</Button>
              <Button className={classes.signOutButton} onClick={signOutCurrentAuthenticatedUser} startIcon={<ExitToApp />} variant="contained">로그아웃</Button>
            </Box>
          </Paper>
        </Box>
      </Box>
    </main>


  )
}

const useStyles = makeStyles((theme) => ({
  discordButton: {
    backgroundColor: '#7289da',
    color: 'white',
    '&:hover': {
      backgroundColor: '#525edc',
    },
    height: 50,
  },
  header: {
    paddingBottom: 24,
  },
  imageIcon: {
    display: 'flex',
    height: 'inherit',
    width: 'inherit',
  },
  main: {
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  left: {
    [theme.breakpoints.up('md')]: {
      marginRight: 16,
    },
  },
  right: {
    [theme.breakpoints.up('md')]: {
      marginLeft: 16,
    },
  },
  redditButton: {
    backgroundColor: '#FF5700',
    color: 'white',
    '&:hover': {
      backgroundColor: '#FF4500',
    },
    height: 50,
  },
  signOutButton: {
    height: 50,
  },
  sipButton: {
    backgroundColor: '#5690cc',
    color: 'white',
    '&:hover': {
      backgroundColor: '#426f9d',
    },
    height: 50,
  },
}))

export default Profile
