import React from 'react'
import PropTypes from 'prop-types'
import CalendarHeatmap from 'react-calendar-heatmap'
import dayjs from 'dayjs'
import ReactTooltip from 'react-tooltip'
import { makeStyles, useMediaQuery, useTheme } from '@material-ui/core'
import 'react-calendar-heatmap/dist/styles.css'

const CalendarHeatMap = ({ dates }) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <CalendarHeatmap
        startDate={dayjs().startOf('month') - 1}
        endDate={dayjs().endOf('month')}
        showWeekdayLabels
        showMonthLabels={!isMobile}
        horizontal={false}
        values={dates}
        classForValue={(value) => {
          if (!value) {
            return 'color-empty'
          }
          return `color-github-${value.count}`
        }}
        tooltipDataAttrs={(value) => ({
          'data-tip': `Completed ${value.count} reviews on ${value.date}`,
        })}
        transformDayElement={(element) => React.cloneElement(element, { rx: 100, ry: 100 })}
      />
      <ReactTooltip />
    </div>
  )
}

CalendarHeatMap.propTypes = {
  dates: PropTypes.arrayOf(PropTypes.object),
}
CalendarHeatMap.defaultProps = {
  dates: [],
}

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    height: '80%',
    '& svg': {
      width: '100%',
      height: '100%',
    },
    margin: '0px auto',
    [theme.breakpoints.down('sm')]: {
      marginRight: '-20px',
    },
  },
}))
export default CalendarHeatMap
