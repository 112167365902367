import React from 'react'
import {
  Box,
  Button,
  Card,
  makeStyles,
  Typography,
} from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 20,
    padding: 20,
    display:'flex',
    width: '100%',
    justifyContent: 'space-between'
  },
  netflixButton: {
    color:"#fff", 
    height: '100%',
    whiteSpace: 'pre'
  }
}))

export default function CongratsNetflix({ card }) {
  const classes = useStyles()

  return (
    <Card
      classes={{
        root: classes.root,
      }}
    >
      <Box>
        <Typography style={{fontWeight: 700}}>학습한 만큼 시청하실래요?</Typography>
        <Typography style={{lineHeight: 1.2}}>{card?.content}</Typography>
      </Box>
      <a 
        style={{textDecoration: 'none', height: '100%'}}
        href={card?.link}
        rel="noopener noreferrer"
        target='_blank'>
        <Button 
          style={{ backgroundColor: "#cf3917"}}
          classes={{
            root: classes.netflixButton
          }}
          variant="contained" 
          onClick={() => ''}>
          넷플릭스 열기
        </Button>
      </a>
    </Card>
  );
}