/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState, useMemo } from 'react'
import { withRouter, useParams } from 'react-router-dom'
import PropTypes from 'prop-types'
import _ from 'lodash'
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Link,
  makeStyles,
  Typography,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
} from '@material-ui/core'
import {
  HelpOutline,
  NavigateNext,
} from '@material-ui/icons'
import {
  WORD_INTERACTIONS,
  updateVocabulary,
  encodeWord,
  isKnownWord,
  cacheModuleInfo,
  setSentenceProps,
  encodeObject,
  removePunctuation,
  findGrammarReference,
} from '../../lib/util'
import { useGlobalStateContext, useVocabContext, useSentencesContext } from '../../contexts'
import { putUserVocabulary } from '../../lib/apigateway/vocabulary'
import { trackComplete } from '../../lib/apigateway/trackcomplete'
import { getReference } from '../../lib/apigateway/references'
import { getModuleById } from '../../lib/apigateway/modules'
import { postAccountSettings } from '../../lib/apigateway/settings'
import { putUserMorphs } from '../../lib/apigateway/morphemes'
import {
  RESURFACE_CARD_LENGTH,
  MODULE_DIFFICULTIES,
  PHRASES_BY_LANG,
  MODULE_REFERENCE_TYPE,
} from '../../lib/constants'
import DIFFICULTY from '../Learn'
import { references } from '../../lib/grammarReferences'
import TtsSwitch from '../../components/UI-Components/TtsSwitch'
import TtsButton from '../../components/UI-Components/TtsButton'
import { putUserSentence } from '../../lib/apigateway/weakSentence'
import CttContentComponent from '../../components/UI-Components/CttContentComponent/CttContentComponent'
import FeedbackButton from '../../components/FeedbackButton'
import GrammarReference from '../../components/GrammarReference'

const Grammar = ({ history }) => {
  const { moduleId } = useParams()
  // index in allCards
  const [cardFocusInd, setCardFocusInd] = useState(0)
  const [allCards, setCards] = useState([])
  const [displayCardInds, setDisplayCardInds] = useState([])
  const [isTranslationExpanded, setIsTranslationExpanded] = useState(false)
  const [moduleInfo, setModuleInfo] = useState({ title: '', contentID: '' })
  const [cttWords, setCttWords] = useState([])
  const [buttonDisabled, setButtonDisabled] = useState(false)
  const [endCardNumber] = useState(-1)
  const [review, setReview] = useState([])
  const [tooltip, setTooltip] = useState({ icon: false, dialog: false, wordList: false })
  const [dialogOpenState, setDialogOpenState] = useState(false)
  const [wordsRemaining, setWordsRemaining] = useState(0)
  const [startTime] = useState(() => Date.now())
  const [grammarReference, setGrammarReference] = useState({})
  const [showGrammar, setShowGrammar] = useState(false)
  const [isTutorial, setIsTutorial] = useState(false)

  const classes = useStyles()
  const { globalState: { user, settings }, setGlobalState } = useGlobalStateContext()
  const { vocab: { vocab, morphs }, setVocab } = useVocabContext()
  const { sentences, setSentences } = useSentencesContext()
  const { translationLang, moduleLang } = settings

  // allCards[displayCardInds[cardFocusInd]] = current card, i.e. cardFocusInd = display index, not
  const card = allCards[displayCardInds[cardFocusInd]]
  // last 2 cards are not actual cards
  const cardsRemaining = Math.max(displayCardInds.length - cardFocusInd + endCardNumber, 0)
  const actualCardsLength = allCards.length + endCardNumber // sometimes can be -3 right
  function getCompletionPercentage(remaining) {
    return Math.min(Math.round((100 * (actualCardsLength - remaining)) / actualCardsLength) || 0,
      100)
  }

  useEffect(() => {
    async function initModule() {
      const {
        cards: moduleCards,
        tags: moduleTags,
        title,
        contentID,
        series,
      } = await getModuleById(moduleId, moduleLang, translationLang)

      const hasTutorialTag = moduleTags.includes('Tutorial')
      setIsTutorial(hasTutorialTag)
      if (!hasTutorialTag) {
        setGrammarReference(await getReference(moduleId, MODULE_REFERENCE_TYPE.GRAMMAR))
      }

      // spliceOtherCards(contentID, title, moduleCards, setEndCardNumber)
      const [difficulty] = _.intersection(moduleTags, MODULE_DIFFICULTIES)
      const cardInds = _.range(moduleCards.length)

      setDisplayCardInds(cardInds)
      setWordsRemaining(cardInds.length)
      setCards(moduleCards)
      setModuleInfo({
        title,
        contentID,
        series: series || 'Beginner Lessons',
        difficulty: difficulty || DIFFICULTY.beginner,
      })
    }
    if (user !== null && vocab) initModule()
  }, [user, moduleId, moduleLang, translationLang, vocab, morphs])

  useEffect(() => { // Create CTT array
    setButtonDisabled(false) // cardFocusInd has changed, enable buttons
    const currCard = allCards[displayCardInds[cardFocusInd]]
    if (currCard && (currCard.type === 'word')) {
      const individualWords = _.compact(currCard.content.map(({ word }) => word))
      setCttWords(_.map(individualWords, () => false))
    }
  }, [cardFocusInd, allCards, displayCardInds])

  const RightButton = ({ position }) => {
    RightButton.propTypes = {
      position: PropTypes.string,
    }
    RightButton.defaultProps = {
      position: '',
    }
    const handleClick = () => {
      const isLast = cardFocusInd >= displayCardInds.length - 1
      if (isLast) {
        const reviewlist = _.uniqBy(review, 'content')
        history.push(`/congrats?moduleId=${moduleId}&moduleLength=${actualCardsLength}&numCardsSeen=${displayCardInds.length}&sentencesLearned=${reviewlist.length}&start=${startTime}&end=${Date.now()}`)
        return
      }
      setCardFocusInd(cardFocusInd + 1)
      setIsTranslationExpanded(false)
    }
    return (
      <IconButton
        className={(position === 'side') ? classes.rightArrowSide : null}
        aria-label="right"
        color="primary"
        onClick={handleClick}
      >
        <NavigateNext fontSize="large" />
      </IconButton>
    )
  }

  async function handleCardChange(
    translationExpanded, interaction, content, translation, word,
  ) {
    if (card && sentences) {
      const wordArr = _.map(content, 'word')
      const contentSentence = wordArr.join(' ')
      const encodedWordsArr = _.map(_.compact(wordArr), encodeWord)
      const encodedMorphArr = _.map(_.compact(_.flatten(_.map(content, 'morphs'))), encodeWord)

      const sentenceObj = {
        [moduleLang]: contentSentence,
        [translationLang]: translation,
      }
      const encodedSentenceObj = encodeObject(sentenceObj)

      if (interaction === WORD_INTERACTIONS.ThumbDown) {
        setReview((prevState) => ([...prevState, {
          content: contentSentence, translation, word,
        }]))
      }

      if (!isTutorial) {
        const updatedCardVocab = updateVocab(translationExpanded, interaction)
        putUserVocabulary(user, _.pick(updatedCardVocab.vocab, encodedWordsArr))
        putUserMorphs(user, _.pick(updatedCardVocab.morphs, encodedMorphArr))
        if (interaction === WORD_INTERACTIONS.ThumbDown || sentences[encodedSentenceObj]) {
          const updatedSentenceProps = setSentenceProps(
            sentences, interaction, encodedSentenceObj, moduleId,
          )
          putUserSentence(user, encodedSentenceObj, updatedSentenceProps)
          setSentences((prevState) => {
            _.extend(prevState, { [encodedSentenceObj]: updatedSentenceProps })
          })
        }
      }

      // update completion status, w/ one fewer card remaining than current
      await trackComplete(user, moduleId, getCompletionPercentage(cardsRemaining - 1))
    }
    if (interaction === WORD_INTERACTIONS.ThumbDown) {
      const currCardInd = displayCardInds[cardFocusInd]
      // Insert 20 cards ahead.  Or if less than 20 cards remaining at end,
      // insert before review card
      setDisplayCardInds((prevState) => {
        prevState.splice(Math.min(cardFocusInd + RESURFACE_CARD_LENGTH - displayCardInds
          .length, endCardNumber), 0, currCardInd)
        return prevState
      })
    }
    return displayCardInds.length
  }

  // return the updated vocabs map since otherwise callers don't get access to the updated vocabs
  // map
  function updateVocab(translationExpanded, interaction) {
    const newVocabAndMorphs = getUpdatedVocab(
      translationExpanded, card, interaction,
    )
    setVocab(newVocabAndMorphs)
    return newVocabAndMorphs
  }

  // for a given card currCard and vocab, returns an updated vocab dict that accounts for the user's
  // response (thumbUp, thumbDown, translationExpanded) to currCard
  function getUpdatedVocab(translationExpanded, currCard, interaction) {
    // Split words into arr for mapping, dropping empty words
    const sentenceWordArr = currCard.content.map(({ word }) => word)
    const wordsArr = _.map(_.compact(sentenceWordArr), encodeWord)
    const interactions = _.map(cttWords, (clickedToTranslate, index) => {
      if (!interaction) return WORD_INTERACTIONS.None
      if (isKnownWord(vocab[wordsArr[index]])) {
        if (interaction === WORD_INTERACTIONS.ThumbDown) {
          return WORD_INTERACTIONS.ThumbDown
        }
        if (translationExpanded) {
          return clickedToTranslate
            ? WORD_INTERACTIONS.TranslateSingleAndAll
            : WORD_INTERACTIONS.Translate
        }
        return clickedToTranslate
          ? WORD_INTERACTIONS.TranslateSingleOnly
          : WORD_INTERACTIONS.ThumbUp
      }
      if (interaction === WORD_INTERACTIONS.ThumbDown) {
        return WORD_INTERACTIONS.weakThumbDown
      }
      if (translationExpanded) {
        return clickedToTranslate
          ? WORD_INTERACTIONS.weakTranslateSingleAndAll
          : WORD_INTERACTIONS.weakTranslate
      }
      return clickedToTranslate
        ? WORD_INTERACTIONS.weakTranslateSingleOnly
        : WORD_INTERACTIONS.weakThumbUp
    })
    return updateVocabulary(vocab, morphs, currCard.content, interactions)
  }

  function handleClosePrompt(setState, prompt) {
    setState(false)
    const newSettings = { ...settings, prompts: { ...settings.prompts, [prompt]: false } }
    setGlobalState((prevState) => ({ ...prevState, settings: newSettings }))
    postAccountSettings(user.username, newSettings)
  }

  const ModuleCard = () => {
    renderYoutubeCardComponent.propTypes = {
      content: PropTypes.string,
    }

    renderYoutubeCardComponent.defaultProps = {
      content: '',
    }

    YoutubeIframe.propTypes = {
      id: PropTypes.string,
    }

    YoutubeIframe.defaultProps = {
      id: '',
    }

    renderGenericCardComponent.propTypes = {
      title: PropTypes.string,
      description: PropTypes.string,
      body: PropTypes.string,
      link: PropTypes.string,
    }

    renderGenericCardComponent.defaultProps = {
      title: 'Lorem ipsum',
      description: 'dolor sit amet, ',
      body: 'consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
      link: '',
    }

    WordCardComponent.propTypes = {
      content: PropTypes.arrayOf(PropTypes.object),
      translation: PropTypes.string,
      word: PropTypes.shape({
        word: PropTypes.string,
        morphs: PropTypes.arrayOf(PropTypes.string),
      }),
      notes: PropTypes.arrayOf(PropTypes.string),
    }

    WordCardComponent.defaultProps = {
      content: 'Lorem ipsum',
      translation: 'dolor sit amet, ',
      word: 'lorum ipsum',
      notes: [],
    }

    async function toggleInteraction(interaction, content, translation, word) {
      const newDisplayCardIndsLength = await handleCardChange(
        isTranslationExpanded, interaction, content, translation, word,
      )
      if (cardFocusInd < newDisplayCardIndsLength - 1) {
        setCardFocusInd((prevState) => prevState + 1)
      } else {
        cacheModuleInfo(moduleInfo)
        const reviewlist = _.uniqBy(review, 'content')
        history.push(`/congrats/grammar?moduleId=${moduleId}&moduleLength=${actualCardsLength}&numCardsSeen=${displayCardInds.length}&sentencesLearned=${reviewlist.length}&start=${startTime}&end=${Date.now()}`)
        return
      }
      setIsTranslationExpanded(false)
      setShowGrammar(false)
    }

    switch (card.type) {
      case 'generic':
        return renderGenericCardComponent(card)
      case 'word':
        return (
          <WordCardComponent
            content={card.content}
            translation={card.translation}
            word={card.word}
            notes={card.notes}
          />
        )
      case 'youtube':
        return renderYoutubeCardComponent(card)
      case 'wordList':
        return renderWordListCardComponent(card)
      default:
        throw TypeError(`Card is of unexpected type: ${card.type}`)
    }
    function renderGenericCardComponent({
      title, description, body, link,
    }) {
      const handleClick = () => {
        setCardFocusInd((prevState) => prevState + 1)
        setWordsRemaining((prev) => prev - 1)
      }
      return (
        <Card>
          <CardContent>
            <Typography variant="h5" component="h2">
              {title}
            </Typography>
            <Typography className={classes.pos} color="textSecondary">
              {description}
            </Typography>
            <Typography component="p" variant="subtitle1">
              {body}
            </Typography>
            <Button color="primary" onClick={handleClick} variant="contained">Next</Button>
            {link && <CardMedia className={classes.tutorialImg} component="img" image={link} />}
          </CardContent>
        </Card>
      )
    }

    function WordCardComponent({
      content, translation, word, notes,
    }) {
      const primaryButtons = (
        <Box>
          <Button
            className={classes.knownButton}
            disableElevation
            onClick={() => {
              setButtonDisabled(true)
              toggleInteraction(
                WORD_INTERACTIONS.ThumbUp, content, translation, word,
              )
              setWordsRemaining((prev) => prev - 1)
            }}
            variant="contained"
            disabled={buttonDisabled}
          >
            {`${PHRASES_BY_LANG.understand[settings.translationLang]}`}
          </Button>
          <Button
            className={classes.showAnswerButton}
            disableElevation
            onClick={() => {
              setIsTranslationExpanded(true)
              if (settings.prompts.showAnswer) setDialogOpenState(true)
            }}
            variant="contained"
            disabled={buttonDisabled}
          >
            {`${PHRASES_BY_LANG.showTranslation[settings.translationLang]}`}
          </Button>
        </Box>
      )
      const correctButtons = (
        <Box>
          <Button
            className={classes.correctButton}
            disableElevation
            onClick={(e) => {
              e.stopPropagation()
              setButtonDisabled(true)
              toggleInteraction(
                WORD_INTERACTIONS.ThumbUp, content, translation, word,
              )
              setWordsRemaining((prev) => prev - 1)
            }}
            variant="contained"
            disabled={buttonDisabled}
          >
            {`${PHRASES_BY_LANG.correct[settings.translationLang]}`}
          </Button>
          <Button
            className={classes.incorrectButton}
            disableElevation
            onClick={(e) => {
              e.stopPropagation()
              setTooltip((prev) => ({ ...prev, icon: true }))
              setButtonDisabled(true)
              toggleInteraction(
                WORD_INTERACTIONS.ThumbDown,
                content,
                translation,
                word,
              )
            }}
            variant="contained"
            disabled={buttonDisabled}
          >
            {`${PHRASES_BY_LANG.incorrect[settings.translationLang]}`}
          </Button>
        </Box>
      )

      const sentenceArray = _.compact(_.map(content, 'word'))
      const sentenceString = sentenceArray.join(' ')
      const cardMorphs = grammarReference[sentenceString]
      const results = useMemo(
        () => findGrammarReference(cardMorphs, references, sentenceString),
        [cardMorphs, sentenceString],
      )

      const handleShowGrammarClick = () => {
        setShowGrammar(true)
      }

      return (
        <Card>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-evenly"
            minHeight="25rem"
            position="relative"
            p={3}
          >
            <Box
              display="flex"
              justifyContent="center"
              fontSize={30}
              fontWeight={400}
              minHeight={125}
            >
              <Box alignSelf="flex-end">
                <CttContentComponent
                  wordsArr={sentenceArray}
                  setCttWords={setCttWords}
                  mainWord={word.word}
                  useSpoiler={settings.audioCard}
                  clickedExpand={isTranslationExpanded}
                />
                <TtsButton sentence={sentenceString} alwaysPlay={settings.audioCard} />
              </Box>
            </Box>
            <Box
              minHeight={50}
              fontSize={20}
              fontWeight="fontWeightLight"
              style={isTranslationExpanded ? { visibility: 'visible' } : { visibility: 'hidden' }}
            >
              {`${translation}`}
            </Box>
            {
              notes.map((text) => (
                <Box
                  color="text.secondary"
                  style={(isTranslationExpanded && notes.length) ? { visibility: 'visible' } : { visibility: 'hidden' }}
                >
                  {`${text}`}
                </Box>
              ))
            }
            <Box
              fontSize={12}
              fontWeight="fontWeightLight"
              style={isTranslationExpanded ? { visibility: 'visible' } : { visibility: 'hidden' }}
              pt={2}
            >
              {`${PHRASES_BY_LANG.translateCorrectly[settings.translationLang]}`}
            </Box>
            <Box>{isTranslationExpanded ? correctButtons : primaryButtons}</Box>
            <Box>
              {
                !!results.length && (
                  <Box textAlign="center" mt={3}>
                    {
                      showGrammar ? (
                        <>
                          {
                            results.map((reference) => (
                              <GrammarReference reference={reference} key={reference.name} />
                            ))
                          }
                        </>
                      ) : (<Box onClick={handleShowGrammarClick} color="text.secondary">Show Grammar</Box>)
                    }
                  </Box>
                )
              }
            </Box>
          </Box>
          <Box display="flex" justifyContent="space-between">
            <Box ml={2} display="flex" justifyContent="center">
              <TtsSwitch autoplayTTS={settings.autoplayTTS} />
            </Box>
            <FeedbackButton
              sentence={sentenceString}
              translation={translation}
              moduleId={moduleId}
              username={`${user.attributes.name}-${user.username}`}
            />

          </Box>
          <Dialog
            open={dialogOpenState}
            onClose={() => setDialogOpenState(false)}
          >
            <DialogTitle>{`${PHRASES_BY_LANG.showAnswerPrompt.title[settings.translationLang]}`}</DialogTitle>
            <DialogContent>
              <DialogContentText>
                {`${PHRASES_BY_LANG.showAnswerPrompt.body[settings.translationLang]}`}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => handleClosePrompt(setDialogOpenState, 'showAnswer')} color="secondary" autoFocus>
                {`${PHRASES_BY_LANG.doNotShow[settings.translationLang]}`}
              </Button>
            </DialogActions>
          </Dialog>
        </Card>
      )
    }
    // TODO: Implement favorite video
    function renderYoutubeCardComponent({ content }) {
      return (
        <Card>
          <CardMedia className={classes.media}>
            <YoutubeIframe id={content} />
          </CardMedia>
        </Card>
      )
    }

    function renderWordListCardComponent() {
      const wordArray = []
      // -2 is from the word list/end video card
      const totalWords = allCards.length - 2
      const weakWords = displayCardInds.length - 2
      displayCardInds.forEach((ind) => {
        const individualCard = allCards[ind]
        if (individualCard.type === 'word') {
          wordArray.push({ cardObj: individualCard.word, ind })
        }
      })
      const handleClick = (individualCard, ind) => {
        setVocab((prevState) => {
          updateVocabulary(
            prevState.vocab, prevState.morphs, [individualCard], [WORD_INTERACTIONS.ThumbUp],
          )
          return prevState
        })
        putUserVocabulary(user, _.pick(vocab, encodeWord(individualCard.word)))
        putUserMorphs(user, _.pick(morphs, _.map(individualCard.morphs, encodeWord)))
        setDisplayCardInds((prevState) => {
          const removedInd = prevState.filter((num) => num !== ind)
          return removedInd
        })
      }
      return (
        <Box>
          <Card>
            <Box p={3} maxWidth={700} margin="0 auto">
              <Box fontSize={24} fontWeight={600}>
                Learn the words required to understand the video
              </Box>
              <Box fontSize={12} fontWeight={300}>
                You&apos;ll be reviewing example sentences of unknown words
                <IconButton
                  size="small"
                  onClick={() => setTooltip((prev) => ({ ...prev, wordList: true }))}
                >
                  <HelpOutline />
                </IconButton>

              </Box>
              <Box display="flex" justifyContent="center" alignItems="flex-end" my={4}>
                <Box>
                  <Box fontSize={30} fontWeight={600} color="grey.500">{`${totalWords}`}</Box>
                  <Box fontSize={16} fontWeight={400} color="grey.500">Total Words</Box>
                </Box>
                <Box className={classes.listStats}>
                  <Box fontSize={55} fontWeight={600} color="primary.main">{`${weakWords}`}</Box>
                  <Box fontSize={20} fontWeight={400} color="primary.main">Words to Learn</Box>
                </Box>
                <Box>
                  <Box fontSize={30} fontWeight={600} color="success.light">{`${totalWords - weakWords}`}</Box>
                  <Box fontSize={16} fontWeight={400} color="success.light">Known Words</Box>
                </Box>
              </Box>
              <Button color="secondary" fullWidth size="small" variant="contained" onClick={() => setCardFocusInd((prev) => prev + 1)}>
                {`${PHRASES_BY_LANG.goToModule[settings.translationLang]}`}
              </Button>
              <Box mt={4}>
                <Box fontWeight={400} fontSize={14}>
                  {`${PHRASES_BY_LANG.wordListBody.header[settings.translationLang]}`}
                </Box>
                <Box fontWeight="fontWeightLight" fontSize={12} pb={1}>
                  {`${PHRASES_BY_LANG.wordListBody[1][settings.translationLang]}`}
                  <strong style={{ color: '#5690cc' }}>{`${PHRASES_BY_LANG.wordListBody[2][settings.translationLang]}`}</strong>
                  {`${PHRASES_BY_LANG.wordListBody[3][settings.translationLang]}`}
                </Box>
              </Box>
              <Box className={classes.wordList}>
                <TableContainer>
                  <Table>
                    <TableBody>
                      {wordArray.map(({ cardObj, ind }) => (
                        <TableRow key={ind}>
                          <TableCell component="th" scope="row">
                            <Typography align="center">{removePunctuation(cardObj.word)}</Typography>
                          </TableCell>
                          <TableCell align="center">
                            <Button color="primary" onClick={() => handleClick(cardObj, ind)} size="small" variant="outlined">
                              {`${PHRASES_BY_LANG.iKnowWord[settings.translationLang]}`}
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Box>
          </Card>
          <Dialog
            open={tooltip.wordList}
            onClose={() => setTooltip((prev) => ({ ...prev, wordList: false }))}
          >
            <DialogTitle>Why do you use example sentences to teach vocabulary?</DialogTitle>
            <DialogContent>
              <DialogContentText>
                To prevent you from memorizing multiple definitions for a single word, we use an
                example sentence to illustrate how to use the specific word form that is used in
                the video (with the same conjugation and particles). We find that this improves
                comprehension of the video and helps you develop an intuition for the grammar.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setTooltip((prev) => ({ ...prev, wordList: false }))} color="primary" autoFocus>
                {`${PHRASES_BY_LANG.close[settings.translationLang]}`}
              </Button>
            </DialogActions>
          </Dialog>

        </Box>
      )
    }
  }

  const YoutubeIframe = ({ id }) => (
    <iframe
      title={id}
      className={classes.iframe}
      src={`https://www.youtube.com/embed/${id}?cc_load_policy=3`}
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
    />
  )

  function getCardNumberLine() {
    return `Cards Remaining: ${wordsRemaining}`
  }

  function getKnownWordsPhrase() {
    const knownWordsCount = _.size(
      _.filter(vocab, isKnownWord),
    )
    return `${knownWordsCount} ${PHRASES_BY_LANG.knownWords[settings.translationLang]}`
  }

  const moduleCards = allCards.map(ModuleCard)

  return (
    <Box className={classes.root}>
      <Grid
        container
        direction="column"
        alignItems="center"
        wrap="nowrap"
      >
        <Grid container item justify="space-between">
          <Grid item>
            <Typography className={classes.textHeaders} color="textSecondary" gutterBottom>
              {getCardNumberLine()}
              {tooltip.icon && (
                <IconButton
                  size="small"
                  onClick={() => setTooltip((prev) => ({ ...prev, dialog: true }))}
                >
                  <HelpOutline />
                </IconButton>
              )}
            </Typography>
            <Dialog
              open={tooltip.dialog}
              onClose={() => setTooltip((prev) => ({ ...prev, dialog: false }))}
            >
              <DialogTitle>{`${PHRASES_BY_LANG.numberTooltip.title[settings.translationLang]}`}</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  {`${PHRASES_BY_LANG.numberTooltip.body[settings.translationLang]}`}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setTooltip((prev) => ({ ...prev, dialog: false }))} color="primary" autoFocus>
                  {`${PHRASES_BY_LANG.close[settings.translationLang]}`}
                </Button>
              </DialogActions>
            </Dialog>
          </Grid>
          <Grid item>
            <Typography className={classes.textHeaders} color="textSecondary" gutterBottom>{getKnownWordsPhrase()}</Typography>
          </Grid>
        </Grid>
        <Grid container item style={{ position: 'relative' }}>
          <Grid className={classes.card} item>
            {displayCardInds[cardFocusInd] != null
              ? moduleCards[displayCardInds[cardFocusInd]]
              : null}
          </Grid>
          {
            card && (card.type === 'video') && (
              <RightButton position="side" />
            )
          }
        </Grid>
        <Grid item container wrap="nowrap">
          <Grid item zeroMinWidth xs style={{ width: 100 }}>
            <Link
              href={`https://www.youtube.com/watch?v=${moduleInfo.contentID}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Typography variant="subtitle2" color="textSecondary" noWrap>{moduleInfo.title}</Typography>
            </Link>
          </Grid>
        </Grid>
        <Grid alignItems="center" container item justify="center">
          <Grid item>
            <Box position="relative" display="inline-flex">
              <CircularProgress variant="static" value={getCompletionPercentage(cardsRemaining)} />
              <Box
                top={0}
                left={0}
                bottom={0}
                right={0}
                position="absolute"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Typography
                  variant="caption"
                  component="div"
                  color="textSecondary"
                >
                  {`${getCompletionPercentage(cardsRemaining)}%`}
                </Typography>
              </Box>
            </Box>
          </Grid>
          {
            card && (card.type === 'youtube') && (
              <Grid item className={classes.bottomNavigationButtons}>
                <RightButton />
              </Grid>
            )
          }
        </Grid>
      </Grid>
    </Box>
  )
}

const useStyles = makeStyles((theme) => ({
  card: {
    alignItems: 'center',
    justify: 'center',
    textAlign: 'center',
    width: '100%',
  },
  media: {
    position: 'relative',
    width: '100%',
    height: 0,
    paddingBottom: '56.25%', /* 16:9 */
    paddingTop: 25,
  },
  iframe: {
    position: 'absolute',
    top: 0,
    left: 0,
    border: 0,
    width: '100%',
    height: '100%',
    overflow: 'hidden',
  },
  pos: {
    marginBottom: 12,
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  root: {
    position: 'relative',
    top: 35,
    paddingBottom: '100px',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
    [theme.breakpoints.up('sm')]: {
      width: '90%',
      margin: '0 auto',
    },
    [theme.breakpoints.up('md')]: {
      width: '70%',
      margin: '0 auto',
    },
  },
  grid: {
    width: '100%',
    direction: 'row',
    justify: 'space-between',
    alignItems: 'flex-end',
    alignContent: 'flex-end',
  },
  hoverEffect: {
    '&:hover': {
      backgroundColor: 'rgba(232, 236, 241, 0.9)',
    },
  },

  cardGridItem: {
    [theme.breakpoints.down('xs')]: {
      minHeight: '25rem',
    },
    [theme.breakpoints.up('sm')]: {
      minHeight: '30rem',
    },
    position: 'relative',
  },
  feedbackButton: {
    position: 'absolute',
    bottom: 4,
    right: 4,
    borderRadius: 3,
  },
  feedbackInput: {
    maxWidth: 400,
    padding: 12,
  },
  tutorialImg: {
    maxHeight: 400,
    objectFit: 'contain',
    paddingTop: '2.25rem',
  },
  bottomNavigationButtons: {
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
    },
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  leftArrowSide: {
    position: 'absolute',
    left: -70,
    top: '50%',
    transform: 'translateY(-50%)',
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  rightArrowSide: {
    position: 'absolute',
    right: -70,
    top: '50%',
    transform: 'translateY(-50%)',
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  switchButton: {
    position: 'absolute',
    bottom: 4,
    left: 15,
  },
  textHeaders: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.75rem',
    },
  },
  correctButton: {
    background: '#57b44c',
    color: 'white',
    '&:hover': {
      backgroundColor: '#4b9a42',
    },
    margin: 6,
    width: 137,
  },
  incorrectButton: {
    background: '#df5f6a',
    color: 'white',
    '&:hover': {
      backgroundColor: '#d2202f',
    },
    margin: 6,
    width: 137,
  },
  knownButton: {
    background: '#2d8fd5',
    color: 'white',
    '&:hover': {
      backgroundColor: '#006fbe',
    },
    margin: 6,
    width: 137,
  },
  showAnswerButton: {
    margin: 6,
    width: 137,
  },
  wordList: {
    overflowY: 'auto',
    maxHeight: 400,
  },
  listStats: {
    [theme.breakpoints.down('xl')]: {
      margin: '0px 72px',
    },
    [theme.breakpoints.down('md')]: {
      margin: '0px 32px',
    },
  },
}))

Grammar.propTypes = {
  history: PropTypes.shape({
    action: PropTypes.string,
    block: PropTypes.func,
    createHref: PropTypes.func,
    go: PropTypes.func,
    goBack: PropTypes.func,
    goForward: PropTypes.func,
    length: PropTypes.number,
    listen: PropTypes.func,
    location: PropTypes.object,
    push: PropTypes.func,
    replace: PropTypes.func,
  }),
}
Grammar.defaultProps = {
  history: {},
}

export default withRouter(Grammar)
