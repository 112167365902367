import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { FormControlLabel, Switch } from '@material-ui/core'
import { useGlobalStateContext } from '../../../contexts/GlobalContext/GlobalContextProvider'
import { postAccountSettings } from '../../../lib/apigateway/settings'
import { PHRASES_BY_LANG } from '../../../lib/constants'

const TtsSwitch = ({ autoplayTTS }) => {
  const [isToggled, setIsToggled] = useState(autoplayTTS)

  const context = useGlobalStateContext()
  const { globalState: { user, settings }, setGlobalState } = context

  const handleChange = () => {
    setIsToggled(!isToggled)
    const newSettings = { ...settings, autoplayTTS: !isToggled }
    setGlobalState((prevState) => ({
      ...prevState,
      settings: newSettings,
    }))
    postAccountSettings(user.username, newSettings)
  }

  return (
    <FormControlLabel
      control={<Switch size="small" checked={isToggled} onChange={handleChange} />}
      label={`${PHRASES_BY_LANG.toggleTTS[settings.translationLang]}`}
    />
  )
}

TtsSwitch.propTypes = {
  autoplayTTS: PropTypes.bool,
}
TtsSwitch.defaultProps = {
  autoplayTTS: false,
}

export default TtsSwitch
