import React, { useState } from 'react'
import _ from 'lodash'
import {
  Box,
  Button,
  Card,
  Grid,
  makeStyles,
  TextField,
  Typography,
} from '@material-ui/core'
import { postUserFeedback } from '../../lib/apigateway/feedback'
import { useGlobalStateContext, useVocabContext } from '../../contexts'
import {
  isKnownWord,
  getTimeSpent,
} from '../../lib/util'
import { PHRASES_BY_LANG } from '../../lib/constants'

const GrammarCongratsPage = () => {
  const [feedback, setFeedback] = useState('')
  const { vocab: { vocab } } = useVocabContext()
  const { globalState: { user, settings } } = useGlobalStateContext()
  const classes = useStyles()

  const searchParams = new URLSearchParams(window.location.href.split('congrats')[1])
  const moduleId = searchParams.get('moduleId')
  const sentencesLearned = searchParams.get('sentencesLearned')
  const start = searchParams.get('start')
  const end = searchParams.get('end')

  const handleSubmit = async () => {
    if (feedback) {
      await postUserFeedback(user.username, moduleId, feedback)
    }
    window.location.href = '/#/'
  }

  return (
    <Box>
      <section className={classes.header}>
        <Box fontSize={36} fontWeight="fontWeightBold">Congrats! You have completed this grammar module</Box>
      </section>

      <Card>
        <Box display="flex" p={2} justifyContent="space-around">
          <Box display="flex" flexDirection="column" alignItems="center">
            <Box color="success.light" className={classes.statNumber} fontWeight={500}>{`+ ${sentencesLearned}`}</Box>
            <Box className={classes.statText} fontWeight={400} color="grey.500" textAlign="center">Sentences Learned</Box>
          </Box>
          <Box display="flex" flexDirection="column" alignItems="center">
            <Box color="success.light" className={classes.statNumber} fontWeight={500}>{getTimeSpent(start, end)}</Box>
            <Box className={classes.statText} fontWeight={400} color="grey.500" textAlign="center">Total Time Spent</Box>
          </Box>
          <Box display="flex" flexDirection="column" alignItems="center">
            <Box color="success.light" className={classes.statNumber} fontWeight={500}>{`${_.filter(vocab, isKnownWord).length}`}</Box>
            <Box className={classes.statText} fontWeight={400} color="grey.500" textAlign="center">Total Words Known</Box>
          </Box>
        </Box>
      </Card>

      <Card className={classes.surveyCard}>
        <TextField
          value={feedback}
          className={classes.feedbackInput}
          placeholder="Any feedback to improve your experience? Let us know!"
          fullWidth
          multiline
          rows={3}
          variant="outlined"
          onChange={(e) => setFeedback(e.target.value)}
        />
        <Grid container justify="center">
          <Grid item>
            <Button
              variant="contained"
              size="large"
              color="secondary"
              className={classes.button}
              onClick={handleSubmit}
            >
              {feedback ? 'Submit' : 'Go Home'}
            </Button>
          </Grid>
        </Grid>
      </Card>
      <Typography align="center">
        {`${PHRASES_BY_LANG.feedbackFooter[settings.translationLang]}`}
        <a
          href="https://www.reddit.com/r/siplanguage/"
          rel="noopener noreferrer"
          target="_blank"
        >
          /r/siplanguage
        </a>
      </Typography>
    </Box>
  )
}

const useStyles = makeStyles((theme) => ({
  body: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.5rem',
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: '2.5rem',
    },
  },
  button: {
    marginTop: 12,
  },
  feedbackInput: {
    padding: '12px 0px',
  },
  header: {
    paddingBottom: 24,
  },
  surveyCard: {
    padding: 24,
    margin: '24px 0px',
  },
  slider: {
    marginTop: 36,
  },
  statNumber: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem',
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: '1.7rem',
    },
  },
  statText: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.85rem',
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: '1rem',
    },
  },
}))
export default GrammarCongratsPage
