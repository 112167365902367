import React from 'react'

import PropTypes from 'prop-types'
import { CircularProgress, Grid, Typography } from '@material-ui/core'

const LoadingSpinner = ({ text, showText }) => (
  <Grid container direction="column" alignItems="center">
    <>
      <CircularProgress />
      {showText && <Typography>{`${text}`}</Typography>}
    </>
  </Grid>
)

LoadingSpinner.propTypes = {
  text: PropTypes.string,
  showText: PropTypes.bool,
}
LoadingSpinner.defaultProps = {
  text: 'Loading...',
  showText: true,
}
export default LoadingSpinner
