import AWS from 'aws-sdk'

AWS.config.region = 'us-west-2' // Region
AWS.config.credentials = new AWS.CognitoIdentityCredentials({
  IdentityPoolId: 'us-west-2:cda89f53-b968-4665-afa5-ead409f728c2',
})
const polly = new AWS.Polly({ apiVersion: '2016-06-10' })

// eslint-disable-next-line arrow-body-style
export const getTranslation = async (text, translationLang, moduleLang) => {
  // Restrictued to just Google Translate API and *.siplanguage.com/#/* domains
  const KEY = 'AIzaSyDFzsMvjLIidQKw1XOu7DcZ_6piJFVFRBU'
  const URL = `https://translation.googleapis.com/language/translate/v2?key=${KEY}`
  const body = {
    q: text,
    source: moduleLang,
    target: translationLang,
    format: 'text',
  }

  const response = await fetch(URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  })
  const { data } = await response.json()
  if (!data) {
    throw Error('Error getting translation. Please contact sip@siplanguage.com to resolve this issue.')
  }
  const { translations } = data
  const { translatedText } = translations[0]
  return translatedText
}


export const getTtsBuffer = async (string, moduleLang) => {
  const speechParams = {
    OutputFormat: 'mp3',
    Engine: moduleLang === 'ko' ? 'standard' : 'neural',
    SampleRate: '22050',
    Text: string,
    TextType: 'text',
    VoiceId: moduleLang === 'ko' ? 'Seoyeon' : 'Joanna',
  }

  const result = await polly.synthesizeSpeech(speechParams).promise()
  return new Uint8Array(result.AudioStream)
}
