/* eslint-disable consistent-return */
import _ from 'lodash'
import { API_GATEWAY_URI, DEV_MODE } from '../constants'

const COMPLETIONS_DEFAULTS = {
  completionDates: [],
}

export const COMPLETION_TYPES = {
  DAILY_GOAL: 'DAILY_GOAL',
}

// eslint-disable-next-line import/prefer-default-export
export const postCompletions = async (user, type, data) => {
  if (DEV_MODE) return

  const requestUri = `${API_GATEWAY_URI}users/completions`
  const body = JSON.stringify({
    id: user.username, type, data: JSON.stringify(data),
  })
  const result = await fetch(requestUri, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
    body,
  })
  return result
}

export const getCompletions = async (user, type) => {
  const requestUri = `${API_GATEWAY_URI}users/completions?id=${user.username}&type=${type}`


  const response = await fetch(requestUri, {
    mode: 'cors',
  })
  const results = await response.json()
  const completions = results.Item
    ? _.defaultsDeep(JSON.parse(results.Item.data.S), COMPLETIONS_DEFAULTS)
    : COMPLETIONS_DEFAULTS


  return completions
}
