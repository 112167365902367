import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'
import {
  Box,
} from '@material-ui/core'
import { partitionByProgress } from '../../lib/util'
import GrammarCard from '../GrammarCard'
import ModuleCard from '../ModuleCard'

const GrammarList = ({ modules, completions, progress }) => {
  const [grammarModules, setGrammarModules] = useState([])

  useEffect(() => {
    const init = async () => {
      const beginnerModules = modules.filter(({ series }) => !!series && series.S === 'Beginner Lessons')
      const sorted = partitionByProgress(beginnerModules, completions)
      sorted[progress].sort((a, b) => _.toNumber(a.title.S.split('.')[0]) - _.toNumber(b.title.S.split('.')[0]))
      setGrammarModules(sorted[progress])
    }
    init()
  }, [completions, modules, progress])


  return (
    <Box>
      <Box display="flex" flexWrap="wrap" justifyContent="center">
        {
          grammarModules.map(({
            title, id, length, tags,
          }) => {
            if (tags.SS.includes('Grammar')) {
              return (
                <Box key={id.S} m={1}>
                  <GrammarCard title={title.S} id={id.S} length={length.N} />
                </Box>
              )
            } return (
              <Box key={id.S} m={1}>
                <ModuleCard title={title.S} id={id.S} length={length.N} completions={completions} />
              </Box>
            )
          })
        }
      </Box>
    </Box>
  )
}

GrammarList.propTypes = {
  modules: PropTypes.arrayOf(PropTypes.object),
  progress: PropTypes.string,
  completions: PropTypes.objectOf(PropTypes.object),
}
GrammarList.defaultProps = {
  modules: [],
  progress: 'lorum',
  completions: {},
}

export default GrammarList
