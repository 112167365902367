import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { makeStyles, Popover } from '@material-ui/core'
import { encodeWord, getTranslation, removePunctuation } from '../../../lib/util'
import { getAwsData, postAwsData } from '../../../lib/apigateway/aws'
import { AWS_SERVICE } from '../../../lib/constants'
import { useGlobalStateContext } from '../../../contexts'
import CttDropdown from '../CttDropdown'

const CttContentComponent = ({
  wordsArr, setCttWords, mainWord, useSpoiler, clickedExpand,
}) => {
  const [isHidden, setHidden] = useState(useSpoiler)
  const [anchorEl, setAnchorEl] = useState(null)
  const [clickedWord, setClickedWord] = useState('')
  const [translation, setTranslation] = useState('')

  const classes = useStyles()
  const { globalState: { settings } } = useGlobalStateContext()

  useEffect(() => {
    if (useSpoiler === true && clickedExpand === false) {
      setHidden(true)
    }
    if (clickedExpand === true) {
      setHidden(false)
    }
  }, [useSpoiler, wordsArr, clickedExpand])

  const handleCTT = async (e, index) => {
    if (isHidden) return
    setAnchorEl(e.currentTarget)
    const clicked = removePunctuation(e.target.innerHTML)
    setClickedWord(removePunctuation(clicked))
    setCttWords((cttWords) => {
      // eslint-disable-next-line no-param-reassign
      cttWords[index] = true
      return cttWords
    })

    const dbTranslation = await getAwsData(encodeWord(clicked), AWS_SERVICE.TRANSLATE)
    if (dbTranslation) {
      setTranslation(dbTranslation)
    } else {
      try {
        const results = await getTranslation(clicked, settings.translationLang, settings.moduleLang)
        setTranslation(results)
        await postAwsData(encodeWord(clicked), AWS_SERVICE.TRANSLATE, results)
      } catch (error) {
        setTranslation(error.message)
      }
    }
  }

  const handleClose = () => {
    setAnchorEl(null)
    setClickedWord()
    setTranslation('')
  }

  return (
    <span
      onClick={() => setHidden(false)}
    >
      {
        wordsArr.map((word, index) => (
          <span key={index}>
            <span
              className={useSpoiler ? null : classes.hoverEffect}
              onClick={(e) => handleCTT(e, index)}
              // style={{ borderBottom: removePunctuation(word).toLowerCase() === mainWord && isHidden === false && '1px black solid' }}
            >
              {word}
            </span>
            {index !== (wordsArr.length - 1) && <span>{' '}</span>}
            <Popover
              open={Boolean(anchorEl)}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <CttDropdown
                word={clickedWord}
                translation={translation}
              />
            </Popover>
          </span>
        ))
      }
    </span>
  )
}

const useStyles = makeStyles(() => ({
  hoverEffect: {
    '&:hover': {
      backgroundColor: 'rgba(0,191,255, .35)',
    },
  },
}))

CttContentComponent.propTypes = {
  wordsArr: PropTypes.arrayOf(PropTypes.string),
  setCttWords: PropTypes.func,
  mainWord: PropTypes.string,
  useSpoiler: PropTypes.bool,
  clickedExpand: PropTypes.bool,
}
CttContentComponent.defaultProps = {
  wordsArr: [],
  setCttWords: _.noop(),
  mainWord: 'lorum ipsum',
  useSpoiler: false,
  clickedExpand: false,
}

export default CttContentComponent
