import Amplify from 'aws-amplify'
import React from 'react'
import ReactGA from 'react-ga'
import { createBrowserHistory } from 'history'
import { withAuthenticator } from 'aws-amplify-react'

import awsconfig from '../aws-exports'
import './App.css'
import { getCurrentAuthenticatedUser } from '../lib/auth'

import GlobalContextProvider from '../contexts/GlobalContext/GlobalContextProvider'
import VocabContextProvider from '../contexts/VocabContextProvider'
import SentenceContextProvider from '../contexts/SentenceContextProvider'
import CoreLayout from './CoreLayout'
import RootRouter from '../routes' // or 'aws-amplify-react-native';

const history = createBrowserHistory()
// google analytics tracking (TODO unit tests for non-test env)
// NODE_ENV is automatically set to 'test', 'development' and 'production' for 'npm
// test/start/build' respectively.
// use `npm run serve` to serve production build
if (process.env.NODE_ENV === 'production') {
  const trackingId = 'UA-157265384-2' // TODO secret
  ReactGA.initialize(trackingId)
  // ReactGA set username async
  getCurrentAuthenticatedUser()
    .then((user) => {
      if (user) ReactGA.set({ userId: user.getUsername() })
    })
    .catch(() => { }) // swallow non-critical error; usually means not authenticated
  history.listen((location) => {
    ReactGA.set({ page: location.hash })
    ReactGA.pageview(location.hash)
  })
}

Amplify.configure(awsconfig)
// rename Username/username to Email/email
Amplify.I18n.setLanguage('en')
Amplify.I18n.putVocabularies({
  en: {
    'Sign in to your account': 'Sip Language 계정에 로그인 하세요',
    Username: '이메일',
    'Enter your username': '이메일을 입력해 주세요',
    Password: '비밀번호',
    'Enter your password': '비밀번호를 입력해 주세요',
    'Forget your password? ': '비밀번호를 잊으셨나요? ',
    'Reset password': '비밀번호 재설정',
    'No account? ': '계정이 없으세요? ',
    'Create account': '회원가입',
    'Sign In': '로그인',
    'Reset your password': '비밀번호를 재설정하세요',
    'Back to Sign In': '로그인 페이지로 돌아가기',
    'Send Code': '인증번호 받기',
    'Create a new account': '새로운 계정을 만드세요',
    Email: '이메일',
    Name: '이름',
    'Have an account? ': '이미 계정이 있으신가요? ',
    'Sign in': '로그인',
    'Create Account': '회원가입',
    'Incorrect username or password': '잘못된 이메일 또는 비밀번호',
    'Confirm Sign Up': '회원가입을 확정하세요',
    'Confirmation Code': '인증번호',
    'Enter your code': '인증번호를 입력해 주세요',
    'Lost your code? ': '인증번호를 못 받았나요? ',
    'Resend Code': '새 인증번호 받기',
    Confirm: '확인',
    Code: '인증번호',
    code: '인증번호',
    'New Password': '새 비밀번호',
    Submit: '확인',
  },
})

const App = () => (
  <GlobalContextProvider>
    <VocabContextProvider>
      <SentenceContextProvider>
        <CoreLayout>
          <RootRouter />
        </CoreLayout>
      </SentenceContextProvider>
    </VocabContextProvider>
  </GlobalContextProvider>
)

const signUpConfig = {
  hiddenDefaults: [
    'username',
    'email',
    'phone_number',
    'password',
  ],
  signUpFields: [
    {
      label: 'Email',
      key: 'username',
      required: true,
      displayOrder: 1,
      type: 'string',
    },
    {
      label: 'Password',
      key: 'password',
      required: true,
      displayOrder: 2,
      type: 'password',
    },
    {
      label: 'Name',
      key: 'name',
      required: false,
      displayOrder: 3,
      type: 'string',
    },
  ],
}

export default withAuthenticator(App, { signUpConfig })
