import React from 'react'

import PropTypes from 'prop-types'
import { CssBaseline } from '@material-ui/core'
import SipThemeProvider from '../SipThemeProvider'
import AppContainer from './AppContainer'

const CoreLayout = (props) => {
  const { children } = props
  return (
    <SipThemeProvider>
      <CssBaseline />
      <AppContainer>
        {children}
      </AppContainer>
    </SipThemeProvider>
  )
}

CoreLayout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
  ]),
}

CoreLayout.defaultProps = {
  children: null,
}

export default CoreLayout
