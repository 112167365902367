import { Auth } from 'aws-amplify'

export async function getCurrentAuthenticatedUser() {
  return Auth.currentAuthenticatedUser({
    // Optional, By default is false. If set to true, this call
    // will send a request to Cognito to get the latest user data
    bypassCache: false,
  }).then((user) => user)
    .catch((err) => { throw err })
}

export async function signOutCurrentAuthenticatedUser() {
  try {
    await Auth.signOut()
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log('error signing out: ', error)
  }
}
