import _ from 'lodash'
import { API_GATEWAY_URI, DEFAULT_ACCOUNT_SETTINGS } from '../constants'

export const postAccountSettings = async (user, settings) => {
  _.defaults(settings, DEFAULT_ACCOUNT_SETTINGS)
  const requestUri = `${API_GATEWAY_URI}users/settings?id=${user}`
  // Must be {data: ...} based off API Gateway mapping template
  const body = JSON.stringify({ data: settings })
  const response = await fetch(requestUri, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
    body,
  })
  const results = await response.json()
  return results
}

export const getAccountSettings = async (user) => {
  const requestUri = `${API_GATEWAY_URI}users/settings?id=${user}`
  const response = await fetch(requestUri, {
    mode: 'cors',
  })
  const results = await response.json()
  const settings = results.Item
    ? _.defaultsDeep(JSON.parse(results.Item.settings.S), DEFAULT_ACCOUNT_SETTINGS)
    : DEFAULT_ACCOUNT_SETTINGS
  return settings
}
