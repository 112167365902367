import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import {
  clear, setMany,
  values,
} from 'idb-keyval'
import {
  Box,
  Chip,
  FormControl,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
} from '@material-ui/core'
import { getCachedModuleInfo, MODULE_LOCAL_STORAGE_KEY, parseModuleId } from '../../lib/util'
import { getModules } from '../../lib/apigateway/modules'
import { getTrackComplete } from '../../lib/apigateway/trackcomplete'
import { useGlobalStateContext } from '../../contexts/GlobalContext/GlobalContextProvider'
import { PHRASES_BY_LANG, MODULE_DIFFICULTIES } from '../../lib/constants'
import VideoList from '../../components/VideoList'
import ContinueWatchingList from '../../components/ContinueWatchingList'
import SeriesList from '../../components/SeriesList'
import GrammarList from '../../components/GrammarList/GrammarList'

export const DIFFICULTY = {
  beginner: 'Beginner',
  intermediate: 'Intermediate',
  advanced: 'Advanced',
  continue: 'Continue Watching',
}


const Learn = () => {
  const [modules, setModules] = useState([])
  const [progress, setProgress] = useState({ progress: 'incomplete', disabled: true })
  const [difficulty, setDifficulty] = useState('')
  const [continueList, setContinueList] = useState([])
  const [completions, setCompletions] = useState()
  const [series, setSeries] = useState(null)
  const { globalState: { user, settings } } = useGlobalStateContext()
  const classes = useStyles()
  useEffect(() => {
    const getAllModules = async () => {
      if (!('indexedDB' in window)) {
        // eslint-disable-next-line no-console
        console.log('IDB not supported')
        const allModules = await getModules(settings.moduleLang, settings.translationLang)
        setModules(allModules)
      } else {
        // Use cached version of modules first
        const trackedCompletions = await getTrackComplete(user)
        const cachedModules = await values()
        setModules(cachedModules)
        setCompletions(trackedCompletions)

        // Update cache for next pageload
        const allModules = await getModules(settings.moduleLang, settings.translationLang)
        if (!cachedModules.length) {
          setModules(allModules)
        }
        await clear()
        const modulesToSet = allModules.map((module) => ([module.id.S, module]))
        await setMany(modulesToSet)
      }
    }
    getAllModules()
  }, [settings.moduleLang, settings.translationLang, user])

  /**
   * Get continue watching list by first sorting all the modules into their respective series
   * Then loop module completions and count the number of completed modules.
   * If the number of completed modules != the total number of modules in a series,
   * it is considered inProgress
  */

  useEffect(() => {
    if (completions && modules.length) {
      const cache = getCachedModuleInfo()

      const sortedModules = modules.reduce((acc, module) => {
        if ((!module.series || !module.series.S) && !acc.Other) {
          acc.Other = [module]
          return acc
        }
        if (!module.series || !module.series.S) {
          acc.Other.push(module)
          return acc
        }
        if (!acc[module.series.S]) {
          acc[module.series.S] = [module]
          return acc
        }
        acc[module.series.S].push(module)
        return acc
      }, {})
      const inProgressArr = {}
      modules.forEach((module) => {
        const [moduleDiff] = _.intersection(MODULE_DIFFICULTIES, module.tags.SS)
        if (completions[module.id.S] && (completions[module.id.S].completionPercentage !== 100)) {
          if (!module.series && !inProgressArr.Other) {
            inProgressArr.Other = { id: null, difficulty: DIFFICULTY.continue, completionCount: 0 }
          } else if (!module.series || !inProgressArr[module.series.S]) {
            inProgressArr[module.series.S] = {
              id: module.id.S.slice(8),
              difficulty: moduleDiff,
              completionCount: 0,
            }
          }
        } else if (completions[module.id.S]) { // If completion === 1000
          if (!module.series && !inProgressArr.Other) {
            inProgressArr.Other = { id: null, difficulty: DIFFICULTY.continue, completionCount: 1 }
          } else if (!module.series) {
            inProgressArr.Other.completionCount += 1
          } else if (!inProgressArr[module.series.S]) {
            inProgressArr[module.series.S] = {
              id: parseModuleId(module.id.S).videoId,
              difficulty: moduleDiff,
              completionCount: 1,
            }
          } else {
            inProgressArr[module.series.S].completionCount += 1
          }
        }
      })

      const continueListArr = []
      // eslint-disable-next-line no-shadow
      _.forEach(inProgressArr, ({ id, difficulty, completionCount }, key) => {
        if (sortedModules[key].length !== completionCount) {
          continueListArr.push({
            title: key,
            youtubeId: id,
            difficulty,
          })
        }
      })
      setContinueList(continueListArr)

      // When user completes a module, this goes to the corresponding list when going back home
      if (cache.series) {
        setSeries(cache.series)
        setDifficulty(cache.difficulty)
        setProgress((prev) => ({ ...prev, disabled: false }))
        localStorage.removeItem(MODULE_LOCAL_STORAGE_KEY)
      } else {
        let userDifficulty = DIFFICULTY.beginner
        if (settings.questionnaire && settings.questionnaire.skill === 'intermediate') {
          userDifficulty = DIFFICULTY.intermediate
        } else if (settings.questionnaire && settings.questionnaire.skill === 'advanced') {
          userDifficulty = DIFFICULTY.advanced
        }

        setDifficulty(continueListArr.length ? 'Continue Watching' : userDifficulty)
        setProgress((prev) => ({ ...prev, disabled: !!continueListArr.length }))
      }
    }
  }, [modules, completions, settings.questionnaire])

  const handleContinueWatching = () => {
    setDifficulty(DIFFICULTY.continue)
    setSeries(null)
    setProgress((prev) => ({ ...prev, disabled: true }))
  }
  const handleDifficulty = (diff) => {
    setDifficulty(diff)
    setSeries(null)
    setProgress((prev) => ({ ...prev, disabled: false }))
  }

  const showContent = () => {
    if (difficulty === DIFFICULTY.continue && series === null) {
      return (
        <ContinueWatchingList
          series={continueList}
          setSeries={setSeries}
          setDifficulty={setDifficulty}
          setProgress={setProgress}
        />
      )
    }
    if (series) {
      return (
        <SeriesList
          modules={modules}
          title={series}
          progress={progress.progress}
          completions={completions}
          difficulty={difficulty}
        />
      )
    }
    if (difficulty === DIFFICULTY.beginner) {
      return (
        <GrammarList
          modules={modules}
          completions={completions}
          progress={progress.progress}
        />
      )
    }
    return (
      <VideoList
        modules={modules}
        progress={progress.progress}
        difficulty={difficulty}
        completions={completions}
        setSeries={setSeries}
      />
    )
  }

  const showDescription = () => {
    if (difficulty === DIFFICULTY.continue) {
      return 'Resume the series that you have started on'
    }
    if (difficulty === DIFFICULTY.beginner) {
      return 'Beginners will learn hangul (Korean alphabet) and Korean grammar'
    }
    if (difficulty === DIFFICULTY.intermediate) {
      return 'Intermediates will acquire new vocabulary while practicing listening'
    }
    return 'Advanced learners will watch content made for native speakers'
  }

  return (
    <main>
      <section className={classes.header}>
        <Box fontSize={36} fontWeight="fontWeightBold">Select a Video to Learn From</Box>
        <Box fontWeight="fontWeightLight">{showDescription()}</Box>
      </section>
      <Box className={classes.selectors}>
        {!!continueList.length && (
          <Chip clickable={false} label={DIFFICULTY.continue} className={difficulty === DIFFICULTY.continue ? classes.selected : null} onClick={handleContinueWatching} style={{ cursor: 'pointer' }} />
        )}
        <Chip clickable={false} label={DIFFICULTY.beginner} onClick={() => handleDifficulty(DIFFICULTY.beginner)} className={difficulty === DIFFICULTY.beginner ? classes.selected : null} style={{ cursor: 'pointer' }} />
        <Chip clickable={false} label={DIFFICULTY.intermediate} onClick={() => handleDifficulty(DIFFICULTY.intermediate)} className={difficulty === DIFFICULTY.intermediate ? classes.selected : null} style={{ cursor: 'pointer' }} />
        <Chip clickable={false} label={DIFFICULTY.advanced} onClick={() => handleDifficulty(DIFFICULTY.advanced)} className={difficulty === DIFFICULTY.advanced ? classes.selected : null} style={{ cursor: 'pointer' }} />
        <FormControl style={{ visibility: progress.disabled ? 'hidden' : 'visible' }} className={classes.progressDropdown}>
          <InputLabel>{`${PHRASES_BY_LANG.progress[settings.translationLang]}`}</InputLabel>
          <Select
            id="progressSelect"
            value={progress.progress}
            onChange={
              (e) => setProgress((prevState) => ({ ...prevState, progress: e.target.value }))
            }
            name="progress"
          >
            <MenuItem value="all">{`${PHRASES_BY_LANG.all[settings.translationLang]}`}</MenuItem>
            <MenuItem value="completed">{`${PHRASES_BY_LANG.complete[settings.translationLang]}`}</MenuItem>
            <MenuItem value="incomplete">Incomplete</MenuItem>
          </Select>
        </FormControl>
      </Box>
      { showContent()}
    </main>
  )
}
const useStyles = makeStyles((theme) => ({
  header: {
    paddingBottom: 24,
  },
  selected: {
    backgroundColor: theme.palette.secondary.main,
    color: 'white',
  },
  selectors: {
    display: 'flex',
    flexWrap: 'wrap',
    [theme.breakpoints.down('xs')]: {
      alignItems: 'flex-start',
      flexDirection: 'column',
      '& > div': {
        width: '100%',
      },
    },

    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      alignItems: 'flex-end',
    },
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
  progressDropdown: {
    marginLeft: 12,
  },
}))
export default Learn
