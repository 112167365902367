export const DEV_MODE = false // Don't track any interactions
export const OFF_WHITE = '#fafafa' // can't find this in the theme, but it's the default bgcolor
export const RESURFACE_CARD_LENGTH = 60
export const MILLISECONDS_IN_DAY = 86400000
export const MODULES_PER_PAGE = 18
export const DEFAULT_DROPDOWN_STATE = {
  progress: 'notStarted', tags: [], size: 'all', series: '',
}
export const MODULE_DIFFICULTIES = ['Beginner', 'Intermediate', 'Advanced']
export const SENTENCE_INTERVAL_LOWER_BOUND = 0.1
export const API_GATEWAY_URI = 'https://n1hlix1dp9.execute-api.us-west-2.amazonaws.com/prod/'
export const AWS_BACKEND_URL = 'ec2-35-167-157-221.us-west-2.compute.amazonaws.com'
export const DEFAULT_ACCOUNT_SETTINGS = {
  moduleLang: 'en',
  translationLang: 'ko',
  autoplayTTS: true,
  audioCard: false,
  prompts: {
    showAnswer: true,
  },
  ttsSpeed: 'normal',
}
export const COMPREHENSION_DEFAULTS = {
  comprehension: null,
  moduleLength: null, // Tracks ALL cards including any spliced cards
  numCardsSeen: null, // Tracks ALL cards including any spliced cards
}
export const TTS_INFO = {
  ko: {
    lang: 'ko-KR',
    voices: ['Google 한국의', 'Yuna'], // [Chrome, Safari] voice
  },
  en: {
    lang: 'en-US',
    voices: ['Google US English', 'English United States', 'Samantha'],
  },
}
export const FULL_LANG = {
  ko: 'korean',
  en: 'english',
}
export const NEW_SENTENCE_DEFAULTS = {
  interactions: [],
  interactionCount: 0,
  interval: 1,
  interactionDates: [],
  source: null,
}
export const NEW_STREAK = {
  totalReviewCompletions: 0,
  completionDates: [],
  streak: 0,
}
export const PHRASES_BY_LANG = {
  beginner: { en: 'Beginner', ko: '초급' },
  intermediate: { en: 'Intermediate', ko: '중급' },
  review: { en: 'Review', ko: '복습' },
  settings: { en: 'Settings', ko: '설정' },
  signout: { en: 'Sign Out', ko: '로그아웃' },
  hello: { en: 'Hello,', ko: '안녕하세요' },
  knownWords: { en: 'Known Words', ko: '총 아는 단어' },
  modulesCompleted: { en: 'Modules Completed', ko: '완료된 모듈' },
  reviewsCompleted: { en: 'Reviews Completed', ko: '완료된 복습' },
  cardsDue: { en: 'Review Cards Due', ko: '해야 할 복습 카드' },
  streak: { en: 'Day Streak', ko: '일 스트리크' },
  streakDetail: {
    en: 'Complete a module or review to keep your streak alive!',
    ko: '매일 모듈 또는 복습을 완료해서 스트리크를 유지하세요!',
  },
  resources: { en: 'Additional Resources', ko: '추가 Resources' },
  discord: {
    en: 'Sip Discord | Ask us questions', ko: 'Sip Discord | 영어에 관련된 질문을 물어보세요',
  },
  reddit: {
    en: 'Sip Reddit | Please leave us feedback', ko: 'Sip Reddit | 피드백을 남겨주세요',
  },
  aquisition: {
    en: 'Acquisition | Our language learning philosophy', ko: '습득 | 우리의 언어 습득 철학',
  },
  beginnerModule: {
    en: 'Beginner Modules', ko: '초급 모듈',
  },
  intermediateModule: {
    en: 'Intermediate Modules', ko: '중급 모듈',
  },
  intermediateDetail: {
    en: "These modules don't use dialogue straight from the video",
    ko: '영상의 대사를 그대로 학습하는 것은 아닙니다',
  },
  intermediateWhy: {
    en: 'Want to know why?', ko: '왜 이러는지 알고 싶다면?',
  },
  progress: { en: 'Progress', ko: '진행' },
  sizeModule: { en: 'Size of Module', ko: '카드 갯수' },
  category: { en: 'Category', ko: '카테고리' },
  complete: { en: 'Complete', ko: '완료' },
  inProgress: { en: 'In Progress', ko: '진행중' },
  all: { en: 'All', ko: '전체' },
  notStarted: { en: 'Not Started', ko: '시작' },
  reviewHeader: { en: 'Review your weak sentences', ko: '약한 문장을 복습하세요' },
  reviewBody1: {
    en: 'When you press start, you will review sentences that you clicked "Review Again" on.',
    ko: '시작을 누르면 "다시 보기" 버튼을 눌렀던 문장을 복습하게 됩니다.',
  },
  reviewBody2: {
    en: 'We use a spaced repetition system (SRS) to prompt you to practice material right before you forget it. ',
    ko: '또한 문장을 잊기 직전에 다시 복습할 수 있도록 간격 반복 시스템을 (Spaced Repetition System) 활용합니다.',
  },
  reviewBody3: {
    en: 'Review modules are generated to show you difficult cards more often while showing easier cards less frequently.',
    ko: '유저의 어려운 문장을 자주 보여주고 쉬운 문장을 적게 보여주는 복습 모듈이 매번 생성됩니다.',
  },
  reviewBody4: {
    en: 'We recommend you visit this page everyday to retain vocabulary and grammar structures.',
    ko: '어휘와 문법 구조를 꾸준히 유지하기 위해 이 페이지를 매일 방문하는 것을 추천해 드립니다.',
  },
  start: { en: 'Start', ko: '시작' },
  reviewWords: { en: 'Weak Words', ko: '복습해야 할 단어' },
  autoplayTTS: { en: 'Autoplay TTS', ko: '텍스트 음성 자동으로 켜기' },
  audioCard: { en: 'Audio Card', ko: '오디오 카드' },
  save: { en: 'Save', ko: '저장' },
  saved: { en: 'Saved', ko: '저장' },
  cardsHidden: {
    title: { en: ' Cards Hidden', ko: ' 제외된 카드' },
    body1: { en: 'Congratulations! You already know ', ko: '축하합니다! 이 모듈에서 이미 ' },
    body2: { en: ' words from this module. You have ', ko: ' 단어를 알고 있습니다. 나머지 ' },
    body3: { en: ' words left to learn.', ko: ' 단어를 배우셔야 합니다.' },
  },
  wordListTitle:
  {
    en: 'This module is designed to help you understand the video.',
    ko: '이 모듈은 동영상을 이해하는 데 도움이 되도록 만들어졌습니다.',
  },
  showTranslation: { en: 'Show Answer', ko: '번역 보기' },
  understand: { en: 'I Know This', ko: '이해해요' },
  reviewAgain: { en: 'Review Again', ko: '다시 보기' },
  badSentence: { en: 'Bad Sentence', ko: '틀린 영어 문장' },
  badTranslation: { en: 'Bad Translation', ko: '틀린 한국어 번역' },
  tooHard: { en: 'Too Hard', ko: '너무 어려운 문장' },
  toggleTTS: { en: 'Autoplay text-to-speech', ko: '텍스트 음성 변환기 자동 재생' },
  seeMoreSentences: { en: 'See more sentences', ko: '더 많은 문장 보기' },
  pickPercentage: { en: 'Pick a percentage to submit score and go back', ko: '몇 퍼센트인지 고르고 확인 버튼을 눌러 주세요' },
  feedbackFooter: { en: 'Have some feedback? Visit us on reddit at ', ko: '피드백 있으세요? 레딧을 방문해 주세요 ' },
  buildSentences: {
    en: 'Do some modules first to build up your weak sentences and then come back!',
    ko: '복습할 약한 문장을 만들기 위해 먼저 모듈을 완료하세요!',
  },
  noSentences: {
    en: "If you are seeing this, congrats! You don't have any more weak sentences. Do more modules and come back a later time.",
    ko: '이 문자가 보이면 오늘 복습할 약한 문장이 더 이상 없다는 표시입니다. 모듈을 더 하고 돌아오세요!',
  },
  word: { en: 'Word', ko: '단어' },
  sentence: { en: 'Sentence', ko: '문장' },
  translation: { en: 'Translation', ko: '번역' },
  weakWords: { en: 'Your Weak Words', ko: '너의 나약한 말' },
  correct: { en: 'Correct', ko: '정답' },
  incorrect: { en: 'Incorrect', ko: '오답' },
  showAnswerPrompt: {
    title: { en: 'Did you know?', ko: '알고 계셨나요?' },
    body: {
      en: 'You can also click individual words within the sentence to look up its definition',
      ko: '개별 단어를 클릭하면 뜻을 볼 수 있어요.',
    },
  },
  doNotShow: { en: 'Do Not Show Again', ko: '다시 보지 않기' },
  numberTooltip: {
    title: { en: "Why Didn't the Number Go Down?", ko: '왜 숫자가 줄어들지 않나요?' },
    body: {
      en: 'When you click "Incorrect", we will continually resurface the cards to help you learn your weak words.',
      ko: '"오답"을 클릭하면 약한 단어를 익힐 수 있도록 카드를 계속 보여줍니다.',
    },
  },
  listTooltip: {
    title: { en: 'What is this list?', ko: '이 목록은 뭐예요?' },
    body: {
      en: 'This list is created from the cards you pressed "Incorrect" on. You can use this list to refer to your weak words while you rewatch the video and help you cement your understanding of your weak words. Click on the blurred sentence to see the translation',
      ko: 'This list is created from the cards you pressed "Incorrect" on. You can use this list to refer to your weak words while you rewatch the video and help you cement your understanding of your weak words.',
    },
  },
  close: { en: 'Close', ko: '닫기' },
  translateCorrectly: { en: 'Did you translate this sentence correctly?', ko: '문장을 정확하게 번역했나요?' },
  endVideoPrompt: {
    en: "Try watching the video without subtitles or with Korean subtitles to see how much you've learned.",
    ko: '얼마나 배웠는지 보기 위해 자막 없이 또는 영어 자막을 틀어서 동영상을 시청하세요.',
  },
  faq: { en: 'Frequently Asked Questions', ko: '자주 묻는 질문' },
  faqList: [
    {
      q: {
        en: 'How does this whole system work?',
        ko: 'Sip은 뭐예요? 어떻게 사용하나요?',
      },
      a: {
        en: 'Sip helps you acquire Korean through YouTube videos. First, you select a video you want to watch. Second, we teach you how to use each word that appears in the video. Finally, you can enjoy the video without subtitles! ',
        ko: 'Sip은 유튜브 동영상을 보면서 영어를 자연스럽게 습득하는 것을 도와주는 웹앱입니다. 먼저 시청할 동영상을 선택하고 동영상에 나타나는 각 단어를 문장에 적용하는 법을 학습합니다. 그 후 자막 없이 동영상을 즐기면 됩니다.',
      },
    },
    {
      q: {
        en: 'Why do you use example sentences to teach vocabulary?',
        ko: '어휘를 가르치는 데 왜 예문을 활용하세요?',
      },
      a: {
        en: 'To prevent you from memorizing multiple definitions for a single word, we use an example sentence to illustrate how to use the specific word form that is used in the video (with the same conjugation and particles). We find that this improves comprehension of the video and helps you develop an intuition for the grammar.',
        ko: '한 단어의 여러 정의를 암기하는 것을 방지하기 위해 예문을 통해서 동영상에 나오는 특정 단어 양식을 사용하는 방법을 알려드립니다. 이 방법이 이해력을 향상시키고 문법에 대한 직관력을 발달시키는 데 도움을 준다는 것을 발견했습니다.',
      },
    },
    {
      q: {
        en: 'Why aren’t my “Cards Remaining” decreasing?',
        ko: '왜 “학습해야 할 단어”가 줄지 않나요?',
      },
      a: {
        en: 'The number of cards you have left only decreases if you press “I Know It" or "Correct.” We keep testing you on the same flashcard to ensure that you fully understand the sentence.',
        ko: '“정답을 알아요"를 누를 때만 모듈의 카드 수가 줄어듭니다. “답변 보기"를 누르면 문장을 완전히 이해했는지 확인하기 위해 같은 카드를 계속 보게 됩니다.',
      },
    },
    {
      q: {
        en: 'How do you track known words?',
        ko: '아는 단어를 어떻게 기록하나요?',
      },
      a: {
        en: 'We track which words you know and don’t know based on whether you click “I KNOW THIS”, “CORRECT”, or “INCORRECT.” Note: words that are conjugated differently will be considered different words. For example, the word, 사랑해, will be counted as one word and the word, 사랑했어, will be counted as another.',
        ko: '“아는 단어와 모르는 단어는 모듈 시작하기 전에 누르는 [알아요], [몰라요] 버튼과 모듈에서 누르는 [정답을 알아요], [정답 보기], [정답], [오답] 버튼을 활용한 알고리즘을 통해 기록합니다.',
      },
    },
    {
      q: {
        en: 'Why are some words underlined?',
        ko: '단어에 왜 밑줄이 그어져 있나요?',
      },
      a: {
        en: 'Each module helps you understand the corresponding video by teaching you relevant vocabulary. We teach this vocabulary in the context of a sentence to help you to develop an intuition for the grammar and improve retention of the word. The underlining helps you distinguish which word appears in the video.',
        ko: '밑줄이 그어진 단어는 해당 동영상에 나타나는 단어입니다. 각 모듈은 해당 동영상을 이해할 수 있도록 동영상에 나오는 단어를 가르칩니다. 다만 단어의 뜻만 가르치지 않고 예문을 통해서 문법에 대한 직관을 발달시키고 배운 단어를 기억하는 것을 돕도록 구성돼있습니다.',
      },
    },
    {
      q: {
        en: 'How can I look up definitions for individual words in the sentence?',
        ko: '개별 단어의 뜻을 알고 싶으면 어떻게 하나요?',
      },
      a: {
        en: 'You can click on any individual word to search for its definition in Naver dictionary.',
        ko: '개별 단어를 누르면 단어가 미리 검색된 네이버 사전 창이 뜹니다.',
      },
    },
    {
      q: {
        en: 'How does the review section work? What is SRS?',
        ko: '“복습"이 뭐예요? SRS는 뭐예요?',
      },
      a: {
        en: 'We automatically add your weak sentences to the review section. This section will function similar to Anki or any other SRS (spaced repetition system). The SRS algorithm will identify when you are about to forget the flashcards and test you before that happens. Numerous studies have shown that this drastically improves retention, particularly if you review everyday.',
        ko: '“복습"은 모듈 외로 약한 문장을 다시 연습할 수 있도록 구성됐습니다. 모듈에서 학습한 약한 문장들이 “복습" 자료에 자동으로 추가됩니다. “복습"은 Anki 또는 다른 SRS(간격 반복 시스템)와 유사하게 작동합니다. SRS 알고리즘은 학습한 문장/카드를 잊어버릴 때를 파악하고 그 전에 단어를 잊지 않도록 학습할 수 있게 테스트합니다. 많은 연구에서 복습을 매일 할 경우 기억이 오랫동안 유지된다는 사실이 밝혀졌습니다.',
      },
    },
  ],
  series: { en: 'Series', ko: '시리즈' },
}
export const MAX_SRS_INTERVAL = 50
export const AWS_SERVICE = {
  TRANSLATE: 'TRANSLATE',
  POLLY: 'POLLY',
}
export const DEFAULT_REVIEW_CHUNK_LENGTH = 50
export const MODULE_REFERENCE_TYPE = {
  GRAMMAR: 'grammar',
}
export const VIDEO_TYPES = {
  YOUTUBE: 'youtube',
  VLIVE: 'vlive',
}
export const PROGRESS_DEFAULTS = {
  displayCardInds: [],
  cardFocusInd: 0,
  wordsRemaining: 0,
  review: [],
}
export const TIMESTAMP_QUERY = '~t'
export const ALL_SENTENCES = 'all'
export const TRACKING_DEFAULTS = {
  completedSessionDailyGoal: false,
  numCardsSeen: 0,
  moduleId: null,
}
