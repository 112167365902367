import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'
import {
  Box,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
  Tooltip,
  IconButton,
} from '@material-ui/core'
import {
  Close,
} from '@material-ui/icons'
import {
  WORD_INTERACTIONS,
  setSentenceProps,
  decodeWord,
  encodeWord,
  updateForInteraction,
  calculateNewInterval,
} from '../../../lib/util'
import { putUserSentence, deleteSentence } from '../../../lib/apigateway/weakSentence'
import { putUserVocabulary, NEW_WORD_DEFAULTS } from '../../../lib/apigateway/vocabulary'
import { useSentencesContext, useGlobalStateContext, useVocabContext } from '../../../contexts'
import { MAX_SRS_INTERVAL, PHRASES_BY_LANG } from '../../../lib/constants'
import TtsButton from '../../../components/UI-Components/TtsButton'
import TtsSwitch from '../../../components/UI-Components/TtsSwitch'
import CttContentComponent from '../../../components/UI-Components/CttContentComponent'

const updateVocabulary = (vocabMap, wordsArr, interactions) => {
  const encodedWordsArr = _.map(wordsArr, encodeWord)

  encodedWordsArr.forEach((word, idx) => {
    const currentInteraction = interactions[idx]
    const wordProps = _.defaultsDeep(vocabMap[word], NEW_WORD_DEFAULTS) // new word
    updateForInteraction(wordProps, currentInteraction)

    _.assign(vocabMap, { [word]: wordProps })
  })

  return vocabMap
}

const ReviewCardComponent = ({
  unknownSentences,
  setUnknownSentences,
  cardInd,
  setCardInd,
  setIsComplete,
  setStats,
}) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const [cttWords, setCttWords] = useState([])
  const [isOpen, setOpen] = useState(false)

  const { globalState: { user, settings } } = useGlobalStateContext()
  const { vocab: { vocab }, setVocab } = useVocabContext()
  const { sentences, setSentences } = useSentencesContext()
  const classes = useStyle()
  const { sentence, props: sentenceProps } = unknownSentences[cardInd]
  const { interval } = sentenceProps
  const sentenceObj = JSON.parse(decodeWord(sentence))
  const wordsArr = _.compact(sentenceObj[settings.moduleLang].split(' '))

  useEffect(() => {
    const currSentence = JSON.parse(decodeWord(unknownSentences[cardInd].sentence))
    if (currSentence) {
      const sentenceArr = _.compact(currSentence[settings.moduleLang].split(' '))
      setCttWords(sentenceArr.map(() => false))
    }
  }, [cardInd, settings.moduleLang, unknownSentences])

  /**
   *  Runs on last card. Remove known (thumbs up) cards from the review module
   */
  const handleLastCard = () => {
    const unknown = unknownSentences.filter((obj) => _.last(obj.props.interactions)
      === WORD_INTERACTIONS.ThumbDown)
    if (unknown.length === 0) {
      setIsComplete(true)
    } else {
      setUnknownSentences(unknown)
      setCardInd(0)
    }
  }

  const handleInteraction = (interaction) => {
    let realInteraction = interaction
    if (interaction === WORD_INTERACTIONS.ThumbDown) {
      realInteraction = WORD_INTERACTIONS.ThumbDown
    } else if (isExpanded === true) {
      realInteraction = WORD_INTERACTIONS.Translate
    } else {
      realInteraction = WORD_INTERACTIONS.ThumbUp
    }
    const updatedProps = setSentenceProps(sentences, realInteraction, sentence)
    if (realInteraction === WORD_INTERACTIONS.ThumbDown) {
      setStats((prev) => ({ ...prev, review: [...prev.review, sentenceObj] }))
    }
    if (updatedProps.interval >= MAX_SRS_INTERVAL) {
      handleDeleteSentence()
    } else {
      putUserSentence(user, sentence, updatedProps)
      setSentences((prevState) => ({ ...prevState, [sentence]: updatedProps }))
      _.assign(unknownSentences[cardInd].props, updatedProps)
      setUnknownSentences(unknownSentences)
      setIsExpanded(false)
      if (cardInd === unknownSentences.length - 1) {
        handleLastCard()
      } else {
        setCardInd((prevState) => prevState + 1)
      }
    }
    const updatedVocab = updateVocab(interaction, isExpanded, cttWords)
    const encodeWordsArr = _.map(wordsArr, encodeWord)
    putUserVocabulary(user, _.pick(updatedVocab, encodeWordsArr))
  }

  const handleCloseClick = () => {
    setOpen(true)
  }

  const handleDeleteSentence = async () => {
    await deleteSentence(user, sentence)
    if (cardInd === unknownSentences.length - 1) {
      const updatedProps = setSentenceProps(sentences, WORD_INTERACTIONS.ThumbUp, sentence)
      setSentences((prevState) => ({ ...prevState, [sentence]: updatedProps }))
      handleLastCard()
    } else {
      // Delete card from sentence array
      setUnknownSentences((prevState) => {
        const clone = _.cloneDeep(prevState)
        clone.splice(cardInd, 1)
        return clone
      })
      setSentences((prevState) => {
        const state = prevState
        delete state[sentence]
        return state
      })
      setOpen(false)
      setIsExpanded(false)
    }
  }

  function updateVocab(interaction, translationExpanded, cttArr) {
    const interactions = cttArr.map((clickedToTranslate) => {
      if (interaction === WORD_INTERACTIONS.ThumbDown) {
        return WORD_INTERACTIONS.ThumbDown
      }
      if (translationExpanded) {
        return clickedToTranslate
          ? WORD_INTERACTIONS.TranslateSingleAndAll
          : WORD_INTERACTIONS.Translate
      }
      return clickedToTranslate
        ? WORD_INTERACTIONS.TranslateSingleOnly
        : WORD_INTERACTIONS.ThumbUp
    })
    const newVocab = updateVocabulary(vocab, wordsArr, interactions)
    setVocab((prev) => ({ ...prev, vocab, newVocab }))
    return newVocab
  }

  const primaryButtons = (
    <Box display="flex" justifyContent="center" flexWrap="wrap">
      <Box>
        <Button
          className={classes.knownButton}
          disableElevation
          onClick={() => handleInteraction(WORD_INTERACTIONS.ThumbUp)}
          variant="contained"
        >
          {`${PHRASES_BY_LANG.understand[settings.translationLang]}`}
        </Button>
        <Box textAlign="center" fontSize={12} color="text.secondary">{`(${calculateNewInterval(interval, WORD_INTERACTIONS.ThumbUp)} 일)`}</Box>
      </Box>
      <Box>
        <Button
          className={classes.showAnswerButton}
          disableElevation
          onClick={() => {
            setIsExpanded(true)
          }}
          variant="contained"
        >
          {`${PHRASES_BY_LANG.showTranslation[settings.translationLang]}`}
        </Button>

      </Box>
    </Box>
  )
  const correctButtons = (
    <Box display="flex" justifyContent="center" flexWrap="wrap">
      <Box>
        <Button
          className={classes.correctButton}
          disableElevation
          onClick={() => handleInteraction(WORD_INTERACTIONS.ThumbUp)}
          variant="contained"
        >
          {`${PHRASES_BY_LANG.correct[settings.translationLang]}`}
        </Button>
        <Box textAlign="center" fontSize={12} color="text.secondary">{`(${calculateNewInterval(interval, WORD_INTERACTIONS.Translate)} 일)`}</Box>
      </Box>
      <Box>
        <Button
          className={classes.incorrectButton}
          disableElevation
          onClick={() => handleInteraction(WORD_INTERACTIONS.ThumbDown)}
          variant="contained"
        >
          {`${PHRASES_BY_LANG.incorrect[settings.translationLang]}`}
        </Button>
        <Box textAlign="center" fontSize={12} color="text.secondary">(1분)</Box>
      </Box>
    </Box>
  )

  return (
    <>
      <Card>
        <Tooltip title="Remove sentence">
          <IconButton fontSize="small" onClick={handleCloseClick}>
            <Close />
          </IconButton>
        </Tooltip>

        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-evenly"
          minHeight="25rem"
          position="relative"
          px={2}
        >
          <Box
            display="flex"
            justifyContent="center"
            fontSize={30}
            fontWeight={400}
            minHeight={100}
          >
            <Box alignSelf="flex-end" textAlign="center">
              <CttContentComponent
                // TODO Re-add setCTttWords when review is compatible with morphs
                setCttWords={() => { }}
                wordsArr={wordsArr}
              />
              <TtsButton sentence={sentenceObj[settings.moduleLang]} />
            </Box>
          </Box>
          <Box display="flex" flexDirection="column" alignItems="center">
            <Box
              minHeight={50}
              fontSize={20}
              fontWeight="fontWeightLight"
              textAlign="center"
              style={isExpanded ? { visibility: 'visible' } : { visibility: 'hidden' }}
            >
              {sentenceObj[settings.translationLang]}
            </Box>
            <Box
              fontSize={12}
              fontWeight="fontWeightLight"
              py={1}
              style={isExpanded ? { visibility: 'visible' } : { visibility: 'hidden' }}
            >
              {`${PHRASES_BY_LANG.translateCorrectly[settings.translationLang]}`}
            </Box>
            {isExpanded ? correctButtons : primaryButtons}
          </Box>
        </Box>
        <Box ml={2} mb={1}>
          <TtsSwitch className={classes.switchButton} autoplayTTS={settings.autoplayTTS} />
        </Box>
      </Card>

      <Dialog
        open={isOpen}
        onClose={() => setOpen(false)}
      >
        <DialogTitle>Permanently remove sentence?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            This will remove the sentence from your weak sentences.  You won&apos;t be
            able to review this sentence anymore.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="primary">Don&apos;t Remove</Button>
          <Button onClick={handleDeleteSentence} color="primary">Remove</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

const useStyle = makeStyles((theme) => ({
  card: {
    [theme.breakpoints.down('xs')]: {
      minHeight: '25rem',
    },
    [theme.breakpoints.up('sm')]: {
      minHeight: '30rem',
    },
    maxWidth: '100%',
    position: 'relative',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  correctButton: {
    background: '#57b44c',
    color: 'white',
    '&:hover': {
      backgroundColor: '#4b9a42',
    },
    margin: 6,
    width: 137,
  },
  incorrectButton: {
    background: '#df5f6a',
    color: 'white',
    '&:hover': {
      backgroundColor: '#d2202f',
    },
    margin: 6,
    width: 137,
  },
  knownButton: {
    background: '#2d8fd5',
    color: 'white',
    '&:hover': {
      backgroundColor: '#006fbe',
    },
    margin: 6,
    width: 137,
  },
  unknowButton: {
    background: '#df5f6a',
    color: 'white',
    '&:hover': {
      backgroundColor: '#d2202f',
    },
  },
  close: {
    position: 'absolute',
    top: 7,
    left: 7,
  },
  showAnswerButton: {
    margin: 6,
    width: 137,
  },
}))

ReviewCardComponent.propTypes = {
  unknownSentences: PropTypes.arrayOf(PropTypes.object),
  setUnknownSentences: PropTypes.func,
  cardInd: PropTypes.number,
  setCardInd: PropTypes.func,
  setIsComplete: PropTypes.func,
  setStats: PropTypes.func,
}
ReviewCardComponent.defaultProps = {
  unknownSentences: [],
  setUnknownSentences: () => { },
  cardInd: 0,
  setCardInd: () => { },
  setIsComplete: () => { },
  setStats: _.noop(),
}

export default ReviewCardComponent
