/* eslint-disable no-param-reassign */
import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Icon,
  IconButton,
  makeStyles,
  Paper,
  Switch,
  useTheme,
} from '@material-ui/core'
import {
  RecordVoiceOver, SurroundSound, Save, Check, HelpOutline,
} from '@material-ui/icons'
// import { loadStripe } from '@stripe/stripe-js'
import { useGlobalStateContext } from '../../contexts'
import { postAccountSettings } from '../../lib/apigateway/settings'
import {
  // AWS_BACKEND_URL,
  PHRASES_BY_LANG,
} from '../../lib/constants'

// const stripePublishableKey = `pk_test_51Hobz8H7Yme0zHMfsEGRp1jEofJk4GFxVFT6jRIjdpCqn6Kyxs1uOyXRTw
// Nlwz0Jlr9RW0BgcoSkByUgGyOvJ1tN00KNpH0TbK`
// const stripePromise = loadStripe(stripePublishableKey)

// <Button onClick={handleStripeClick}>
//   Stripe
// </Button>
// async function handleStripeClick(event) {
//   // Get Stripe.js instance
//   const stripe = await stripePromise

//   // Call your backend to create the Checkout Session
//   const response = await fetch(
//     `http://${AWS_BACKEND_URL}:6402/create-checkout-session`,
//     { method: 'POST', mode: 'cors' },
//   )

//   const session = await response.json()

//   // When the customer clicks on the button, redirect them to Checkout.
//   const result = await stripe.redirectToCheckout({
//     sessionId: session.id,
//   })

//   if (result.error) {
//     // TODO redirect to an error page
//   }
// }

const Settings = () => {
  const { globalState: { settings, user }, setGlobalState } = useGlobalStateContext()
  const [toggled, setToggled] = useState([])
  const [newSettings, setNewSettings] = useState({ ...settings })
  const [success, setSucesss] = useState(false)
  const [dialog, setDialog] = useState({ isOpen: false, desc: '' })

  const classes = useStyles()
  const theme = useTheme()

  useEffect(() => {
    const settingsToggle = []
    _.forEach(settings, (value, key) => {
      if (value === true) {
        settingsToggle.push(key)
      }
    })
    setToggled(settingsToggle)
  }, [settings])

  const handleToggle = (value) => () => {
    const currentIndex = toggled.indexOf(value)
    const newtoggled = [...toggled]
    if (currentIndex === -1) {
      newtoggled.push(value)
      setNewSettings((prev) => {
        prev[value] = true
        return prev
      })
    } else {
      newtoggled.splice(currentIndex, 1)
      setNewSettings((prev) => {
        prev[value] = false
        return prev
      })
    }
    setToggled(newtoggled)
    setSucesss(false)
  }

  const handleSave = async () => {
    await postAccountSettings(user.username, newSettings)
    setGlobalState((prevState) => ({ ...prevState, settings: newSettings }))
    setSucesss(true)
  }

  const handleDialogClick = (icon) => {
    let desc
    if (icon === 'tts') {
      desc = {
        title: '텍스트 음성 자동 재생',
        body: '모든 카드에 텍스트 음성 자동 재생하기',
      }
    } else if (icon === 'audiocard') {
      desc = {
        title: '오디오 카드',
        body: '듣기 연습을 위해 예문이 안 보이게 만들기',
      }
    }
    setDialog({ isOpen: true, desc })
  }

  const handleDialogClose = () => {
    setDialog((prev) => ({ ...prev, isOpen: false }))
  }
  return (
    <>
      <Paper className={classes.root}>
        <Box p={2}>
          <Box mb={2} fontSize={16} color={theme.palette.text.secondary}>{`${PHRASES_BY_LANG.settings[settings.translationLang]}`}</Box>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Box display="flex" alignItems="center" fontSize={17}>
              <Icon className={classes.icon}><RecordVoiceOver /></Icon>
              {`${PHRASES_BY_LANG.autoplayTTS[settings.translationLang]}`}
              <IconButton onClick={() => handleDialogClick('tts')}>
                <HelpOutline />
              </IconButton>
            </Box>
            <Switch
              onChange={handleToggle('autoplayTTS')}
              checked={toggled.indexOf('autoplayTTS') !== -1}
            />
          </Box>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Box display="flex" alignItems="center" fontSize={17}>
              <Icon className={classes.icon}><SurroundSound /></Icon>
              {`${PHRASES_BY_LANG.audioCard[settings.translationLang]}`}
              <IconButton onClick={() => handleDialogClick('audiocard')}>
                <HelpOutline />
              </IconButton>
            </Box>
            <Switch
              onChange={handleToggle('audioCard')}
              checked={toggled.indexOf('audioCard') !== -1}
            />
          </Box>
          <Button className={classes.save} color="secondary" onClick={handleSave} variant="outlined" startIcon={success ? <Check /> : <Save />}>
            {success ? `${PHRASES_BY_LANG.saved[settings.translationLang]}` : `${PHRASES_BY_LANG.save[settings.translationLang]}`}
          </Button>
        </Box>
        <Dialog
          open={dialog.isOpen}
          onClose={handleDialogClose}
        >
          <DialogTitle>
            {`${dialog.desc.title}`}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              {`${dialog.desc.body}`}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDialogClose} color="secondary">
              {`${PHRASES_BY_LANG.close[settings.translationLang]}`}
            </Button>
          </DialogActions>
        </Dialog>
      </Paper>
    </>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 12,
    width: '100%',
    maxWidth: 500,
    margin: '0px auto',
  },
  icon: {
    marginRight: 12,
    color: theme.palette.text.secondary,
  },
  save: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    marginTop: 24,

  },
}))

export default Settings
