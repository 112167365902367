/* eslint-disable consistent-return */
import { API_GATEWAY_URI, DEV_MODE } from '../constants'
import { encodeObject, decodeObject } from '../util/misc'

export const postUserProgress = async (user, moduleId, data) => {
  if (DEV_MODE) return

  const requestUri = `${API_GATEWAY_URI}users/progress`
  const body = JSON.stringify({
    id: user.username, moduleId, data: encodeObject(data),
  })
  const result = await fetch(requestUri, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
    body,
  })
  return result
}

export const getUserProgress = async (user, moduleId) => {
  const requestUri = `${API_GATEWAY_URI}users/progress?id=${user.username}&moduleId=${moduleId}`
  const response = await fetch(requestUri, {
    mode: 'cors',
  })
  const json = await response.json()
  const jsonItem = json.Item

  if (!jsonItem) {
    return null
  }

  return {
    data: decodeObject(jsonItem.data.S),
    id: jsonItem.id.S,
    moduleId: jsonItem['module-id'].S,
  }
}

export const deleteUserProgress = async (user, moduleId) => {
  const requestUri = `${API_GATEWAY_URI}users/progress?id=${user.username}&moduleId=${moduleId}`
  const response = await fetch(requestUri, {
    method: 'DELETE',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
  })
  const json = await response.json()
  return json
}
