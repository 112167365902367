import React from 'react'
import PropTypes from 'prop-types'
import { Box } from '@material-ui/core'
import { Check } from '@material-ui/icons'

// eslint-disable-next-line arrow-body-style
const Day = ({ label, isCheck }) => {
  return isCheck ? (
    <Box
      alignItems="center"
      borderRadius="50%"
      bgcolor="orange.main"
      color="white"
      display="flex"
      justifyContent="center"
      height={35}
      width={35}
    >
      {label}
    </Box>
  ) : (
    <Box
      alignItems="center"
      borderRadius="50%"
      bgcolor="grey.300"
      display="flex"
      justifyContent="center"
      height={35}
      width={35}
      color="text.secondary"
    >
      {label}
    </Box>
  )
}

Day.propTypes = {
  label: PropTypes.string.isRequired,
  isCheck: PropTypes.bool.isRequired,
}

export default Day
